import { UserLanguage } from '@/utils/language.util';
import { capitalize } from '@mui/material';

export type Label = {
    id?: Nullable<number>;
} & LabelTranslation;

export type LabelRequest = Omit<Label, 'id'>;

type LabelTranslation = {
    // we are forced to add optional because of an issue with yup inferred type in reviews
    -readonly [Key in keyof typeof UserLanguage as `translation${Capitalize<Lowercase<Key>>}`]?: Nullable<string>;
};

export const LABEL_TRANSLATION_KEYS = Object.keys(UserLanguage).map(key => `translation${capitalize(key.toLowerCase())}` as keyof LabelTranslation);
