import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { getEmployeeSkills, searchEmployeeSkills } from '@/domain/employee-skill/EmployeeSkill.service';
import { EmployeeSkill, EmployeeSkillSearchRequest } from '@/domain/employee-skill/EmployeeSkill.model';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeSkills = (employeeId?: number): UseQueryResult<EmployeeSkill[]> => {
    const [employeeSkill, setEmployeeSkill] = useState<EmployeeSkill[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeSkills = useCallback(async () => {
        if (!employeeId || isNaN(employeeId)) {
            setIsLoading(false);
            return;
        }
        try {
            const employeeSkillsData = await getEmployeeSkills(employeeId);
            setEmployeeSkill(employeeSkillsData);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [employeeId]);

    useEffect(() => {
        fetchEmployeeSkills().catch(handleError);
    }, [fetchEmployeeSkills]);

    return {
        data: employeeSkill,
        refetch: fetchEmployeeSkills,
        setData: setEmployeeSkill,
        isLoading,
        isError: !!error,
        error,
    };
};

export const useSearchEmployeeSkills = (
    employeeSkillSearchRequest: EmployeeSkillSearchRequest = {},
    { enabled = true }: { enabled?: boolean } = {},
): UseQueryResult<EmployeeSkill[]> => {
    const [employeeSkills, setEmployeeSkills] = useState<EmployeeSkill[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeSkills = useCallback(
        async (request: EmployeeSkillSearchRequest) => {
            if (!enabled) {
                return;
            }
            try {
                const employeeSkills = await searchEmployeeSkills(request);
                setEmployeeSkills(employeeSkills);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeeSkills(employeeSkillSearchRequest).catch(handleError);
    }, [fetchEmployeeSkills, employeeSkillSearchRequest]);

    return {
        data: employeeSkills,
        setData: setEmployeeSkills,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeSkills(employeeSkillSearchRequest),
    };
};
