import { AgGridWrapper, AgGridWrapperColumnType, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { ReportField, ReportFieldNumber, ReportRow, ReportSort } from '@/domain/report/Report.model';
import { getFieldName } from '@/domain/report/Report.service';
import { SectionFieldValue, SectionFieldValueType } from '@/domain/section-setting/Section.model';
import { isSameFieldDefinition } from '@/domain/section-setting/Section.service';
import { ReportColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { formatInDefaultDate, toDate } from '@/utils/datetime.util';

import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { Label } from '@/domain/label/Label.model';
import I18n from '@/i18n/i18n';
import { ReportColumnHeader } from '@/page/report/report-grid/ReportColumnHeader';
import { getLabelTranslation } from '@/utils/language.util';
import { useTheme } from '@mui/material';
import { CellClassParams, CellRendererSelectorResult, ColDef, ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

type ReportGridProps = AgGridWrapperProps<ReportGridRow> & {
    rows: ReportRow[];
    columns: (ReportColumn & { sectionDefinitionName?: Label })[];
    highlightRange: [LocalDate, LocalDate] | [];
    sorts: ReportSort[];
};

export type ReportGridType = AgGridWrapperColumnType;

export type ReportGridRow = Record<string, unknown> & {
    id: string;
    employeeId: number;
    resourceId: number;
};
/**
 * ReportGrid is a wrapper around AgGridWrapper that takes a ReportRows object as input
 * this component extracts the column definitions from the data itself
 */
export const ReportGrid: FC<ReportGridProps> = ({ initRef, rows, columns, highlightRange, sorts, ...rest }) => {
    const theme = useTheme();
    const columnDefs = getColumnDefs(columns, sorts).map(colDef => {
        return {
            ...colDef,

            // this is necessary to display the total row at the bottom of the grid with the correct style and not with a stacked avatar
            cellRendererSelector: (params: ICellRendererParams<ReportGridRow>): CellRendererSelectorResult | undefined => {
                if (params.node.rowPinned) {
                    return {
                        params: {
                            ...params,
                            type: 'number',
                        },
                    };
                }
            },

            cellStyle: ({ value, data }: CellClassParams<ReportGridRow>) => {
                const isNotValidRange = highlightRange.length !== 2 || !value;

                if (isNotValidRange) {
                    return;
                }
                // For each field we have a column with the updatedAt date
                const updatedAt = data?.[`${colDef.field}_UPDATED_AT`] as Date;
                const [from, to] = highlightRange;

                const isDateIncludedInHighlightRange = updatedAt >= toDate(from) && updatedAt <= toDate(to);
                if (isDateIncludedInHighlightRange) {
                    return {
                        backgroundColor: theme.palette.secondary.light,
                    };
                }
            },
        };
    });

    const rowData = getRows(rows);

    return (
        <AgGridWrapper<ReportGridRow>
            columnDefs={columnDefs}
            rowData={rowData}
            {...rest}
            initRef={initRef}
            enableBrowserTooltips={false}
            tooltipShowDelay={0}
            pinnedBottomRowData={getPinBottomRowData(columns, rowData)}
            statusBar={undefined}
            disableAutoSize={true}
        />
    );
};

const getRows = (rows: ReportRow[]): ReportGridRow[] => {
    if (rows) {
        // Table rows are extracted from the rows
        return rows.map(row => {
            const gridRow: ReportGridRow = {
                id: `${row.employeeId}_${row.resourceId}`, // We need a unique id for each row, we use employeeId and resourceId
                employeeId: row.employeeId,
                resourceId: row.resourceId,
            };

            row.fields.forEach(field => {
                gridRow[getFieldName(field.fieldDefinition)] = getFieldValue(field);
                // foreach field we add a column with the updatedAt date for highlight feature and tooltip
                // An other solution could be to handle object for each field
                // { value: field.value, updatedAt: field.updatedAt, sectionId: field.sectionId, fieldId: field.fieldId, ...}
                // and update field value getter in getColumnDefs

                gridRow[getFieldName(field.fieldDefinition) + '_UPDATED_AT'] = field.updatedAt;
            });

            return gridRow;
        });
    }
    return [];
};

const getColumnDefs = (columns: (ReportColumn & { sectionDefinitionName?: Label })[], sorts: ReportSort[]): ColDef<ReportGridRow>[] => {
    const DEFAULT_COLUMN_WIDTH = 200;
    // Columns are sorted with the order property
    const orderedColumns = [...(columns ?? [])]?.sort((a, b) => a.order - b.order);
    return orderedColumns.map(({ sectionDefinitionName, ...column }) => {
        const reportSort = sorts.find(s =>
            isSameFieldDefinition(column.sectionFieldDefinition ?? { fieldType: column.fieldType }, {
                id: s.sectionFieldDefinitionId,
                fieldType: s.fieldType,
            }),
        );
        const getSort = (): 'asc' | 'desc' | undefined => {
            if (reportSort) {
                return reportSort.direction === 'ASC' ? 'asc' : 'desc';
            }
        };

        const sort = getSort();
        const sortIndex = reportSort?.order ?? undefined;

        const sectionDisplayName = getLabelTranslation(sectionDefinitionName);
        const fieldDisplayName = getFieldDefinitionTranslation({
            id: column.sectionFieldDefinition?.id,
            fieldType: column.fieldType,
            name: column.sectionFieldDefinition?.name,
            valueType: column.valueType,
        });

        return {
            headerName: `${sectionDisplayName ? sectionDisplayName + ' / ' : ''}${fieldDisplayName}`,
            headerComponent: ReportColumnHeader,
            headerComponentParams: {
                sectionDisplayName: sectionDisplayName,
                fieldDisplayName: fieldDisplayName,
            },
            field: getFieldName(column),
            // In case of number we dont to use the default column type, to be able to use the valueGetter for export
            type: column.valueType === 'NUMBER' ? undefined : getGridColumnType(column.valueType),
            // we use the valueGetter to force excel export to use the number value
            valueGetter: column.valueType === 'NUMBER' ? ({ data }) => (data?.[getFieldName(column)] as ReportFieldNumber)?.numberValue : undefined,
            hide: !column.visible,
            tooltipValueGetter: params => {
                const updateAt = params.data?.[`${getFieldName(column)}_UPDATED_AT`] as Date;
                return updateAt ? formatInDefaultDate(updateAt) : '';
            },
            // We dont used cellClass to avoid to override the cellClass of the column type
            cellClassRules: {
                'display-flex': () => true,
            },

            sort,
            sortIndex,
            pinned: column.fieldType === 'EMPLOYEE' ? 'left' : undefined,
            width: column.size || DEFAULT_COLUMN_WIDTH, // fallback to default width if size equals 0
        };
    });
};

const getGridColumnType = (reportField: SectionFieldValueType | 'BOOLEAN'): ReportGridType => {
    switch (reportField) {
        case 'LABEL':
            return 'label';
        case 'DOCUMENT':
            return 'documents';
        case 'SECTION_FIELD_DOCUMENT':
            return 'sectionFieldDocuments';
        case 'EMPLOYEE':
            return 'stackedAvatars';
        case 'DATE':
            return 'date';
        case 'BOOLEAN':
            return 'booleanTick';
        default:
            return 'fieldValue';
    }
};

/**
 * Get the display value for a report field
 * @param field
 * @returns
 */
const getFieldValue = (field: ReportField): unknown => {
    if (!field.type) {
        return;
    }

    switch (field.type) {
        case 'EMPLOYEE':
            return field.employeeValues;
        case 'SECTION_FIELD_DOCUMENT':
            return { documents: field.sectionFieldDocumentValues ? field.sectionFieldDocumentValues : [] };
        case 'DOCUMENT':
            // Ag grid wrapper type documents expects an array of documents
            return { documents: field.documentValue ? [field.documentValue] : [] };
        case 'LABEL':
            return field.labelValue;
        case 'DATE':
            return field.dateValue;
        case 'BOOLEAN':
            return field.booleanValue;
        default:
            return mapToSectionFieldValues(field);
    }
};

const mapToSectionFieldValues = (field: ReportField): SectionFieldValue => {
    // This is a hack to fix the valueType of the field (Remove this when the backend is fixed)
    const valueType = field.fieldDefinition?.sectionFieldDefinition?.valueType === 'COUNTRY' ? 'COUNTRY' : field.fieldDefinition.valueType;

    return {
        documents: [],
        customListItemReferences: [],
        employeeReferences: [],
        employeeValues: [],
        ...field,
        sectionFieldDefinition: {
            ...field.fieldDefinition,
            valueType,
        },
    };
};

const getPinBottomRowData = (columns: ReportColumn[], rowData: ReportGridRow[]): ReportGridRow[] => {
    const visibleColumns = columns.filter(({ visible }) => visible);
    if (!visibleColumns.length || !rowData.length) {
        return [];
    }

    const numericColumns = visibleColumns.filter(({ valueType }) => valueType === 'NUMBER');

    const totalLabel = `${rowData.length} ${I18n.t('general.employee', { count: rowData.length }).toLowerCase()}`;
    // We use the display name to show the total because the column is an employeeAvatar type
    // TODO: find a better way to show the total
    const initialRowData = { ['EMPLOYEE']: [{ displayName: totalLabel }] };

    // This function calculates totals for numeric fields and returns a custom "total" row.
    // We're bypassing the standard row renderer by manually creating the row structure.
    // Since we are not using the same renderer, it's acceptable that this row doesn't fully conform to the row structure.
    return [
        {
            id: 'total',
            employeeId: -1,
            resourceId: -1,
            ...calculateTotalRowData(numericColumns, rowData, initialRowData),
        },
    ];
};

const calculateTotalRowData = (numericColumns: ReportColumn[], rowData: ReportGridRow[], initialRowData: Record<string, unknown>): Record<string, unknown> => {
    return numericColumns.reduce((acc, column) => {
        const fieldName = getFieldName(column);
        const fieldValues = rowData.map(row => (row?.[fieldName] as SectionFieldValue)?.numberValue ?? 0);
        const total = fieldValues.reduce((sum, value) => (value ? (sum ?? 0) + value : sum), 0);
        const firstRowValue = rowData[0][fieldName] || {};

        // Fix the total to 2 decimal places and remove non significant zeros
        const formattedTotal = Number(total.toFixed(2));

        return {
            ...acc,
            [fieldName]: { ...firstRowValue, numberValue: formattedTotal },
        };
    }, initialRowData);
};
