import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { DepartmentNode } from '@/domain/department/Department.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { CostCenterAssignmentListField } from '@/page/cost-center/CostCenterAssignmentListField';
import {
    DepartmentFormValues,
    DepartmentManagerFormValue,
    getDepartmentFormSchema,
} from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';
import { UserLanguage } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RecursiveValue } from '@/components/tree-select/TreeSelect';
import { mapDepartmentNodeToRecursiveValue } from '@/domain/department/Department.utils';
import { FieldTreeSelect } from '@/components/form/field-tree-select/FieldTreeSelect';
import { findRecursiveItemParent } from '@/utils/object.util';

type DepartmentDialogProps = DialogWrapperProps & {
    defaultDepartment?: DepartmentNode;
    defaultParent?: DepartmentNode;
    departments: DepartmentNode[];
    translationLanguage: UserLanguage;
    onSubmitDepartment: (department: DepartmentFormValues) => void;
};

export const DepartmentDialog: FC<DepartmentDialogProps> = props => {
    const { defaultDepartment, departments, defaultParent, translationLanguage, onSubmitDepartment, ...restDialogProps } = props;
    const { t } = useTranslation();
    const { data: allEmployees = [] } = useGetEmployees({ statuses: [EmploymentStatus.HIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.ON_LONG_LEAVE] });

    const departmentTreeItems = removeArchived(departments)
        .map(dep => mapDepartmentNodeToRecursiveValue(dep, translationLanguage, true))
        // disable the department provided to avoid choosing it and its children as parent
        .map(dep => disableItem(dep, currentDep => currentDep.id === defaultDepartment?.id));

    const parentDepartmentToEdit = defaultDepartment ? findRecursiveItemParent(departmentTreeItems, defaultDepartment.id) : undefined;
    const defaultValues: Partial<DepartmentFormValues> = {
        // defaultParent is used to create a sub department
        parent: mapDepartmentNodeToRecursiveValue(defaultParent) ?? parentDepartmentToEdit,
        name: defaultDepartment?.name,
        managers: defaultDepartment?.managers.map(e => mapEmployeeToDepartmentManagerOption(e)) ?? [],
        departmentCostCenters: defaultDepartment?.departmentCostCenters ?? [],
    };
    const formMethods = useForm<DepartmentFormValues>({
        resolver: yupResolver(getDepartmentFormSchema(translationLanguage)),
        defaultValues,
    });
    const { control, handleSubmit } = formMethods;

    return (
        <DialogWrapper {...restDialogProps} header={t('settings_organization.departments.add_dialog.title')}>
            <DialogContent>
                <Stack gap={2}>
                    <FieldLabel
                        name={'name'}
                        control={control}
                        fullWidth
                        autoFocus
                        label={t('settings_organization.departments.add_dialog.name_field')}
                        language={translationLanguage}
                    />

                    <FormControlLabel
                        label={t('settings_organization.departments.parent_field')}
                        control={<FieldTreeSelect name={'parent'} control={control} itemsData={departmentTreeItems} fullWidth={true} />}
                    />

                    <FormControlLabel
                        sx={{ width: '100%' }}
                        label={t('settings_organization.departments.add_dialog.managers_field')}
                        control={
                            <FieldSelect
                                name={'managers'}
                                control={control}
                                multiple
                                fullWidth
                                options={allEmployees.map(e => mapEmployeeToDepartmentManagerOption(e))}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={option => option.displayName}
                                getOptionKey={option => option.id}
                                autocompleteProps={{ disableCloseOnSelect: true }}
                            />
                        }
                    />
                    <FormProvider {...formMethods}>
                        <CostCenterAssignmentListField fieldName={'departmentCostCenters'} />
                    </FormProvider>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button fullWidth onClick={() => handleSubmit(onSubmitDepartment, console.error)()}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const mapEmployeeToDepartmentManagerOption = (employee: EmployeeAvatar): DepartmentManagerFormValue => ({
    id: employee.id,
    displayName: employee.displayName,
});

const disableItem = (departmentTreeItem: RecursiveValue, disablePredicate: (dep: RecursiveValue) => boolean): RecursiveValue => {
    return {
        ...departmentTreeItem,
        disabled: departmentTreeItem.disabled || disablePredicate(departmentTreeItem),
        children: departmentTreeItem.children.map(child => disableItem(child, disablePredicate)),
    };
};

const removeArchived = (departmentNodes: DepartmentNode[]): DepartmentNode[] => {
    return departmentNodes.reduce<DepartmentNode[]>((acc, department) => {
        if (department.archived) {
            return acc;
        }
        return [...acc, { ...department, children: removeArchived(department.children) }];
    }, []);
};
