import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JobLevel, JobLevelCreationMutation } from '@/domain/job-level/JobLevel.model';
import { createJobLevel, deleteJobLevel, updateJobLevel } from '@/domain/job-level/JobLevel.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { useGetJobLevels } from '@/hooks/job-level/JobLevel.hook';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { JobLevelSettingsDialog } from '@/page/setting/job-level/job-level-dialog/JobLevelSettingsDialog';
import { ConfigType } from '../types';

export const JobLevelSettingsPage: FC = () => {
    const { t } = useTranslation();

    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [jobLevelToEdit, setJobLevelToEdit] = useState<JobLevel>();

    const { data: jobLevels = [], isLoading, isError, error: errorJobLevels, refetch: refetchJobLevels } = useGetJobLevels();

    const hasJobFamiliesFeature = useRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES);

    if (!hasJobFamiliesFeature) {
        return;
    }

    const handleDelete = async (id: number) => {
        try {
            await deleteJobLevel(id);
            await refetchJobLevels();
        } catch (e) {
            handleError(e);
        }
    };

    const handleOnClose = () => {
        setOpenCreateDialog(false);
        setJobLevelToEdit(undefined);
    };

    const handleOnSave = async (data: JobLevelCreationMutation) => {
        try {
            if (jobLevelToEdit?.id) {
                await updateJobLevel(jobLevelToEdit.id, data);
            } else {
                await createJobLevel(data);
            }
            await refetchJobLevels();
            handleOnClose();
        } catch (e) {
            handleError(e);
        }
    };

    const getMenuItems = (params: ICellRendererParams<JobLevel>) => {
        return [
            {
                title: t('general.edit'),
                onClick: () => {
                    setJobLevelToEdit(params.data);
                },
            },
            {
                title: t('general.delete'),
                onClick: () => params.data?.id && handleDelete(params.data.id),
            },
        ];
    };
    const cellActionRenderer = (params: ICellRendererParams<JobLevel>) => {
        return <BasicMenu items={getMenuItems(params)} />;
    };

    const config: ConfigType<JobLevel> = {
        type: 'table',
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setOpenCreateDialog(true);
            },
        },
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<JobLevel>) => {
                    setJobLevelToEdit(params.data);
                },
            },
            columns: [
                {
                    field: 'name',
                    type: 'label',
                    headerName: t('job_level_setting_page.table_headers.name'),
                    width: 300,
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={errorJobLevels}>
            <CompanySettingsLayout options={config} data={jobLevels} />
            {openCreateDialog && <JobLevelSettingsDialog open={openCreateDialog} onSave={handleOnSave} onClose={handleOnClose} jobLevel={undefined} />}
            {jobLevelToEdit && <JobLevelSettingsDialog open={!!jobLevelToEdit} onSave={handleOnSave} onClose={handleOnClose} jobLevel={jobLevelToEdit} />}
        </StateHandler>
    );
};
