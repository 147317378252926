import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Calendar, CalendarDayType } from '@/domain/calendar/Calendar.model';
import { createCalendar, deleteCalendar } from '@/domain/calendar/Calendar.service';
import { useGetCalendars } from '@/hooks/calendar/Calendar.hook';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ConfigType } from '../types';
import { ImportCalendar } from './import-calendar/ImportCalendar';

export const CalendarsSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [isImportCalendarDialogOpen, setIsImportCalendarDialogOpen] = useState<boolean>(false);

    const { data: calendars, isLoading, isError, error: errorCalendars, refetch: refetchCalendars } = useGetCalendars();

    const handleDelete = async (params: ICellRendererParams<Calendar>) => {
        if (!params.data?.id) {
            return;
        }
        try {
            await deleteCalendar(params.data.id);
            await refetchCalendars();
        } catch (e) {
            handleError(e);
        }
    };

    const getMoreButton = (params: ICellRendererParams<Calendar>) => {
        const menuItems: BasicMenuItem[] = [
            {
                title: t('general.delete'),
                onClick: () => {
                    handleDelete(params);
                },
            },
        ];

        return <BasicMenu items={menuItems} />;
    };

    const getTotalHolidays = (calendar: Calendar | undefined): number => {
        if (!calendar) {
            return 0;
        }
        return calendar?.days?.filter(day => day.dayType === CalendarDayType.HOLIDAY).length ?? 0;
    };

    const config: ConfigType<Calendar> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('calendars_settings_page.calendar')}`,
            secondaryButtonCaption: t('calendars_settings_page.import_calendar'),
            secondaryButtonAction: () => setIsImportCalendarDialogOpen(true),
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'name',
                    headerName: t('calendars_settings_page.table_headers.calendar_name'),
                },
                {
                    field: 'days',
                    headerName: t('calendars_settings_page.table_headers.total_holidays'),
                    cellRenderer: (row: ICellRendererParams<Calendar>) => {
                        return getTotalHolidays(row.data).toString();
                    },
                    valueFormatter: ({ data }) => {
                        return getTotalHolidays(data).toString();
                    },
                },
                {
                    type: 'actionMenu',
                    cellRenderer: getMoreButton,
                },
            ],
        },
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={errorCalendars}>
            <CompanySettingsLayout options={config} data={calendars} />

            {isImportCalendarDialogOpen && (
                <ImportCalendar
                    open={isImportCalendarDialogOpen}
                    onClose={() => {
                        setIsImportCalendarDialogOpen(false);
                    }}
                    onSave={data => {
                        createCalendar({ ...data, days: [] }).then(async () => {
                            await refetchCalendars();
                            setIsImportCalendarDialogOpen(false);
                        });
                    }}
                />
            )}
        </StateHandler>
    );
};
