import { Select } from '@/components/form/field-select/Select';
import { FullScreenProgress } from '@/components/full-screen-progress/FullScreenProgress';
import { TotalPieChart } from '@/components/chart/TotalPieChart';
import {
    AnswerResult,
    getSurveyCategoryTranslationKey,
    SURVEY_QUESTION_CATEGORIES,
    SurveyQuestion,
    SurveyQuestionCategory,
    SurveyResultFilterType,
    SurveyResults,
} from '@/domain/survey/Survey.model';
import {
    COLORS_SCHEME,
    getResults,
    isLickertScale,
    isNPS,
    isOpinionScale,
    isRenderAsMultipleChoice,
    isRenderAsSection,
    isRenderAsSingleChoice,
    isRenderAsText,
} from '@/domain/survey/Survey.service';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { calculatePercentage } from '@/utils/math.util';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Paper,
    Select as SelectMui,
    SelectChangeEvent,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { InformationCircleIcon, ViewIcon, ViewOffIcon } from 'hugeicons-react';
import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ChoiceResult } from './survey-results/ChoiceResult';
import { ScaleResult } from './survey-results/ScaleResult';
import { SurveyCategories } from './survey-results/SurveyCategories';
import { TextResults } from './survey-results/TextResults';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

interface CategoriesChartDataList {
    x: string;
    y: number;
}

const GROUPS = [
    SurveyResultFilterType.ALL,
    SurveyResultFilterType.AGE,
    SurveyResultFilterType.GENDER,
    SurveyResultFilterType.DEPARTMENT,
    SurveyResultFilterType.LOCATION,
];

type SurveyResultsPageParams = {
    surveyId: string;
};

export const SurveyResultsPage: FC = () => {
    const { surveyId } = useParams<SurveyResultsPageParams>();
    const { t } = useTranslation();
    const [groupBy, setGroupBy] = useState<SurveyResultFilterType>(SurveyResultFilterType.ALL);
    const [surveyResults, setSurveyResults] = useState<SurveyResults>();
    const [activeTab, setActiveTab] = useState<number>(SURVEY_RESULT_SUMMARY_TAB);
    const [activeCategories, setActiveCategories] = useState<AnswerResult[]>();
    const [activeCategory, setActiveCategory] = useState<SurveyQuestionCategory>();
    const [filtersByGroup, setFiltersByGroup] = useState<string[]>();
    const [activeGroupByFilters, setActiveGroupByFilters] = useState<string[]>([]);
    const [annotationNote, setAnnotationNote] = useState<string>();
    const [tableCellId, setTableCellId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [displayMessages, setDisplayMessages] = useLocalStorage<boolean>('Survey.display_comments', true);

    // if there is only one category, set it as active or if all categories are other (meaning, no categories), set other as active
    useEffect(() => {
        const isOtherCategory = !!surveyResults?.answerResults.some(result => !result.question.category);
        const isEveryResultAreOther = !!surveyResults?.answerResults.every(result => !result.question.category);
        const isEveryResultAreSameCategory = !!surveyResults?.answerResults.every(
            result => result.question.category === surveyResults.answerResults[0].question.category,
        );

        if (isEveryResultAreSameCategory && !isOtherCategory) {
            setActiveCategory(surveyResults?.answerResults[0].question.category);
        }

        if (isEveryResultAreOther) {
            setActiveCategory(SurveyQuestionCategory.OTHER);
        }
    }, [surveyResults, activeTab]);

    useEffect(() => {
        setLoading(true);
        const doesCategoriesMatched = (value: AnswerResult, index: number, self: AnswerResult[]) =>
            index === self.findIndex(ar => ar.question.category === value.question.category);
        getResults(Number(surveyId))
            .then(data => {
                setSurveyResults(data);
                const categories = data.answerResults.filter(doesCategoriesMatched);
                setActiveCategories(categories);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [surveyId]);

    const renderHeatMapChart = useCallback(() => {
        const comparedCategoriesWithParticipations =
            surveyResults?.categories[groupBy].filter(category =>
                surveyResults?.participations[groupBy].some(participation => category.key === participation.key),
            ) ?? [];
        const otherGroupRemovedFromCategories =
            comparedCategoriesWithParticipations.filter(item => {
                return (
                    item.key !== 'OTHER' &&
                    (!activeGroupByFilters?.length || activeGroupByFilters.includes(item.key) || activeGroupByFilters.includes(getLabelTranslation(item.label)))
                );
            }) ?? [];

        return otherGroupRemovedFromCategories.map(group => {
            const listOfCategories: CategoriesChartDataList[] = [];

            const newCategory = {
                id: getLabelTranslation(group.label),
                data: listOfCategories,
            };
            SURVEY_QUESTION_CATEGORIES.forEach(category => {
                if (Object.prototype.hasOwnProperty.call(group.categoryAverageScore, category)) {
                    const categoryValue = {
                        x: category,
                        y: group.categoryAverageScore[category],
                        yColor: 'red',
                    };
                    newCategory.data.push(categoryValue);
                }
            });
            return newCategory;
        });
    }, [activeGroupByFilters, groupBy, surveyResults?.categories, surveyResults?.participations]);

    // TODO use custom hook, loading state, error state
    if (!surveyResults) {
        return;
    }

    const changeTab = (_event: SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        setActiveCategory(undefined);
    };
    const onSelectGroupBy = (event: SelectChangeEvent<SurveyResultFilterType>) => {
        setGroupBy(event.target.value as SurveyResultFilterType);
        setActiveGroupByFilters([]);
        if (event.target.value !== SurveyResultFilterType.ALL) {
            if (surveyResults.answerResults?.length) {
                const groupByFilters = surveyResults.participations[event.target.value as SurveyResultFilterType]
                    .map(question => question.label.translationEn ?? '')
                    .filter(t => t !== '');
                setFiltersByGroup(groupByFilters);
            }
        } else {
            setFiltersByGroup(undefined);
        }
    };

    const showGroupByTranslationKey = (group: SurveyResultFilterType) => {
        switch (group) {
            case SurveyResultFilterType.ALL:
                return t('survey_results.group_by_all');
            case SurveyResultFilterType.LOCATION:
                return t('survey_results.location');
            case SurveyResultFilterType.GENDER:
                return t('survey_results.gender');
            case SurveyResultFilterType.DEPARTMENT:
                return t('survey_results.department');
            case SurveyResultFilterType.AGE:
                return t('survey_results.age');
            default:
                break;
        }
    };

    const resultTab = (index: number) => {
        return {
            id: `results-tab-${index}`,
            'aria-controls': `results-tabpanell-${index}`,
        };
    };

    const renderQuestions = (questions: SurveyQuestion[], activeCategory: SurveyQuestionCategory | undefined) => {
        if (questions?.length) {
            if (activeCategory) {
                if (activeCategory === SurveyQuestionCategory.OTHER) {
                    return questions.filter(q => !q.category);
                }

                return questions.filter(q => q.category === activeCategory);
            } else {
                return questions.sort((a: SurveyQuestion, b: SurveyQuestion) => {
                    return a.order - b.order;
                });
            }
        } else {
            return [];
        }
    };

    const renderAnswerResult = (question: SurveyQuestion, answerResults: AnswerResult[]) => {
        const answer = answerResults?.find(a => a.question.id === question.id) as AnswerResult;

        if (isRenderAsText(question.type)) {
            return (
                <TextResults
                    title={question.question}
                    activeGroupByFilters={activeGroupByFilters}
                    answer={answer}
                    groupBy={groupBy}
                    displayMessage={displayMessages}
                />
            );
        } else if (isRenderAsSection(question.type)) {
            return <Typography variant='h2'>{getLabelTranslation(question.question)}</Typography>;
        } else if (isLickertScale(question.type) || isOpinionScale(question.type) || isNPS(question.type)) {
            return <ScaleResult title={question.question} activeGroupByFilters={activeGroupByFilters} groupBy={groupBy} answer={answer} />;
        } else if (isRenderAsSingleChoice(question.type)) {
            return <ChoiceResult activeGroupByFilters={activeGroupByFilters} answer={answer} groupBy={groupBy} title={question.question} />;
        } else if (isRenderAsMultipleChoice(question.type)) {
            return <ChoiceResult activeGroupByFilters={activeGroupByFilters} answer={answer} groupBy={groupBy} title={question.question} />;
        } else {
            return undefined;
        }
    };

    const setCategory = (category: SurveyQuestionCategory | undefined) => {
        setActiveCategory(category);
    };

    if (loading) {
        return <FullScreenProgress />;
    }

    const totalHeatMapHeight = () => {
        const CELL_HEIGHT = 44;
        const SPACE_AROUND_CHART = 125;
        return renderHeatMapChart() ? renderHeatMapChart().filter(item => item.data.length).length * CELL_HEIGHT + SPACE_AROUND_CHART : 0;
    };

    if (!surveyResults) {
        return <></>;
    }
    const otherBar = {
        width: 'calc(100% - 16px)',
        height: 24,
        borderRadius: 4,
        backgroundColor: '#F8F8F7',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'rgba(1, 21, 60, 0.5)',
        },
    };

    const groupKeyTotal = {
        width: 160,
        textAlign: 'right',
        marginRight: 1,
        display: '-webkit-box',
        overflow: 'hidden',
        boxSizing: 'border-box',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
    };

    const progressBar = {
        width: 'calc(100% - 16px)',
        height: 31,
        borderRadius: 4,
        backgroundColor: '#F8F8F7',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'rgba(1, 205, 205, 0.5)',
        },
    };

    const totalPieChartDescription = {
        fontStyle: 'italic',
        marginTop: 0.5,
        paddingRight: 3,
    };

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Paper elevation={1} sx={{ p: 3 }}>
                    <Grid container direction={'row'} sx={{ flexWrap: 'inherit' }} spacing={4} justifyContent={'space-between'}>
                        <Grid item xs={6}>
                            <Typography variant='h2'>{getLabelTranslation(surveyResults.survey.name)}</Typography>
                            <Typography variant='body1'>{getLabelTranslation(surveyResults.survey.description)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid
                item
                sx={{
                    '& #results-tabpanel-1': {
                        padding: 3,
                        paddingTop: 0,
                    },
                    '& #results-tabpanel-2': {
                        padding: 3,
                        paddingTop: 0,
                    },
                }}
            >
                <Paper elevation={1}>
                    <Tabs value={activeTab} centered onChange={changeTab} sx={{ paddingTop: 2 }} aria-label='simple tabs example' scrollButtons>
                        <Tab label={`${t('survey_results.summary')}`} {...resultTab(SURVEY_RESULT_SUMMARY_TAB)} />
                        <Tab label={`${t('survey_results.all_answers')}`} {...resultTab(SURVEY_RESULT_ANSWERS_TAB)} />
                        <Tab label={`${t('survey_results.participation')}`} {...resultTab(SURVEY_RESULT_PARTICIPATION_TAB)} />
                    </Tabs>
                    <Grid
                        container
                        direction={'row'}
                        sx={{
                            flexFlow: 'inherit',
                            marginBottom: 3,
                            padding: 2,
                        }}
                        alignItems='center'
                    >
                        <Grid
                            item
                            sx={{
                                padding: 1,
                                paddingBottom: 0,
                            }}
                        >
                            <FormControl>
                                <SelectMui labelId='demo-customized-select-label' id='demo-customized-select' value={groupBy} onChange={onSelectGroupBy}>
                                    {GROUPS.map((group: SurveyResultFilterType) => (
                                        <MenuItem key={group} value={group}>
                                            {showGroupByTranslationKey(group)}
                                        </MenuItem>
                                    ))}
                                </SelectMui>
                            </FormControl>
                        </Grid>
                        {filtersByGroup &&
                            // We don't want to apply filters when tab is participation
                            activeTab !== SURVEY_RESULT_PARTICIPATION_TAB && (
                                <Grid
                                    item
                                    sx={{
                                        p: 1,
                                        pb: 0,
                                    }}
                                >
                                    <Select
                                        multiple
                                        options={filtersByGroup}
                                        value={activeGroupByFilters}
                                        autocompleteProps={{
                                            style: { width: 300 },
                                        }}
                                        fullWidth
                                        getOptionLabel={option => option}
                                        onChange={value => {
                                            setActiveGroupByFilters(value);
                                        }}
                                        placeholder={`Select ${showGroupByTranslationKey(groupBy)}`}
                                    />
                                </Grid>
                            )}

                        <Grid item flex='1' justifyContent='flex-end' display='flex' alignItems='center'>
                            <Typography>{displayMessages ? t('survey_results.hide_comments') : t('survey_results.display_comments')}</Typography>
                            <IconButton onClick={() => setDisplayMessages(!displayMessages)} size='large'>
                                {displayMessages ? <ViewIcon /> : <ViewOffIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>

                    <ResultTabPanel value={activeTab} index={SURVEY_RESULT_PARTICIPATION_TAB}>
                        {groupBy !== SurveyResultFilterType.ALL ? (
                            <>
                                <Grid item>
                                    <Typography variant='body1bold'>{t('survey_results.participation')}</Typography>
                                </Grid>
                                <Box mt={1} width={'100%'}>
                                    <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item xs>
                                            <LinearProgress
                                                variant='determinate'
                                                sx={progressBar}
                                                value={calculatePercentage(
                                                    surveyResults.participations.ALL[0].participated,
                                                    surveyResults.participations.ALL[0].invited,
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1bold'>
                                                {surveyResults.participations.ALL[0].participated} /
                                                <span style={{ fontWeight: 400 }}> {surveyResults.participations.ALL[0].invited}</span>{' '}
                                                {calculatePercentage(
                                                    surveyResults.participations.ALL[0].participated,
                                                    surveyResults.participations.ALL[0].invited,
                                                )}
                                                %
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {surveyResults.participations[groupBy]?.map((participation, index) =>
                                        participation.key === 'OTHER' ? (
                                            <Grid
                                                container
                                                direction={'row'}
                                                key={index}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                sx={{ marginTop: 1 }}
                                            >
                                                <Grid item sx={groupKeyTotal}>
                                                    <Typography
                                                        variant='body2'
                                                        color='textPrimary'
                                                        sx={{
                                                            '& svg': {
                                                                verticalAlign: 'middle',
                                                                marginLeft: 0.5,
                                                            },
                                                        }}
                                                    >
                                                        {getLabelTranslation(participation.label)}
                                                        <Tooltip title={t('survey_results.less_5_answers')}>
                                                            <InformationCircleIcon />
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs>
                                                    <LinearProgress
                                                        variant='determinate'
                                                        sx={otherBar}
                                                        value={calculatePercentage(participation.participated, participation.invited)}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body1bold'>
                                                        {participation.participated} /<span style={{ fontWeight: 400 }}> {participation.invited}</span>{' '}
                                                        {calculatePercentage(participation.participated, participation.invited)}%
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                direction={'row'}
                                                key={index}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                sx={{ marginTop: 1 }}
                                            >
                                                <Grid item sx={groupKeyTotal}>
                                                    <Typography variant='body2' color='textPrimary'>
                                                        {getLabelTranslation(participation.label)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs>
                                                    <Grid sx={otherBar}>
                                                        <Box
                                                            sx={otherBar}
                                                            style={{
                                                                width: `${calculatePercentage(participation.participated, participation.invited)}%`,
                                                                background: `${COLORS_SCHEME[index < COLORS_SCHEME.length ? index : 0].color}`,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body1bold'>
                                                        {participation.participated} /<span style={{ fontWeight: 400 }}> {participation.invited}</span>{' '}
                                                        {calculatePercentage(participation.participated, participation.invited)}%
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ),
                                    )}
                                </Box>
                            </>
                        ) : (
                            <Grid container direction={'row'}>
                                <Grid item xs={12} sm={6}>
                                    <TotalPieChart
                                        data={{
                                            title: t('survey_results.participation'),
                                            average: {
                                                title: t('survey_results.participated'),
                                                value: surveyResults.participations.ALL[0].participated,
                                            },
                                            total: {
                                                title: t('survey_results.not_participated'),
                                                value: surveyResults.participations.ALL[0].invited,
                                            },
                                        }}
                                    />
                                    <Typography variant='body2' sx={{ totalPieChartDescription }}>
                                        {t('survey_results.this_indicate_how_many_people')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </ResultTabPanel>
                    {activeTab === SURVEY_RESULT_SUMMARY_TAB && (
                        <Grid container direction={'column'} spacing={2}>
                            {groupBy !== SurveyResultFilterType.ALL ? (
                                <Box
                                    style={{
                                        width: '99%',
                                        height: totalHeatMapHeight(),
                                        margin: 'auto',
                                        minWidth: 0,
                                    }}
                                    sx={{
                                        '& div': {
                                            fontFamily: 'Inter !important',
                                        },
                                    }}
                                >
                                    <ResponsiveHeatMap
                                        data={renderHeatMapChart() ? renderHeatMapChart().filter(item => item.data.length) : []}
                                        margin={{ top: 60, right: 30, bottom: 60, left: 208 }}
                                        valueFormat='>-.2s'
                                        xInnerPadding={0.05}
                                        theme={{
                                            text: {
                                                fill: '#585869',
                                                fontSize: 14,
                                            },
                                        }}
                                        yInnerPadding={0.2}
                                        legends={[]}
                                        onClick={cell => {
                                            const surveyQuestionCat = SURVEY_QUESTION_CATEGORIES.find(category => category === cell.data.x);
                                            setTableCellId(cell.id);
                                            setAnnotationNote(`${cell.serieId} in ${t(getSurveyCategoryTranslationKey(surveyQuestionCat))}`);
                                            setActiveGroupByFilters([cell.serieId]);
                                            setCategory(surveyQuestionCat);
                                        }}
                                        axisTop={{
                                            format: v => {
                                                return t(getSurveyCategoryTranslationKey(v));
                                            },
                                            tickSize: 0,
                                            tickPadding: 8,
                                            tickRotation: 0,
                                            legend: '',
                                            legendOffset: -60,
                                        }}
                                        axisLeft={{
                                            truncateTickAt: 22,
                                            tickSize: 0,
                                            tickPadding: 8,
                                            tickRotation: 0,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: -60,
                                        }}
                                        colors={{
                                            type: 'diverging',
                                            scheme: 'red_yellow_green',
                                            divergeAt: 0.5,
                                            minValue: 0,
                                            maxValue: 10,
                                        }}
                                        annotations={[
                                            {
                                                type: 'rect',
                                                match: {
                                                    id: tableCellId,
                                                },
                                                note: annotationNote ?? '',
                                                noteX: -5,
                                                noteY: -45,
                                                offset: 3,
                                                noteTextOffset: 5,
                                                borderRadius: 2,
                                            },
                                        ]}
                                        emptyColor='#555555'
                                        borderRadius={5}
                                    />
                                </Box>
                            ) : (
                                <SurveyCategories
                                    results={surveyResults}
                                    groupBy={groupBy}
                                    activeCategory={activeCategory}
                                    categories={activeCategories ?? []}
                                    setCategory={setCategory}
                                />
                            )}
                        </Grid>
                    )}
                </Paper>
            </Grid>
            <Grid item>
                {(activeTab === SURVEY_RESULT_ANSWERS_TAB || (activeTab === SURVEY_RESULT_SUMMARY_TAB && activeCategory)) && (
                    <Grid container direction={'column'} spacing={2} sx={{ flexWrap: 'inherit' }}>
                        {!!surveyResults.survey?.questions?.length &&
                            renderQuestions(surveyResults.survey?.questions, activeCategory).map(question => (
                                <Grid item key={question.id}>
                                    {renderAnswerResult(question, surveyResults.answerResults)}
                                </Grid>
                            ))}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

const ResultTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role='tabpanel' hidden={value !== index} id={`results-tabpanel-${index}`} aria-labelledby={`results-tab-${index}`} {...other}>
            {value === index && (
                <Box p={0}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const SURVEY_RESULT_SUMMARY_TAB = 0;
const SURVEY_RESULT_ANSWERS_TAB = 1;
const SURVEY_RESULT_PARTICIPATION_TAB = 2;
