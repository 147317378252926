import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { getLocations } from '@/domain/location/Location.service';
import { useTranslation } from 'react-i18next';
import { getDepartmentNodes } from '@/domain/department/Department.service';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeFilterType } from '@/domain/employee/Employee.service';
import { AsyncSelectFilter, AsyncTreeSelectFilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.utils';

export type EmployeeReviewFilter = SelectFilter | AsyncSelectFilter | AsyncTreeSelectFilterType;
/**
 * Hook to get the list of filter for employee review
 */
export const useEmployeeReviewFilters = (): { filters: EmployeeReviewFilter[] } => {
    const { t } = useTranslation();

    const options = Object.values(EmployeeReviewStatus).map(status => ({
        label: t('reviews.employee_review.status', { context: status }),
        value: status,
    }));

    const filters: EmployeeReviewFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'tree-multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departmentsNodes = await getDepartmentNodes();
                return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE_REVIEW_STATUS' }),
            type: 'multi-select',
            key: 'status',
            selectMode: 'SYNC',
            options: options,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
