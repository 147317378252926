import { Grid, Paper, Typography, useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FC } from 'react';

import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import Slide from '@mui/material/Slide';
import { SurveyAnswerForm, SurveyQuestionForm } from '@/page/survey/SurveyForm.util';

type Props = {
    onSelect: (value: string) => void;
    question: SurveyQuestionForm;
    answer: SurveyAnswerForm;
    selectedLanguage: UserLanguage;
};

export const SurveyAnswerSingleChoice: FC<Props> = ({ onSelect, question, answer, selectedLanguage }) => {
    getLabelTranslation(question.question, selectedLanguage);
    getLabelTranslation(question.question, selectedLanguage);
    const onChangeSingleChoice = (option: string) => {
        onSelect(option);
    };

    const isSingleChoiceSelected = (option: string) => {
        if (!question && !answer) {
            return true;
        }
        return answer?.items?.some(item => item.localId === option);
    };

    const theme = useTheme();

    const radioSelectStyle = {
        '& .selectOptionBox': {
            width: '20%!important',
            [theme.breakpoints.down('lg')]: {
                width: '100%!important',
            },
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
            textAlign: 'center',
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
            '& .MuiFormControlLabel-root': {
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: 1,
                width: '100%',
                boxSizing: 'border-box',
                padding: theme.spacing(2),
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(2),
                },
                '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                    fontSize: 14,
                },
            },
            '&:hover': {
                '& .MuiFormControlLabel-root': {
                    background: `${theme.palette.primary.light}`,
                    border: `1px solid ${theme.palette.primary.main}`,
                    '& .MuiFormControlLabel-label': {
                        color: theme.palette.primary.main,
                    },
                },
            },
            '&.active': {
                '& .MuiFormControlLabel-root': {
                    background: `${theme.palette.primary.light}`,
                    border: `1px solid ${theme.palette.primary.main}`,
                    '& .MuiFormControlLabel-label': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },

        '& .MuiFormControlLabel-root': {
            margin: 0,
            padding: theme.spacing(1),
            '& .MuiButtonBase-root': {
                opacity: 0,
                position: 'absolute',
            },
        },
    };

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Paper
                sx={{
                    padding: 6,
                    maxWidth: 960,
                    margin: 'auto',
                }}
            >
                <Grid item>
                    <Typography
                        variant='h1'
                        sx={{
                            marginBottom: theme.spacing(3),
                            display: 'block',
                            textAlign: 'center',
                            [theme.breakpoints.down('md')]: {
                                fontSize: 18,
                            },
                        }}
                    >
                        {getLabelTranslation(question.question, selectedLanguage)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction='row' sx={radioSelectStyle} justifyContent={'center'}>
                        {question.items?.map(item => (
                            <Grid key={item.localId} item className={`${isSingleChoiceSelected(item.localId) ? 'active' : ''} selectOptionBox`}>
                                <FormControlLabel
                                    value={item.localId}
                                    control={
                                        <Radio
                                            checked={isSingleChoiceSelected(item.localId)}
                                            onChange={() => onChangeSingleChoice(item.localId)}
                                            value={item.localId}
                                            name='radio-button-demo'
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    }
                                    label={
                                        <>
                                            {item.imageUrl && (
                                                <img
                                                    src={item.imageUrl}
                                                    key={item.localId}
                                                    alt={'Icon'}
                                                    style={{
                                                        display: 'block',
                                                        margin: 'auto',
                                                        width: 'auto',
                                                        marginBottom: 2,
                                                        height: 108,
                                                        objectFit: 'contain',
                                                        maxWidth: '100%',
                                                    }}
                                                    width='40px'
                                                    height='auto'
                                                />
                                            )}
                                            {getLabelTranslation(item.label, selectedLanguage)}
                                        </>
                                    }
                                    labelPlacement='end'
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Slide>
    );
};
