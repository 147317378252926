import { Font, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { Objective } from '@/domain/objective/Objective.model';
import { EmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReviewFeedbackQuestionPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackQuestionPDF';
import { EmployeeReviewFeedbackSectionPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackSection';
import { EmployeeReviewFeedbackObjectivesPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackObjectivePDF';
import { EmployeeReviewFeedbackItemsType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { EmployeeReviewFeedbackSkillsPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackSkillsPDF';
import { useTheme } from '@mui/material';
import { EmployeeReviewFeedbackLayoutPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackLayoutPDF';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';

type EmployeeReviewFeedbackFormPDFProps = {
    employeeReviewFeedback: EmployeeReviewFeedback;
    employeeReviewFeedbackItems: EmployeeReviewFeedbackItemsType;
    employeeReview: EmployeeReview;
    objectives: Objective[];
};

// To allow emoji rendering in PDF
Font.registerEmojiSource({
    format: 'png',
    url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

export const EmployeeReviewFeedbackFormPDF: FC<EmployeeReviewFeedbackFormPDFProps> = ({
    employeeReviewFeedback,
    objectives,
    employeeReviewFeedbackItems,
    employeeReview,
}) => {
    const theme = useTheme();

    return (
        <EmployeeReviewFeedbackLayoutPDF employeeReviewFeedback={employeeReviewFeedback} employeeReview={employeeReview}>
            <View
                style={{
                    gap: theme.spacing(2),
                }}
            >
                {employeeReviewFeedbackItems.length ? (
                    employeeReviewFeedbackItems.map(feedbackItem => {
                        switch (feedbackItem.type) {
                            case 'QUESTION':
                            case 'SKILL':
                                return <EmployeeReviewFeedbackQuestionPDF key={feedbackItem.id} questionItem={feedbackItem} rating={feedbackItem.rating} />;
                            case 'SECTION':
                                return <EmployeeReviewFeedbackSectionPDF key={feedbackItem.id} sectionItem={feedbackItem} />;
                            case 'REVIEW_OBJECTIVES':
                                return <EmployeeReviewFeedbackObjectivesPDF key={feedbackItem.id} objectives={objectives} />;
                            case 'SKILLS':
                                return <EmployeeReviewFeedbackSkillsPDF key={feedbackItem.id} skills={feedbackItem} />;
                            default:
                                return <></>;
                        }
                    })
                ) : (
                    <></>
                )}
            </View>
        </EmployeeReviewFeedbackLayoutPDF>
    );
};
