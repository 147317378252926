import { timesheetAPI } from '@/api/timesheet/Timesheet.api';
import {
    ApproveTimesheetMutation,
    ClockInOutCreationMutation,
    DailyTimesheetReport,
    DeclineTimesheetMutation,
    EmployeeTimesheetMutation,
    MonthlyTimesheetReport,
    PendingDayTimesheet,
    Timesheet,
    TimesheetClockInAreaUpdateMutation,
    TimesheetClockInRule,
    TimesheetCycle,
    TimesheetEmployeeMonthSearch,
    TimesheetPendingSearch,
    TimesheetSearch,
    TimesheetType,
} from '@/domain/timesheet/Timesheet.model';
import {
    addYears,
    formatToLocalDate,
    getAllYears,
    getCurrentLocalDate,
    getEndOfYear,
    getStartOfYear,
    getTodayDate,
    isBeforeDate,
    isSameDate,
    MONTHS,
    subDaysAndFormat,
    toDate,
} from '@/utils/datetime.util';
import { activeMonth } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistory.util';
import { Employee } from '@/domain/employee/Employee.model';

export const searchEmployeeTimesheets = (request: TimesheetSearch): Promise<DailyTimesheetReport[]> => {
    return timesheetAPI.searchEmployeeTimesheets(request);
};

export const searchEmployeeMonthTimesheets = (request: TimesheetEmployeeMonthSearch): Promise<DailyTimesheetReport[]> => {
    return timesheetAPI.searchEmployeeMonthTimesheets(request);
};

export const searchTimesheets = (request: TimesheetSearch): Promise<Timesheet[]> => {
    if (request?.startDate && request?.endDate && !isSameDate(request?.startDate, request?.endDate) && !isBeforeDate(request?.startDate, request?.endDate)) {
        return Promise.resolve([]);
    }
    return timesheetAPI.searchTimesheets(request);
};

export const searchPendingTimesheets = (request: TimesheetPendingSearch): Promise<PendingDayTimesheet[]> => {
    return timesheetAPI.searchPendingTimesheets(request);
};

export const countPendingTimesheets = async (request: TimesheetPendingSearch = {}): Promise<number> => {
    // TODO replace by a specific endpoint to get only the count : https://rogerhr.atlassian.net/browse/RP-3992
    const pendingDayTimesheets = await timesheetAPI.searchPendingTimesheets(request);
    return Promise.resolve(pendingDayTimesheets?.length ?? 0);
};

export const updateTimesheet = async (
    mutation: EmployeeTimesheetMutation,
    options?: {
        withApproval: boolean;
    },
): Promise<Timesheet[]> => {
    const updatedTimesheets = await timesheetAPI.updateTimesheet(mutation);
    if (options?.withApproval) {
        return await approveTimesheets([
            {
                employeeId: mutation.employeeId,
                timesheetIds: updatedTimesheets.map(timesheet => timesheet.id),
            },
        ]);
    }
    return updatedTimesheets;
};

export const updatePendingTimesheet = async (
    mutation: EmployeeTimesheetMutation,
    options?: {
        withApproval: boolean;
    },
): Promise<Timesheet[]> => {
    const updatedTimesheets = await timesheetAPI.updatePendingTimesheet(mutation);
    if (options?.withApproval) {
        return await approveTimesheets([
            {
                employeeId: mutation.employeeId,
                timesheetIds: updatedTimesheets.map(timesheet => timesheet.id),
            },
        ]);
    }
    return updatedTimesheets;
};

export const createTimesheets = async (
    mutation: EmployeeTimesheetMutation,
    options?: {
        withApproval: boolean;
    },
): Promise<Timesheet[]> => {
    const createdTimesheets = await timesheetAPI.createTimesheets(mutation);
    if (options?.withApproval) {
        return await approveTimesheets([
            {
                employeeId: mutation.employeeId,
                timesheetIds: createdTimesheets.map(timesheet => timesheet.id),
            },
        ]);
    }
    return createdTimesheets;
};

export const clockInOut = (mutation: ClockInOutCreationMutation): Promise<Timesheet> => {
    return timesheetAPI.clockInOut(mutation);
};

export const correctClockIn = (mutation: ClockInOutCreationMutation): Promise<Timesheet> => {
    return timesheetAPI.correctClockIn(mutation);
};

export const getLastTimesheetClockInOut = (employeeId: number): Promise<Timesheet> => {
    return timesheetAPI.getLastTimesheetClockInOut(employeeId);
};

export const getAllowClockInOutsideWorkingHours = (employeeId: number): Promise<TimesheetClockInRule> => {
    return timesheetAPI.getAllowClockIn(employeeId);
};

export const cancelPendingTimesheet = (mutation: DeclineTimesheetMutation): Promise<Timesheet> => {
    return timesheetAPI.cancelPendingTimesheet(mutation);
};

export const cancelTimesheet = (mutation: DeclineTimesheetMutation): Promise<Timesheet> => {
    return timesheetAPI.cancelTimesheet(mutation);
};

export const declineTimesheet = (mutation: DeclineTimesheetMutation): Promise<Timesheet> => {
    return timesheetAPI.declineTimesheet(mutation);
};

export const approveTimesheets = (mutations: ApproveTimesheetMutation[]): Promise<Timesheet[]> => {
    return timesheetAPI.approveTimesheet(mutations);
};

export const approveTimesheet = (mutation: ApproveTimesheetMutation): Promise<Timesheet[]> => {
    return timesheetAPI.approveTimesheet([mutation]);
};

export const deleteLastTimesheetClockInOut = (employeeId: number): Promise<void> => {
    return timesheetAPI.deleteLastTimesheetClockInOut(employeeId);
};

export const getClockOutForceBreakDuration = (employeeId: number): Promise<number> => {
    return timesheetAPI.getClockOutForceBreakDuration(employeeId);
};

export const updateTimesheetArea = (mutation: TimesheetClockInAreaUpdateMutation, timesheetId: number): Promise<Timesheet> => {
    return timesheetAPI.updateTimesheetArea(mutation, timesheetId);
};

export const searchEmployeeMonthlyTimesheets = (request: TimesheetSearch): Promise<MonthlyTimesheetReport[]> => {
    return timesheetAPI.searchEmployeeMonthlyTimesheets(request);
};

const isTimesheetOverlapping = (timesheet: Timesheet): boolean => {
    const overlappingDate = getTodayDate();
    return !!timesheet.startAt && isBeforeDate(timesheet.startAt, overlappingDate) && (!timesheet.endAt || isBeforeDate(overlappingDate, timesheet.endAt));
};

export const getOverlappingTimesheets = (timesheets: Timesheet[]): Timesheet | undefined => {
    const filteredTimesheets = timesheets
        .filter(t => t.type === TimesheetType.TIMESHEET || t.type === TimesheetType.SHIFT_TIMESHEET)
        .map(timesheet => {
            if (isTimesheetOverlapping(timesheet)) {
                return timesheet;
            }
            return undefined;
        })
        .filter(t => t !== undefined) as Timesheet[];

    if (filteredTimesheets.length > 0) {
        return filteredTimesheets[0];
    }
};

export const getYearFromTimesheetCycle = (month: MONTHS, timesheetCycle: TimesheetCycle): string => {
    const selectedMonth = activeMonth(month);
    const cycleStart = timesheetCycle.cycleStartDate;
    const cycleEnd = timesheetCycle.cycleEndDate;
    const cycleStartMonth = toDate(cycleStart).getMonth();
    const cycleEndMonth = toDate(cycleEnd).getMonth();
    const cycleStartYear = toDate(cycleStart).getFullYear();
    const cycleEndYear = toDate(cycleEnd).getFullYear();

    const isCycleWithinSingleYear = cycleStartMonth <= cycleEndMonth && selectedMonth >= cycleStartMonth && selectedMonth <= cycleEndMonth;
    if (isCycleWithinSingleYear) {
        return cycleStartYear.toString();
    }
    return (selectedMonth >= cycleStartMonth ? cycleStartYear : cycleEndYear).toString();
};

export const buildCycles = (employee: Employee): TimesheetCycle[] => {
    const timesheetSettings = employee?.currentWorkingPattern?.timesheetSetting;
    return getAllYears().map(year => {
        //e.g: 2021 -> september 2021 -> october 2022
        const month = timesheetSettings?.cycleStartMonth ?? MONTHS.JANUARY;
        const startDate = toDate(getCurrentLocalDate());
        startDate.setFullYear(year);
        startDate.setMonth(activeMonth(month));
        startDate.setDate(1);
        const endDate = subDaysAndFormat(addYears(startDate, 1), 1);
        return {
            cycleStartDate: formatToLocalDate(startDate),
            cycleEndDate: endDate,
            startMonth: month,
            year: year,
        };
    });
};

export const getDefaultCycle = (): TimesheetCycle => {
    return {
        cycleStartDate: getStartOfYear(),
        cycleEndDate: getEndOfYear(),
        startMonth: MONTHS.JANUARY,
        year: getTodayDate().getFullYear(),
    };
};
