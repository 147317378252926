import { SCALE_COLOR_SCHEME } from '@/domain/survey/Survey.service';

export const getColorProgressBarIndex = (score: number): number => {
    const scoreRoundNumber = score.toFixed(0);
    if (parseInt(scoreRoundNumber) < SCALE_COLOR_SCHEME.length) {
        return parseInt(scoreRoundNumber);
    } else {
        return 0;
    }
};

/**
 * Converts an RGB color string (e.g., "rgb(255, 0, 0)") to an RGBA color string
 * by adding the specified alpha value.
 *
 * @param rgbColorString - A string representing a color in RGB format (e.g., "rgb(255, 0, 0)").
 * @param alpha - The alpha (opacity) value to be added to the RGBA string.
 * @returns A string representing the color in RGBA format (e.g., "rgba(255, 0, 0, 0.5)").
 */
export const rgbToRgba = (rgbColorString: string, alpha: number): string => {
    // The match method is used with a regular expression to extract all numeric values (red, green, blue)
    const [r, g, b] = rgbColorString.match(/\d+/g)?.map(Number) ?? [0, 0, 0];

    // Return the rgba string with the extracted RGB values and the provided alpha value
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const DEFAULT_SHIFT_BACKGROUND_COLOR = '#616161';

export const LIGHTEN_COEFFICIENT = 0.7;

export const COLORS = ['primary', 'success', 'error', 'info', 'warning', 'secondary'] as const;
export type Color = (typeof COLORS)[number];

export const isColor = (color: unknown): color is Color => {
    return typeof color === 'string' && (COLORS as readonly string[]).includes(color);
};
