import { AgGridReact } from 'ag-grid-react';
import { ChangeEvent, MutableRefObject, useCallback, useRef, useState } from 'react';

export const useAgGridWrapper = <T,>(): {
    gridRef: MutableRefObject<AgGridReact<T> | undefined>;
    setGridRef: (node: AgGridReact<T>) => void;
    selectedRows: T[];
    quickFilter: (e: ChangeEvent<HTMLInputElement>) => void;
} => {
    const gridRef = useRef<AgGridReact<T>>();

    const quickFilter = (e: ChangeEvent<HTMLInputElement>) => {
        gridRef.current?.api.setGridOption('quickFilterText', e.target.value);
    };

    const [selectedRows, setSelectedRows] = useState<T[]>([]);

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current?.api.getSelectedRows();
        setSelectedRows(selectedRows ?? []);
    }, []);

    const setGridRef = useCallback(
        (node: AgGridReact) => {
            if (node !== undefined) {
                // TODO: Improvement - use zustand to store selected rows and avoid rerendering to much components
                node.api.addEventListener('selectionChanged', onSelectionChanged);
            }
            gridRef.current = node;
        },
        [onSelectionChanged],
    );

    return {
        gridRef,
        setGridRef,
        selectedRows,
        quickFilter,
    };
};
