import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import { EmploymentDTO, mapEmploymentDTO } from '@/api/employment/Employment.api';
import {
    Employee,
    EmployeeAnniversary,
    EmployeeAnniversaryRequest,
    EmployeeAuthentication,
    EmployeeAvatar,
    EmployeeBasicInfo,
    EmployeeBasicInfoUpdateMutation,
    EmployeeEmailUpdateMutation,
    EmployeeLanguageUpdateRequest,
    EmployeeLoginMethod,
    EmployeeLoginMethodUpdateMutation,
    EmployeeLoginMutation,
    EmployeeSearch,
    EmployeeSearchLoginMethodRequest,
    EmployeeShiftSettings,
    EmployeeShiftSettingsUpdateMutation,
    EmployeeSyncRequest,
} from '@/domain/employee/Employee.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { LoginMethod } from '@/domain/realm/Realm.model';
import { AxiosResponse } from 'axios';

type EmployeeAuthenticationDTO = Overwrite<
    EmployeeAuthentication,
    {
        employee: EmployeeDTO;
    }
>;

export type EmployeeDTO = Overwrite<Employee, { currentEmployments: EmploymentDTO[] }>;
export type EmployeeAvatarDTO = EmployeeAvatar;

type EmployeeLoginMethodDTO = EmployeeLoginMethod;

export const mapEmployeeDTO = (employee: EmployeeDTO): Employee => {
    const { currentEmployments, ...restEmployee } = employee ?? {};
    return {
        ...restEmployee,
        currentEmployments:
            currentEmployments?.map(mapEmploymentDTO).sort((e1, e2) => {
                // principal employment should be first
                if (e1.principal) {
                    return -1;
                }
                if (e2.principal) {
                    return 1;
                }
                // sort by id
                return e1.id < e2.id ? -1 : 1;
            }) ?? [],
    };
};

type EmployeeAnniversaryRequestDTO = DateToString<EmployeeAnniversaryRequest>;
export type EmployeeSearchRequestDTO = EmployeeSearch;
export type EmployeeSyncRequestDTO = EmployeeSyncRequest;
export type EmployeeAnniversaryDTO = EmployeeAnniversary;

const getCurrentEmployee = async (): Promise<EmployeeAuthentication> => {
    const { data } = await client.get<EmployeeAuthenticationDTO>(API_BASE_URL + '/employees/me');
    return {
        ...data,
        employee: mapEmployeeDTO(data.employee),
    };
};

const getEmployeeById = async (employeeId: number): Promise<Employee> => {
    const { data } = await client.get<EmployeeDTO>(API_BASE_URL + '/employees/' + employeeId);
    return mapEmployeeDTO(data);
};

const searchEmployees = async (request: EmployeeSearch = {}): Promise<Employee[]> => {
    const { data } = await client.post<EmployeeDTO[], AxiosResponse<EmployeeDTO[]>, EmployeeSearchRequestDTO>(API_BASE_URL + '/employees/search', request);

    return data.map(mapEmployeeDTO);
};

const searchEmployeeBirthdays = async (request: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    const url = API_BASE_URL + '/employees/birthday';

    return (await client.post<EmployeeAnniversaryDTO[], AxiosResponse<EmployeeAnniversaryDTO[]>, EmployeeAnniversaryRequestDTO>(url, request)).data;
};

const searchEmployeeWorkAnniversaries = async (request: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    const url = API_BASE_URL + '/employees/work-anniversary';
    return (await client.post<EmployeeAnniversaryDTO[], AxiosResponse<EmployeeAnniversaryDTO[]>, EmployeeAnniversaryRequestDTO>(url, request)).data;
};

const deleteEmployee = async (employeeId: number, email: string): Promise<void> => {
    await client.delete(API_BASE_URL + '/employees/' + employeeId + '/' + email);
};

const searchCurrentEmployeeLoginMethod = async (request: EmployeeSearchLoginMethodRequest): Promise<EmployeeLoginMethodDTO> => {
    return (
        await client.post<EmployeeLoginMethodDTO, AxiosResponse<EmployeeLoginMethodDTO>, EmployeeSearchLoginMethodRequest>(
            API_BASE_URL + `/employees/login-method`,
            request,
        )
    ).data;
};

const getEmployeeLoginMethod = async (employeeId: number): Promise<LoginMethod> => {
    return (await client.get<LoginMethod>(API_BASE_URL + `/employees/${employeeId}/login-method`)).data;
};

const updateLoginMethod = async (employeeId: number, mutation: EmployeeLoginMethodUpdateMutation): Promise<Employee> => {
    return (
        await client.put<Employee, AxiosResponse<Employee>, EmployeeLoginMethodUpdateMutation>(API_BASE_URL + `/employees/${employeeId}/login-method`, mutation)
    ).data;
};

const updateEmployeeLogin = async (mutation: EmployeeLoginMutation): Promise<void> => {
    return client.post(API_BASE_URL + `/employees/login`, mutation);
};

const updateEmployeeBasicInfo = async (employeeId: number, mutation: EmployeeBasicInfoUpdateMutation): Promise<EmployeeBasicInfo> => {
    return (
        await client.put<EmployeeBasicInfo, AxiosResponse<EmployeeBasicInfo>, EmployeeBasicInfoUpdateMutation>(
            API_BASE_URL + `/employees/${employeeId}/basic-info`,
            mutation,
        )
    ).data;
};

const updateEmail = async (employeeId: number, mutation: EmployeeEmailUpdateMutation): Promise<void> => {
    return (await client.put(API_BASE_URL + `/employees/${employeeId}/email`, mutation)).data;
};

const deactivateEmployee = async (employeeId: number): Promise<Employee> => {
    const data = (await client.put<EmployeeDTO>(API_BASE_URL + `/employees/${employeeId}/deactivate`, {})).data;
    return mapEmployeeDTO(data);
};

const syncEmployees = async (employeeSyncRequest: EmployeeSyncRequest): Promise<void> => {
    await client.post<EmployeeDTO, AxiosResponse<EmployeeDTO>, EmployeeSyncRequestDTO>(API_BASE_URL + `/employees/sync`, employeeSyncRequest);
};

const activateEmployee = async (employeeId: number): Promise<Employee> => {
    const data = (await client.put<EmployeeDTO>(API_BASE_URL + `/employees/${employeeId}/activate`, {})).data;
    return mapEmployeeDTO(data);
};

const getEmployeeAvatar = async (employeeId: number): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/employees/${employeeId}/avatar`)).data;
};

const uploadAvatar = async (employeeId: number, file: File): Promise<Employee> => {
    // Build form data with file
    const form = new FormData();
    form.append('avatar', file);
    const { data } = await client.putForm<EmployeeDTO, AxiosResponse<EmployeeDTO>, FormData>(API_BASE_URL + `/employees/${employeeId}/avatar`, form);
    return mapEmployeeDTO(data);
};

const updateEmployeeShiftSettings = async (userId: number, mutation: EmployeeShiftSettingsUpdateMutation): Promise<EmployeeShiftSettings> => {
    return (
        await client.put<EmployeeShiftSettings, AxiosResponse<EmployeeShiftSettings>, EmployeeShiftSettingsUpdateMutation>(
            API_BASE_URL + `/employees/${userId}/shift-settings`,
            mutation,
        )
    ).data;
};

const updateEmployeeLanguage = async (mutation: EmployeeLanguageUpdateRequest): Promise<void> => {
    await client.put(API_BASE_URL + `/employees/me/language`, mutation);
};

const importEmployees = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/employees/import`, formData)).data;
};

export const employeeAPI = {
    getCurrentEmployee,
    getEmployeeById,
    searchEmployees,
    searchEmployeeBirthdays,
    searchEmployeeWorkAnniversaries,
    deleteEmployee,
    searchCurrentEmployeeLoginMethod,
    getEmployeeLoginMethod,
    updateLoginMethod,
    updateEmployeeLogin,
    updateEmployeeBasicInfo,
    updateEmail,
    deactivateEmployee,
    activateEmployee,
    uploadAvatar,
    getEmployeeAvatar: getEmployeeAvatar,
    updateEmployeeShiftSettings,
    importEmployees,
    updateEmployeeLanguage,
    syncEmployees,
};
