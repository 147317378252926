import { FC, useEffect, useState } from 'react';
import { AsyncTreeSelectFilterType, TreeSelectFilterOption, TreeSelectFilterType } from '@/components/filters-bar/FilterBar.type';
import { RecursiveValue, TreeSelect } from '@/components/tree-select/TreeSelect';
import { Box } from '@mui/material';

type TreeSelectFilterOptionWithId = Overwrite<TreeSelectFilterOption, { id: number; children: TreeSelectFilterOptionWithId[] }>;
export const TreeSelectFilter: FC<{
    filter: TreeSelectFilterType | AsyncTreeSelectFilterType;
    onFilterUpdated: (filter: TreeSelectFilterType | AsyncTreeSelectFilterType) => void;
}> = ({ filter, onFilterUpdated }) => {
    const isMultiSelect = filter.type === 'tree-multi-select';
    const [items, setItems] = useState<TreeSelectFilterOption[]>([]);
    const [optionsLoading, setOptionsLoading] = useState(true);

    useEffect(() => {
        if (!items.length && optionsLoading) {
            if (filter.selectMode === 'SYNC') {
                setItems(filter.options);
                setOptionsLoading(false);
            } else {
                filter.fetchOptions().then(options => {
                    setItems(options);
                    setOptionsLoading(false);
                });
            }
        }
    }, [filter, items.length, optionsLoading]);

    const handleSelectionChange = (value: RecursiveValue[] | RecursiveValue | null) => {
        if (isMultiSelect) {
            if (!value) {
                onFilterUpdated({ ...filter, value: [] });
                return;
            }
            onFilterUpdated({ ...filter, value: (value as RecursiveValue[]).map(mapRecursiveValueToTreeFilterOption) });
            return;
        }
        onFilterUpdated({ ...filter, value: value ? [mapRecursiveValueToTreeFilterOption(value as RecursiveValue)] : undefined });
    };

    // TreeSelect needs an id in the option to work
    const mapToOptionWithId = (option: TreeSelectFilterOption): TreeSelectFilterOptionWithId => {
        return {
            ...option,
            id: option.value,
            children: option.children?.map(mapToOptionWithId) ?? [],
        };
    };

    // we need the reverse map to get the original type. Could be improved by add a generic type TValue in TreeSelect
    const mapRecursiveValueToTreeFilterOption = (recursiveValue: RecursiveValue): TreeSelectFilterOption => {
        return { value: recursiveValue.id, label: recursiveValue.label, children: recursiveValue.children.map(mapRecursiveValueToTreeFilterOption) };
    };

    const getTreeSelectValue = (value: TreeSelectFilterOption[] | undefined): TreeSelectFilterOptionWithId[] | TreeSelectFilterOptionWithId | undefined => {
        if (isMultiSelect) {
            return value?.map(mapToOptionWithId);
        }
        return value ? mapToOptionWithId(value[0]) : undefined;
    };
    return (
        <Box p={1} pb={0}>
            <TreeSelect
                multiSelect={isMultiSelect}
                value={getTreeSelectValue(filter.value)}
                itemsData={items.map(mapToOptionWithId)}
                onChange={handleSelectionChange}
                disablePopper={true}
                selectionPropagation={isMultiSelect}
                autoFocus={true}
                slotProps={{
                    // in the filter bar, we don't want to show selected values and the clearValue button
                    input: {
                        startAdornment: undefined,
                        endAdornment: undefined,
                    },
                }}
            />
        </Box>
    );
};
