import { useLocalStorage } from '@/hooks/Storage.hook';
import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FilterType } from '@/components/filters-bar/FilterBar.type';

export const useFiltersStorage = <T extends FilterType>(storageKey: string, availableFilters: T[]): [T[], (filters: T[]) => void] => {
    // TODO challenge typing because we get strings from the session storage
    const [filterInStorage, setFilterInStorage] = useLocalStorage<FilterType[]>(storageKey, []);
    const [filters, setFilters] = useState<T[]>([]);

    const handleFilterChange = (filters: T[]) => {
        setFilterInStorage(filters);
        setFilters(filters);
    };

    // get the filters from the local storage
    useDeepCompareEffect(() => {
        if (filters?.length) {
            return;
        }

        if (filterInStorage.length) {
            const currentFilters = availableFilters.map(filter => {
                const filterFromStorage = filterInStorage.find(filterFromStorage => filterFromStorage.key === filter.key);
                return { ...filter, value: filterFromStorage?.value };
            });

            setFilters(currentFilters);
            return;
        }
        setFilters(availableFilters);
    }, [availableFilters, filters]);

    return [filters, handleFilterChange];
};
