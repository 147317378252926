import { Employee } from '@/domain/employee/Employee.model';
import { FilteringCondition, PermissionFilteringConditionRequest, PermissionFilterType, PolicyPermission } from '@/domain/permission/Permission.model';
import { ContractType, EmploymentStatus } from '@/domain/employment/Employment.model';

export enum PermissionGroupType {
    CUSTOM = 'CUSTOM',
    ADMIN = 'ADMIN',
    ALL_EMPLOYEES = 'ALL_EMPLOYEES',
    SELF = 'SELF',
    MANAGER = 'MANAGER',
}

export type PermissionGroupPolicy = {
    policy: PolicyPermission;
    resourceId?: number;
};

export type PermissionGroup = {
    id?: number;
    groupName: string;
    description: string;
    groupType: PermissionGroupType;
    filterType: PermissionFilterType;
    targetMembersFilterType: PermissionFilterType;
    groupMembers: Employee[];
    groupPolicies: PermissionGroupPolicy[];
    targetMembers: Employee[];
    notificationsEnabled: boolean;
    groupMemberConditions: FilteringCondition[];
    targetMemberConditions: FilteringCondition[];
};

export type FilteringConditionValueMutation = {
    id?: number;
    managerId?: number;
    departmentId?: number;
    locationId?: number;
    legalUnitId?: number;
    jobId?: number;
    employmentStatus?: EmploymentStatus;
    contractType?: ContractType;
};

export type FilteringConditionMutation = Overwrite<
    FilteringCondition,
    {
        id?: number;
        filterConditionValues: FilteringConditionValueMutation[];
    }
>;
export type PermissionGroupMutation = Overwrite<
    Omit<PermissionGroup, 'id'>,
    {
        groupMemberConditions: FilteringConditionMutation[];
        targetMemberConditions: FilteringConditionMutation[];
    }
>;

export type PermissionGroupRequest = {
    groupName: string;
    description: string;
    filterType: PermissionFilterType;
    targetMembersFilterType: PermissionFilterType;
    groupMemberIds: number[];
    groupPolicies: PermissionGroupPolicy[];
    targetMemberIds: number[];
    notificationsEnabled: boolean;
    groupMemberConditions: PermissionFilteringConditionRequest[];
    targetMemberConditions: PermissionFilteringConditionRequest[];
};
