import { getFilterValueIdsByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Objective } from '@/domain/objective/Objective.model';
import { canEditObjectives } from '@/domain/permission/Permission.service';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { ObjectivesList } from '@/page/objective/objectives-list/ObjectivesList';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { ArrowDown02Icon, ArrowUp02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useObjectivesFilters } from '@/page/objective/ObjectivesFilters.hook';
import { EmptyStateObjectives } from '@/page/objective/objectives-page/EmptyStateObjectives';
import { ObjectivesPageHeader } from '@/page/objective/objectives-page/ObjectivePageHeader';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { AddDepartmentObjectiveDialog } from '@/page/objective/add-department-objective-dialog/AddDepartmentObjectiveDialog';
import { getObjectivesFiltered } from '@/page/objective/objectives-page/objectivesFilter.util';

export const DepartmentObjectivesPage: FC = () => {
    const { t } = useTranslation();
    const employeeId = useEmployeeProfileId();
    const policies = useCurrentPolicies();

    const [objectiveDialogOpen, setObjectiveDialogOpen] = useState<boolean>(false);
    const [showArchivedObjectives, setShowArchivedObjectives] = useState<boolean>(false);
    const [activeObjective, setActiveObjective] = useState<Objective>();

    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const { filters: availableFilters } = useObjectivesFilters(
        {
            objectiveCategoriesEnabled: objectiveSetting?.objectiveCategoriesEnabled ?? false,
            isFromProfile: false,
        },
        !isLoadingObjectiveSetting,
    );
    const [filters, setFilters] = useFiltersStorage('department-objectives', availableFilters);

    const {
        data: objectives = [],
        isLoading: isObjectivesLoading,
        error: objectivesError,
        isError: isObjectivesError,
        refetch: refetchObjectives,
    } = useGetObjectives(
        {
            statusCompletionIds: getFilterValueIdsByKey(filters, 'statusCompletionIds'),
            includeArchived: true,
            objectiveType: 'DEPARTMENT',
        },
        { enabled: !!filters?.length },
    );

    const handleEdit = (id: number) => {
        const editObjective = objectives.find(objective => objective.id === id);
        setActiveObjective(editObjective);
        setObjectiveDialogOpen(true);
    };

    const nonArchivedObjectivesNonFiltered = objectives.filter(objective => !objective.archived);
    const objectivesFiltered = getObjectivesFiltered(filters, objectives);
    const archivedObjectives = objectivesFiltered.filter(objective => objective.archived);
    const nonArchivedObjectives = objectivesFiltered.filter(objective => !objective.archived);

    const showFilters = !!nonArchivedObjectivesNonFiltered.length || !!getFilterValueIdsByKey(filters, 'statusCompletionIds').length;
    const handleShowHideArchived = () => {
        setShowArchivedObjectives(!showArchivedObjectives);
    };

    return (
        <Stack gap={2} flex={1}>
            {/* Filters and global actions */}
            <Stack component={Paper} gap={1} p={2} flex={1}>
                {showFilters && (
                    <ObjectivesPageHeader
                        objectiveType='DEPARTMENT'
                        filters={filters}
                        onFiltersChange={setFilters}
                        onAddClick={() => setObjectiveDialogOpen(true)}
                        employeeId={employeeId}
                    />
                )}
                <StateHandler
                    isLoading={isObjectivesLoading || isLoadingObjectiveSetting}
                    isError={isObjectivesError || isErrorObjectiveSetting}
                    isEmpty={!nonArchivedObjectives?.length}
                    emptyStateComponent={<EmptyStateObjectives employeeId={employeeId} onOpenObjectiveDialog={() => setObjectiveDialogOpen(true)} />}
                    error={objectivesError || errorObjectiveSetting}
                >
                    <ObjectivesList
                        objectives={nonArchivedObjectives}
                        onSuccess={() => refetchObjectives()}
                        onEditObjective={handleEdit}
                        editable={canEditObjectives(policies, employeeId)}
                        displayWeight={objectiveSetting?.objectiveWeightEnabled ?? false}
                        displayAvatarsMobile={false}
                    />
                </StateHandler>
            </Stack>
            {archivedObjectives.length > 0 && (
                <Stack gap={2}>
                    <Divider sx={{ width: '100%' }}>
                        <IconButton aria-label='toggle archived objective visibility' onClick={handleShowHideArchived} edge='end' size='small'>
                            <Typography variant='body1'>{showArchivedObjectives ? t('objectives.hide_archived') : t('objectives.show_archived')}</Typography>
                            {showArchivedObjectives ? <ArrowUp02Icon /> : <ArrowDown02Icon />}
                        </IconButton>
                    </Divider>
                    {showArchivedObjectives && (
                        <Stack component={Paper} gap={2} flex={1} p={1}>
                            <Typography variant='h1' pl={1} p={2}>
                                {t('objectives.archived_objectives')}
                            </Typography>
                            <StateHandler
                                isLoading={isObjectivesLoading || isLoadingObjectiveSetting}
                                isError={isObjectivesError || isErrorObjectiveSetting}
                                isEmpty={!archivedObjectives?.length}
                                emptyStateComponent={
                                    <EmptyStateObjectives employeeId={employeeId} onOpenObjectiveDialog={() => setObjectiveDialogOpen(true)} />
                                }
                                error={objectivesError || errorObjectiveSetting}
                            >
                                <ObjectivesList
                                    objectives={archivedObjectives}
                                    onSuccess={() => refetchObjectives()}
                                    onEditObjective={handleEdit}
                                    editable={canEditObjectives(policies, employeeId)}
                                    displayWeight={objectiveSetting?.objectiveWeightEnabled ?? false}
                                    displayAvatarsMobile={false}
                                />
                            </StateHandler>
                        </Stack>
                    )}
                </Stack>
            )}

            {objectiveDialogOpen && (
                <AddDepartmentObjectiveDialog
                    open={objectiveDialogOpen}
                    activeObjective={activeObjective}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    employeeId={activeObjective ? activeObjective?.assignee?.id : employeeId}
                    onSaveObjective={() => {
                        setActiveObjective(undefined);
                        refetchObjectives().catch(handleError);
                    }}
                    onClose={() => {
                        setActiveObjective(undefined);
                        setObjectiveDialogOpen(false);
                    }}
                />
            )}
        </Stack>
    );
};
