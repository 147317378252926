import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { SquareTextCheckbox } from '@/components/square-text-checkbox/SquareTextCheckbox';
import { ReviewArray } from '@/domain/review/Review.model';
import { FORM_COMPANY_SETTINGS_CONTAINER_CLASS } from '@/page/setting/CompanySettings.constants';
import { AboutStepFormType } from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import { UserLanguage } from '@/utils/language.util';
import { FormControlLabel, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AboutStepFormProps = {
    onEmployeePrivateQuestionToggle: (value: boolean) => void;
    onManagerPrivateQuestionToggle: (value: boolean) => void;
    translationLanguage: UserLanguage;
};
export const AboutStepForm: FC<AboutStepFormProps> = ({
    onEmployeePrivateQuestionToggle,
    onManagerPrivateQuestionToggle,
    translationLanguage,
}): JSX.Element => (
    <Stack gap={2}>
        <About translationLanguage={translationLanguage} />
        <Workflow onManagerPrivateQuestionToggle={onManagerPrivateQuestionToggle} onEmployeePrivateQuestionToggle={onEmployeePrivateQuestionToggle} />
    </Stack>
);

type AboutProps = {
    translationLanguage: UserLanguage;
};

const About: FC<AboutProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<AboutStepFormType>();
    const { t } = useTranslation();
    return (
        <Stack p={2} gap={3} component={Paper} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS}>
            <Typography variant={'h1'}>{t('reviews_settings_page.review_template_form.step_about')}</Typography>
            <FieldLabel
                control={control}
                name={'name'}
                fullWidth
                label={t('reviews_settings_page.review_template_form.template_name')}
                language={translationLanguage}
            />

            <FormControlLabel
                label={t('reviews_settings_page.review_template_form.select_template_type_title')}
                control={
                    <FieldSelect
                        name='reviewType'
                        control={control}
                        options={Object.values(ReviewArray)}
                        getOptionLabel={reviewType => (reviewType ? t('reviews.review_type.enum', { context: reviewType }) : '')}
                        disableClearable
                        fullWidth
                    />
                }
            />
        </Stack>
    );
};

type WorkflowProps = {
    onManagerPrivateQuestionToggle: (value: boolean) => void;
    onEmployeePrivateQuestionToggle: (value: boolean) => void;
};

export const Workflow: FC<WorkflowProps> = ({ onManagerPrivateQuestionToggle, onEmployeePrivateQuestionToggle }) => {
    const { watch, setValue, control } = useFormContext<AboutStepFormType>();
    const { t } = useTranslation();
    const selfReview = watch('selfReview');
    const managerPreparation = watch('managerPreparation');
    const peerFeedback = watch('peerFeedback');
    const upwardFeedback = watch('upwardFeedback');
    const includePreparationStep = watch('includePreparationStep');
    const managerPreparationAccess = watch('managerPreparationAccess');

    return (
        <Paper>
            <Stack p={2} gap={3}>
                <Typography variant={'h1'}>{t('reviews_settings_page.review_template_form.workflow_title')}</Typography>

                <Stack gap={2}>
                    <FieldSwitch
                        name='includePreparationStep'
                        control={control}
                        label={t('reviews_settings_page.review_template_form.workflow_switch_preparation_step')}
                        labelPlacement='end'
                    />

                    {includePreparationStep && (
                        <Stack direction={'row'} gap={3} flexWrap={'wrap'}>
                            <SquareTextCheckbox
                                title={t('reviews_settings_page.review_template_form.square_text_checkbox_employee_title')}
                                description={t('reviews_settings_page.review_template_form.square_text_checkbox_employee_description')}
                                checked={selfReview}
                                onClick={() => {
                                    setValue('selfReview', !selfReview);
                                    // the condition here is on selfReview and not !selfReview, because the state has not been updated yet
                                    setValue('managerPreparation', selfReview ? false : managerPreparation);
                                }}
                            />
                            <SquareTextCheckbox
                                title={t('reviews_settings_page.review_template_form.square_text_checkbox_manager_title')}
                                description={t('reviews_settings_page.review_template_form.square_text_checkbox_manager_description')}
                                checked={managerPreparation && selfReview}
                                onClick={() => setValue('managerPreparation', !managerPreparation)}
                                disabled={!selfReview}
                            />
                            <SquareTextCheckbox
                                title={t('reviews_settings_page.review_template_form.square_text_checkbox_peers_title')}
                                description={t('reviews_settings_page.review_template_form.square_text_checkbox_peers_description')}
                                checked={peerFeedback}
                                onClick={() => setValue('peerFeedback', !peerFeedback)}
                            />
                            <SquareTextCheckbox
                                title={t('reviews_settings_page.review_template_form.square_text_checkbox_direct_reports_title')}
                                description={t('reviews_settings_page.review_template_form.square_text_checkbox_direct_reports_description')}
                                checked={upwardFeedback}
                                onClick={() => setValue('upwardFeedback', !upwardFeedback)}
                            />
                        </Stack>
                    )}

                    {managerPreparation && (
                        <FieldSwitch
                            name='managerPreparationAccess'
                            control={control}
                            label={t('reviews_settings_page.review_template_form.workflow_switch_manager_preparation')}
                            labelPlacement='end'
                        />
                    )}

                    {managerPreparation && (
                        <FieldSwitch
                            name='allowManagerToSkipPreparationStep'
                            control={control}
                            label={t('reviews_settings_page.review_template_form.workflow_switch_allow_manager_skip_preparation')}
                            labelPlacement='end'
                        />
                    )}

                    {managerPreparationAccess && (
                        <FieldSwitch
                            name='employeePreparationAccess'
                            control={control}
                            label={t('reviews_settings_page.review_template_form.workflow_switch_employee_preparation')}
                            labelPlacement='end'
                        />
                    )}

                    <FieldSwitch
                        label={t('reviews_settings_page.review_template_form.workflow_switch_validation_step')}
                        labelPlacement='end'
                        name='includeValidationStep'
                        control={control}
                    />

                    <FieldSwitch
                        name='includePrivateManagerQuestions'
                        label={t('reviews_settings_page.review_template_form.workflow_switch_private_manager_feedback')}
                        labelPlacement='end'
                        control={control}
                        onChange={onManagerPrivateQuestionToggle}
                    />
                    <FieldSwitch
                        disabled={true}
                        label={t('reviews_settings_page.review_template_form.workflow_switch_private_employee_feedback')}
                        labelPlacement='end'
                        name='includePrivateEmployeeQuestions'
                        control={control}
                        onChange={onEmployeePrivateQuestionToggle}
                    />
                </Stack>
            </Stack>
        </Paper>
    );
};
