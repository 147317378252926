import { FC, ReactNode } from 'react';
import { Stack } from '@mui/system';
import { Chip, Link, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Department, DepartmentNode } from '@/domain/department/Department.model';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { Add01Icon } from 'hugeicons-react';

export type ItemLabelProps = {
    item: DepartmentNode;
    parent: Department | undefined;
    label: ReactNode;
    onAddClick: (department: DepartmentNode) => void;
    onEditClick: (department: DepartmentNode) => void;
    onDeleteClick: (department: DepartmentNode) => void;
    onArchiveClick: (department: DepartmentNode) => void;
    onUnarchiveClick: (department: DepartmentNode) => void;
};

type ItemAction = {
    key: string; // used as key for loop
    title: ReactNode;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: ReactNode;
    hide?: boolean;
};
export const DepartmentTreeItemLabel: FC<ItemLabelProps> = props => {
    const { item, parent, label, onAddClick, onEditClick, onDeleteClick, onArchiveClick, onUnarchiveClick } = props;
    const { t } = useTranslation();

    const disableDelete = item.children.length > 0;
    const disableArchive = item.children.some(dep => !dep.archived);
    const disableUnarchive = parent?.archived ?? false;

    const actions: ItemAction[] = [
        {
            key: 'add',
            title: (
                <Stack direction={'row'} alignItems={'center'}>
                    <Add01Icon size={20} /> {t('settings_organization.departments.sub_departments')}
                </Stack>
            ),
            onClick: () => onAddClick(item),
            hide: item.archived,
        },
        {
            key: 'edit',
            title: t('general.edit'),
            onClick: () => onEditClick(item),
            hide: item.archived,
        },
        {
            key: 'delete',
            title: t('general.delete'),
            onClick: () => onDeleteClick(item),
            disabled: disableDelete,
            tooltip: disableDelete ? t('settings_organization.departments.unable_to_delete_parent') : '',
            hide: item.archived,
        },
        {
            key: 'archive',
            title: t('general.archive'),
            onClick: () => onArchiveClick(item),
            disabled: disableArchive,
            tooltip: disableArchive ? t('settings_organization.departments.unable_to_archive_parent') : '',
            hide: item.archived,
        },
        {
            key: 'unarchive',
            title: t('general.unarchive'),
            onClick: () => onUnarchiveClick(item),
            hide: !item.archived,
            disabled: disableUnarchive,
            tooltip: disableUnarchive ? t('settings_organization.departments.unable_to_unarchive_child') : '',
        },
    ];

    return (
        <Stack direction={'row'} alignItems={'center'} flex={1} gap={3}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography color={item.archived ? 'textDisabled' : undefined}>{label}</Typography>
                {item.archived && <Chip color={'warning'} label={t('general.archived')} size={'small'} sx={{ height: '22px' }} />}
            </Stack>

            <Stack direction={'row'} display={'none'} className={'display-on-hover'} justifyContent={'space-between'} flex={1}>
                <Stack direction={'row'} gap={3}>
                    {actions.map(({ key, ...action }) => (
                        <ItemAction key={key} {...action} />
                    ))}
                </Stack>

                {!!item.managers.length && (
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Typography variant={'body1'}>{t('settings_organization.departments.managers')}</Typography>
                        <StackedAvatars employeeAvatars={item.managers} />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

type ItemActionProps = {
    title: ReactNode;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: ReactNode;
    hide?: boolean;
};
const ItemAction: FC<ItemActionProps> = ({ title, onClick, disabled, hide, tooltip }) => {
    if (hide) {
        return;
    }
    return (
        <Tooltip title={tooltip}>
            <Link
                component={'button'}
                color={disabled ? 'text.disabled' : 'primary'}
                underline={disabled ? 'none' : 'hover'}
                onClick={e => {
                    e.stopPropagation();
                    if (disabled) {
                        return;
                    }
                    onClick();
                }}
                sx={{
                    ':hover': {
                        cursor: disabled ? 'initial' : 'pointer',
                    },
                }}
            >
                {title}
            </Link>
        </Tooltip>
    );
};
