import { AgGridWrapper, AgGridWrapperColumnType } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { SectionActionButton, SectionColumn, SectionRow } from '@/components/section/types';
import { Spinner } from '@/components/spinner/Spinner';
import { SectionFieldValueType } from '@/domain/section-setting/Section.model';
import { getLabelTranslation } from '@/utils/language.util';
import { formatIBAN, formatPhoneNumber } from '@/utils/strings.util';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { FC } from 'react';

type TableSectionProps = {
    sectionTitle: string;
    columns: SectionColumn[];
    rows: SectionRow[];
    actionButton?: SectionActionButton;
    actionMenuCellRenderer?: (params: ICellRendererParams) => ColDef['cellRenderer'];
};

export const TableSection: FC<TableSectionProps> = ({ sectionTitle, columns, rows, actionButton, actionMenuCellRenderer }) => {
    const { palette } = useTheme();
    const agGridWrapper = useAgGridWrapper<SectionRow>();
    const columnDefs: ColDef<SectionRow>[] = columns?.map((column, index) => {
        const type = getType(column.valueType);
        const sort = column.valueType === 'DATE' ? 'desc' : 'asc';
        return {
            // We set the sort only on the first column
            sort: index === 0 ? sort : undefined,
            headerName: column.title,
            type,
            valueGetter: ({ data }) => {
                if (!data?.fields?.length) {
                    return;
                }

                // we are trying to find the field by id if it exists, otherwise we try to find it by title
                // this is because the id is not always present in the data (hardcoded fields)
                const field = data.fields.find(field =>
                    field.fieldDefinitionId ? field.fieldDefinitionId === column.fieldDefinitionId : field.title === column.title,
                );

                if (!field) {
                    return;
                }

                switch (field.valueType) {
                    case 'DATE':
                        return field.dateValue;
                    case 'NUMBER':
                        return field.numberValue?.toLocaleString();
                    case 'COUNTRY':
                        return field.countryValue?.label ?? '';
                    case 'STRING':
                    case 'ENUM':
                    case 'AUTO_INCREMENT':
                        return field.stringValue;
                    case 'PHONE_NUMBER':
                        return formatPhoneNumber(field.stringValue ?? '');
                    case 'IBAN_NUMBER':
                        return formatIBAN(field.stringValue ?? '');
                    case 'CUSTOM_LIST_ITEM':
                        return field.customListItemReferences?.map(item => getLabelTranslation(item.label)).join();
                    case 'CUSTOM_MULTI_LIST_ITEM':
                        return field.customListItemReferences?.map(item => getLabelTranslation(item.label)).join(', ');
                    case 'SECTION_FIELD_DOCUMENT':
                        return field;
                    case 'EMPLOYEE':
                        return field.employeeReferences;
                    default:
                        return undefined;
                }
            },
        };
    });

    function getType(type: SectionFieldValueType): AgGridWrapperColumnType | undefined {
        switch (type) {
            case 'DATE':
                return 'date';
            case 'SECTION_FIELD_DOCUMENT':
                return 'sectionFieldDocuments';
            // This is a workaround
            case 'EMPLOYEE':
                return 'stackedAvatars';
            default:
                return undefined;
        }
    }

    if (actionMenuCellRenderer) {
        columnDefs.push({
            type: 'actionMenu',
            cellRenderer: actionMenuCellRenderer,
        });
    }

    if (!columns) {
        return <Spinner />;
    }

    // We compute the height of the table based on the number of rows
    const tableHeight = getTableHeight({ rowsLength: rows.length, disableFooter: true });

    return (
        <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Stack justifyContent='center' alignItems='flex-start' gap={1} pl={1} flex={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1} width='100%'>
                    <Typography variant='h2'>{sectionTitle}</Typography>
                    {actionButton && (
                        <Tooltip title={actionButton?.tooltipTitle}>
                            <span>
                                <Button variant='text' onClick={actionButton.onClick} disabled={actionButton?.disabled}>
                                    {actionButton.title}
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </Stack>
                {!!rows?.length && (
                    <Stack width='100%' minHeight={100} maxHeight={300} height={tableHeight}>
                        <AgGridWrapper<SectionRow>
                            initRef={agGridWrapper.setGridRef}
                            gridId={sectionTitle}
                            rowData={rows}
                            columnDefs={columnDefs}
                            statusBar={undefined}
                            getRowStyle={params => {
                                return params?.data?.isPending
                                    ? {
                                          fontStyle: 'italic',
                                          backgroundColor: palette.warning.light,
                                      }
                                    : undefined;
                            }}
                            // This is a fix to avoid column resizing when the data is updated
                            // When the dialog to add a row is opened, the table is rerendered and the columns are resized
                            // the best way is to avoid the rerendering of the table when the dialog is opened (or anything else changes)
                            // INFO : The condition on top of TableSection to use an other display for mobile is responsible for the rerendering
                            //  {!isSmallDevice ? (
                            // <TableSection
                            onRowDataUpdated={params => {
                                params.api.autoSizeAllColumns();
                            }}
                            compact={false}
                            loading={false}
                        />
                    </Stack>
                )}
            </Stack>
        </Paper>
    );
};
