import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { StateHandler } from '@/components/state-handler/StateHandler';

import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { Button, Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { getDepartmentNodes } from '@/domain/department/Department.service';
import { EmployeeFilterType } from '@/domain/employee/Employee.service';
import { getLocations } from '@/domain/location/Location.service';
import { useSearchEmployeeNotification } from '@/hooks/employee-notification/EmployeeNotification.hook';
import { EmployeeNotification } from '@/domain/employee-notification/EmployeeNotification.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { EmployeesNotificationsDialog } from '@/page/setting/notification/EmployeesNotificationsDialog';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { AsyncSelectFilter, AsyncTreeSelectFilterType } from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.utils';
import { ColDef } from 'ag-grid-enterprise';

export const EmployeesNotificationsPage: FC = () => {
    const { t } = useTranslation();
    const [employeesToUpdateNotification, setEmployeesToUpdateNotification] = useState<EmployeeNotification[]>();
    // FILTERS
    const { filters: availableFilters } = useEmployeesNotificationsPageFilters();

    const [filters, setFilters] = useFiltersStorage('employees-notifications-filters', availableFilters);

    const mapFiltersToEmployeeNotificationSearchRequest = (filters: EmployeesNotificationsFilter[]) => {
        return {
            locationIds: getFilterValueIdsByKey(filters, 'LOCATION_IDS'),
            departmentIds: getFilterValueIdsByKey(filters, 'DEPARTMENT_IDS'),
        };
    };

    const {
        data: employeeNotifications = [],
        isLoading,
        isFetching = false,
        isError,
        error,
        refetch: refetchEmployeeNotifications,
    } = useSearchEmployeeNotification(mapFiltersToEmployeeNotificationSearchRequest(filters));

    const { gridRef, setGridRef, quickFilter, selectedRows } = useAgGridWrapper<EmployeeNotification>();

    const columnDefs = useGetColumnDefs();

    const onBtnExport = () => {
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });
    };

    const handleOpenEditEmployeeNotificationDialog = (employeesNotifications: EmployeeNotification[]) => {
        setEmployeesToUpdateNotification(employeesNotifications);
    };

    const handleOnSaveEmployeeNotificationDialog = async () => {
        await refetchEmployeeNotifications();
        setEmployeesToUpdateNotification(undefined);
    };

    const handleOnCloseEmployeeNotificationDialog = () => {
        setEmployeesToUpdateNotification(undefined);
    };

    return (
        <Stack gap={2} flex={1}>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <Stack flexGrow={1} gap={2}>
                    <Stack component={Paper} p={1} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <FiltersBar filters={filters} onFiltersChange={setFilters} />
                        <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
                    </Stack>
                    <Stack component={Paper} flex={1}>
                        <AgGridWrapper<EmployeeNotification>
                            initRef={setGridRef}
                            onRowClicked={({ data }) => (data?.employee.id ? setEmployeesToUpdateNotification([data]) : undefined)}
                            rowData={employeeNotifications}
                            columnDefs={columnDefs}
                            loading={isFetching}
                            rowSelection={{
                                mode: 'multiRow',
                            }}
                            toolbarActions={
                                <Stack direction='row' gap={1}>
                                    <Button onClick={() => handleOpenEditEmployeeNotificationDialog(selectedRows)}>
                                        {t('employees_notifications_page.open_edit_employee_notification_dialog')}
                                    </Button>
                                </Stack>
                            }
                        />
                    </Stack>
                    {employeesToUpdateNotification && (
                        <EmployeesNotificationsDialog
                            open={true}
                            onClose={handleOnCloseEmployeeNotificationDialog}
                            onSave={handleOnSaveEmployeeNotificationDialog}
                            employeesNotifications={employeesToUpdateNotification}
                        />
                    )}
                </Stack>
            </StateHandler>
        </Stack>
    );
};

const useGetColumnDefs = (): ColDef<EmployeeNotification>[] => {
    const { t } = useTranslation();

    const isLeaveFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.LEAVES);
    const isPlanningFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.PLANNING);
    const isDocumentFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS);
    const isTimesheetFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.TIMESHEET);
    const isAdvancedEmployeeProfileFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE);

    const columns: ColDef<EmployeeNotification>[] = [
        {
            field: 'employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
    ];

    if (isLeaveFeatureEnabled) {
        columns.push({
            field: 'leaveNotificationsEnabled',
            type: 'booleanTick',
            headerName: t('employees_notifications_page.table_headers.leave_notifications'),
        });
        columns.push({
            field: 'leaveAttachmentNotificationsEnabled',
            type: 'booleanTick',
            headerName: t('employees_notifications_page.table_headers.leave_attachment_notifications'),
        });
    }

    if (isTimesheetFeatureEnabled) {
        columns.push({
            field: 'timesheetUpdatesNotificationsEnabled',
            type: 'booleanTick',
            headerName: t('employees_notifications_page.table_headers.timesheet_updates_notifications'),
        });
    }

    if (isAdvancedEmployeeProfileFeatureEnabled) {
        columns.push({
            field: 'profileChangeNotificationsEnabled',
            type: 'booleanTick',
            headerName: t('employees_notifications_page.table_headers.profile_change_notifications'),
        });
    }

    if (isDocumentFeatureEnabled) {
        columns.push({
            field: 'documentNotificationsEnabled',
            type: 'booleanTick',
            headerName: t('employees_notifications_page.table_headers.document_notifications'),
        });
    }

    if (isPlanningFeatureEnabled) {
        columns.push({
            field: 'planningUpdatesNotificationsEnabled',
            type: 'booleanTick',
            headerName: t('employees_notifications_page.table_headers.planning_updates_notifications'),
        });
    }

    columns.push({
        field: 'announcementNotificationsEnabled',
        type: 'booleanTick',
        headerName: t('employees_notifications_page.table_headers.announcement_notifications'),
    });

    return columns;
};

type EmployeesNotificationsFilter = AsyncSelectFilter | AsyncTreeSelectFilterType;
const useEmployeesNotificationsPageFilters = (): { filters: EmployeesNotificationsFilter[] } => {
    const filters: EmployeesNotificationsFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'tree-multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departmentsNodes = await getDepartmentNodes();
                return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
        },
    ];

    return {
        filters,
    };
};
