import { View } from '@react-pdf/renderer';
import { FC } from 'react';
import { Objective } from '@/domain/objective/Objective.model';
import { useTheme } from '@mui/material';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReviewSummaryFeedbackItemsFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewSummaryFeedbackQuestionPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/EmployeeReviewSummaryFeedbackQuestionPDF';
import { EmployeeReviewFeedbackSectionPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackSection';
import { EmployeeReviewFeedbackObjectivesPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackObjectivePDF';
import { EmployeeReviewSummaryFeedbackSkillsPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/EmployeeReviewSummaryFeedbackSkillsPDF';
import { EmployeeReviewFeedbackLayoutPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackLayoutPDF';
import { showCompletedReviewSummary } from '@/domain/employee-review/EmployeeReview.service';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeReviewSummaryCompletedPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/EmployeeReviewSummaryCompletedPDF';
import { UserLanguage } from '@/utils/language.util';

type EmployeeReviewSummaryFeedbackPDFProps = {
    employeeReviewManagerSummary: EmployeeReviewManagerSummary;
    employeeReviewSummaryFeedbackItems: EmployeeReviewSummaryFeedbackItemsFormType;
    objectives: Objective[];
    employeeReview: EmployeeReview;
    userLanguage: UserLanguage;
};

export const EmployeeReviewSummaryFeedbackPDF: FC<EmployeeReviewSummaryFeedbackPDFProps> = ({
    objectives,
    employeeReviewSummaryFeedbackItems,
    employeeReviewManagerSummary,
    employeeReview,
    userLanguage,
}) => {
    const theme = useTheme();

    return (
        <EmployeeReviewFeedbackLayoutPDF employeeReviewFeedback={employeeReviewManagerSummary} employeeReview={employeeReview}>
            {showCompletedReviewSummary(employeeReview, employeeReviewManagerSummary) && (
                <EmployeeReviewSummaryCompletedPDF employeeReviewManagerSummary={employeeReviewManagerSummary} userLanguage={userLanguage} />
            )}
            <View
                style={{
                    gap: theme.spacing(2),
                }}
            >
                {!!employeeReviewSummaryFeedbackItems.length &&
                    employeeReviewSummaryFeedbackItems.map(summaryFeedbackItem => {
                        switch (summaryFeedbackItem.type) {
                            case 'QUESTION':
                            case 'SKILL':
                                return (
                                    <EmployeeReviewSummaryFeedbackQuestionPDF
                                        key={summaryFeedbackItem.id}
                                        questionItem={summaryFeedbackItem}
                                        rating={summaryFeedbackItem.rating}
                                    />
                                );
                            case 'SECTION':
                                return <EmployeeReviewFeedbackSectionPDF key={summaryFeedbackItem.id} sectionItem={summaryFeedbackItem} />;
                            case 'REVIEW_OBJECTIVES':
                                return <EmployeeReviewFeedbackObjectivesPDF key={summaryFeedbackItem.id} objectives={objectives} />;
                            case 'SKILLS':
                                return <EmployeeReviewSummaryFeedbackSkillsPDF key={summaryFeedbackItem.id} skills={summaryFeedbackItem} />;
                            default:
                                return <></>;
                        }
                    })}
            </View>
        </EmployeeReviewFeedbackLayoutPDF>
    );
};
