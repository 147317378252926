import { AvatarCell, EmployeeCell, StackedAvatarsCell } from '@/components/ag-grid-wrapper/column-types/Employee';
import { EmployeeSectionDocumentLink } from '@/components/section/EmployeeSectionDocumentLink/EmployeeSectionDocumentLink';
import { SectionField } from '@/components/section/types';
import { getEmployeeDocumentDownloadUrl } from '@/domain/document/Document.service';
import { getEmployeeSectionFieldDocumentUrl } from '@/domain/employee-section/EmployeeSection.service';
import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { Label } from '@/domain/label/Label.model';
import { FieldDefinition, SectionFieldValue } from '@/domain/section-setting/Section.model';
import { getEnumFieldValueTranslation } from '@/domain/section-setting/Section.service';
import { getCountry } from '@/utils/countries.util';
import { formatDurationInHours, formatInDefaultDate } from '@/utils/datetime.util';

import { getLabelTranslation } from '@/utils/language.util';
import { getRoundedNumber, RoundingType } from '@/utils/math.util';
import { formatIBAN, formatPhoneNumber, localeCompareString } from '@/utils/strings.util';
import { Stack } from '@mui/material';
import { ColDef, ValueFormatterFunc } from 'ag-grid-community';
import { Tick02Icon } from 'hugeicons-react';
import i18next from 'i18next';

export const useColumnTypes = (
    compact: boolean,
): {
    date: ColDef<unknown, LocalDate>;
    documents: ColDef<unknown, SectionField>;
    sectionFieldDocuments: ColDef<unknown, SectionField>;
    label: ColDef<unknown, Label>;
    employee: ColDef<unknown, EmployeeAvatarType>;
    stackedAvatars: ColDef<unknown, EmployeeAvatarType[]>;
    avatar: ColDef<unknown, EmployeeAvatarType>;
    actionMenu: ColDef;
    minutesToHours: ColDef<unknown, number>;
    hours: ColDef<unknown, number>;
    fieldDefinition: ColDef<unknown, Partial<FieldDefinition>>;
    fieldValue: ColDef<unknown, SectionFieldValue>;
    booleanTick: ColDef<unknown, boolean>;
} => {
    const dateValueFormatter: ValueFormatterFunc<unknown, LocalDate> = ({ value }) => (value ? formatInDefaultDate(value) : '');

    const date: ColDef<unknown, LocalDate> = {
        valueFormatter: dateValueFormatter,
        getQuickFilterText: dateValueFormatter,
        cellClass: ['dateCH'],
    };

    const documentValueFormatter: ValueFormatterFunc<unknown, SectionField> = ({ value }) =>
        value?.documents ? value.documents.map(d => d.name).join(', ') : '';

    const documents: ColDef<unknown, SectionField> = {
        autoHeight: true,
        // use for export
        valueFormatter: documentValueFormatter,
        getQuickFilterText: documentValueFormatter,
        cellRenderer: ({ value }: { value: SectionField }) => {
            return (
                <Stack direction={'row'} columnGap={1} rowGap={0} flexWrap={'wrap'}>
                    {value?.documents?.map(document => {
                        return (
                            <EmployeeSectionDocumentLink
                                document={document}
                                onDownload={contentDisposition => getEmployeeDocumentDownloadUrl(document.id, contentDisposition)}
                                key={document.id}
                            />
                        );
                    })}
                </Stack>
            );
        },
        cellClass: ['display-flex'],
    };

    // We are using the same valueFormatter for documents and sectionFieldDocuments
    const sectionFieldDocumentsValueFormatter: ValueFormatterFunc<unknown, SectionField> = documentValueFormatter;

    const sectionFieldDocuments: ColDef<unknown, SectionField> = {
        // use for export
        valueFormatter: sectionFieldDocumentsValueFormatter,
        getQuickFilterText: sectionFieldDocumentsValueFormatter,
        cellRenderer: ({ value }: { value: SectionField }) => {
            return (
                <Stack direction={'row'} columnGap={1} rowGap={0} flexWrap={'wrap'}>
                    {value?.documents?.map(document => {
                        return (
                            <EmployeeSectionDocumentLink
                                document={document}
                                onDownload={contentDisposition => getEmployeeSectionFieldDocumentUrl(document.id, contentDisposition)}
                                key={document.id}
                            />
                        );
                    })}
                </Stack>
            );
        },
        autoHeight: true,
        cellClass: ['display-flex'],
    };

    const labelValueFormatter: ValueFormatterFunc<unknown, Label> = ({ value }) => getLabelTranslation(value);

    const label: ColDef<unknown, Label> = {
        valueFormatter: labelValueFormatter,
        getQuickFilterText: labelValueFormatter,
        comparator: (value1, value2) => {
            const value1String = getLabelTranslation(value1);
            const value2String = getLabelTranslation(value2);
            return localeCompareString(value1String, value2String);
        },
    };

    const getEmployeeDisplayName = (value: Nullable<EmployeeAvatarType>) => value?.displayName ?? '';
    const employeeValueFormatter: ValueFormatterFunc<unknown, EmployeeAvatarType> = ({ value }) => getEmployeeDisplayName(value);

    const employee: ColDef<unknown, EmployeeAvatarType> = {
        valueFormatter: employeeValueFormatter,
        getQuickFilterText: employeeValueFormatter,
        comparator: (a, b) => {
            const aDisplayName = getEmployeeDisplayName(a);
            const bDisplayName = getEmployeeDisplayName(b);
            return localeCompareString(aDisplayName, bDisplayName);
        },
        cellRenderer: ({ value, cellNavDisabled = false }: { cellNavDisabled: boolean; value: EmployeeAvatarType }) => (
            <EmployeeCell value={value} compact={compact} navDisabled={cellNavDisabled} />
        ),
        cellClass: ['display-flex'],
    };

    const stackedAvatarsValueFormatter: ValueFormatterFunc<unknown, EmployeeAvatarType[]> = ({ value }) => value?.map(e => e.displayName).join(', ') ?? '';

    const stackedAvatars: ColDef<unknown, EmployeeAvatarType[]> = {
        cellRenderer: ({ value, cellNavDisabled }: { cellNavDisabled: boolean; value: EmployeeAvatarType[] }) => (
            <StackedAvatarsCell value={value} compact={compact} cellNavDisabled={cellNavDisabled} />
        ),
        // use for export and sorting
        valueFormatter: stackedAvatarsValueFormatter,
        getQuickFilterText: stackedAvatarsValueFormatter,
        // We have to defined comparator because valueFormatter sorting doesn't work when field is not defined (ex in review caused by flatmap )
        comparator: (a, b) => {
            const aDisplayName = getEmployeeDisplayName(a?.at(0));
            const bDisplayName = getEmployeeDisplayName(b?.at(0));
            return localeCompareString(aDisplayName, bDisplayName);
        },
        cellClass: ['display-flex', 'full-width'],
    };

    const avatar: ColDef<unknown, EmployeeAvatarType> = {
        cellRenderer: ({ value }: { value: EmployeeAvatarType }) => (value ? <AvatarCell value={value} /> : ''),
        cellClass: ['display-flex'],
        resizable: false,
        sortable: false,
        width: 60,
    };

    const actionMenu: ColDef = {
        width: 52,
        maxWidth: 52,
        lockPinned: true,
        pinned: 'right',
        suppressSizeToFit: true,
        resizable: false,
        sortable: false,
        cellClass: ['display-flex'],
    };

    const minutesToHoursValueFormatter: ValueFormatterFunc<unknown, number> = ({ data, colDef }) => {
        if (!data || !colDef.field || !data[colDef.field]) {
            return '-';
        }
        return formatDurationInHours(data[colDef.field] as number);
    };

    /**
     * @deprecated
     * This column type is deprecated and should not be used anymore.
     * Use the `hours` column type instead.
     **/
    const minutesToHours: ColDef<unknown, number> = {
        // Convert the value from minutes to hours to fix the export issue with number values
        // This is the value used in the export function from ag grid
        valueGetter: ({ data, colDef }) => {
            if (data && colDef.field) {
                return getRoundedNumber((data[colDef.field] as number) / 60, RoundingType.NEAREST_2_DECIMALS);
            }
            return 0;
        },
        // format the number to display in ag grid (this value wont be used in the export)
        valueFormatter: minutesToHoursValueFormatter,
        getQuickFilterText: minutesToHoursValueFormatter,
    };

    /**
     * Column type to display the hours in the grid
     * Value is expected to be in minutes
     **/
    const hours: ColDef<unknown, number> = {
        valueFormatter: ({ value }) => (value ? formatDurationInHours(value) : '-'),
        getQuickFilterText: ({ value }) => (value ? formatDurationInHours(value) : ''),
    };

    const fieldDefinitionValueFormatter: ValueFormatterFunc<unknown, Partial<FieldDefinition>> = ({ value }) =>
        value ? getFieldDefinitionTranslation(value) : '';
    const fieldDefinition: ColDef<unknown, Partial<FieldDefinition>> = {
        valueFormatter: fieldDefinitionValueFormatter,
        getQuickFilterText: fieldDefinitionValueFormatter,
    };

    const booleanTick: ColDef<unknown, boolean> = {
        cellRenderer: ({ value }: { value: boolean }) => (value ? <Tick02Icon /> : ''),
        cellClass: ['display-flex'],
        cellStyle: { justifyContent: 'center' },
    };

    const fieldValueValueFormatter: ValueFormatterFunc<unknown, SectionFieldValue> = ({ value }) => {
        // TODO add yup validation
        // This is a hack to fix excel export issue with empty string
        // If empty ag grid will use valueGetter to get the value and it will return [object Object]
        const emptyValue = ' ';

        if (!value?.sectionFieldDefinition) {
            return emptyValue;
        }
        const fieldValue = getFieldValue(value);

        if (!fieldValue?.trim()) {
            return emptyValue;
        }
        return fieldValue;
    };

    const fieldValue: ColDef<unknown, SectionFieldValue> = {
        // valueFormatter is very important for excel export
        valueFormatter: fieldValueValueFormatter,
        getQuickFilterText: fieldValueValueFormatter,
        comparator: function (value1, value2) {
            /*
            0 valueA is the same as valueB
            > 0 Sort valueA after valueB
            < 0 Sort valueA before valueB
             */
            if (value1 && value2) {
                const value1String = getFieldValue(value1);
                const value2String = getFieldValue(value2);
                return localeCompareString(value1String, value2String);
            }
            if (!value1 && !value2) {
                return 0;
            }
            if (!value1) {
                return -1;
            }
            return 1;
        },
        // By adding a cell Renderer we are decreasing the performance of the grid
        // If needed we have to define another column type without cellRenderer
        cellRenderer: ({ value }: { value: SectionFieldValue }) => {
            if (!value?.sectionFieldDefinition) {
                return '';
            }

            const valueType = value.sectionFieldDefinition.valueType;

            // Add other value types here if needed
            if (valueType === 'SECTION_FIELD_DOCUMENT') {
                return (
                    <Stack direction={'row'} columnGap={1} rowGap={0} flexWrap={'wrap'}>
                        {value?.documents?.map(document => {
                            return (
                                <EmployeeSectionDocumentLink
                                    document={document}
                                    onDownload={contentDisposition => getEmployeeSectionFieldDocumentUrl(document.id, contentDisposition)}
                                    key={document.id}
                                />
                            );
                        })}
                    </Stack>
                );
            }

            const fieldValue = getFieldValue(value);

            return fieldValue?.trim() ?? '';
        },
    };

    return {
        actionMenu,
        // Label
        label,
        // Employee
        employee,
        avatar,
        stackedAvatars,
        // Documents
        sectionFieldDocuments,
        documents,
        // Date
        date,
        minutesToHours,
        hours,
        // Custom column type to display data structure based on field definition
        fieldDefinition,
        fieldValue,
        booleanTick,
    };
};

export const getFieldDefinitionTranslation = (sectionFieldDefinition: Partial<FieldDefinition>): string => {
    if (sectionFieldDefinition?.id) {
        return getLabelTranslation(sectionFieldDefinition.name);
    }

    if (!sectionFieldDefinition?.fieldType) {
        return '';
    }

    return i18next.t('field_definition_type.enum', {
        context: sectionFieldDefinition.fieldType.replace('CURRENT_', ''),
        defaultValue: sectionFieldDefinition.fieldType,
    });
};

const getFieldValue = (field: SectionFieldValue): string => {
    switch (field.sectionFieldDefinition.valueType) {
        case 'DATE':
            return formatInDefaultDate(field.dateValue);
        case 'NUMBER':
            return field.numberValue?.toString() ?? '';
        case 'STRING':
        case 'AVS_NUMBER':
        case 'EMAIL':
        case 'AUTO_INCREMENT':
            return field.stringValue ?? '';
        case 'PHONE_NUMBER':
            return formatPhoneNumber(field.stringValue ?? '');
        case 'IBAN_NUMBER':
            return formatIBAN(field.stringValue ?? '');
        case 'SECTION_FIELD_DOCUMENT':
            return field.documents.map(d => d.name).join(', ');
        case 'COUNTRY':
            return field.stringValue ? getCountry(field.stringValue).label : '';
        case 'LABEL':
            return field.labelValue ? getLabelTranslation(field.labelValue) : '';
        case 'ENUM':
            return field.stringValue ? getEnumFieldValueTranslation(field.sectionFieldDefinition.fieldType, field.stringValue) : '';
        case 'CUSTOM_LIST_ITEM':
        case 'CUSTOM_MULTI_LIST_ITEM':
            return field.customListItemReferences?.map(c => getLabelTranslation(c.label)).join(', ');
        case 'EMPLOYEE':
            if (field.employeeReferences.length) {
                return field.employeeReferences?.map(e => e.displayName).join(', ');
            }

            // Type doesn't accept null or undefined but api can return null instead of empty array
            return field.employeeValues?.map(e => e.displayName).join(', ');
        default:
            return field.sectionFieldDefinition.valueType;
    }
};
