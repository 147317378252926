import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { Objective } from '@/domain/objective/Objective.model';
import { getNestedValueByPath } from '@/components/filters-bar/FiltersBar.util';

export const getObjectivesFiltered = (filters?: (AsyncSelectFilter | SelectFilter)[], objectives: Objective[] = []): Objective[] => {
    // if no filters are applied, we return the original data
    const filtersFilled = filters?.filter(
        filter =>
            !!filter.value?.length &&
            // statusCompletionIds is filtered on server side
            filter.key !== 'statusCompletionIds',
    );
    if (!filtersFilled?.length) {
        return objectives;
    }

    const isFilterMatched = (filter: AsyncSelectFilter | SelectFilter, row: Objective) => {
        const valueFromRow = getNestedValueByPath(row, filter.key);
        return filter.value?.find(option => option.value === valueFromRow);
    };

    return objectives.filter(row => {
        // if one of the filter is not matching, we don't want to display the row
        return filtersFilled.every(filter => isFilterMatched(filter, row));
    });
};
