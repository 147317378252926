import * as yup from 'yup';
import { isRealmLanguage, UserLanguage } from '@/utils/language.util';
import i18next from 'i18next';
import { Label } from '@/domain/label/Label.model';

const isLanguageRequired = (translationLanguage: UserLanguage | undefined, language: UserLanguage, isRequired: boolean): boolean => {
    return translationLanguage === language && isRequired;
};

const isDefaultLanguageRequired = (language: UserLanguage, isRequired: boolean): boolean => {
    return isRealmLanguage(language) && isRequired;
};

// Todo: refactor this to improve DX : isRequired = !!translationLanguage is not meaningful
export const getLabelFormSchema = (translationLanguage?: UserLanguage): yup.ObjectSchema<Label> => {
    const isRequired = !!translationLanguage;

    const createTranslationSchema = (language: UserLanguage) =>
        yup
            .string()
            .when({
                is: () => isLanguageRequired(translationLanguage, language, isRequired),
                then: schema => schema.trim().min(1, i18next.t('general.validations.required')).required(),
            })
            .when({
                is: () => isDefaultLanguageRequired(language, isRequired),
                then: schema => schema.trim().min(1, i18next.t('label.validations.default_required')).required(),
            })
            .default('');

    return yup.object().shape({
        id: yup.number().nullable(),
        translationEn: createTranslationSchema(UserLanguage.EN).nullable(),
        translationFr: createTranslationSchema(UserLanguage.FR).nullable(),
        translationDe: createTranslationSchema(UserLanguage.DE).nullable(),
        translationIt: createTranslationSchema(UserLanguage.IT).nullable(),
        translationEs: createTranslationSchema(UserLanguage.ES).nullable(),
        translationPt: createTranslationSchema(UserLanguage.PT).nullable(),
    });
};
