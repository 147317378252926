import { FC, useState } from 'react';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, DialogActions, FormControlLabel, Stack, Typography } from '@mui/material';
import { Department, DepartmentNode, DepartmentUpdateMutation } from '@/domain/department/Department.model';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { ArrowRight02Icon } from 'hugeicons-react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getLocalDateTestConfig, isAfterDate } from '@/utils/datetime.util';
import { archiveDepartment, createDepartment, updateDepartment, updateDepartmentOrders } from '@/domain/department/Department.service';
import { handleError } from '@/utils/api.util';
import { Employee } from '@/domain/employee/Employee.model';
import {
    Employment,
    EmploymentBulkCreateMutationItem,
    EmploymentBulkMutation,
    EmploymentBulkUpdateMutationItem,
    EmploymentCreateReason,
} from '@/domain/employment/Employment.model';
import { bulkCreateEmployment } from '@/domain/employment/Employment.service';
import { getLabelTranslation } from '@/utils/language.util';
import { useGetEmployments } from '@/hooks/employment/Employment.hook';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { findRecursiveItemById, findRecursiveItemParent, findRecursiveItemParents } from '@/utils/object.util';

type DepartmentMoveDialogProps = {
    departmentNodes: DepartmentNode[];
    departmentNode: DepartmentNode;
    newParentId: number | undefined; // undefined means the department is moved to the root
    newIndex: number;
    onSave?: () => void;
} & DialogWrapperProps;

export const DepartmentMoveDialog: FC<DepartmentMoveDialogProps> = ({ departmentNodes, departmentNode, newParentId, newIndex, onSave, ...restDialog }) => {
    const [employeeIdsToMove, setEmployeeIdsToMove] = useState<number[]>([]);
    const [openEmployeeSelectionDialog, setOpenEmployeeSelectionDialog] = useState(false);

    const { t } = useTranslation();

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(getSchema()),
        defaultValues: {
            applyChangesInTheFuture: false,
        },
    });
    const [applyChangesInTheFuture, effectiveDate] = useWatch({
        control,
        name: ['applyChangesInTheFuture', 'effectiveDate'],
    });

    const { data: employees = [] } = useGetEmployees();

    const enableSearchEmployments = !!effectiveDate;

    // search current and future
    // BE should accept a departmentIds array
    const { data: employments = [], isLoading: isEmploymentsLoading } = useGetEmployments(
        { departmentIds: [departmentNode.id], fromDate: effectiveDate, principal: true },
        { enabled: enableSearchEmployments },
    );

    const employeesInDepartment = employments.reduce<Employee[]>((acc, employment) => {
        if (!acc.some(e => e.id === employment.employeeId)) {
            const foundEmployee = employees.find(e => e.id === employment.employeeId);
            if (foundEmployee) {
                return [...acc, foundEmployee];
            }
        }
        return acc;
    }, []);

    useDeepCompareEffect(() => {
        if (enableSearchEmployments) {
            setEmployeeIdsToMove(employeesInDepartment.map(e => e.id));
        }
    }, [employeesInDepartment, enableSearchEmployments]);

    const previousHierarchy = findRecursiveItemParents(departmentNodes, departmentNode.id);
    const newParentItem = newParentId ? findRecursiveItemById(departmentNodes, newParentId) : undefined;
    const newHierarchy = newParentItem ? [...findRecursiveItemParents(departmentNodes, newParentItem.id), newParentItem] : undefined;

    const handleSave = async ({ applyChangesInTheFuture, effectiveDate }: DepartmentMoveFormValues) => {
        const shouldCreateNewEmployments = applyChangesInTheFuture && effectiveDate;

        try {
            if (shouldCreateNewEmployments) {
                const filteredEmployments = employments.filter(e => employeeIdsToMove.includes(e.employeeId));
                await archiveAndCreateNewDepartment({
                    departmentNodes,
                    departmentNode,
                    newParentId,
                    newIndex,
                    effectiveDate,
                    employments: filteredEmployments,
                });
            } else {
                await moveIntoOtherParent({
                    departmentNodes,
                    departmentNode,
                    newParentId,
                    newIndex,
                });
            }
            restDialog.onClose();
            onSave?.();
        } catch (error) {
            handleError(error);
        }
    };

    const getFullPath = (parents: DepartmentNode[]) => {
        const allNodeNames = [...parents.map(p => getLabelTranslation(p.name)), getLabelTranslation(departmentNode.name)];
        return allNodeNames.join(' / ');
    };

    return (
        <DialogWrapper {...restDialog} header={t('settings_organization.departments.move_department_dialog.title')} maxWidth={'md'}>
            <Stack gap={3} component={DialogContent}>
                <FieldSwitch
                    name={'applyChangesInTheFuture'}
                    control={control}
                    label={t('settings_organization.departments.move_department_dialog.apply_changes_in_the_future')}
                    labelPlacement={'end'}
                />

                {applyChangesInTheFuture && (
                    <FormControlLabel
                        control={<FieldLocalDate control={control} name={'effectiveDate'} />}
                        label={t('settings_organization.departments.move_department_dialog.effective_date')}
                    />
                )}
                {effectiveDate && !isEmploymentsLoading && (
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography>
                            {t('settings_organization.departments.move_department_dialog.employeesToMove', {
                                nbEmployeesToMove: employeeIdsToMove.length,
                                totalEmployees: employeesInDepartment.length,
                            })}
                        </Typography>
                        <Stack direction={'row'} alignItems={'center'}>
                            <StackedAvatars employeeAvatars={employeesInDepartment.filter(e => employeeIdsToMove.includes(e.id))} />
                            {employeesInDepartment.length > 0 && (
                                <Button onClick={() => setOpenEmployeeSelectionDialog(true)} size={'small'} variant={'text'}>
                                    {t('settings_organization.departments.move_department_dialog.chooseEmployees')}
                                </Button>
                            )}
                            {openEmployeeSelectionDialog && (
                                <EmployeeSelectionToMoveDialog
                                    employees={employeesInDepartment}
                                    employeeIdsToMove={employeeIdsToMove}
                                    onSave={selectedEmployeeIds => {
                                        setEmployeeIdsToMove(selectedEmployeeIds);
                                        setOpenEmployeeSelectionDialog(false);
                                    }}
                                    disabledEmployeeIds={[]}
                                    open={openEmployeeSelectionDialog}
                                    onClose={() => setOpenEmployeeSelectionDialog(false)}
                                />
                            )}
                        </Stack>
                    </Stack>
                )}
                <Stack>
                    <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                        {t('settings_organization.departments.move_department_dialog.parents_changes')}
                    </Typography>

                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                        <Typography
                            variant={'body1bold'}
                            sx={{
                                flex: 1,
                                textDecoration: 'line-through',
                                color: 'red',
                            }}
                        >
                            {getFullPath(previousHierarchy)}
                        </Typography>
                        <Stack direction={'row'} alignItems={'center'} gap={2} flex={1}>
                            <ArrowRight02Icon />
                            <Typography>{getFullPath(newHierarchy ?? [])}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} fullWidth={true}>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type EmployeeSelectionToMoveDialogProps = {
    employees: Employee[];
    employeeIdsToMove: number[];
    onSave: (ids: number[]) => void;
    disabledEmployeeIds: number[];
} & DialogWrapperProps;
const EmployeeSelectionToMoveDialog: FC<EmployeeSelectionToMoveDialogProps> = ({
    employees,
    employeeIdsToMove,
    onSave,
    disabledEmployeeIds,
    ...restDialog
}) => {
    const [employeesSelected, setEmployeesSelected] = useState<number[]>(employeeIdsToMove);
    const { t } = useTranslation();

    const isDisabled = (employee: Employee) => {
        return disabledEmployeeIds.includes(employee.id);
    };

    const handleToggleEmployee = (employee: Employee, checked: boolean) => {
        setEmployeesSelected(prev => (checked ? [...prev, employee.id] : prev.filter(id => id !== employee.id)));
    };
    return (
        <DialogWrapper header={t('settings_organization.departments.move_department_dialog.select_employees_title_dialog')} {...restDialog}>
            <Stack component={DialogContent}>
                {employees.map(employee => (
                    <FormControlLabel
                        key={employee.id}
                        label={
                            employee.displayName +
                            (isDisabled(employee) ? ` (${t('settings_organization.departments.move_department_dialog.unable_to_move')})` : '')
                        }
                        labelPlacement={'end'}
                        control={
                            <Checkbox
                                checked={employeesSelected.includes(employee.id)}
                                onChange={(_, checked) => {
                                    handleToggleEmployee(employee, checked);
                                }}
                                disabled={isDisabled(employee)}
                            />
                        }
                    />
                ))}
            </Stack>
            <DialogActions>
                <Button onClick={() => onSave(employeesSelected)} fullWidth>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getSchema = () => {
    return yup.object().shape({
        applyChangesInTheFuture: yup.boolean().required().default(false),
        effectiveDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .when('applyChangesInTheFuture', {
                is: true,
                then: schema => schema.required(),
            }),
    });
};
type DepartmentMoveFormValues = yup.InferType<ReturnType<typeof getSchema>>;

type MoveIntoOtherParentParams = {
    departmentNodes: DepartmentNode[];
    departmentNode: DepartmentNode;
    newParentId: number | undefined;
    newIndex: number;
};
const moveIntoOtherParent = async ({ departmentNodes, departmentNode, newParentId, newIndex }: MoveIntoOtherParentParams) => {
    // change the department parent
    try {
        const updateMutation: DepartmentUpdateMutation = {
            name: departmentNode.name,
            order: newIndex,
            parentId: newParentId,
            managerIds: departmentNode.managers.map(m => m.id),
            departmentCostCenters: departmentNode.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
        };
        await updateDepartment(departmentNode.id, updateMutation);
    } catch (error) {
        handleError(error);
    }

    // build mutations of the previous children it was in
    // an undefined parent means the department is at the root
    const parentNode = findRecursiveItemParent(departmentNodes, departmentNode.id);
    const childrenSource = parentNode?.children ?? departmentNodes;
    const childrenSourceOrderMutation = childrenSource
        .filter(child => child.id !== departmentNode.id) // exclude the moved item
        .map((child, index) => ({
            resourceId: child.id,
            order: index,
        }));

    // build mutations of the new children with the moved item
    // an undefined parentId means the department is at the root
    const childrenDestination = newParentId ? (findRecursiveItemById(departmentNodes, newParentId)?.children ?? []) : departmentNodes;
    const newChildrenDestination = [...childrenDestination];
    newChildrenDestination.splice(newIndex, 0, departmentNode); // insert the moved item

    const childrenDestinationOrderMutation = newChildrenDestination.map((child, index) => ({
        resourceId: child.id,
        order: index,
    }));

    // reorder separately the source and destination children (each branch of the tree has its own order)
    try {
        await updateDepartmentOrders([...childrenSourceOrderMutation, ...childrenDestinationOrderMutation]);
    } catch (error) {
        handleError(error);
    }
};

type ArchiveAndCreateNewDepartment = MoveIntoOtherParentParams & {
    effectiveDate: LocalDate;
    employments: Employment[];
};
const archiveAndCreateNewDepartment = async ({
    departmentNodes,
    departmentNode,
    newParentId,
    newIndex,
    effectiveDate,
    employments,
}: ArchiveAndCreateNewDepartment) => {
    try {
        // 1- Create a new department, same name but different parent
        const newDepartment = await createDepartment({
            name: departmentNode.name,
            parentId: newParentId,
            managerIds: departmentNode.managers.map(m => m.id),
            departmentCostCenters: departmentNode.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
            order: newIndex,
        });

        // 2- archive the moved department
        await archiveDepartment(departmentNode.id);

        // 3- Create new employment for each employee with the new department
        const bulkMutations = buildBulkEmploymentMutations(employments, newDepartment, effectiveDate);
        if (bulkMutations.length) {
            await bulkCreateEmployment(bulkMutations);
        }

        // 4- Reorder the new branch
        // build mutations of the new children with the moved item
        // an undefined parentId means the department is at the root
        const childrenDestination = newParentId ? (findRecursiveItemById(departmentNodes, newParentId)?.children ?? []) : departmentNodes;
        const newChildrenDestination = [...childrenDestination];
        newChildrenDestination.splice(newIndex, 0, departmentNode); // insert the moved item

        const childrenDestinationOrderMutation = newChildrenDestination.map((child, index) => ({
            resourceId: child.id,
            order: index,
        }));
        await updateDepartmentOrders(childrenDestinationOrderMutation);
    } catch (error) {
        handleError(error);
    }
};

const buildBulkEmploymentMutations = (employments: Employment[], department: Department, effectiveDate: LocalDate) => {
    // For future employments and not selected employees, we just want to change the parent => UPDATE
    // For ongoing employments and selected employees, we want to clone the employment with the new parent => CREATE
    return employments.reduce<EmploymentBulkMutation[]>((acc, employment) => {
        const isUpdate = isAfterDate(employment.startDate, effectiveDate);

        if (isUpdate) {
            const updateRequest = {
                departmentId: department.id,
                employmentCostCenters: department.departmentCostCenters.map(dcc => ({
                    costCenterId: dcc.costCenter.id,
                    percentage: dcc.percentage,
                })),
                locationId: employment.location.id,
                jobId: employment.job.id,
                jobFamilyId: employment.jobFamily?.id,
                managerIds: employment.managers.map(m => m.id),
            } satisfies EmploymentBulkUpdateMutationItem;

            return [
                ...acc,
                {
                    employeeId: employment.employeeId,
                    employmentId: employment.id,
                    action: 'UPDATE',
                    updateRequest: updateRequest,
                } satisfies EmploymentBulkMutation,
            ];
        }

        const createRequest = {
            // updated attributes
            departmentId: department.id,
            startDate: effectiveDate,
            employmentCostCenters: department.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
            employmentCreateReason: EmploymentCreateReason.NEW_ENTITY,
            // fill values from the employment
            managerIds: employment.managers.map(m => m.id),
            principal: employment.principal,
            contractType: employment.contractType,
            locationId: employment.location.id,
            jobId: employment.job.id,
            jobFamilyId: employment.jobFamily?.id,
            // we clone only the employment principal, the percentage is always 100
            percentage: 100,
        } satisfies EmploymentBulkCreateMutationItem;

        const existsMutation = acc.some(m => m.employeeId === employment.employeeId);

        if (existsMutation) {
            return acc.map(mutation => {
                if (mutation.employeeId !== employment.employeeId && mutation.action === 'CREATE') {
                    return {
                        ...mutation,
                        creationRequests: [...mutation.creationRequests, createRequest],
                    } satisfies EmploymentBulkMutation;
                }
                return mutation;
            });
        } else {
            return [
                ...acc,
                {
                    employeeId: employment.employeeId,
                    action: 'CREATE',
                    creationRequests: [createRequest],
                } satisfies EmploymentBulkMutation,
            ];
        }
    }, [] as EmploymentBulkMutation[]);
};
