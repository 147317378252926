import { Employment, EmploymentsSearchRequest } from '@/domain/employment/Employment.model';
import { getContracts, getEmployments } from '@/domain/employment/Employment.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployments = (
    employmentSearchMutation: EmploymentsSearchRequest = { employeeIds: [], departmentIds: [] },
    { enabled }: { enabled: boolean } = { enabled: true },
): UseQueryResult<Employment[]> => {
    const [employment, setEmployment] = useState<Employment[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployment = useCallback(
        async (searchRequest: EmploymentsSearchRequest) => {
            if (!enabled) {
                return;
            }

            try {
                const employmentData = await getEmployments(searchRequest);
                setEmployment(employmentData);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployment(employmentSearchMutation).catch(handleError);
    }, [fetchEmployment, employmentSearchMutation]);

    return {
        data: employment,
        setData: setEmployment,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployment(employmentSearchMutation),
    };
};

export const useGetContracts = (
    employmentSearchMutation: EmploymentsSearchRequest = { employeeIds: [], departmentIds: [] },
    { enabled }: { enabled: boolean } = { enabled: true },
): UseQueryResult<Employment[]> => {
    const { data: employments = [], ...rest } = useGetEmployments(employmentSearchMutation, { enabled });

    return {
        data: getContracts(employments),
        ...rest,
    };
};
