import { FC, Fragment } from 'react';
import { Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { Department } from '@/domain/department/Department.model';
import { getLabelTranslation } from '@/utils/language.util';

type DepartmentHierarchyLabelProps = {
    departmentId: number;
} & StackProps;
/**
 * Department hierarchy label component to display the hierarchy of a department.
 * @param departmentId The id of the department to display its hierarchy.
 * @param rootProps The props of the root Stack component.
 * @returns The DepartmentHierarchyLabel component like a breadcrumb.
 */
export const DepartmentHierarchyLabel: FC<DepartmentHierarchyLabelProps> = ({ departmentId, ...rootProps }) => {
    const { data: departments = [] } = useGetDepartments();

    const getParents = (id: number): Department[] => {
        const department = departments.find(department => department.id === id);
        if (!department) {
            return [];
        }
        if (!department.parentId) {
            return [department];
        }

        return [...getParents(department.parentId), department];
    };

    const parents = getParents(departmentId);
    const SEPARATOR = '/';
    return (
        <Stack {...rootProps} direction={'row'} gap={0.25}>
            <Tooltip title={parents.map(parent => getLabelTranslation(parent.name)).join(' / ')}>
                <div>
                    {parents.map((item, index) => (
                        <Fragment key={item.id}>
                            <Typography component='span' overflow='hidden' textOverflow='ellipsis' flexShrink={parents.length - index} noWrap>
                                {getLabelTranslation(item.name)}
                            </Typography>
                            {index < parents.length - 1 && <Typography component='span'>{SEPARATOR}</Typography>}
                        </Fragment>
                    ))}
                </div>
            </Tooltip>
        </Stack>
    );
};
