import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigType } from '../types';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { handleError } from '@/utils/api.util';
import { ScaleDialog } from './scale-dialog/ScaleDialog';
import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import {
    createCompletionStatus,
    deleteCompletionStatus,
    searchCompletionStatuses,
    updateCompletionStatus,
    updateCompletionStatusOrder,
} from '@/domain/objective-completion-status/ObjectiveCompletionStatus.service';
import { ObjectiveCompletionStatus } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.model';
import { mapToReorderRequest } from '@/domain/common';
import { getLabelTranslation } from '@/utils/language.util';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { Label } from '@/domain/label/Label.model';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';

export const ScaleSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [completionStatuses, setCompletionStatuses] = useState<ObjectiveCompletionStatus[]>([]);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
    const [activeCompletionStatus, setActiveCompletionStatus] = useState<
        Omit<ObjectiveCompletionStatus, 'id'> & {
            id?: number;
        }
    >();

    const fetchCompletionStatuses = async () => {
        try {
            const data = await searchCompletionStatuses({});
            setCompletionStatuses(data);
        } catch (e) {
            handleError(e);
        }
    };

    useEffect(() => {
        fetchCompletionStatuses().catch(handleError);
    }, []);

    const onClickCompletionScaleRow = (row: RowClickedEvent<ObjectiveCompletionStatus>) => {
        setActiveCompletionStatus(row.data);
    };

    const handleDelete = async (params: ICellRendererParams<ObjectiveCompletionStatus>) => {
        if (!params.data?.id) {
            return;
        }
        try {
            await deleteCompletionStatus(params.data.id);
        } catch (e) {
            handleError(e);
        } finally {
            fetchCompletionStatuses().catch(handleError);
        }
    };

    const getMoreButton = (params: ICellRendererParams<ObjectiveCompletionStatus>) => {
        const menuItems: BasicMenuItem[] = [
            {
                title: t('general.edit'),
                onClick: () => {
                    setActiveCompletionStatus(params.data);
                },
            },
            {
                title: t('general.delete'),
                onClick: () => handleDelete(params),
            },
        ];
        return <BasicMenu items={menuItems} />;
    };

    const config: ConfigType<ObjectiveCompletionStatus> = {
        type: 'table',
        header: {
            primaryButtonCaption: t('permissions_setting_page.completion_status'),
            primaryButtonAction: () => addCompletionScale(),
            defaultButtonCaption: t('permissions_setting_page.reorder'),
            defaultButtonAction: () => setIsOrderModalOpen(true),
        },
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: onClickCompletionScaleRow,
            },
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                    type: 'label',
                },
                {
                    type: 'actionMenu',
                    cellRenderer: getMoreButton,
                },
            ],
        },
    };

    const addCompletionScale = () => {
        setActiveCompletionStatus({
            id: undefined,
            name: createDefaultLabel(),
            order: completionStatuses.length,
        });
    };

    const handleUpdateCompletionScale = (name: Label) => {
        if (!activeCompletionStatus?.id) {
            return;
        }

        const completionScaleUpdateRequest = {
            name: name,
            order: activeCompletionStatus?.order ?? 0,
        };
        updateCompletionStatus(completionScaleUpdateRequest, activeCompletionStatus.id)
            .catch(handleError)
            .finally(() => {
                setActiveCompletionStatus(undefined);
                fetchCompletionStatuses().catch(handleError);
            });
    };

    const handleCreateCompletionScale = (name: Label) => {
        const completionScaleCreateRequest = {
            name: name,
            order: completionStatuses.length,
        };
        createCompletionStatus(completionScaleCreateRequest)
            .catch(handleError)
            .finally(() => {
                setActiveCompletionStatus(undefined);
                fetchCompletionStatuses().catch(handleError);
            });
    };

    const onSaveCompletionScale = async (name: Label) => {
        if (activeCompletionStatus?.id) {
            handleUpdateCompletionScale(name);
        } else {
            handleCreateCompletionScale(name);
        }
    };

    const onSaveOrderCompletionScale = async (reorderItems: ReorderItem[]) => {
        const orderRequest = mapToReorderRequest(reorderItems);

        try {
            await updateCompletionStatusOrder(orderRequest);
        } catch (e) {
            handleError(e);
        } finally {
            setIsOrderModalOpen(false);
            await fetchCompletionStatuses();
        }
    };

    const getCompletionStatusesSorted = () => [...completionStatuses].sort((a, b) => a.order - b.order);

    const getReorderItems = (completionStatuses: ObjectiveCompletionStatus[]) => {
        return completionStatuses.map((completionStatus, index) => ({
            id: completionStatus.id,
            name: getLabelTranslation(completionStatus.name),
            order: index,
        }));
    };
    return (
        <>
            <CompanySettingsLayout options={config} data={getCompletionStatusesSorted()} />
            {activeCompletionStatus && (
                <ScaleDialog
                    handleSave={onSaveCompletionScale}
                    closeDialog={() => setActiveCompletionStatus(undefined)}
                    activeCompletionStatus={activeCompletionStatus}
                />
            )}

            {isOrderModalOpen && (
                <ReorderModal
                    open={true}
                    onSave={onSaveOrderCompletionScale}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                    initialReorderItems={getReorderItems(completionStatuses)}
                />
            )}
        </>
    );
};
