import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { Navigate, RouteObject } from 'react-router';
import { SkillsMatrixPage } from '@/page/employee-skill/skills-matrix/SkillsMatrixPage';

const config: RoutesConfig[] = [
    {
        path: 'skills-matrix',
        breadCrumb: [{ nameKey: 'pages.skills_matrix' }],
        component: <SkillsMatrixPage />,
    },
];

export const employeeSkillRoutes: RouteObject[] = [
    {
        path: 'employee-skill',
        errorElement: <RouterBoundary />,
        children: [
            {
                path: '',
                element: <Navigate to='/employee-skill/skills-matrix' replace />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
