import { StateHandler } from '@/components/state-handler/StateHandler';
import { Objective } from '@/domain/objective/Objective.model';
import { canCreateObjectives, canEditObjectives, canViewObjectives } from '@/domain/permission/Permission.service';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { ObjectivesList } from '@/page/objective/objectives-list/ObjectivesList';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { Button, Paper, Stack, StackProps, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIndividualObjectiveDialog } from '@/page/objective/add-individual-objective-dialog/AddIndividualObjectiveDialog';

type EmployeeReviewFeedbackObjectivesProps = {
    employeeId: number;
    disabled?: boolean;
    objectives: Objective[];
    refetchObjectives: () => void;
    objectiveCreationEnabled?: boolean;
} & StackProps;

export const EmployeeReviewFeedbackObjectives: FC<EmployeeReviewFeedbackObjectivesProps> = ({
    employeeId,
    disabled,
    objectives,
    refetchObjectives,
    objectiveCreationEnabled = false,
    ...rest
}) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();
    const [activeObjective, setActiveObjective] = useState<Objective>();
    const [objectiveDialogOpen, setObjectiveDialogOpen] = useState<boolean>(false);
    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const emptyState = (
        <Stack p={2}>
            <Typography variant={'body1'}>{t('reviews.review_summary.no_open_objectives')}</Typography>
        </Stack>
    );

    if (!canViewObjectives(realm.realmFeatures, policies, employeeId)) {
        return <></>;
    }

    const handleObjectiveEdit = (id: number) => {
        const editObjective = (objectives ?? []).find(objective => objective.id === id);
        setActiveObjective(editObjective);
        setObjectiveDialogOpen(true);
    };

    return (
        <Stack component={Paper} p={2} {...rest}>
            <StateHandler
                isLoading={isLoadingObjectiveSetting}
                isError={isErrorObjectiveSetting}
                error={errorObjectiveSetting}
                isEmpty={!objectives?.length}
                emptyStateComponent={emptyState}
            >
                <Stack gap={2}>
                    <Typography variant='h2'>{t('reviews.review_summary.review_objectives_title')}</Typography>
                    <ObjectivesList
                        objectives={objectives || []}
                        onSuccess={() => refetchObjectives()}
                        onEditObjective={handleObjectiveEdit}
                        editable={canEditObjectives(policies, employeeId)}
                        disabled={disabled}
                        displayWeight={objectiveSetting?.objectiveWeightEnabled ?? false}
                        displayAvatarsMobile={true}
                        showAssignee={false}
                        p={0}
                    />
                </Stack>
            </StateHandler>

            {objectiveDialogOpen && (
                <AddIndividualObjectiveDialog
                    open={objectiveDialogOpen}
                    activeObjective={activeObjective}
                    disabledEmployeeSelection={true}
                    onSaveObjective={() => refetchObjectives()}
                    employeeId={employeeId}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    onClose={() => {
                        setActiveObjective(undefined);
                        setObjectiveDialogOpen(false);
                    }}
                />
            )}

            {canCreateObjectives(policies, employeeId) && objectiveCreationEnabled && (
                <Button fullWidth onClick={() => setObjectiveDialogOpen(true)} disabled={disabled}>
                    {t('reviews.review_summary.add_objective')}
                </Button>
            )}
        </Stack>
    );
};
