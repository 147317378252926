import { EmployeePolicy } from '@/domain/employee/Employee.model';
import {
    canManageAnnouncements,
    hasConfigureEmployeeSectionPolicy,
    hasConfigureFolderPolicy,
    hasConfigureLeavePolicy,
    hasConfigureObjectivesPolicy,
    hasConfigureOrganizationPolicy,
    hasConfigurePayrollPolicy,
    hasConfigurePermissionPolicy,
    hasConfigurePlanningPolicy,
    hasConfigureReviewPolicy,
    hasConfigureThirdPartiesPolicy,
    hasConfigureTimeManagementPolicy,
    hasImportDataPolicy,
} from '@/domain/permission/Permission.service';
import { RealmFeature, RealmFeaturesType } from '@/domain/realm/Realm.model';

import { getObjectiveSetting } from '@/domain/objective-setting/ObjectiveSetting.service';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Card, CardContent, CardProps, Grid, Link, Stack, Typography } from '@mui/material';
import {
    ApiIcon,
    BankIcon as PayrollIcon,
    Building06Icon,
    Clock01Icon as TimesheetsIcon,
    DashboardSquare02Icon as PlanningIcon,
    File01Icon as DocumentIcon,
    Megaphone02Icon,
    Notification02Icon,
    SquareLock01Icon,
    StarIcon as ReviewsIcon,
    Target01Icon as ObjectivesIcon,
    UploadCircle02Icon,
    UserMultiple02Icon as EmployeesIcon,
} from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router';

export const CompanySettingsPage: FC = () => {
    const { t } = useTranslation();

    const grantedPolicies = useCurrentPolicies();
    const realm = useCurrentRealm();
    const [cards, setCards] = useState<SettingGroup[]>([]);

    useEffect(() => {
        getSettingsPages(realm?.realmFeatures ?? [], grantedPolicies).then(setCards);
    }, [realm, grantedPolicies]);

    return (
        <Stack>
            <Grid container spacing={2}>
                {cards
                    // we don't want to show empty cards
                    .filter(card => !!card.children?.length)
                    .map(({ title, icon: Icon, children }) => (
                        <Grid item key={title} xs={12} sm={6} md={4} lg={3}>
                            <CompanySettingsCard title={t(`company_settings.tabs.${title}`)} icon={Icon}>
                                {children.map(({ title, link }) => (
                                    <Link key={link} to={link} component={RouterLink}>
                                        {t(`company_settings.tabs.${title}`)}
                                    </Link>
                                ))}
                            </CompanySettingsCard>
                        </Grid>
                    ))}
            </Grid>
        </Stack>
    );
};

const CompanySettingsCard: FC<{ title: string; icon: FC } & CardProps> = ({ title, icon: Icon, children, ...rest }) => {
    return (
        <Card sx={{ flex: 1, minHeight: '100%' }} {...rest}>
            <CardContent sx={{ padding: 3 }}>
                <Stack direction='row' alignItems='center' gap={1} pb={2}>
                    <Icon />
                    <Typography variant='h2' noWrap>
                        {title}
                    </Typography>
                </Stack>
                <Stack gap={1}>{children}</Stack>
            </CardContent>
        </Card>
    );
};

type SettingGroup = { title: string; icon: FC; children: { title: string; link: string }[] };

const getSettingsPages = async (realmFeatures: RealmFeature[], policies: EmployeePolicy[]): Promise<SettingGroup[]> => {
    const objectivesGroup = await buildObjectivesGroup(policies, realmFeatures);
    return [
        buildOrganizationGroup(policies, realmFeatures),
        buildTimeManagementGroup(policies, realmFeatures),
        buildDocumentsGroup(policies, realmFeatures),
        buildEmployeesGroup(policies, realmFeatures),
        buildPlanningGroup(policies, realmFeatures),
        buildReviewsGroup(policies, realmFeatures),
        objectivesGroup,
        buildPermissionsGroup(policies, realmFeatures),
        buildPayrollGroup(policies, realmFeatures),
        buildImportsGroup(policies, realmFeatures),
        buildAnnouncementsGroup(policies),
        buildThirdPartiesGroup(policies, realmFeatures),
        buildNotificationGroup(policies),
    ];
};

const buildOrganizationGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    const organization: SettingGroup = {
        title: 'organization',
        icon: Building06Icon,
        children: [],
    };

    if (hasConfigureOrganizationPolicy(policies)) {
        organization.children.push({
            title: 'legal_units',
            link: 'organization/legal-units',
        });
        organization.children.push({
            title: 'departments',
            link: 'organization/departments',
        });
        organization.children.push({
            title: 'jobs',
            link: 'organization/jobs',
        });
        if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.JOB_FAMILIES)) {
            organization.children.push({
                title: 'job_families',
                link: 'organization/job-families',
            });
            organization.children.push({
                title: 'job_levels',
                link: 'organization/job-levels',
            });
        }
        if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.COST_CENTERS)) {
            organization.children.push({
                title: 'cost_centers',
                link: 'organization/cost-centers',
            });
        }
    }
    return organization;
};

const buildNotificationGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const organization: SettingGroup = {
        title: 'notifications',
        icon: Notification02Icon,
        children: [],
    };

    if (hasConfigureOrganizationPolicy(policies)) {
        organization.children.push({
            title: 'employees_notifications',
            link: 'notifications/employees-notifications',
        });
        organization.children.push({
            title: 'default_notifications',
            link: 'notifications/default-notifications',
        });
    }
    return organization;
};

const buildTimeManagementGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    const timeManagement: SettingGroup = {
        title: 'time_management',
        icon: TimesheetsIcon,
        children: [],
    };

    if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.LEAVES)) {
        if (hasConfigureOrganizationPolicy(policies)) {
            timeManagement.children.push({
                title: 'holidays_calendars',
                link: 'time-management/calendars',
            });
        }

        if (hasConfigureLeavePolicy(policies)) {
            timeManagement.children.push({
                title: 'leaves',
                link: 'time-management/leaves',
            });
        }

        if (hasConfigureTimeManagementPolicy(policies)) {
            timeManagement.children.push({
                title: 'base_working_time',
                link: 'time-management/base-working-time',
            });
            timeManagement.children.push({
                title: 'work_pattern_template',
                link: 'time-management/working-patterns',
            });

            if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.TIMESHEET)) {
                timeManagement.children.push({
                    title: 'time_management_settings',
                    link: 'time-management/settings',
                });
            }
        }
    }

    return timeManagement;
};

const buildDocumentsGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    return {
        title: 'documents',
        icon: DocumentIcon,
        children:
            hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.DOCUMENTS) && hasConfigureFolderPolicy(policies)
                ? [
                      {
                          title: 'folders',
                          link: 'documents/folders',
                      },
                  ]
                : [],
    };
};

const buildEmployeesGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    return {
        title: 'employees',
        icon: EmployeesIcon,
        children:
            hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE) && hasConfigureEmployeeSectionPolicy(policies)
                ? [
                      {
                          title: 'custom_fields',
                          link: 'employees/fields',
                      },
                  ]
                : [],
    };
};

const buildPlanningGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    return {
        title: 'planning',
        icon: PlanningIcon,
        children:
            hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.PLANNING) && hasConfigurePlanningPolicy(policies)
                ? [
                      {
                          title: 'planning_options',
                          link: 'planning/options',
                      },
                      {
                          title: 'tags',
                          link: 'planning/planningTags',
                      },
                      {
                          title: 'positions',
                          link: 'planning/planningPositions',
                      },
                      {
                          title: 'shift_type',
                          link: 'planning/shift-type',
                      },
                      {
                          title: 'shift_breaks',
                          link: 'planning/shift-breaks',
                      },
                  ]
                : [],
    };
};

const buildReviewsGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => ({
    title: 'reviews',
    icon: ReviewsIcon,
    children:
        hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.REVIEWS) && hasConfigureReviewPolicy(policies)
            ? [
                  {
                      title: 'templates',
                      link: 'reviews/templates',
                  },
                  {
                      title: 'skills',
                      link: 'reviews/skills',
                  },
                  {
                      title: 'skill_categories',
                      link: 'reviews/categories',
                  },
                  {
                      title: 'scales',
                      link: 'reviews/scales',
                  },
              ]
            : [],
});

const buildObjectivesGroup = async (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): Promise<SettingGroup> => {
    const canShowObjectives = hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.REVIEWS) && hasConfigureObjectivesPolicy(policies);

    if (!canShowObjectives) {
        return {
            title: 'objectives',
            icon: ObjectivesIcon,
            children: [],
        };
    }

    const children = [
        {
            title: 'objective_settings',
            link: 'objectives/settings',
        },
    ];

    try {
        const objectiveSetting = await getObjectiveSetting();

        if (objectiveSetting?.objectiveCategoriesEnabled) {
            children.push({
                title: 'objective_categories',
                link: 'objectives/categories',
            });
        }
    } catch (error) {
        console.error('Failed to fetch objective settings', error);
        handleError(error);
    }

    children.push({
        title: 'completion_scale',
        link: 'objectives/completion_scale',
    });

    return {
        title: 'objectives',
        icon: ObjectivesIcon,
        children: children,
    };
};

const buildImportsGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    const imports: SettingGroup = {
        title: 'imports',
        icon: UploadCircle02Icon,
        children: [],
    };

    if (hasImportDataPolicy(policies)) {
        imports.children.push({
            title: 'employees',
            link: 'import/employees',
        });

        if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.LEAVES)) {
            imports.children.push({
                title: 'leaves',
                link: 'import/leaves',
            });
        }
        if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.TIMESHEET)) {
            imports.children.push({
                title: 'timesheets',
                link: 'import/timesheets',
            });
        }

        if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.REVIEWS)) {
            imports.children.push({
                title: 'objectives',
                link: 'import/objectives',
            });

            imports.children.push({
                title: 'skills',
                link: 'import/skills',
            });
        }

        imports.children.push({
            title: 'organization',
            link: 'import/organization',
        });

        imports.children.push({
            title: 'documents',
            link: 'import/documents',
        });
    }
    return imports;
};

const buildPayrollGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    const payroll: SettingGroup = {
        title: 'payroll',
        icon: PayrollIcon,
        children: [],
    };

    if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.PAYROLL) && hasConfigurePayrollPolicy(policies)) {
        payroll.children.push({
            title: 'payroll_fields',
            link: 'payroll',
        });
    }

    return payroll;
};

const buildAnnouncementsGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const announcements: SettingGroup = {
        title: 'announcements',
        icon: Megaphone02Icon,
        children: [],
    };
    if (canManageAnnouncements(policies)) {
        announcements.children.push({
            title: 'announcements',
            link: 'announcements',
        });
    }
    return announcements;
};

const buildThirdPartiesGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    const thirdParties: SettingGroup = {
        title: 'third_parties_groups',
        icon: ApiIcon,
        children: [],
    };
    if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.THIRD_PARTIES) && hasConfigureThirdPartiesPolicy(policies)) {
        thirdParties.children.push({
            title: 'third_parties',
            link: 'third-parties',
        });

        thirdParties.children.push({
            title: 'third_party_mappings',
            link: 'third-party-mappings',
        });
    }
    return thirdParties;
};

const buildPermissionsGroup = (policies: EmployeePolicy[], realmFeatures: RealmFeature[]): SettingGroup => {
    const permissions: SettingGroup = {
        title: 'permissions',
        icon: SquareLock01Icon,
        children: [],
    };

    if (hasConfigurePermissionPolicy(policies)) {
        permissions.children.push({
            title: 'permissions_base',
            link: 'permissions',
        });
    }

    if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.PLANNING) && hasConfigurePlanningPolicy(policies)) {
        permissions.children.push({
            title: 'permissions_planning',
            link: 'permissions/planning',
        });
    }
    return permissions;
};
