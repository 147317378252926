import {
    EmployeeLeaveBalanceHistorySearchRequest,
    EmployeeLeaveTypeBalance,
    LeaveBalanceSearchRequest,
    LeaveTypeHistory,
} from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { leaveTypeHistoryApi } from '@/api/leave-type-history/LeaveTypeHistory.api';

export function getLeaveTypeHistories(searchRequest: EmployeeLeaveBalanceHistorySearchRequest): Promise<LeaveTypeHistory[]> {
    return leaveTypeHistoryApi.getLeaveTypeHistories(searchRequest);
}

export function getLeaveTypeHistory(employeeId: number, employeeLeaveTypeId: number): Promise<LeaveTypeHistory> {
    return leaveTypeHistoryApi.getLeaveTypeHistory(employeeId, employeeLeaveTypeId);
}

export function getLeaveBalance(leaveTypeId: number, searchRequest: LeaveBalanceSearchRequest): Promise<EmployeeLeaveTypeBalance[]> {
    return leaveTypeHistoryApi.getLeaveBalance(leaveTypeId, searchRequest);
}
