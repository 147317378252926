import { permissionGroupApi } from '@/api/permission-group/PermissionGroup.api';
import { PermissionGroup, PermissionGroupMutation } from '@/domain/permission-group/PermissionGroup.model';

export const getPermissionGroups = (): Promise<PermissionGroup[]> => {
    return permissionGroupApi.getPermissionGroups();
};

export const getPermissionGroupById = (groupId: number): Promise<PermissionGroup> => {
    return permissionGroupApi.getPermissionGroupById(groupId);
};

export const createPermissionGroup = (permissionGroupMutation: PermissionGroupMutation): Promise<PermissionGroup> => {
    return permissionGroupApi.createPermissionGroup(permissionGroupMutation);
};

export const updatePermissionGroup = (permissionGroupMutation: PermissionGroupMutation, groupId: number): Promise<PermissionGroup> => {
    return permissionGroupApi.updatePermissionGroup(permissionGroupMutation, groupId);
};

export const deletePermissionGroup = (groupId: number): Promise<void> => {
    return permissionGroupApi.deletePermissionGroup(groupId);
};

export const getGroupsByEmployeeId = (employeeId: number): Promise<PermissionGroup[]> => {
    return permissionGroupApi.getGroupsByEmployeeId(employeeId);
};
