import { DayOfWeek } from '@/utils/datetime.util';
import { DayPeriod } from '../date/Date.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import {
    EmployeeWorkingPattern,
    EmployeeWorkingPatternSearchRequest,
    EmployeeWorkingPatternTemplateCreationMutation,
    EmployeeWorkingPatternUpdateMutation,
    WorkingDay,
} from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { employeeWorkingPatternAPI } from '@/api/employee-working-pattern/EmployeeWorkingPattern.api';

export const mapWorkingDays = (
    morningWorkingDays: DayOfWeek[],
    afternoonWorkingDays: DayOfWeek[],
    selectedWeeklyWorkingTime?: WeeklyWorkingTime,
): WorkingDay[] => {
    const baseAmount = selectedWeeklyWorkingTime ? selectedWeeklyWorkingTime.weeklyAmountWorked / 10 : 0;

    return Object.keys(DayOfWeek)
        .map((k: string) => DayOfWeek[k as keyof typeof DayOfWeek])
        .map(dayOfWeek => {
            const isMorning = morningWorkingDays.includes(dayOfWeek);
            const isAfternoon = afternoonWorkingDays.includes(dayOfWeek);

            if (!isMorning && !isAfternoon) {
                return {
                    dayOfWeek: dayOfWeek,
                    amountWorked: 0,
                    dayPeriod: DayPeriod.ALL_DAY,
                };
            }

            if (isMorning && !isAfternoon) {
                return {
                    dayOfWeek: dayOfWeek,
                    amountWorked: baseAmount,
                    dayPeriod: DayPeriod.MORNING,
                };
            }
            if (!isMorning && isAfternoon) {
                return {
                    dayOfWeek: dayOfWeek,
                    amountWorked: baseAmount,
                    dayPeriod: DayPeriod.AFTERNOON,
                };
            }

            return {
                dayOfWeek: dayOfWeek,
                amountWorked: baseAmount * 2,
                dayPeriod: DayPeriod.ALL_DAY,
            };
        });
};

export const searchEmployeeWorkingPatterns = (searchRequest: EmployeeWorkingPatternSearchRequest): Promise<EmployeeWorkingPattern[]> => {
    return employeeWorkingPatternAPI.searchEmployeeWorkingPatterns(searchRequest);
};

export const createEmployeeWorkingPattern = (mutation: EmployeeWorkingPatternTemplateCreationMutation): Promise<EmployeeWorkingPattern> => {
    return employeeWorkingPatternAPI.createEmployeeWorkingPattern(mutation);
};

export const updateEmployeeWorkingPattern = (id: number, mutation: EmployeeWorkingPatternUpdateMutation): Promise<EmployeeWorkingPattern> => {
    return employeeWorkingPatternAPI.updateEmployeeWorkingPattern(id, mutation);
};

export const deleteEmployeeWorkingPattern = (id: number): Promise<void> => {
    return employeeWorkingPatternAPI.deleteEmployeeWorkingPattern(id);
};
