import { StateHandler } from '@/components/state-handler/StateHandler';
import { getEmployeeLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { getUserLeaveRequests } from '@/domain/leave-request/LeaveRequest.service';
import { useGetEmployeeById } from '@/hooks/employee/Employee.hook';
import { useGetLeaveTypeHistories } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { MyAllowance } from '@/page/employee-profile/employee-profile-leave/MyAllowance';
import { getCurrentLocalDate, isAfterDate, isBeforeDate } from '@/utils/datetime.util';
import { TimesheetCycle } from '@/domain/timesheet/Timesheet.model';
import { EmployeeLeaveBalanceHistorySearchRequest } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { LeavesActionType } from '@/stores/reducers/leavesActions';
import { EmployeeLeaves } from '@/page/employee-profile/employee-profile-leave/EmployeeLeaves';
import { Stack } from '@mui/material';
import { buildCycles, getDefaultCycle } from '@/domain/timesheet/Timesheet.service';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { Employee } from '@/domain/employee/Employee.model';

export const LeavesOverviewPage: FC = () => {
    const employeeId = useEmployeeProfileId();
    const { data: activeEmployee, isLoading: isEmployeeLoading, isError: isEmployeeError, error: employeeError } = useGetEmployeeById(employeeId);

    return (
        <Stack direction='column' gap={2} alignItems='stretch' flex={1}>
            <StateHandler isLoading={isEmployeeLoading} isError={isEmployeeError} error={employeeError}>
                {activeEmployee && <LeavesOverview activeEmployee={activeEmployee} />}
            </StateHandler>
        </Stack>
    );
};

type LeavesOverviewProps = {
    activeEmployee: Employee;
};

const LeavesOverview: FC<LeavesOverviewProps> = ({ activeEmployee }) => {
    const employeeId = activeEmployee.id;
    const dispatch = useAppDispatch();
    const userLeaveTypePolicies = useAppSelector(state => state.leaves.userLeaveTypePolicies) ?? [];

    const leaveCycles = buildCycles(activeEmployee);

    const currentLeaveCycle =
        leaveCycles.find(cycle => !isAfterDate(cycle.cycleStartDate, getCurrentLocalDate()) && !isBeforeDate(cycle.cycleEndDate, getCurrentLocalDate())) ??
        getDefaultCycle();
    const [selectedLeaveCycle, setSelectedLeaveCycle] = useState<TimesheetCycle>(currentLeaveCycle);

    const searchRequest: EmployeeLeaveBalanceHistorySearchRequest = {
        employeeId,
        cycleStartDate: selectedLeaveCycle.cycleStartDate,
    };

    const {
        data: leaveTypeHistories = [],
        isLoading: isLeaveTypeHistoriesLoading,
        refetch: refetchLeaveTypeHistories,
        isError: isLeaveTypeHistoriesError,
        error: leaveTypeHistoriesError,
    } = useGetLeaveTypeHistories(searchRequest);

    const callUserLeaveTypePolicies = useCallback(() => {
        getEmployeeLeaveTypePolicies(employeeId)
            .then(userLeaveTypePolicies => {
                dispatch({
                    type: LeavesActionType.USER_LEAVE_TYPE_POLICY_LOADED,
                    userLeaveTypePolicies,
                });
            })
            .catch(handleError);
    }, [dispatch, employeeId]);

    useEffect(() => {
        callUserLeaveTypePolicies();
    }, [employeeId, callUserLeaveTypePolicies]);

    const [leaveRequests, setLeaveRequests] = useState<LeaveRequest[]>([]);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const fetchLeaveRequest = useCallback(async () => {
        try {
            const data = await getUserLeaveRequests(employeeId);
            setLeaveRequests(data);
        } catch {
            setIsError(true);
        } finally {
            setLoading(false);
        }
    }, [employeeId]);

    useEffect(() => {
        fetchLeaveRequest().catch(handleError);
    }, [employeeId, fetchLeaveRequest]);

    if (!userLeaveTypePolicies) {
        return <></>;
    }

    const handleChange = () => {
        fetchLeaveRequest().catch(handleError);
        refetchLeaveTypeHistories().catch(handleError);
    };

    return (
        <Stack direction='column' gap={2} alignItems='stretch' flex={1}>
            <StateHandler isLoading={loading || isLeaveTypeHistoriesLoading} isError={isError || isLeaveTypeHistoriesError} error={leaveTypeHistoriesError}>
                {activeEmployee && (
                    <MyAllowance
                        activeEmployee={activeEmployee}
                        userLeaveTypePolicies={userLeaveTypePolicies}
                        onPoliciesUpdated={() => {
                            callUserLeaveTypePolicies();
                        }}
                        onChange={handleChange}
                        leaveTypeHistories={leaveTypeHistories}
                        selectedLeaveCycle={selectedLeaveCycle}
                        leaveCycles={leaveCycles}
                        onChangeLeaveCycle={(cycle: TimesheetCycle) => {
                            setSelectedLeaveCycle(cycle);
                        }}
                    />
                )}

                <EmployeeLeaves employeeId={employeeId} leaveRequests={leaveRequests} onChange={handleChange} />
            </StateHandler>
        </Stack>
    );
};
