import { Employment, EmploymentCreateReason } from '@/domain/employment/Employment.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, FormHelperText, IconButton, Link, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DepartmentAutocomplete, JobsAutocomplete, LocationAutocomplete, ManagerAutocomplete } from './EmploymentDialogCommonFields';

import { Department } from '@/domain/department/Department.model';
import { mapDepartmentToRecursiveValue } from '@/domain/department/Department.utils';
import { CostCenterAssignmentListField } from '@/page/cost-center/CostCenterAssignmentListField';
import {
    EmploymentDialogFormValues,
    EmploymentSchemaConfig,
    getEmploymentDialogSchema,
} from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';

import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelectJobFamily } from '@/domain-ui/job-family/FieldSelectJobFamily';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { usePreFillJobFamily } from '@/page/employee-profile/employee-profile-info/EmploymentSection/usePrefillJobFamily';
import { formatToLocalDate, getCurrentLocalDate } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import { Add01Icon, Copy01Icon, RemoveCircleIcon } from 'hugeicons-react';
import { useGetEmployments } from '@/hooks/employment/Employment.hook';
import { getContractByEmployment, getContracts, isDateInAllowedRanges } from '@/domain/employment/Employment.service';
import { Employee } from '@/domain/employee/Employee.model';

type EmploymentDialogProps = {
    open: boolean;
    employee: Employee;
    employments?: Employment[];
    onSave: (employmentFormValues: EmploymentDialogFormValues) => void;
    onClose: () => void;
};

const nestedEmploymentsFieldName = 'items';
type EmploymentFieldFormValues = EmploymentDialogFormValues[typeof nestedEmploymentsFieldName][0];

export const EmploymentDialog: FC<EmploymentDialogProps> = ({ open, employee, onClose, onSave, employments = [] }) => {
    const { t } = useTranslation();
    const employeeId = employee.id;
    const isEdition = employments.length > 0;
    const title = isEdition ? t('employee.employment.update_employment') : t('employee.employment.add_employment');

    // Common fields
    const firstEmployment = isEdition ? employments[0] : undefined;
    const principalEmployment = employments.find(e => e.principal) ?? firstEmployment; // Fallback to the first employment if no principal
    const { startDate, employmentCreateReason } = principalEmployment ?? {}; // Get the proper employment

    const { data: allEmployments = [] } = useGetEmployments({ employeeIds: [employeeId] });
    // related contract when editing an employment
    const relatedContract = principalEmployment ? getContractByEmployment(allEmployments, principalEmployment) : undefined;
    // all existing contract
    const allContracts = getContracts(allEmployments);

    // Get the allowed ranges for the start date to avoid creating an employment outside of contracts
    // or  editing the start date outside the related contract
    const allowedRanges: [LocalDate, LocalDate | undefined][] = relatedContract
        ? [[relatedContract.startDate, relatedContract.endDate]]
        : allContracts.map(contract => [contract.startDate, contract.endDate]);

    const schemaConfig: EmploymentSchemaConfig = {
        startDate: {
            allowedStartDateRanges: allowedRanges,
        },
    };
    const schema = getEmploymentDialogSchema(schemaConfig);

    const formMethods = useForm<EmploymentDialogFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            startDate: startDate ?? getCurrentLocalDate(),
            employmentUpdateReason: employmentCreateReason,

            [nestedEmploymentsFieldName]: employments?.length
                ? employments.map(mapToDefaultItem)
                : [
                      {
                          employmentCostCenters: [],
                          managers: [],
                      },
                  ],
        },
    });

    const {
        fields: items,
        append: appendEmployment,
        remove: removeEmployment,
    } = useFieldArray({
        control: formMethods.control,
        name: 'items',
    });

    const onCloseDialog = () => {
        formMethods.reset();
        onClose();
    };

    const isNewEmployeeOrRehired = (): boolean => {
        return employmentCreateReason === EmploymentCreateReason.NEW_EMPLOYEE || employmentCreateReason === EmploymentCreateReason.REHIRED;
    };

    const handleAddSecondaryEmployment = () => {
        const emptyEmployment: Partial<EmploymentFieldFormValues> = {
            location: undefined,
            job: undefined,
            managers: [],
            employmentCostCenters: [],
            department: undefined,
        };
        // We are forced to cast because we are prefilling the form, user still needs to fill the fields with valid values before submitting
        appendEmployment(emptyEmployment as EmploymentFieldFormValues);
    };

    return (
        <DialogWrapper header={title} open={open} onClose={onCloseDialog}>
            <FormProvider {...formMethods}>
                <Stack component={DialogContent} gap={3}>
                    {!isNewEmployeeOrRehired() && (
                        <Stack gap={2}>
                            <FormControlLabel
                                label={t('employee.employment.start_date') + '*'}
                                control={
                                    <FieldLocalDate
                                        control={formMethods.control}
                                        name={'startDate'}
                                        datePickerProps={{
                                            shouldDisableDate: date => !isDateInAllowedRanges(formatToLocalDate(date), allowedRanges),
                                        }}
                                    />
                                }
                            />
                            <EmploymentCreateReasonField />
                        </Stack>
                    )}

                    {items.map((employment, index) => (
                        <EmploymentItem
                            key={employment.id}
                            // Index is used to watch the changes of the employment item
                            index={index}
                            employee={employee}
                            isEdition={isEdition}
                            onRemove={() => removeEmployment(index)}
                        />
                    ))}
                    <Typography variant='body1bold'>{t('employee.employment.employment_secondary')}</Typography>
                    <Button
                        variant='text'
                        sx={{ alignSelf: 'flex-start' }}
                        startIcon={<Add01Icon width={20} height={20} />}
                        onClick={handleAddSecondaryEmployment}
                    >
                        {t('employee.employment.employment_add')}
                    </Button>
                </Stack>
            </FormProvider>
            <DialogActions>
                <Button onClick={formMethods.handleSubmit(onSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type EmploymentItemProps = {
    index: number;
    employee: Employee;
    isEdition: boolean;
    onRemove: () => void;
};
const EmploymentItem: FC<EmploymentItemProps> = ({ index, employee, isEdition, onRemove }) => {
    const { t } = useTranslation();
    const employeeId = employee.id;

    const isCostCenterFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.COST_CENTERS);

    const { setValue } = useFormContext<EmploymentDialogFormValues>();

    // Watch job to fill job family field
    usePreFillJobFamily<EmploymentDialogFormValues>(`${nestedEmploymentsFieldName}.${index}.job`, `${nestedEmploymentsFieldName}.${index}.jobFamily`);

    const handleDepartmentChange = (index: number) => (department: Department | null) => {
        // erase cost centers and manager fields when department changes
        const managers = department?.managers ?? [];

        const employmentCostCenters = isCostCenterFeatureEnabled ? (department?.departmentCostCenters ?? []) : [];

        setValue(`${nestedEmploymentsFieldName}.${index}.managers`, managers);
        setValue(`${nestedEmploymentsFieldName}.${index}.employmentCostCenters`, employmentCostCenters);
    };

    const hasJobFamilyFeature = useRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES);

    const handleCopyFromCurrentEmployment = () => {
        setValue(nestedEmploymentsFieldName, employee.currentEmployments.map(mapToDefaultItem));
    };
    // copy from current feature is only when adding a new employment
    const isNewEmploymentPrincipal = index === 0 && !isEdition;

    return (
        <Stack direction='column' spacing={2}>
            <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
                <Typography variant='body1bold'>
                    {t(index === 0 ? 'employee.employment.employment_principal' : 'employee.employment.employment_secondary')}
                </Typography>
                {isNewEmploymentPrincipal && (
                    <Link
                        component='button'
                        color='primary'
                        onClick={handleCopyFromCurrentEmployment}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        <Copy01Icon /> {t('employee.employment.copy_from_current')}
                    </Link>
                )}

                {index > 0 && (
                    <IconButton size='small' onClick={onRemove} aria-label={'remove-secondary-employment'}>
                        <RemoveCircleIcon />
                    </IconButton>
                )}
            </Stack>
            <LocationAutocomplete name={`${nestedEmploymentsFieldName}.${index}.location`} />
            <DepartmentAutocomplete name={`${nestedEmploymentsFieldName}.${index}.department`} onDepartmentChange={handleDepartmentChange(index)} />

            <JobsAutocomplete name={`${nestedEmploymentsFieldName}.${index}.job`} />

            {hasJobFamilyFeature && (
                <FormControlLabel
                    label={getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB_FAMILY' })}
                    control={<FieldSelectJobFamily name={`${nestedEmploymentsFieldName}.${index}.jobFamily`} />}
                />
            )}

            <ManagerAutocomplete name={`${nestedEmploymentsFieldName}.${index}.managers`} excludeEmployeeIds={[employeeId]} />
            <CostCenterAssignmentListField fieldName={`${nestedEmploymentsFieldName}.${index}.employmentCostCenters`} />
        </Stack>
    );
};

const EmploymentCreateReasonField: FC = () => {
    const { t } = useTranslation();
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const formValueName = 'employmentUpdateReason';

    const getNewEmploymentCreateReason = (): string[] => {
        const newEmploymentCreateReasonNotWanted: EmploymentCreateReason[] = [EmploymentCreateReason.NEW_EMPLOYEE, EmploymentCreateReason.REHIRED];
        return Object.keys(EmploymentCreateReason).filter(key => !newEmploymentCreateReasonNotWanted.includes(key as EmploymentCreateReason));
    };

    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.employment_update_reason')}
                {'*'}
            </Typography>
            <Controller
                render={({ field: { ref, value, ...rest } }) => (
                    <Select {...rest} value={value ?? getNull()} inputRef={ref} error={!!errors[formValueName]} autoFocus={false}>
                        {getNewEmploymentCreateReason().map(employmentCreateReason => {
                            return (
                                <MenuItem key={employmentCreateReason} value={employmentCreateReason ?? ''}>
                                    {t('employee.employment.employment_create_reason', { context: employmentCreateReason })}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
                name={formValueName}
                control={control}
            />
            {!!errors[formValueName] && <FormHelperText error={!!errors[formValueName]}>{errors[formValueName].message?.toString()}</FormHelperText>}
        </Stack>
    );
};

const mapToDefaultItem = (employment: Employment): EmploymentFieldFormValues => {
    return {
        id: employment.id,
        location: employment.location,
        job: employment.job,
        jobFamily: employment.jobFamily ? { id: employment.jobFamily.id } : getNull(),
        managers: employment.managers,
        employmentCostCenters: employment.employmentCostCenters,
        department: mapDepartmentToRecursiveValue(employment.department),
    };
};
