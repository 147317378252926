import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import { showSnackbar } from '@/utils/snackbar.util';
import { ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { SkillCategory } from '@/domain/skill-category/SkillCategory.model';
import { deleteSkillCategory, reorderSkillCategoriesRequest } from '@/domain/skill-category/SkillCategory.service';
import { useSearchSkillCategories } from '@/hooks/skill-category/SkillCategory.hook';

import { EmptyState } from '@/components/empty-state/EmptyState';
import { EmptyStateIcon } from '@/components/empty-state/icons/EmptyStateIcon';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { mapToReorderRequest } from '@/domain/common';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

export const ReviewSkillCategorySettingPage: FC = () => {
    const { t } = useTranslation();
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const route = useLocation();
    const {
        data: skillCategories = [],
        isError: isSkillCategoriesError,
        isLoading: isSkillCategoriesLoading,
        refetch: refetchSkillCategories,
        error: skillCategoriesError,
    } = useSearchSkillCategories();

    const onClickCategoriesRow = (category: SkillCategory) => {
        navigate(`${route.pathname}/${category.id}`);
    };

    const onDeleteCategory = async (category: SkillCategory) => {
        try {
            await deleteSkillCategory(category.id);
            showSnackbar(t('reviews_settings_page.messages.skill_category_deleted'), 'success');
            refetchSkillCategories().catch(handleError);
        } catch (error) {
            handleError(error);
        }
    };

    const initialReorderItems = skillCategories
        .map(skillCategory => ({
            ...skillCategory,
            name: getLabelTranslation(skillCategory.name),
        }))
        .sort((a, b) => a.order - b.order);

    const cellActionRenderer = (params: ICellRendererParams<SkillCategory>) => {
        const data = params.data;
        if (!data) {
            return;
        }
        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => onClickCategoriesRow(data),
                    },
                    {
                        title: t('general.delete'),
                        onClick: () => {
                            onDeleteCategory(data);
                        },
                    },
                ]}
            />
        );
    };

    const config: ConfigType<SkillCategory> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('reviews_settings_page.category'),
            defaultButtonCaption: t('permissions_setting_page.reorder'),
            defaultButtonAction: () => handleOrderModal(),
        },
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<SkillCategory>) => params?.data && onClickCategoriesRow(params.data),
            },
            columns: [
                {
                    field: 'name',
                    type: 'label',
                    headerName: t('reviews_settings_page.categories'),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    const handleOrderModal = () => {
        setIsOrderModalOpen(true);
    };

    const handleReorderSave = async (reorderItems: ReorderItem[]) => {
        const reorderRequest = mapToReorderRequest(reorderItems);

        try {
            await reorderSkillCategoriesRequest(reorderRequest);
            refetchSkillCategories().catch(handleError);
            setIsOrderModalOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const getEmptyStateComponent = () => {
        return (
            <EmptyState
                sx={{
                    margin: 'auto',
                }}
                icon={<EmptyStateIcon />}
                title={t('reviews_settings_page.messages.no_skill_categories')}
                action={<Button onClick={() => navigate(`${route.pathname}/new`)}>{t('reviews_settings_page.messages.add_skill_category')}</Button>}
            />
        );
    };

    return (
        <StateHandler
            isLoading={isSkillCategoriesLoading}
            isError={isSkillCategoriesError}
            isEmpty={!skillCategories.length}
            error={skillCategoriesError}
            emptyStateComponent={getEmptyStateComponent()}
        >
            <CompanySettingsLayout options={config} data={(skillCategories ?? []).sort((a, b) => a.order - b.order)} isSearchAvailable />

            {isOrderModalOpen && (
                <ReorderModal
                    open={true}
                    initialReorderItems={initialReorderItems}
                    onSave={handleReorderSave}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                />
            )}
        </StateHandler>
    );
};
