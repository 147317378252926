import { FiltersBar, FiltersBarProps } from '@/components/filters-bar/FiltersBar';
import { ReportFieldType } from '@/domain/report/Report.model';
import { SectionFieldDefinition, SectionFieldValueType } from '@/domain/section-setting/Section.model';
import { ReportColumnsSelector, ReportColumnsSelectorProps } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { Stack, TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isGroupingFilter } from '@/domain/report/Report.service';
import { DateSimpleFilterType, FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';

export type ReportFilterItemBar = FilterType & {
    fieldType: ReportFieldType;
    fieldValueType: SectionFieldValueType;
    fieldFilterType: SectionFieldValueType;
    sectionFieldDefinition?: SectionFieldDefinition;
};

export type GroupingFilters = [SelectFilter, SelectFilter];

export type ReportFilersBarProps = {
    filtersBarProps: FiltersBarProps<ReportFilterItemBar>;
    onSearch?: TextFieldProps['onChange'];
    columnsSelectorProps?: ReportColumnsSelectorProps;
    readOnly?: boolean;
    highlightRangeProps?: {
        range: [DateSimpleFilterType, DateSimpleFilterType];
        onRangeChange: (range: [DateSimpleFilterType, DateSimpleFilterType]) => void;
    };
    groupingFiltersProps?: {
        groupingFilters: GroupingFilters;
        onGroupingFiltersChange: (groupBy: GroupingFilters) => void;
    };
};

export const ReportEditorBar: FC<ReportFilersBarProps> = ({
    filtersBarProps,
    columnsSelectorProps = {
        groups: [],
        onGroupChange: () => {},
    },
    readOnly,
    onSearch,
    highlightRangeProps,
    groupingFiltersProps,
}) => {
    const { t } = useTranslation();
    const { filters, onFiltersChange, ...restFilterBarProps } = filtersBarProps;

    return (
        <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' gap={1} alignItems='center'>
                {highlightRangeProps && (
                    <FiltersBar
                        // V1 we don't display a range filter but a single date filter
                        filters={[highlightRangeProps.range[0]]}
                        onFiltersChange={filters => {
                            highlightRangeProps.onRangeChange([filters[0], highlightRangeProps.range[1]]);
                        }}
                        readOnly={true}
                        wrapperProps={{ color: 'secondary' }}
                    />
                )}
                <FiltersBar filters={filters} onFiltersChange={onFiltersChange} readOnly={readOnly} {...restFilterBarProps} />
            </Stack>

            <Stack direction='row' gap={1}>
                {!!onSearch && <TextField onChange={onSearch} placeholder={t('general.search')} />}
                {!readOnly && !!columnsSelectorProps.groups.length && <ReportColumnsSelector {...columnsSelectorProps} />}

                {groupingFiltersProps && (
                    <FiltersBar
                        filters={groupingFiltersProps?.groupingFilters || []}
                        onFiltersChange={filters => {
                            if (!isGroupingFilter(filters)) {
                                return;
                            }
                            groupingFiltersProps?.onGroupingFiltersChange(filters);
                        }}
                        wrapperProps={{ color: 'secondary' }}
                        clearable={false}
                    />
                )}
            </Stack>
        </Stack>
    );
};
