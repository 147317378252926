import { Button, Paper, Stack, TextField, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import { SurveyAnswerForm, SurveyQuestionForm } from '@/page/survey/SurveyForm.util';

type Props = {
    onUpdate: (content: string, anonymous: boolean) => void;
    onSubmit: () => void;
    question: SurveyQuestionForm;
    answer: SurveyAnswerForm;
    selectedLanguage: UserLanguage;
};

export const SurveyAnswerText: FC<Props> = ({ onUpdate, question, answer, onSubmit, selectedLanguage }) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState<string>('');
    const [isAnonymous, setIsAnonymous] = useState<boolean>(true);

    useEffect(() => {
        if (!comment) {
            setComment(answer?.textAnswer ?? '');
        }
    }, [answer, comment]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsAnonymous(event.target.checked);
        onUpdate(comment, event.target.checked);
    };

    const onUpdateComment = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(event.target.value);
        onUpdate(event.target.value, isAnonymous);
    };

    if (!answer) {
        return <></>;
    }

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Paper elevation={0} sx={{ padding: 6, maxWidth: '720px', margin: 'auto' }}>
                <Stack mb={{ xs: 3, md: 1 }}>
                    <Typography variant='h1' textAlign={'center'} display={'block'} fontSize={18}>
                        {getLabelTranslation(question.question, selectedLanguage)}
                    </Typography>
                </Stack>
                <Stack>
                    <FormControlLabel
                        label={t('anonymous_surveys.comment')}
                        labelPlacement='top'
                        control={
                            <TextField
                                fullWidth
                                InputProps={{ multiline: true, minRows: 4 }}
                                value={answer?.textAnswer}
                                onChange={event => {
                                    onUpdateComment(event);
                                }}
                            />
                        }
                    />
                    <Stack direction='row' justifyContent={'space-between'} mt={1}>
                        <Stack mt={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={isAnonymous} color='primary' onChange={handleChange} name='checkedA' />}
                                    label={isAnonymous ? t('anonymous_surveys.anonymous') : t('anonymous_surveys.not_anonymous')}
                                    labelPlacement='end'
                                />
                            </FormGroup>
                        </Stack>
                        {answer?.textAnswer && (
                            <Stack>
                                <Button color={'primary'} variant='contained' disabled={!answer?.textAnswer} onClick={() => onSubmit()}>
                                    {t('anonymous_surveys.submit')}
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Paper>
        </Slide>
    );
};
