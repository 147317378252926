import { Department, DepartmentNode } from '@/domain/department/Department.model';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';

import { RecursiveValue } from '@/components/tree-select/TreeSelect';
import { CustomTreeViewBaseItem } from '@/page/setting/organization/department/department-tree/DepartmentTreeItem';
import { TreeSelectFilterOption } from '@/components/filters-bar/FilterBar.type';

/**
 * Maps a department node to a list of departments (the department and its children)
 * @param department
 * @param parent
 */
export const mapDepartmentNodeToDepartments = (department: DepartmentNode, parent: DepartmentNode | undefined): Department[] => {
    if (department.children.length === 0) {
        return [mapDepartmentNodeToSingleDepartment(department, parent)];
    }
    return [mapDepartmentNodeToSingleDepartment(department, parent), ...department.children.flatMap(d => mapDepartmentNodeToDepartments(d, department))];
};

const mapDepartmentNodeToSingleDepartment = (department: DepartmentNode, parent: DepartmentNode | undefined): Department => {
    return {
        id: department.id,
        name: department.name,
        parentId: parent?.id,
        order: department.order,
        managers: department.managers,
        departmentCostCenters: department.departmentCostCenters,
        archived: department.archived,
    };
};

/**
 * Maps a list of department nodes to a list of departments (all departments at the root and their children)
 * @param departments
 */
export const mapDepartmentNodesListToDepartments = (departments: DepartmentNode[]): Department[] => {
    return departments.flatMap(department => mapDepartmentNodeToDepartments(department, undefined));
};

export const mapDepartmentToRecursiveValue = (department: Department, translationLanguage?: UserLanguage): RecursiveValue => ({
    id: department.id,
    label: getLabelTranslation(department.name, translationLanguage),
    children: [],
});

// Function to map Department list to RecursiveValue list
export const mapDepartmentsToRecursiveValues = (departments: Department[], translationLanguage?: UserLanguage): RecursiveValue[] => {
    const departmentMap: Record<number, RecursiveValue> = {};

    // Initialize all departments as RecursiveValue without children
    departments.forEach(dept => {
        departmentMap[dept.id] = mapDepartmentToRecursiveValue(dept, translationLanguage);
    });

    const rootNodes: RecursiveValue[] = [];

    // Populate children and find root nodes
    departments.forEach(dept => {
        const node = departmentMap[dept.id];

        if (dept.parentId !== undefined && departmentMap[dept.parentId]) {
            // If parent exists, add this department as a child
            departmentMap[dept.parentId].children.push(node);
        } else {
            // If no parentId, it's a root node
            rootNodes.push(node);
        }
    });

    return rootNodes;
};

export const mapDepartmentNodeToRecursiveValue = <T extends DepartmentNode | undefined>(
    department: T,
    translationLanguage?: UserLanguage,
    disableArchived = false,
): T extends undefined ? undefined : RecursiveValue => {
    if (!department) {
        return undefined as T extends undefined ? undefined : RecursiveValue;
    }
    return {
        id: department.id,
        label: getLabelTranslation(department.name, translationLanguage),
        children: department.children.map(child => mapDepartmentNodeToRecursiveValue(child, translationLanguage, disableArchived)),
        disabled: disableArchived ? department.archived : false,
    } as T extends undefined ? undefined : RecursiveValue;
};

export const mapDepartmentNodesToTreeItems = (
    departments: DepartmentNode[],
    flatDepartments: Department[],
    translationLanguage?: UserLanguage,
): CustomTreeViewBaseItem[] => {
    return [...departments]
        .sort((d1, d2) => (d1.order >= d2.order ? 1 : -1))
        .map(department => {
            const parentId = flatDepartments.find(d => d.id === department.id)?.parentId;
            return {
                id: department.id,
                label: getLabelTranslation(department.name, translationLanguage),
                children: mapDepartmentNodesToTreeItems(department.children, flatDepartments, translationLanguage),
                originalData: department,
                parent: parentId ? flatDepartments.find(d => d.id === parentId) : undefined,
            };
        });
};

export const mapDepartmentNodesToTreeSelectFilterOptions = (departmentNodes: DepartmentNode[], departmentIdsToFilter?: number[]): TreeSelectFilterOption[] => {
    return departmentNodes
        .map(departmentNode => ({
            label: getLabelTranslation(departmentNode.name),
            value: departmentNode.id,
            children: mapDepartmentNodesToTreeSelectFilterOptions(departmentNode.children, departmentIdsToFilter),
        }))
        .filter(filterOption => !departmentIdsToFilter || departmentIdsToFilter.includes(filterOption.value));
};
