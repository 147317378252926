import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { Box, Button, CircularProgress, Paper, Stack } from '@mui/material';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ConfigType, HeaderType } from './types';
import { Add01Icon } from 'hugeicons-react';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';

type Props<TData> = {
    options: ConfigType<TData>;
    data?: TData[];
    isSearchAvailable?: boolean;
    onExport?: () => void;
    onQuickFilter?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function CompanySettingsLayout<TData>({
    options,
    data,
    children,
    isSearchAvailable = true,
    onExport,
    onQuickFilter,
}: PropsWithChildren<Props<TData>>): ReactElement {
    const agGridWrapper = useAgGridWrapper<TData>();
    const internalOnExport = () => agGridWrapper.gridRef.current?.api?.exportDataAsExcel();
    const internalOnQuickFilter = agGridWrapper.quickFilter;

    // If the user has provided a custom initRef function, use that, otherwise use the default one
    const initRef: AgGridWrapperProps<TData>['initRef'] = options.table?.agGridProps?.initRef ?? agGridWrapper.setGridRef;
    const agGridProps: AgGridWrapperProps<TData> = { ...options.table?.agGridProps, initRef, loading: false };

    const { table } = options;
    const overriddenTable: ConfigType<TData>['table'] = table
        ? {
              ...table,
              columns: table?.columns ?? [],
              agGridProps,
          }
        : undefined;

    return (
        <Stack direction='column' gap={2} flex={1}>
            {options.header && <CompanySettingsHeader options={options.header} />}
            <Stack flex={1}>
                <CompanySettingsContent
                    isSearchAvailable={isSearchAvailable}
                    options={{ ...options, table: overriddenTable }}
                    data={data}
                    onExport={onExport ?? internalOnExport}
                    onQuickFilter={onQuickFilter ?? internalOnQuickFilter}
                >
                    {children}
                </CompanySettingsContent>
            </Stack>
        </Stack>
    );
}

type CompanySettingsContentProps<TData> = {
    options: ConfigType<TData>;
    data?: TData[];
    isSearchAvailable?: boolean;
    onExport?: () => void;
    onQuickFilter?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function CompanySettingsContent<TData>({
    options,
    data,
    children,
    isSearchAvailable,
    onExport,
    onQuickFilter,
}: PropsWithChildren<CompanySettingsContentProps<TData>>): ReactElement {
    if (!children) {
        return (
            <Stack flex={1}>
                {options?.table && (options?.table || options?.type === 'table') ? (
                    <CompanySettingsContentTable
                        data={data ?? []}
                        isSearchAvailable={isSearchAvailable}
                        columns={options?.table?.columns}
                        options={options}
                        onQuickFilter={onQuickFilter}
                        onExport={onExport}
                    />
                ) : (
                    <Box>
                        <CircularProgress />
                    </Box>
                )}
            </Stack>
        );
    } else {
        return <Stack direction='row'>{children}</Stack>;
    }
}

const CompanySettingsHeader: FC<{
    options: HeaderType;
}> = ({ options }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const route = useLocation();
    const isPrimaryButtonVisible = options.primaryButtonVisible ?? true;

    const primaryAction = () => {
        if (options.primaryButtonAction) {
            options.primaryButtonAction();
        } else {
            navigate(`${route.pathname}/new`);
        }
    };

    return (
        <Paper sx={{ p: 1 }}>
            <Stack direction='row' alignItems='center' justifyContent={'flex-end'}>
                <Stack direction={'row'}>
                    {options.defaultButtonAction && (
                        <Button variant='text' onClick={options.defaultButtonAction} size='small'>
                            {options.defaultButtonCaption ?? t('general.create')}
                        </Button>
                    )}
                    {options.secondaryButtonAction && (
                        <Button variant='text' onClick={options.secondaryButtonAction} size='small'>
                            {options.secondaryButtonCaption ?? t('general.create')}
                        </Button>
                    )}
                    {isPrimaryButtonVisible && (
                        <Button startIcon={<Add01Icon />} onClick={primaryAction} sx={{ ml: 2 }} size='small'>
                            {options.primaryButtonCaption ?? t('general.create')}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Paper>
    );
};

type CompanySettingsContentTableProps<TData> = {
    columns: ColDef<TData>[];
    data: TData[];
    isSearchAvailable?: boolean;
    options: ConfigType<TData>;
    onExport?: () => void;
    onQuickFilter?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CompanySettingsContentTable = <TData,>({
    columns,
    data,
    isSearchAvailable = true,
    options,
    onExport,
    onQuickFilter,
}: CompanySettingsContentTableProps<TData>): ReactElement => {
    const navigate = useNavigate();
    const route = useLocation();

    const onRowClick = (row: RowClickedEvent) => {
        if (!row.event?.defaultPrevented && options.isOnRowClickActive) {
            if (options.table?.agGridProps?.onRowClicked) {
                options.table?.agGridProps?.onRowClicked(row);
            } else if (row?.data?.id) {
                navigate(`${route.pathname}/${row.data.id}`);
            }
        }
    };

    return (
        <Stack flex={1} gap={2}>
            {isSearchAvailable && (
                <Stack direction='row-reverse' gap={2} alignItems='center' justifyContent='space-between'>
                    {isSearchAvailable && onQuickFilter && <DatatableAdditionalAction quickFilter={onQuickFilter} onBtnExport={onExport} />}
                </Stack>
            )}
            <Stack flex={1}>
                {!!data?.length && (
                    <AgGridWrapper<TData>
                        {...options.table?.agGridProps}
                        rowData={data}
                        columnDefs={columns}
                        onRowClicked={options.isOnRowClickActive ? event => onRowClick(event) : undefined}
                        disableAutoSize={options.table?.agGridProps?.disableAutoSize}
                        loading={false}
                    />
                )}
            </Stack>
        </Stack>
    );
};
