import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { useGetEmployeePayrollLock } from '@/hooks/payroll/Payroll.hook';
import { LockTimesheetDialog } from '@/page/payroll/lock-timesheet-dialog/LockTimesheetDialog';
import { UnlockEmployeePayrollLocksDialog } from '@/page/payroll/unlock-employee-payroll-locks-dialog/UnlockEmployeePayrollLocksDialog';
import { handleError } from '@/utils/api.util';
import { Paper, Stack } from '@mui/material';
import { ColDef } from 'ag-grid-enterprise';
import { ArrowDown01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export const EmployeePayrollLocksPage: FC = () => {
    const params = useParams();
    const employeeId = Number(params.employeeId) || undefined;

    const handleRefetch = () => {
        refetch().catch(handleError);
    };

    const {
        data: employeePayrollLocks = [],
        isFetching = true,
        refetch,
    } = useGetEmployeePayrollLock({
        employeeIds: employeeId ? [employeeId] : [],
    });

    return (
        employeeId && (
            <EmployeePayrollLocksTable
                employeePayrollLocks={employeePayrollLocks}
                refetchEmployeePayrollLocks={handleRefetch}
                employeeId={employeeId}
                isLoading={isFetching}
            />
        )
    );
};

type EmployeePayrollLocksTableProps = {
    employeePayrollLocks: EmployeePayrollLock[];
    refetchEmployeePayrollLocks: () => void;
    employeeId: number;
    isLoading: boolean;
};

const EmployeePayrollLocksTable: FC<EmployeePayrollLocksTableProps> = ({ employeePayrollLocks, refetchEmployeePayrollLocks, employeeId, isLoading }) => {
    const { t } = useTranslation();
    const agGridWrapper = useAgGridWrapper<EmployeePayrollLock>();

    const [employeeIdToUnlock, setEmployeeIdToUnlock] = useState<number>();
    const [employeeIdToLock, setEmployeeIdToLock] = useState<number>();

    const columnDefs: ColDef<EmployeePayrollLock>[] = [
        {
            field: 'employee',
            headerName: t('payroll_locks_page.table_headers.employee'),
            type: 'employee',
            width: 350,
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },
        {
            headerName: t('payroll_locks_page.table_headers.lockedAt'),
            type: 'date',
            valueGetter: params => (!params.data ? undefined : params.data.lockedAt),
        },
        {
            headerName: t('payroll_locks_page.table_headers.comment'),
            valueGetter: params => (!params.data ? undefined : params.data.comment),
        },
        {
            headerName: t('payroll_locks_page.table_headers.lockedBy'),
            valueGetter: params => (!params.data ? undefined : params.data.createdBy?.displayName),
        },
    ];

    const handleUnlockEmployeeLockButton = () => {
        setEmployeeIdToUnlock(employeeId);
    };

    const handleOnCancelUnlock = () => {
        setEmployeeIdToUnlock(undefined);
    };

    const handleOnSaveUnlock = () => {
        setEmployeeIdToUnlock(undefined);
        refetchEmployeePayrollLocks();
    };

    const handleLockEmployeeLockButton = () => {
        setEmployeeIdToLock(employeeId);
    };

    const handleOnSaveLock = () => {
        setEmployeeIdToLock(undefined);
        refetchEmployeePayrollLocks();
    };

    const handleOnCloseLock = () => {
        setEmployeeIdToLock(undefined);
    };

    const addEmployeeLockButton: BasicMenuItem = {
        title: t('payroll_locks_page.action_button.lock'),
        onClick: () => {
            handleLockEmployeeLockButton();
        },
    };

    const addUnlockEmployeeLockButton: BasicMenuItem = {
        title: t('payroll_locks_page.action_button.unlock'),
        onClick: () => {
            handleUnlockEmployeeLockButton();
        },
    };

    const getDropdownActionButtons = (): BasicMenuItem[] => {
        return [addEmployeeLockButton, addUnlockEmployeeLockButton];
    };

    return (
        <Stack direction='column' gap={2} flex={1}>
            <Stack component={Paper} p={1} gap={1} direction='row' justifyContent='space-between' alignItems='center' height={'37px'}>
                <Stack direction='row' gap={1} alignItems='center'>
                    <BasicMenu items={getDropdownActionButtons()} dropdownTitle={t('payroll_locks_page.action_button.title')} endIcon={<ArrowDown01Icon />} />
                </Stack>
            </Stack>
            <Stack component={Paper} flex={1}>
                <AgGridWrapper<EmployeePayrollLock>
                    gridId='employee-payroll-locks-table'
                    columnDefs={columnDefs}
                    rowData={employeePayrollLocks}
                    initRef={agGridWrapper.setGridRef}
                    getRowId={params => params.data.id.toString()}
                    loading={isLoading}
                    compact
                />
            </Stack>
            {!!employeeIdToUnlock && (
                <UnlockEmployeePayrollLocksDialog onSave={handleOnSaveUnlock} onCancel={handleOnCancelUnlock} employeeIds={[employeeIdToUnlock]} />
            )}
            {!!employeeIdToLock && <LockTimesheetDialog onClose={handleOnCloseLock} onSave={handleOnSaveLock} employeeIds={[employeeIdToLock]} />}
        </Stack>
    );
};
