import { IconButton, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Label } from '@/domain/label/Label.model';
import { SurveyQuestionType } from '@/domain/survey/Survey.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyUploadFile } from './SurveyUploadFile';
import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { UserLanguage } from '@/utils/language.util';
import { Delete02Icon } from 'hugeicons-react';
import { assignLocalId } from '@/utils/object.util';
import { SurveyQuestionForm, SurveyQuestionItemForm } from '@/page/survey/SurveyForm.util';

type MultipleChoiceProps = {
    question: SurveyQuestionForm;
    isTranslationMode: boolean;
    onQuestionItemAdded: (option: SurveyQuestionItemForm) => void;
    onChangeQuestionItemTitle: (title: Label, index: number) => void;
    onChangeQuestionItemImage: (file: File | undefined, index: number) => void;
    onDeleteQuestionItem: (index: number, questionType: SurveyQuestionType) => void;
    translationLanguage: UserLanguage;
};

const MIN_MULTIPLE_CHOICE_OPTIONS = 2;

export const SurveyMultipleChoice: FC<MultipleChoiceProps> = ({
    question,
    isTranslationMode,
    onQuestionItemAdded,
    onChangeQuestionItemTitle,
    onChangeQuestionItemImage,
    onDeleteQuestionItem,
    translationLanguage,
}) => {
    const { t } = useTranslation();

    const choiceOption: SurveyQuestionItemForm = assignLocalId({
        order: question.items?.length,
        score: 0,
        label: {
            id: 0,
            translationDe: '',
            translationFr: '',
            translationIt: '',
            translationEn: '',
        },
        imageUrl: '',
    });

    return (
        <Stack gap={2}>
            {question.items?.map((item, index) => (
                <Stack direction='row' key={item.localId} alignItems='center' gap={1}>
                    {/*todo: implement the error helper text message*/}
                    <TranslatableLabelInput
                        translationLanguage={translationLanguage}
                        fullWidth
                        value={item?.label}
                        onChange={(value: Label) => {
                            onChangeQuestionItemTitle(value, index);
                        }}
                    />
                    {!isTranslationMode && (
                        <Stack direction={'row'} sx={{ minWidth: '240px' }}>
                            {/* Display a tooltip when the button is disabled to explain why it is disabled */}
                            <Tooltip
                                title={
                                    question.items.length <= MIN_MULTIPLE_CHOICE_OPTIONS
                                        ? t('survey_templates_page.delete_choice_condition', { count: MIN_MULTIPLE_CHOICE_OPTIONS })
                                        : undefined
                                }
                            >
                                {/*Hack to display the tooltip when the button is disabled*/}
                                <span>
                                    <IconButton
                                        disabled={question.items.length <= MIN_MULTIPLE_CHOICE_OPTIONS}
                                        onClick={() => onDeleteQuestionItem(index, question.type)}
                                    >
                                        <Delete02Icon />
                                    </IconButton>
                                </span>
                            </Tooltip>

                            <SurveyUploadFile
                                defaultImageUrl={item.imageUrl}
                                onUpload={upload => onChangeQuestionItemImage(upload.data, index)}
                                onDelete={() => onChangeQuestionItemImage(undefined, index)}
                            />
                        </Stack>
                    )}
                </Stack>
            ))}
            {!isTranslationMode && (
                <Button sx={{ justifyContent: 'flex-start', width: 100 }} variant='text' onClick={() => onQuestionItemAdded(choiceOption)}>
                    {t('survey_templates_page.add_choice')}
                </Button>
            )}
        </Stack>
    );
};
