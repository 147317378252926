import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { EmployeeReviewFeedbackResult } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import {
    getQuestionsFromFeedbacksItems,
    groupEmployeeReviewFeedbackItemByQuestion,
    isQuestionFeedbackItem,
} from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { ReviewReportMenu } from '@/page/review/review-report/ReviewReportMenu';
import { Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';

type ReviewReportQuestionViewProps = {
    feedbacksItems: EmployeeReviewFeedbackResult['items'];
    filters: FilterType[];
    onFiltersChange: (filters: FilterType[]) => void;
};

export const ReviewReportQuestionView: FC<ReviewReportQuestionViewProps> = ({ feedbacksItems, filters, onFiltersChange }) => {
    const { t } = useTranslation();

    const [filter, setFilter] = useState<SelectFilter>({
        key: 'question',
        filterName: t('reviews.report.question_filter'),
        type: 'select',
        availableRules: [],
        rule: 'EQUALS',
        selectMode: 'SYNC',
        defaultVisibility: 'visible',
        options: [],
    });

    useDeepCompareEffect(() => {
        const questions = getQuestionsFromFeedbacksItems(feedbacksItems);

        const questionsOptions = questions.map(q => ({ label: q, value: q }));

        setFilter({
            ...filter,
            options: questionsOptions,
        });
    }, [feedbacksItems]);

    const feedbacksItemsWithQuestion = feedbacksItems.filter(isQuestionFeedbackItem);

    const filterValue = filter.value?.[0]?.value?.toString();
    const feedbacksItemsGroupedByQuestion = groupEmployeeReviewFeedbackItemByQuestion(feedbacksItemsWithQuestion).filter(
        ({ question }) => !filterValue || question === filterValue,
    );

    return (
        <>
            <Stack component={Paper} direction='row' p={1} justifyContent='space-between'>
                <Stack direction='row' gap={2}>
                    <ReviewReportMenu />
                    <FiltersBar filters={filters} onFiltersChange={onFiltersChange} />
                </Stack>
                <FiltersBar filters={[filter]} onFiltersChange={([filter]) => setFilter(filter)} wrapperProps={{ color: 'secondary' }} clearable={false} />
            </Stack>

            <Stack component={Paper} p={2} flex={1} gap={3}>
                <StateHandler isError={false} isLoading={false} isEmpty={!feedbacksItemsGroupedByQuestion?.length} error={undefined}>
                    {feedbacksItemsGroupedByQuestion.map(({ question, items }) => (
                        <Stack key={question} gap={2}>
                            <Typography variant='body1bold'>{question}</Typography>
                            <Stack gap={1}>
                                {items
                                    .filter(({ comment }) => !!comment)
                                    .map(({ employeeReview, comment }) => (
                                        <Stack key={employeeReview.id} direction='row' gap={1} alignItems='flex-start'>
                                            <EmployeeAvatar employeeAvatar={employeeReview.employee} />
                                            <Typography component='pre' variant='body2' whiteSpace={'pre-wrap'}>
                                                {comment ?? ''}
                                            </Typography>
                                        </Stack>
                                    ))}
                            </Stack>
                        </Stack>
                    ))}
                </StateHandler>
            </Stack>
        </>
    );
};
