import { API_BASE_URL, client } from '@/api/common';
import { ShiftBreak, ShiftBreakMutation } from '@/domain/shift-break/ShiftBreak.model';
import { AxiosResponse } from 'axios';

const getShiftBreaks = async (): Promise<ShiftBreak[]> => {
    const url = API_BASE_URL + `/shift-breaks`;
    const { data } = await client.get<ShiftBreakDTO[]>(url);
    return data;
};

const createShiftBreak = async (request: ShiftBreakMutation): Promise<ShiftBreak> => {
    const url = API_BASE_URL + '/shift-breaks';
    const { data } = await client.post<ShiftBreakDTO, AxiosResponse<ShiftBreakDTO>, ShiftBreakMutationDTO>(url, request);
    return data;
};

const updateShiftBreak = async (id: number, request: ShiftBreakMutation): Promise<ShiftBreak> => {
    const url = API_BASE_URL + `/shift-breaks/${id}`;
    const { data } = await client.put<ShiftBreakDTO, AxiosResponse<ShiftBreakDTO>, ShiftBreakMutationDTO>(url, request);
    return data;
};

const deleteShiftBreak = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/shift-breaks/${id}`;
    await client.delete(url);
};

export type ShiftBreakDTO = ShiftBreak;
export type ShiftBreakMutationDTO = ShiftBreakMutation;

export const shiftBreakApi = {
    getShiftBreaks,
    createShiftBreak,
    updateShiftBreak,
    deleteShiftBreak,
};
