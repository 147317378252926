import { StateHandler } from '@/components/state-handler/StateHandler';
import { canCreateObjectives } from '@/domain/permission/Permission.service';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { useCurrentPolicies } from '@/stores/store';
import { Button, Paper, Stack, StackProps, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIndividualObjectiveDialog } from '@/page/objective/add-individual-objective-dialog/AddIndividualObjectiveDialog';

type EmployeeReviewFeedbackNewObjectivesProps = {
    employeeId: number;
    disabled?: boolean;
    refetchObjectives: () => void;
} & StackProps;

export const EmployeeReviewFeedbackNewObjectives: FC<EmployeeReviewFeedbackNewObjectivesProps> = ({ employeeId, disabled, refetchObjectives }) => {
    const { t } = useTranslation();
    const [objectiveDialogOpen, setObjectiveDialogOpen] = useState<boolean>(false);
    const policies = useCurrentPolicies();

    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const emptyState = (
        <Stack p={2}>
            <Typography variant={'body1'}>{t('reviews.review_summary.no_open_objectives')}</Typography>
        </Stack>
    );

    return (
        <Stack gap={2} component={Paper} p={2}>
            <StateHandler
                isLoading={isLoadingObjectiveSetting}
                isError={isErrorObjectiveSetting}
                error={errorObjectiveSetting}
                emptyStateComponent={emptyState}
            >
                <Typography variant='h2'>{t('reviews.review_summary.create_future_objectives')}</Typography>

                {canCreateObjectives(policies, employeeId) && (
                    <Button fullWidth onClick={() => setObjectiveDialogOpen(true)} disabled={disabled}>
                        {t('reviews.review_summary.add_objective')}
                    </Button>
                )}
            </StateHandler>

            {objectiveDialogOpen && (
                <AddIndividualObjectiveDialog
                    open={objectiveDialogOpen}
                    activeObjective={undefined}
                    disabledEmployeeSelection={true}
                    onSaveObjective={() => refetchObjectives()}
                    employeeId={employeeId}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    onClose={() => {
                        setObjectiveDialogOpen(false);
                    }}
                />
            )}
        </Stack>
    );
};
