import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { WizardBreadcrumbs } from '@/components/wizard-breadcrumbs/WizardBreadcrumbs';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { approveEmployeeReview, isEmployeeReviewSubmitted } from '@/domain/employee-review/EmployeeReview.service';
import { useGetEmployeeReview, useGetEmployeeReviewSelfSummary, useGetEmployeeReviewSummary } from '@/hooks/employee-review/EmployeeReview.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { Footer, FooterActions } from '@/page/layout/Footer';
import { EmployeeReviewSummaryFeedbackForm } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm';
import { useCurrentEmployee } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, DialogActions, DialogContent, FormControlLabel, TextField } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

export const EmployeeReviewSummaryFeedbackFormPage: FC = () => {
    const { employeeReviewId } = useParams();
    const currentEmployee = useCurrentEmployee();
    const {
        data: employeeReview,
        isLoading: isEmployeeReviewLoading,
        isError: isEmployeeReviewError,
        error: employeeReviewError,
    } = useGetEmployeeReview(Number(employeeReviewId));
    const scrollRef = useRef<HTMLDivElement>();
    const { t } = useTranslation();
    const employeeId = employeeReview?.employee?.id;
    const isSelfSummary = employeeId === currentEmployee?.id;
    const navigate = useNavigate();
    const breadcrumbs = [t('reviews.review_summary.discussion'), t('reviews.review_summary.validation')];

    // TODO create custom to load everything with one hook
    const {
        data: employeeReviewSelfSummary,
        isLoading: isEmployeeReviewSelfSummaryLoading,
        isError: isEmployeeReviewSelfSummaryError,
        error: employeeReviewSelfSummaryError,
    } = useGetEmployeeReviewSelfSummary(Number(employeeReviewId), isSelfSummary);

    const {
        data: employeeReviewSummary,
        isLoading: isEmployeeReviewSummaryLoading,
        isError: isEmployeeReviewSummaryError,
        error: employeeReviewSummaryError,
    } = useGetEmployeeReviewSummary(Number(employeeReviewId), !isSelfSummary && !!employeeId && !!currentEmployee?.id);

    const {
        data: objectives = [],
        error: objectivesError,
        isLoading: objectivesLoading,
        refetch: refetchObjectives,
    } = useGetObjectives(employeeId ? { employeeIds: [employeeId] } : undefined);

    const reviewSummary: EmployeeReviewManagerSummary | undefined = employeeReviewSelfSummary
        ? {
              ...employeeReviewSelfSummary,
              upwardReviewers: undefined,
              peerReviewers: undefined,
          }
        : employeeReviewSummary;

    const approveDiscussion = () => {
        approveEmployeeReview(Number(employeeReviewId), {
            comment,
        })
            .then(() => {
                showSnackbar(t('reviews.review_summary.messages.review_approved'), 'success');
                navigate('/profile/reviews');
            })
            .catch(handleError);
    };

    const getBreadcrumbStep = (employeeReviewStatus: EmployeeReviewStatus | undefined): number => {
        switch (employeeReviewStatus) {
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return 0;
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                return 1;
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
                return 1;
            case EmployeeReviewStatus.INPUT_NEEDED:
            case EmployeeReviewStatus.CANCELLED:
            default:
                return 0;
        }
    };

    const getPrimaryActionLabel = (employeeReviewStatus: EmployeeReviewStatus | undefined): string => {
        switch (employeeReviewStatus) {
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return t('reviews.review_summary.close');
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                return isSelfSummary ? t('reviews.review_summary.validate_and_close') : t('reviews.review_summary.close');
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
                return t('reviews.review_summary.close');
            case EmployeeReviewStatus.INPUT_NEEDED:
            case EmployeeReviewStatus.CANCELLED:
            default:
                return t('reviews.review_summary.close');
        }
    };

    const primaryAction = (): void => {
        if (employeeReview && isEmployeeReviewSubmitted(employeeReview.status) && isSelfSummary) {
            setApproveOrRejectDiscussionOpen(true);
        } else {
            navigate(-1);
        }
    };

    const onCancelDiscussionDialog = () => {
        setApproveOrRejectDiscussionOpen(false);
    };

    const [comment, setComment] = useState<string>('');

    // state for approve or reject discussion dialog
    const [approveOrRejectDiscussionOpen, setApproveOrRejectDiscussionOpen] = useState<boolean>(false);

    return (
        <StateHandler
            isLoading={isEmployeeReviewSelfSummaryLoading || isEmployeeReviewSummaryLoading || isEmployeeReviewLoading || !reviewSummary || objectivesLoading}
            isError={isEmployeeReviewSelfSummaryError || isEmployeeReviewSummaryError || isEmployeeReviewError || !!objectivesError}
            error={employeeReviewSelfSummaryError || employeeReviewSummaryError || objectivesError || employeeReviewError}
        >
            {reviewSummary && employeeId && employeeReview && (
                <EmployeeReviewSummaryFeedbackForm
                    employeeReviewManagerSummary={reviewSummary}
                    isFormDisabled={true}
                    employeeId={employeeId}
                    employeeReview={employeeReview}
                    scrollRef={scrollRef}
                    objectives={objectives}
                    refetchObjectives={refetchObjectives}
                />
            )}

            {/* Dialog to approve or reject the review */}
            <DialogWrapper open={approveOrRejectDiscussionOpen} onClose={onCancelDiscussionDialog} header={t('reviews.review_summary.approval_dialog.title')}>
                <DialogContent>
                    <FormControlLabel
                        label={t('reviews.review_summary.approval_dialog.label')}
                        style={{ width: '100%' }}
                        value={comment}
                        control={
                            <TextField
                                fullWidth
                                onChange={event => setComment(event.target.value)}
                                placeholder={t('reviews.review_summary.approval_dialog.label')}
                                multiline
                                minRows={4}
                            />
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button fullWidth color='error' onClick={onCancelDiscussionDialog}>
                        {t('general.cancel')}
                    </Button>
                    <Button fullWidth onClick={approveDiscussion}>
                        {t('reviews.review_summary.approval_dialog.action_label')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
            <Footer justifyContent='space-between'>
                {breadcrumbs && <WizardBreadcrumbs steps={breadcrumbs} activeStep={getBreadcrumbStep(employeeReview?.status)} />}

                <FooterActions
                    actions={[
                        {
                            name: 'primary-action',
                            onClick: primaryAction,
                            children: getPrimaryActionLabel(employeeReview?.status),
                            variant: 'contained',
                        },
                    ]}
                />
            </Footer>
        </StateHandler>
    );
};
