import { EmployeeReviewFeedback, EmployeeReviewFeedbackItem } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import {
    EmployeeReviewFeedbackFormType,
    EmployeeReviewFeedbackItemForm,
    EmployeeReviewFeedbackNewObjectiveFormType,
    EmployeeReviewFeedbackObjectiveFormType,
    EmployeeReviewFeedbackQuestionFormType,
    EmployeeReviewFeedbackSectionFormType,
    EmployeeReviewFeedbackSkillQuestionFormType,
    EmployeeReviewFeedbackSkillsFormType,
    RatingFormType,
    SkillFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { getLabelTranslation } from '@/utils/language.util';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { ContributorType, ReviewItem } from '@/domain/review/Review.model';
import {
    isReviewTemplateItemNewObjectives,
    isReviewTemplateItemObjectives,
    isReviewTemplateItemPrivateEmployeeQuestion,
    isReviewTemplateItemPrivateManagerQuestion,
    isReviewTemplateItemQuestion,
    isReviewTemplateItemSection,
    isReviewTemplateItemSkill,
    isReviewTemplateItemSkills,
} from '@/domain/review-template/ReviewTemplate.service';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { Skill } from '@/domain/skill/Skill.model';

export const mapRatingScale = (rating: ReviewRatingScale): RatingFormType => {
    return {
        items: (rating.items ?? []).map(item => {
            return {
                score: item.score,
                label: getLabelTranslation(item.label) ?? '',
                description: getLabelTranslation(item.description) ?? '',
                commentRequired: item.commentRequired,
            };
        }),
    };
};
export const mapEmployeeReviewFeedbackSectionForm = (item: EmployeeReviewFeedbackItem): EmployeeReviewFeedbackSectionFormType => {
    if (!isReviewTemplateItemSection(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        order: item.order,
        title: item?.employeeReviewFeedbackSection?.title ?? createDefaultLabel(),
        type: item.type,
    };
};

export const mapEmployeeReviewFeedbackSkillQuestionForm = (item: EmployeeReviewFeedbackItem): EmployeeReviewFeedbackSkillQuestionFormType => {
    if (!isReviewTemplateItemSkill(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        order: item.order,
        required: item.required,
        score: item.employeeReviewFeedbackSkillQuestion?.score ?? undefined,
        comment: item.employeeReviewFeedbackSkillQuestion?.comment ?? '',
        title: getLabelTranslation(item.employeeReviewFeedbackSkillQuestion?.skill?.name),
        description: getLabelTranslation(item.employeeReviewFeedbackSkillQuestion?.skill?.description),
        type: item.type,
        skillId: item.employeeReviewFeedbackSkillQuestion?.skill?.id,
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
    };
};

export const mapEmployeeReviewFeedbackObjectiveForm = (item: EmployeeReviewFeedbackItem): EmployeeReviewFeedbackObjectiveFormType => {
    if (!isReviewTemplateItemObjectives(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: item.type,
        order: item.order,
    };
};

export const mapEmployeeReviewFeedbackSkillsForm = (item: EmployeeReviewFeedbackItem): EmployeeReviewFeedbackSkillsFormType => {
    if (!isReviewTemplateItemSkills(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
        id: item.id,
        type: item.type,
        order: item.order,
        skills: (item.employeeReviewFeedbackSkills?.skills ?? [])
            .sort((a, b) => a.skill.category.order - b.skill.category.order)
            .map(skill => ({
                score: skill.score ?? undefined,
                comment: skill.comment ?? '',
                skill: {
                    id: skill.skill.id,
                    name: getLabelTranslation(skill.skill.name),
                    description: getLabelTranslation(skill.skill.description) ?? '',
                    levels: skill.skill.category.levels.map(level => ({
                        id: level.id,
                        description: getLabelTranslation(level.description) ?? '',
                        commentRequired: level.commentRequired,
                        score: level.score,
                        name: getLabelTranslation(level.name),
                    })),
                },
                category: {
                    id: skill.skill.category.id,
                    name: getLabelTranslation(skill.skill.category.name) ?? '',
                },
            })),
        minSkills: item.employeeReviewFeedbackSkills?.minSkills ?? undefined,
        maxSkills: item.employeeReviewFeedbackSkills?.maxSkills ?? undefined,
        allSkillsRequired: item.employeeReviewFeedbackSkills?.allSkillsRequired ?? false,
        instruction: item.employeeReviewFeedbackSkills?.instruction ?? createDefaultLabel(),
    };
};

export const mapEmployeeReviewFeedbackQuestionForm = (item: EmployeeReviewFeedbackItem): EmployeeReviewFeedbackQuestionFormType => {
    if (
        !isReviewTemplateItemQuestion(item.type) &&
        !isReviewTemplateItemPrivateEmployeeQuestion(item.type) &&
        !isReviewTemplateItemPrivateManagerQuestion(item.type)
    ) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
        required: item.required,
        order: item.order,
        id: item.id,
        score: item.employeeReviewFeedbackQuestion?.score ?? undefined,
        comment: item.employeeReviewFeedbackQuestion?.comment ?? '',
        instruction: item.employeeReviewFeedbackQuestion?.instruction ?? createDefaultLabel(),
        title: item.employeeReviewFeedbackQuestion?.title ?? createDefaultLabel(),
        type: item.type,
    };
};

const mapEmployeeReviewNewObjectiveForm = (item: EmployeeReviewFeedbackItem): EmployeeReviewFeedbackNewObjectiveFormType => {
    if (!isReviewTemplateItemNewObjectives(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'NEW_OBJECTIVES',
        order: item.order,
    };
};

export const mapEmployeeReviewFeedback = (employeeReviewFeedbackItem: EmployeeReviewFeedbackItem): EmployeeReviewFeedbackItemForm => {
    switch (employeeReviewFeedbackItem.type) {
        case 'SECTION':
            return mapEmployeeReviewFeedbackSectionForm(employeeReviewFeedbackItem);
        case 'SKILL':
            return mapEmployeeReviewFeedbackSkillQuestionForm(employeeReviewFeedbackItem);
        case 'REVIEW_OBJECTIVES':
            return mapEmployeeReviewFeedbackObjectiveForm(employeeReviewFeedbackItem);
        case 'NEW_OBJECTIVES':
            return mapEmployeeReviewNewObjectiveForm(employeeReviewFeedbackItem);
        case 'SKILLS':
            return employeeReviewFeedbackFormUtil.mapEmployeeReviewFeedbackSkillsForm(employeeReviewFeedbackItem);
        case 'QUESTION':
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
            return mapEmployeeReviewFeedbackQuestionForm(employeeReviewFeedbackItem);
        default:
            throw new Error(`Unsupported item type: ${employeeReviewFeedbackItem.type}`);
    }
};

export const isRatingCommentRequired = (rating: RatingFormType, score: number): boolean => {
    const isCommentRequired = (rating?.items ?? []).find(item => item.score === score)?.commentRequired;
    const isScoreNotNull = score >= 0;
    return isScoreNotNull && !!isCommentRequired;
};

export const isSkillRatingCommentRequired = (skill: SkillFormType, score: number): boolean => {
    const isCommentRequired = skill.levels.find(level => level.score === score)?.commentRequired;
    const isScoreNotNull = score >= 0;
    return isScoreNotNull && !!isCommentRequired;
};

export const getPreviewInitialValues = (employeeReview: EmployeeReview, previewRole: ContributorType, skills: Skill[]): EmployeeReviewFeedbackFormType => {
    const filteredItems = employeeReview?.review?.items.filter(item => {
        if (item.contributorTypes?.includes(previewRole)) {
            if (isReviewTemplateItemSkill(item.type) && item.skill) {
                return skills.some(skill => skill.id === item.skill.id);
            }
            return true;
        }
    });
    return {
        employeeReviewFeedbackItems: (filteredItems ?? [])
            .map(reviewItem => mapPreviewFeedbackItem(reviewItem))
            .filter((item): item is EmployeeReviewFeedbackItemForm => !!item),
    };
};

export const mapPreviewFeedbackItem = (reviewItem: ReviewItem): EmployeeReviewFeedbackItemForm | undefined => {
    switch (reviewItem.type) {
        case 'SECTION':
            return {
                id: reviewItem.id,
                order: reviewItem.order,
                title: reviewItem.title,
                type: 'SECTION' as const,
            } satisfies EmployeeReviewFeedbackSectionFormType;
        case 'SKILL':
            return {
                id: reviewItem.id,
                required: reviewItem.required,
                order: reviewItem.order,
                score: undefined,
                comment: '',
                title: getLabelTranslation(reviewItem.skill?.name),
                description: getLabelTranslation(reviewItem.skill?.description),
                type: 'SKILL' as const,
                skillId: reviewItem.skill?.id,
                rating: reviewItem.rating ? mapRatingScale(reviewItem.rating) : undefined,
            } satisfies EmployeeReviewFeedbackSkillQuestionFormType;
        case 'REVIEW_OBJECTIVES':
            return {
                id: reviewItem.id,
                order: reviewItem.order,
                type: 'REVIEW_OBJECTIVES' as const,
            } satisfies EmployeeReviewFeedbackObjectiveFormType;
        case 'QUESTION':
            return {
                id: reviewItem.id,
                order: reviewItem.order,
                score: undefined,
                comment: '',
                required: reviewItem.required,
                rating: reviewItem.rating ? mapRatingScale(reviewItem.rating) : undefined,
                instruction: reviewItem.instruction ?? createDefaultLabel(),
                title: reviewItem.title ?? createDefaultLabel(),
                type: 'QUESTION' as const,
            } satisfies EmployeeReviewFeedbackQuestionFormType;
        case 'SKILLS':
            return {
                rating: reviewItem.rating ? mapRatingScale(reviewItem.rating) : undefined,
                id: reviewItem.id,
                type: 'SKILLS' as const,
                order: reviewItem.order,
                // todo: skills is empty here, we need to find a way to get the skills of the employee we want to preview
                skills: [],
                minSkills: reviewItem?.minSkills ?? undefined,
                maxSkills: reviewItem?.maxSkills ?? undefined,
                allSkillsRequired: reviewItem?.allSkillsRequired ?? false,
                instruction: reviewItem?.instruction ?? createDefaultLabel(),
            } satisfies EmployeeReviewFeedbackSkillsFormType;
    }
};

const getDefaultValues = (
    feedbacks: EmployeeReviewFeedback['feedbacks'] | undefined,
    skills: Skill[],
    previewRole: ContributorType | undefined,
    employeeReview: EmployeeReview,
    isPreview: boolean,
): EmployeeReviewFeedbackFormType => {
    if (isPreview && previewRole) {
        return getPreviewInitialValues(employeeReview, previewRole, skills);
    }

    return {
        employeeReviewFeedbackItems: (feedbacks ?? [])
            .map(feedback => employeeReviewFeedbackFormUtil.mapEmployeeReviewFeedback(feedback))
            .sort((a, b) => (a?.order && b?.order ? a.order - b.order : 0)),
    };
};

export const employeeReviewFeedbackFormUtil = {
    getDefaultValues,
    mapEmployeeReviewFeedback,
    mapEmployeeReviewFeedbackSectionForm,
    mapEmployeeReviewFeedbackSkillQuestionForm,
    mapEmployeeReviewFeedbackObjectiveForm,
    mapEmployeeReviewFeedbackSkillsForm,
    mapEmployeeReviewFeedbackQuestionForm,
    isRatingCommentRequired,
    getPreviewInitialValues,
    mapPreviewFeedbackItem,
};
