import { isNPS } from '@/domain/survey/Survey.service';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import { Grid, Paper, Stack, TextField, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Slide from '@mui/material/Slide';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { InformationCircleIcon } from 'hugeicons-react';
import { t } from 'i18next';
import { ChangeEvent, FC } from 'react';
import { SurveyAnswerForm, SurveyQuestionForm } from '@/page/survey/SurveyForm.util';

type Props = {
    question: SurveyQuestionForm;
    answer: SurveyAnswerForm;
    onSelect: (selected: string, comment: string) => void;
    selectedLanguage: UserLanguage;
};

const NPSTooltip: FC = () => {
    return (
        <Stack direction={'row'} gap={0.5} alignItems={'center'} justifyContent={'center'}>
            <Typography display={'block'} textAlign={'center'} fontSize={13}>
                {t('survey_templates_page.nps_question')}
            </Typography>
            <Tooltip
                sx={{
                    maxWidth: 432,
                }}
                placement='top'
                title={<span style={{ whiteSpace: 'pre-line' }}>{t('survey_templates_page.nps_question_explanation')}</span>}
            >
                <Typography>
                    <InformationCircleIcon />
                </Typography>
            </Tooltip>
        </Stack>
    );
};

export const SurveyAnswerScale: FC<Props> = ({ question, answer, onSelect, selectedLanguage }) => {
    const getSelectedItem = question?.items.find(item => item.localId === answer?.items[0]?.localId);
    const onUpdateComment = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onSelect(answer?.items[0]?.localId, event.target.value);
    };
    const handleChange = () => {
        onSelect(answer?.items[0]?.localId, answer.comment);
    };

    const renderScalePhoto = () => {
        const score = getSelectedItem?.score ?? 0;
        if (score <= 3) {
            return question.items[1].imageUrl && <img src={question.items[1].imageUrl} alt={'Scale1'} />;
        } else if (score <= 5) {
            return question.items[4].imageUrl && <img src={question.items[4].imageUrl} alt={'Scale2'} />;
        } else if (score <= 7) {
            return question.items[6].imageUrl && <img src={question.items[6].imageUrl} alt={'Scale3'} />;
        } else if (score <= 10) {
            return question.items[8].imageUrl && <img src={question.items[8].imageUrl} alt={'Scale4'} />;
        }
    };

    const marks = question.items.map(item => ({
        label: item.score,
        value: item.score,
    }));

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Paper
                sx={{
                    padding: 6,
                    maxWidth: 720,
                    margin: 'auto',
                }}
            >
                <Stack gap={1.5} marginBottom={2}>
                    <Typography
                        variant='h1'
                        sx={theme => ({
                            display: 'block',
                            textAlign: 'center',
                            [theme.breakpoints.down('md')]: {
                                fontSize: 18,
                                display: 'block',
                            },
                        })}
                    >
                        {getLabelTranslation(question.question, selectedLanguage)}
                    </Typography>
                    {isNPS(question.type) && <NPSTooltip />}
                </Stack>
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item>
                            <Typography variant='body1bold'>{getLabelTranslation(question.items[0].label, selectedLanguage)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body1bold'>
                                {getLabelTranslation(question.items[question.items.length - 1].label, selectedLanguage)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Slider
                        defaultValue={answer?.items[0]?.score ?? 0}
                        onChange={(_, val) => {
                            const score = val as number;
                            const selectedItem = question.items.find(i => i.score === score);
                            if (selectedItem) {
                                onSelect(selectedItem?.localId, answer.comment);
                            }
                        }}
                        min={0}
                        max={question.items.length - 1}
                        step={1}
                        marks={marks}
                    />
                </Grid>
                {question.allowComment && (
                    <>
                        <Grid item>
                            <FormControlLabel
                                label='Comment'
                                labelPlacement='top'
                                sx={theme => ({
                                    width: '100%',
                                    marginBottom: theme.spacing(2),
                                    marginTop: theme.spacing(4),
                                    [theme.breakpoints.down('md')]: {
                                        marginTop: theme.spacing(2),
                                    },
                                    '& .MuiFormControlLabel-label': {
                                        color: '#C4C4C4',
                                        fontSize: 16,
                                    },
                                })}
                                control={
                                    <TextField
                                        fullWidth
                                        InputProps={{ multiline: true, minRows: 4 }}
                                        value={answer.comment}
                                        onChange={event => {
                                            onUpdateComment(event);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            alignItems={'center'}
                            sx={theme => ({
                                flexFlow: 'inherit',
                                [theme.breakpoints.down('lg')]: {
                                    flexFlow: 'wrap',
                                },
                                '& .MuiFormControlLabel-label': {
                                    color: '#000A1D',
                                    fontSize: 14,
                                    margin: 0,
                                },
                            })}
                        >
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={answer.anonymous} color='primary' onChange={handleChange} name='checkedA' />}
                                        label={answer.anonymous ? 'Anonymous' : 'Not anonymous'}
                                        labelPlacement='end'
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid container direction={'column'}>
                    <Grid
                        item
                        sx={theme => ({
                            textAlign: 'center',
                            marginTop: theme.spacing(3),
                            '& img': {
                                maxWidth: 210,
                            },
                        })}
                    >
                        {renderScalePhoto()}
                    </Grid>
                </Grid>
            </Paper>
        </Slide>
    );
};
