import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getNestedValueByPath } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import { mapToReorderRequest } from '@/domain/common';
import { getJobFamilies } from '@/domain/job-family/JobFamily.service';
import { Label } from '@/domain/label/Label.model';
import { searchSkillCategories } from '@/domain/skill-category/SkillCategory.service';
import { Skill } from '@/domain/skill/Skill.model';
import { deleteSkill, reorderSkillsRequest } from '@/domain/skill/Skill.service';
import { useGetReviewSkills } from '@/hooks/review-skill/ReviewSkill.hook';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { htmlToPlainText } from '@/utils/strings.util';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { Button, Paper, Stack } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FilterBar.type';

export const ReviewSkillSettingPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const route = useLocation();
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

    const { data: skills = [], refetch: refetchSkills } = useGetReviewSkills();
    const { filters: availableFilters } = useReviewSkillFilters();
    const [filters, setFilters] = useFiltersStorage('review-skill', availableFilters);
    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<Skill>();

    const onBtnExport = () => {
        gridRef.current?.api?.exportDataAsExcel();
    };

    const onClickSkillRow = (skill: Skill) => {
        navigate(`${route.pathname}/${skill.id}`);
    };

    const onDeleteSkill = async (skill: Skill) => {
        try {
            await deleteSkill(skill.id);
            showSnackbar(t('reviews_settings_page.messages.skill_deleted'), 'success');
            refetchSkills();
        } catch (error) {
            handleError(error);
        }
    };

    const getSkillsFiltered = () => {
        const filtersFilled = filters?.filter(filter => !!filter.value?.length);
        return !filtersFilled?.length ? skills : (skills ?? []).filter(row => filtersFilled.every(filter => isFilterMatched(filter, row)));
    };

    const isFilterMatched = (filter: SelectFilter | AsyncSelectFilter, skill: Skill): boolean => {
        if (filter.key === 'JOB_FAMILY_ID') {
            return !!filter.value?.every(option => skill.jobFamilies.some(jobFamily => jobFamily.id === option.value));
        }
        const nestedValue = getNestedValueByPath(skill, filter.key);
        return !!filter.value?.find(option => option.value === nestedValue);
    };

    const skillsFiltered = getSkillsFiltered();

    const handleOrderModal = () => {
        setIsOrderModalOpen(true);
    };

    const getReorderItems = (skills: Skill[]) => {
        return skills.map((skill, index) => ({
            id: skill.id,
            name: getLabelTranslation(skill.name),
            order: index,
        }));
    };

    const handleReorderSave = async (reorderItems: ReorderItem[]) => {
        const reorderRequest = mapToReorderRequest(reorderItems);
        await reorderSkillsRequest(reorderRequest);
        refetchSkills().catch(handleError);
        setIsOrderModalOpen(false);
    };

    const actionMenuRenderer = (params: ICellRendererParams<Skill>) => {
        if (!params.data) {
            return <></>;
        }

        const skill = params.data;

        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => onClickSkillRow(skill),
                    },
                    {
                        title: t('general.delete'),
                        onClick: () => {
                            onDeleteSkill(skill).catch(handleError);
                        },
                    },
                ]}
            />
        );
    };

    const handleRowClick = (params: RowClickedEvent<Skill>) => {
        if (params.event?.defaultPrevented || !params.data) {
            return;
        }
        onClickSkillRow(params.data);
    };

    const columns: ColDef<Skill>[] = [
        {
            field: 'name',
            headerName: t('reviews_settings_page.measure'),
            type: 'label',
            cellClass: ['display-flex'],
        },
        {
            field: 'category.name',
            headerName: t('reviews_settings_page.category'),
            type: 'label',
        },
        {
            field: 'description',
            headerName: t('reviews_settings_page.default'),
            valueFormatter: ({ value }: { value: Label }) => htmlToPlainText(getLabelTranslation(value)),
            maxWidth: 500,
            cellClass: ['display-flex'],
        },
        {
            field: 'jobFamilies',
            headerName: t('reviews_settings_page.job_families'),
            valueFormatter: ({ data, value }: { data: Skill | undefined; value: Skill['jobFamilies'] | undefined }) => {
                if (data?.allEmployeesSelected) {
                    return t('reviews_settings_page.all_employees_selected');
                }

                return value?.map(jobFamily => jobFamily.name).join(', ') ?? '';
            },
        },
        {
            type: 'actionMenu',
            cellRenderer: actionMenuRenderer,
        },
    ];

    return (
        <>
            <Stack direction='row' justifyContent='space-between' alignItems='center' component={Paper} p={1}>
                <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />

                <Stack direction='row' gap={2}>
                    <Button variant='text' onClick={handleOrderModal}>
                        {t('permissions_setting_page.reorder')}
                    </Button>
                    <Button variant='contained' onClick={() => navigate(`${route.pathname}/new`)} startIcon={<Add01Icon />}>
                        {t('reviews_settings_page.skill')}
                    </Button>

                    <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} />
                </Stack>
            </Stack>
            <AgGridWrapper rowData={skillsFiltered} columnDefs={columns} initRef={setGridRef} onRowClicked={handleRowClick} loading={false} />
            {isOrderModalOpen && (
                <ReorderModal
                    initialReorderItems={getReorderItems(skills ?? [])}
                    open={true}
                    onSave={handleReorderSave}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

const useReviewSkillFilters = () => {
    const { t } = useTranslation();
    const filters: (AsyncSelectFilter | SelectFilter)[] = [
        {
            filterName: t('reviews_settings_page.category'),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const categories = await searchSkillCategories();
                return categories.map(category => ({
                    label: getLabelTranslation(category.name),
                    value: category.id,
                }));
            },
            key: 'CATEGORY_ID',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: t('reviews_settings_page.job_families'),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobFamilies = await getJobFamilies();
                return jobFamilies.map(jobFamily => ({
                    label: jobFamily.name,
                    value: jobFamily.id,
                }));
            },
            key: 'JOB_FAMILY_ID',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];
    return {
        filters,
    };
};
