import { shiftBreakApi } from '@/api/shift-break/ShiftBreak.api';
import { ShiftBreak, ShiftBreakMutation } from '@/domain/shift-break/ShiftBreak.model';

export const getShiftBreaks = async (): Promise<ShiftBreak[]> => {
    return shiftBreakApi.getShiftBreaks();
};

export const createShiftBreak = async (request: ShiftBreakMutation): Promise<ShiftBreak> => {
    return shiftBreakApi.createShiftBreak(request);
};

export const updateShiftBreak = async (id: number, request: ShiftBreakMutation): Promise<ShiftBreak> => {
    return shiftBreakApi.updateShiftBreak(id, request);
};

export const deleteShiftBreak = async (id: number): Promise<void> => {
    return shiftBreakApi.deleteShiftBreak(id);
};
