import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getDepartments } from '@/domain/department/Department.service';
import { FilterType } from '@/components/filters-bar/FilterBar.type';

export const useEmployeeFeedbacksResultFilters = (): [FilterType[], (filters: FilterType[]) => void] => {
    const availableFilters: FilterType[] = [
        {
            key: 'employmentLocation',
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () =>
                getLocations().then(locations =>
                    locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    })),
                ),
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'employmentDepartment',
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () =>
                getDepartments().then(departments =>
                    departments?.map(department => ({
                        label: getLabelTranslation(department.name),
                        value: department.id,
                    })),
                ),
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'employmentManager',
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () =>
                searchEmployees().then(employees =>
                    employees?.map(employee => ({
                        label: employee.displayName,
                        value: employee.id,
                    })),
                ),
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    const [filters, setFilters] = useFiltersStorage('employee-feedbacks-result-filters', availableFilters);

    return [filters, setFilters];
};
