import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { getDepartmentNodes } from '@/domain/department/Department.service';
import { EmployeeFilterType, searchEmployees } from '@/domain/employee/Employee.service';
import { getJobs } from '@/domain/job/Job.service';
import { getLocations } from '@/domain/location/Location.service';
import { formatToLocalDate, getCurrentLocalDate, getEndOfYear, getStartOfMonth, MONTHS, setMonth, toDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { format } from 'date-fns';
import { activeMonth } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistory.util';
import { AsyncSelectFilter, AsyncTreeSelectFilterType, DateFilter, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.utils';

export type BalanceTimesheetFilter = SelectFilter | AsyncSelectFilter | DateFilter | AsyncTreeSelectFilterType;
export const useBalanceTimesheetPageFilters = (
    timesheetSettings: TimesheetSetting[],
    defaultTimesheetSetting: TimesheetSetting,
    haveDifferentCycleStartMonth: boolean,
): { filters: BalanceTimesheetFilter[] } => {
    const { t } = useTranslation();

    const filters: BalanceTimesheetFilter[] = [
        {
            filterName: t('balance_page.filters.cycle'),
            type: 'select',
            selectMode: 'SYNC',
            value: [
                {
                    label: getFormatedMonth(defaultTimesheetSetting.cycleStartMonth),
                    value: defaultTimesheetSetting.cycleStartMonth,
                },
            ],
            //only unique months should be displayed
            options: Array.from(new Set(timesheetSettings?.map(timesheetSetting => timesheetSetting.cycleStartMonth))).map(month => ({
                label: getFormatedMonth(month),
                value: month,
            })),
            key: 'startCycleMonth',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
            hide: !haveDifferentCycleStartMonth,
        },
        {
            key: 'endDate',
            filterName: t('timesheets.period'),
            type: 'date',
            value: getEndOfYear(),
            dateType: 'MORE_THAN',
            availableRules: ['MORE_THAN'],
            clearable: false,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'tree-multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departmentsNodes = await getDepartmentNodes();
                return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'MANAGER_IDS' satisfies EmployeeFilterType,
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employees = await searchEmployees();
                return employees?.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};

const getFormatedMonth = (month: MONTHS): string => {
    const date = toDate(getStartOfMonth(getCurrentLocalDate()));
    const startDate = formatToLocalDate(setMonth(date, activeMonth(month)));

    return format(startDate, 'MMM');
};
