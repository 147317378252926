import { EmployeeFilterType, getUserEmploymentStatusTranslationKey, searchEmployees } from '@/domain/employee/Employee.service';

import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { getDepartmentNodes } from '@/domain/department/Department.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { getJobs } from '@/domain/job/Job.service';
import { getLocations } from '@/domain/location/Location.service';
import { convertEnumToOptions } from '@/utils/enum.util';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';
import { AsyncSelectFilter, AsyncTreeSelectFilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.utils';

export type EmployeeSelectionFilter = SelectFilter | AsyncSelectFilter | AsyncTreeSelectFilterType;

/**
 * Hook to get the list of filter for employee
 */
export const useEmployeeSelectionFilters = (): { filters: EmployeeSelectionFilter[] } => {
    const { t } = useTranslation();
    const filters: EmployeeSelectionFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_STATUS' }),
            type: 'multi-select',
            options: convertEnumToOptions(EmploymentStatus, key => t(getUserEmploymentStatusTranslationKey(), { context: key })).filter(
                ({ value }) => value !== EmploymentStatus.TERMINATED,
            ),
            selectMode: 'SYNC',
            value: [
                {
                    label: t(getUserEmploymentStatusTranslationKey(), { context: EmploymentStatus.EMPLOYED }),
                    value: EmploymentStatus.EMPLOYED,
                },
            ],
            key: 'employmentStatus',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'tree-multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departmentsNodes = await getDepartmentNodes();
                return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'MANAGER_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
