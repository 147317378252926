import { useState } from 'react';
import { FormState } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

/**
 * Hook that scrolls to the first error in the form.
 * @param formState The form state to check for errors
 */
export const useScrollToFirstError = <TFieldValues extends Record<string, unknown>>(
    formState: FormState<TFieldValues>,
): {
    resetFocus: () => void;
} => {
    // this state is used to prevent the scroll from happening multiple times, we want to scroll only once, when the error occurs
    // when we scroll to the first error, we set this state to false
    const [canFocus, setCanFocus] = useState(true);

    useDeepCompareEffect(() => {
        if (formState.errors && canFocus) {
            const elementsOnError = Object.keys(formState.errors)
                .map(name => document.getElementsByName(name)[0] as HTMLElement | undefined)
                .filter((el): el is HTMLElement => !!el);

            // Sort elements by their vertical position on the page
            elementsOnError.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);

            if (elementsOnError.length > 0) {
                const errorElement = elementsOnError[0];
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setCanFocus(false);
            }
        }
    }, [formState.errors, canFocus]);

    return { resetFocus: () => setCanFocus(true) }; // Optional resetFocus function
};
