import { PermissionFilteringConditionRule, PermissionFilteringConditionType, PermissionFilterType } from '@/domain/permission/Permission.model';
import * as yup from 'yup';
import { PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';
import { ContractType, EmploymentStatus } from '@/domain/employment/Employment.model';

const filteringConditionValueSchema = yup.object().shape({
    location: yup
        .object()
        .shape({
            id: yup.number().required(),
            label: yup.string().required(),
        })
        .optional()
        .default(undefined),
    legalUnit: yup
        .object()
        .shape({
            id: yup.number().required(),
            label: yup.string().required(),
        })
        .optional()
        .default(undefined),
    department: yup
        .object()
        .shape({
            id: yup.number().required(),
            label: yup.string().required(),
        })
        .optional()
        .default(undefined),
    job: yup
        .object()
        .shape({
            id: yup.number().required(),
            label: yup.string().required(),
        })
        .optional()
        .default(undefined),
    employmentStatus: yup.string().oneOf(Object.values(EmploymentStatus)).optional().default(undefined),
    contractType: yup.string().oneOf(Object.values(ContractType)).optional().default(undefined),
});

export type FilteringConditionValueFormValues = yup.InferType<typeof filteringConditionValueSchema>;

const filteringConditionSchema = yup.object().shape({
    id: yup.number().optional(),
    conditionType: yup.string().oneOf(Object.values(PermissionFilteringConditionType)).optional(),
    filterConditionValues: yup.array().of(filteringConditionValueSchema).required(),
    conditionRule: yup.string().oneOf(Object.values(PermissionFilteringConditionRule)).required(),
});

export type FilteringConditionFormValues = yup.InferType<typeof filteringConditionSchema>;

const schema = yup.object().shape({
    groupName: yup.string().trim().required(),
    description: yup.string().trim().required(),
    groupType: yup
        .string()
        .oneOf(Object.values(PermissionGroupType) as PermissionGroupType[])
        .required(),
    filterType: yup
        .string()
        .oneOf(Object.values(PermissionFilterType) as PermissionFilterType[])
        .required(),
    targetMembersFilterType: yup
        .string()
        .oneOf(Object.values(PermissionFilterType) as PermissionFilterType[])
        .required(),
    groupMembers: yup.array().required(),
    groupPolicies: yup.array().required(),
    targetMembers: yup.array().required(),
    notificationsEnabled: yup.boolean().default(true),
    groupMemberConditions: yup.array().of(filteringConditionSchema).required(),
    targetMemberConditions: yup.array().of(filteringConditionSchema).required(),
});

export const permissionSettingFormSchema = schema;

export type PermissionFormValues = yup.InferType<typeof schema>;
