import { AllowanceType, EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { MenuItem, MenuItemProps, Select, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { canCorrectEmployeeBalance, canManagePendingLeaveRequests, hasConfigureLeavePolicy } from '@/domain/permission/Permission.service';
import { LeaveRequestDialog } from '@/page/leave/leave-request-dialog/LeaveRequestDialog';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { AssignPolicyDialog } from './Dialogs/AssignPolicyDialog';
import { UnassignPolicyDialog } from './Dialogs/UnassignPolicyDialog';

import { SplitButtonGroup } from '@/components/button-group/SplitButtonGroup';
import { Employee } from '@/domain/employee/Employee.model';
import { LeaveCorrectionCreateMutation } from '@/domain/leave-correction/LeaveCorrection.model';
import { leaveCorrectionService } from '@/domain/leave-correction/LeaveCorrection.service';
import { LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { LeaveTypeCard } from '@/page/employee-profile/employee-profile-leave/LeaveTypeCard';
import { LeaveCorrectionDialog, LeaveCorrectionValues } from '@/page/leave/leave-correction-dialog/LeaveCorrectionDialog';
import { useAppDispatch, useCurrentPolicies } from '@/stores/store';
import { desktopBlockVisible } from '@/theme/responsive';
import { handleError } from '@/utils/api.util';
import { formatDate, formatInDefaultDate, MONTHS } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Add01Icon } from 'hugeicons-react';
import { TimesheetCycle } from '@/domain/timesheet/Timesheet.model';

type MyAllowanceProps = {
    activeEmployee: Employee;
    userLeaveTypePolicies: EmployeeLeaveTypePolicy[];
    onPoliciesUpdated: (newPolicies: EmployeeLeaveTypePolicy[]) => void;
    onChange: () => void;
    leaveTypeHistories: LeaveTypeHistory[];
    leaveCycles: TimesheetCycle[];
    selectedLeaveCycle: TimesheetCycle;
    onChangeLeaveCycle: (cycle: TimesheetCycle) => void;
};

export const MyAllowance: FC<MyAllowanceProps> = ({
    activeEmployee,
    userLeaveTypePolicies,
    onPoliciesUpdated,
    onChange,
    leaveTypeHistories,
    leaveCycles,
    selectedLeaveCycle,
    onChangeLeaveCycle,
}) => {
    const { t } = useTranslation();
    const employeeId = activeEmployee.id;
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const dispatch = useAppDispatch();
    const policies = useCurrentPolicies();
    const [employeeIdForLeaveRequestCreation, setEmployeeIdForLeaveRequestCreation] = useState<number>();
    const [openLeaveCorrectionDialog, setOpenLeaveCorrectionDialog] = useState(false);

    const showLeaveTypeHistoryCard = (history: LeaveTypeHistory) =>
        history.leaveType.displayCounter == 'ALWAYS' || (history.usedAmountInMinutes > 0 && history.leaveType.displayCounter == 'SHOW_IF_THERE_IS_LEAVES');

    leaveTypeHistories.sort((columnA, columnB) => {
        return columnA.leaveType.order - columnB.leaveType.order;
    });

    const leaveTypeHistoriesToShow = leaveTypeHistories.filter(history => showLeaveTypeHistoryCard(history));
    const leaveTypeHistoriesNotUnlimited = leaveTypeHistoriesToShow.filter(lth => lth.leaveType.allowanceType === AllowanceType.NOT_UNLIMITED);

    const getLeaveMenuItems = () => {
        const leaveMenuItems: MenuItemProps[] = [];
        if (userLeaveTypePolicies.length !== 0) {
            leaveMenuItems.push({
                title: t('my_leaves.leave_label'),
                onClick: () => {
                    setEmployeeIdForLeaveRequestCreation(employeeId);
                },

                color: 'primary',
            });
        }

        if (hasConfigureLeavePolicy(policies)) {
            leaveMenuItems.push({
                title: t('my_leaves.assign_policy'),
                onClick: () => {
                    dispatch({
                        type: UiActionType.ASSIGN_LEAVE_TYPE_DIALOG_OPEN,
                        userId: employeeId,
                        open: true,
                    });
                },
                key: 'assign-policy',
            });
            if (userLeaveTypePolicies.length > 0) {
                leaveMenuItems.push({
                    title: t('my_leaves.unassign_policy'),
                    disabled: !userLeaveTypePolicies?.length,
                    onClick: () => {
                        dispatch({
                            type: UiActionType.UNASSIGN_LEAVE_TYPE_DIALOG_OPEN,
                            userId: employeeId,
                            open: true,
                        });
                    },
                    key: 'unassign-policy',
                });
            }
        }

        if (leaveTypeHistoriesNotUnlimited.length > 0 && canCorrectEmployeeBalance(policies, employeeId)) {
            leaveMenuItems.push({
                title: t('my_leaves_history.add_correction'),
                onClick: () => {
                    setOpenLeaveCorrectionDialog(true);
                },
                key: 'add-correction',
            });
        }
        return leaveMenuItems;
    };

    const leaveMenuItems = getLeaveMenuItems();

    const handleLeaveCorrectionCreate = async (values: LeaveCorrectionValues) => {
        const { leaveType, ...rest } = values;
        const mutation: LeaveCorrectionCreateMutation = {
            ...rest,
            employeeId,
            leaveTypeId: leaveType.id,
        };
        try {
            await leaveCorrectionService.createLeaveCorrection(mutation);
            setOpenLeaveCorrectionDialog(false);
            showSnackbar(t('add_correction_dialog.messages.correction_added'), 'success');
            onChange();
        } catch (error) {
            handleError(error);
        }
    };

    const getTimesheetCycleByYear = (year: string) => {
        return leaveCycles.find(cycle => cycle.year.toString() === year) ?? selectedLeaveCycle;
    };

    return (
        <>
            <AssignPolicyDialog
                onPolicyAssigned={(newPolicies: EmployeeLeaveTypePolicy[]) => {
                    onPoliciesUpdated(newPolicies);
                    onChange();
                }}
            />

            {userLeaveTypePolicies?.length > 0 && (
                <UnassignPolicyDialog
                    userLeaveTypePolicies={userLeaveTypePolicies}
                    onPolicyUnassigned={(newPolicies: EmployeeLeaveTypePolicy[]) => {
                        onPoliciesUpdated(newPolicies);
                        onChange();
                    }}
                />
            )}
            <Stack direction='column' spacing={2}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent={'space-between'}>
                    {!!leaveTypeHistories.length && (
                        <Typography variant='h2' sx={desktopBlockVisible}>
                            {t('my_leaves.balance_at', {
                                endDate: formatInDefaultDate(selectedLeaveCycle.cycleEndDate),
                            })}
                        </Typography>
                    )}
                    <Stack direction='row' gap={1}>
                        <Select value={selectedLeaveCycle.year.toString()} onChange={event => onChangeLeaveCycle(getTimesheetCycleByYear(event.target.value))}>
                            {leaveCycles.map(cycle => (
                                <MenuItem key={cycle.year.toString()} value={cycle.year.toString()}>
                                    {formatDate(cycle.cycleStartDate, cycle.startMonth === MONTHS.JANUARY ? 'yyyy' : 'MMM yyyy')}
                                </MenuItem>
                            ))}
                        </Select>
                        {canManagePendingLeaveRequests(policies, employeeId) && (
                            <SplitButtonGroup
                                buttonProps={{
                                    startIcon: <Add01Icon size={20} />,
                                    size: 'small',
                                }}
                                menuItemsProps={[...leaveMenuItems]}
                                ariaLabel={t('my_leaves.request_leave')}
                                fullWidth={isMobile}
                            />
                        )}
                    </Stack>
                </Stack>
                <Stack direction='row' gap={2} flexWrap={'nowrap'} overflow={'auto'}>
                    {leaveTypeHistoriesToShow.map(history => (
                        <LeaveTypeCard
                            key={history.leaveType.id}
                            employeeId={employeeId}
                            leaveType={history.leaveType}
                            availableAmountInMinutes={history.availableAmountInMinutes}
                            usedAmountInMinutes={history.usedAmountInMinutes}
                            allowanceInMinutes={history.allowanceInMinutes}
                            availableAmountInDays={history.availableAmountInDays}
                            usedAmountInDays={history.usedAmountInDays}
                            allowanceInDays={history.allowanceInDays}
                            year={selectedLeaveCycle.year}
                        />
                    ))}
                </Stack>
            </Stack>
            {!!employeeIdForLeaveRequestCreation && (
                <LeaveRequestDialog
                    open={true}
                    onClose={() => setEmployeeIdForLeaveRequestCreation(undefined)}
                    onSave={() => {
                        setEmployeeIdForLeaveRequestCreation(undefined);
                        onChange();
                    }}
                    employeeId={employeeIdForLeaveRequestCreation}
                />
            )}

            {openLeaveCorrectionDialog && !!activeEmployee?.currentWorkingPattern && (
                <LeaveCorrectionDialog
                    open={true}
                    workingPattern={activeEmployee.currentWorkingPattern}
                    leaveTypes={leaveTypeHistoriesNotUnlimited.map(lth => lth.leaveType)}
                    onSave={handleLeaveCorrectionCreate}
                    onClose={() => {
                        setOpenLeaveCorrectionDialog(false);
                    }}
                />
            )}
        </>
    );
};
