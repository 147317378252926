import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { ImportFailedRow, ImportResult, ImportType } from '@/domain/import/Import.model';
import { CellClassParams, ColDef, ValueGetterParams } from 'ag-grid-community';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { Download02Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ImportedTableProps = {
    importResult: ImportResult;
    importType: ImportType;
};

export const ImportedTable: FC<ImportedTableProps> = ({ importResult, importType }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const agGridWrapper = useAgGridWrapper<ImportFailedRow>();

    const getHeaders = (importResult: ImportResult) => {
        if (importResult?.headers?.length) {
            return importResult.headers;
        }
        return [];
    };

    const columnDefs: ColDef<ImportFailedRow>[] = [
        {
            field: 'error.message',
            cellStyle: { color: theme.palette.error.main },
            cellClass: ['display-flex'],
        },
        ...getHeaders(importResult).map((el, index) => {
            return {
                headerName: el,
                valueGetter: (params: ValueGetterParams<ImportFailedRow>) => params?.data?.rawData[index],
                cellStyle: ({ data: { cellIndex } }: CellClassParams) => (cellIndex === index ? { borderColor: 'red' } : undefined),
                cellClass: ['display-flex'],
            };
        }),
    ];

    if (!importResult) {
        return <></>;
    }

    return (
        <Stack gap={2} p={2} flex={1}>
            <Typography variant='h2' alignSelf={'center'}>
                {importResult.totalRows - importResult.failedRows?.length} / {importResult.totalRows} {t(`import_page.${importType}.import_success`)}
            </Typography>

            {!!importResult.failedRows?.length && (
                <Stack textAlign={'center'} gap={2} flex={1}>
                    <Button
                        variant='text'
                        color={'error'}
                        endIcon={<Download02Icon />}
                        onClick={() => {
                            agGridWrapper.gridRef.current?.api?.exportDataAsExcel({ allColumns: true });
                        }}
                    >
                        {t('import_page.export_multiple_errors', { errors_number: importResult.failedRows?.length })}
                    </Button>

                    <AgGridWrapper<ImportFailedRow>
                        rowData={importResult?.failedRows}
                        initRef={agGridWrapper.setGridRef}
                        columnDefs={columnDefs}
                        loading={false}
                    />
                </Stack>
            )}
        </Stack>
    );
};
