import { Label, LABEL_TRANSLATION_KEYS, LabelRequest } from '@/domain/label/Label.model';
import { getLabelTranslation } from '@/utils/language.util';

export const createDefaultLabel = (label: Partial<LabelRequest> = {}): LabelRequest => ({
    ...Object.fromEntries(LABEL_TRANSLATION_KEYS.map(key => [key, ''])),
    ...label,
});

export const isEmptyLabel = ({ id: _id, ...restLabel }: Label): boolean => Object.values(restLabel).every(value => !value);

export const isLabelUnique = (label: LabelRequest, list: Label[]): boolean => {
    if (isEmptyLabel(label)) {
        return true;
    }

    const isLabelLanguageEqual = (toCreateTranslation: Nullable<string>, oldTranslation: Nullable<string>): boolean => {
        return !!toCreateTranslation && !!oldTranslation && toCreateTranslation.toLowerCase() === oldTranslation.toLowerCase();
    };

    const translationKeys = Object.keys(label).filter(key => key !== 'id') as (keyof LabelRequest)[];
    return !list.some(label2 => translationKeys.some(key => isLabelLanguageEqual(label[key], label2[key])));
};

export const getUniqueTranslationLabels = (labels: Label[]): string[] => {
    return Array.from(new Set(labels.map(label => getLabelTranslation(label))));
};
