import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { SquareTextCheckbox } from '@/components/square-text-checkbox/SquareTextCheckbox';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import Slide from '@mui/material/Slide';
import { SurveyAnswerForm, SurveyQuestionForm } from '../SurveyForm.util';

type Props = {
    onSelect: (value: string, checked: boolean) => void;
    question: SurveyQuestionForm;
    answer: SurveyAnswerForm;
    selectedLanguage: UserLanguage;
};

export const SurveyAnswerMultiChoice: FC<Props> = ({ onSelect, question, answer, selectedLanguage }) => {
    const handleChange = (option: string) => {
        onSelect(option, !isOptionSelected(option));
    };

    const isOptionSelected = (option: string) => {
        if (!question && !answer) {
            return true;
        }
        return answer?.items?.some(item => item.localId === option);
    };

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Stack elevation={0} component={Paper} p={4} maxWidth={720} margin='auto' alignItems={'center'} gap={2}>
                <Typography variant='h1'>{getLabelTranslation(question.question, selectedLanguage)}</Typography>
                <Stack direction='row' justifyContent={'center'} gap={2} flexWrap={'wrap'}>
                    {question.items?.map(item => (
                        <SquareTextCheckbox
                            title={''}
                            key={item.localId}
                            description={
                                <Stack gap={0.5} alignItems={'flex-start'} direction={'row'}>
                                    {item.imageUrl && (
                                        <Stack p={1} pl={2}>
                                            <img src={item.imageUrl} alt={'Icon'} width='40px' height='auto' />
                                        </Stack>
                                    )}
                                    <Stack alignItems={'center'} flex={1} height={'100%'} justifyContent={'center'}>
                                        <Typography variant='body1'>{getLabelTranslation(item.label, selectedLanguage)}</Typography>
                                    </Stack>
                                </Stack>
                            }
                            checked={isOptionSelected(item.localId)}
                            onClick={() => handleChange(item.localId)}
                        />
                    ))}
                </Stack>
            </Stack>
        </Slide>
    );
};
