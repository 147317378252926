import SurveyQuestionImage from '@/assets/images/survey-questions.png';
import { Survey } from '@/domain/survey/Survey.model';
import { Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import { SurveyTemplateForm } from '@/page/survey/SurveyForm.util';

type Props = {
    survey?: Survey;
    isPreviewMode: boolean;
    selectedLanguage: UserLanguage;
    surveyTemplate?: SurveyTemplateForm;
};

export const WelcomePage: FC<Props> = ({ survey, selectedLanguage, isPreviewMode, surveyTemplate }) => {
    if (!survey && !isPreviewMode) {
        return <></>;
    }
    return (
        <Paper
            elevation={0}
            sx={{
                padding: 6,
                textAlign: 'center',
                maxWidth: 720,
                margin: 'auto',
            }}
        >
            <img src={SurveyQuestionImage} alt='questions' />
            <Typography variant='h1' marginBottom={4}>
                {getLabelTranslation(isPreviewMode ? surveyTemplate?.name : survey?.name, selectedLanguage)}
            </Typography>
            <Typography variant='body1'>{getLabelTranslation(isPreviewMode ? surveyTemplate?.description : survey?.description, selectedLanguage)}</Typography>
        </Paper>
    );
};
