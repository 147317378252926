import { getLabelTranslation } from '@/utils/language.util';
import { t } from 'i18next';

import { SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { CustomSectionAgGridData } from './CustomSectionsImportCard';
import { ColDef } from 'ag-grid-enterprise';

// 45323 = 01.02.2024 -> Excel stores dates and times as serial numbers, and it is only a cell's formatting that forces a number to be displayed as a date
const DATE_VALUE = '45323';
const NUMBER_VALUE = '99.99';
const STRING_VALUE_KEY = 'import_page.text';
const CUSTOM_LIST_ITEM_VALUE_KEY = 'import_page.list_item';
const CUSTOM_MULTI_LIST_ITEM_VALUE_KEY = 'import_page.custom_list_item';
const EMAIL_VALUE = 'john.doe@rogerhr.ch';

export const getColumnDefs = (fields: SectionFieldDefinition[]): ColDef<CustomSectionAgGridData>[] => {
    if (!fields) {
        return [];
    }
    const colDef = fields
        .filter(field => field.valueType !== 'SECTION_FIELD_DOCUMENT')
        .map(field => {
            return {
                field: getLabelTranslation(field.name),
                type: field.valueType === 'DATE' ? 'number' : 'string',
                cellClass: field.valueType === 'DATE' ? 'dateISO' : undefined,
            };
        });
    return [{ field: 'email-employee' }, ...colDef];
};

export const getRowData = (fields: SectionFieldDefinition[]): CustomSectionAgGridData[] => {
    if (!fields) {
        return [];
    }

    const rowData: CustomSectionAgGridData[] = fields
        .filter(field => field.valueType !== 'SECTION_FIELD_DOCUMENT')
        .map(field => {
            const label = getLabelTranslation(field.name);
            switch (field.valueType) {
                case 'DATE':
                    return { [label]: DATE_VALUE, type: 'number' };
                case 'NUMBER':
                    return { [label]: NUMBER_VALUE };
                case 'CUSTOM_LIST_ITEM':
                    return { [label]: t(CUSTOM_LIST_ITEM_VALUE_KEY) };
                case 'CUSTOM_MULTI_LIST_ITEM':
                    return { [label]: t(CUSTOM_MULTI_LIST_ITEM_VALUE_KEY) };
                case 'STRING':
                case 'AUTO_INCREMENT':
                default:
                    return { [label]: t(STRING_VALUE_KEY) };
            }
        });

    const emailRowData: CustomSectionAgGridData[] = [{ 'email-employee': EMAIL_VALUE }, ...rowData];

    return [Object.assign({}, ...emailRowData)];
};

export const getSectionTypeTranslationKey = (sectionType: string): string => {
    switch (sectionType) {
        case 'CUSTOM_SINGLE_ROW':
            return 'general.section';
        case 'CUSTOM_MULTI_ROW':
            return 'general.table';
        default:
            return '';
    }
};
