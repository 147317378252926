import { useTranslation } from 'react-i18next';
import { searchCompletionStatuses } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.service';
import { getLabelTranslation } from '@/utils/language.util';
import { ObjectiveStatus } from '@/domain/objective/Objective.model';
import { getObjectiveStatusTranslationKey } from '@/domain/objective/Objective.service';
import { searchObjectiveCategories } from '@/domain/objective-category/ObjectiveCategory.service';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';

export const useObjectivesFilters = (
    { isFromProfile, objectiveCategoriesEnabled }: { isFromProfile: boolean; objectiveCategoriesEnabled: boolean },
    enabled = true,
): { filters: (AsyncSelectFilter | SelectFilter)[] } => {
    const { t } = useTranslation();

    if (!enabled) {
        return { filters: [] };
    }

    const filters: (AsyncSelectFilter | SelectFilter)[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'OBJECTIVE_COMPLETION_STATUS' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const statuses = await searchCompletionStatuses();
                return statuses.map(status => ({
                    label: getLabelTranslation(status.name),
                    value: status.id,
                }));
            },
            key: 'statusCompletionIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: t('objectives.status_label'),
            type: 'multi-select',
            selectMode: 'SYNC',
            options: Object.keys(ObjectiveStatus).map(status => ({
                label: t(getObjectiveStatusTranslationKey(status as ObjectiveStatus)),
                value: status,
            })),
            key: 'lastStatusUpdate.status',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'OBJECTIVE_CATEGORY' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const categories = await searchObjectiveCategories();
                return categories.map(category => ({
                    label: getLabelTranslation(category.name),
                    value: category.id,
                }));
            },
            key: 'category.id',
            rule: 'EQUALS',
            availableRules: [],
            hide: !objectiveCategoriesEnabled,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'assignee.id',
            rule: 'EQUALS',
            availableRules: [],
            hide: isFromProfile,
        },
    ];

    return { filters };
};
