import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';
import { forwardRef } from 'react';
import { useUserLanguage } from '@/utils/language.util';
import { deleteWhitespace } from '@/utils/strings.util';

export type InputPhoneNumberProps = Omit<MuiTelInputProps, 'forceCallingCode'>;

export const InputPhoneNumber = forwardRef<HTMLInputElement, InputPhoneNumberProps>((props, ref) => {
    const userLanguage = useUserLanguage();
    return (
        <MuiTelInput
            ref={ref}
            // We don't want to force the calling code to force the user to select a country code himself
            // when the phone number doesn't start with a country code
            defaultCountry={'CH'}
            focusOnSelectCountry={true}
            langOfCountryName={userLanguage}
            {...props}
            // TODO: switch off disableFormatting when the library fixed the issue https://rogerhr.atlassian.net/browse/RP-6243
            disableFormatting={true}
            forceCallingCode={true}
            onChange={(phone, info) => {
                if (props.onChange) {
                    // TODO: remove calling deleteWhiteSpace and return info.number value
                    // when the library fixed the issue https://rogerhr.atlassian.net/browse/RP-6243
                    props.onChange(deleteWhitespace(phone), info);
                }
            }}
        />
    );
});
