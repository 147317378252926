import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { InputPhoneNumber, InputPhoneNumberProps } from '@/components/input-phone-number/InputPhoneNumber';

type InputPhoneNumberRootProps = Pick<InputPhoneNumberProps, 'variant' | 'autoFocus' | 'fullWidth' | 'placeholder' | 'disabled' | 'slotProps'>;

export type FieldPhoneNumberProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    inputPhoneNumberProps?: RemoveFromType<RemoveFromType<InputPhoneNumberProps, InputPhoneNumberRootProps>, ControllerRenderProps>;
} & InputPhoneNumberRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldPhoneNumber = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldPhoneNumberProps<TFieldValues, TName>,
): JSX.Element => {
    const { inputPhoneNumberProps, variant, autoFocus, fullWidth, placeholder, disabled, slotProps, ...controllerProps } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <InputPhoneNumber
                    variant={variant}
                    autoFocus={autoFocus}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    disabled={disabled}
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                    slotProps={{
                        htmlInput: {
                            'aria-label': controllerProps.name,
                        },
                        ...slotProps,
                    }}
                    {...inputPhoneNumberProps}
                />
            )}
        />
    );
};
