import { API_BASE_URL, client } from '../common';
import { PermissionFilteringConditionRequest, PermissionFilteringConditionType } from '@/domain/permission/Permission.model';

import { AxiosResponse } from 'axios';
import {
    FilteringConditionMutation,
    FilteringConditionValueMutation,
    PermissionGroup,
    PermissionGroupMutation,
    PermissionGroupRequest,
} from '@/domain/permission-group/PermissionGroup.model';

const getPermissionGroups = async (): Promise<PermissionGroup[]> => {
    return (await client.get<PermissionGroup[]>(API_BASE_URL + '/permission-groups')).data;
};

const getPermissionGroupById = async (groupId: number): Promise<PermissionGroup> => {
    return (await client.get<PermissionGroup>(API_BASE_URL + `/permission-groups/${groupId}`)).data;
};

const createPermissionGroupConditions = (conditions: FilteringConditionMutation[]): PermissionFilteringConditionRequest[] => {
    function getValue(conditionType: PermissionFilteringConditionType, fcv: FilteringConditionValueMutation) {
        switch (conditionType) {
            case PermissionFilteringConditionType.LOCATION:
                return fcv.locationId;
            case PermissionFilteringConditionType.LEGAL_UNIT:
                return fcv.legalUnitId;
            case PermissionFilteringConditionType.DEPARTMENT:
                return fcv.departmentId;
            case PermissionFilteringConditionType.JOB:
                return fcv.jobId;
            case PermissionFilteringConditionType.EMPLOYMENT_STATUS:
                return fcv.employmentStatus as string;
            case PermissionFilteringConditionType.CONTRACT_TYPE:
                return fcv.contractType as string;
        }
    }

    return (
        conditions
            // Here conditionType should not be undefined
            ?.filter(
                (
                    c,
                ): c is FilteringConditionMutation & {
                    conditionType: PermissionFilteringConditionType;
                } => !!c.conditionType,
            )
            .map(condition => {
                const values = condition.filterConditionValues.map(fcv => getValue(condition.conditionType, fcv));

                const hasNumberValue = values.every(element => typeof element === 'number');
                //need to check if the array is made of numbers or strings (if is made of numbers we need to send the conditionValueIds, if is made of strings we need to send the conditionValueNames(enums))
                return {
                    conditionType: condition.conditionType,
                    [hasNumberValue ? 'conditionValueIds' : 'conditionValueNames']: values,
                    conditionRule: condition.conditionRule,
                };
            })
    );
};

const createPermissionGroup = async (permissionGroupMutation: PermissionGroupMutation): Promise<PermissionGroup> => {
    const request: PermissionGroupRequest = {
        groupName: permissionGroupMutation.groupName,
        description: permissionGroupMutation.description,
        filterType: permissionGroupMutation.filterType,
        groupMemberIds: permissionGroupMutation.groupMembers.map(m => m.id),
        groupPolicies: permissionGroupMutation.groupPolicies,
        targetMembersFilterType: permissionGroupMutation.targetMembersFilterType,
        targetMemberIds: permissionGroupMutation.targetMembers.map(m => m.id),
        notificationsEnabled: permissionGroupMutation.notificationsEnabled,
        groupMemberConditions: createPermissionGroupConditions(permissionGroupMutation.groupMemberConditions),
        targetMemberConditions: createPermissionGroupConditions(permissionGroupMutation.targetMemberConditions),
    };
    return (await client.post<PermissionGroup, AxiosResponse<PermissionGroup>, PermissionGroupRequest>(API_BASE_URL + '/permission-groups', request)).data;
};

const updatePermissionGroup = async (permissionGroupMutation: PermissionGroupMutation, groupId: number): Promise<PermissionGroup> => {
    const request: PermissionGroupRequest = {
        groupName: permissionGroupMutation.groupName,
        description: permissionGroupMutation.description,
        filterType: permissionGroupMutation.filterType,
        groupMemberIds: permissionGroupMutation.groupMembers?.map(m => m.id),
        groupPolicies: permissionGroupMutation.groupPolicies,
        targetMembersFilterType: permissionGroupMutation.targetMembersFilterType,
        targetMemberIds: permissionGroupMutation.targetMembers?.map(m => m.id),
        notificationsEnabled: permissionGroupMutation.notificationsEnabled,
        groupMemberConditions: createPermissionGroupConditions(permissionGroupMutation.groupMemberConditions),
        targetMemberConditions: createPermissionGroupConditions(permissionGroupMutation.targetMemberConditions),
    };
    return client.put(API_BASE_URL + `/permission-groups/${groupId}`, request);
};

const deletePermissionGroup = async (groupId: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/permission-groups/${groupId}`);
};

const getGroupsByEmployeeId = async (employeeId: number): Promise<PermissionGroup[]> => {
    return (await client.get(API_BASE_URL + `/permission-groups/employee/${employeeId}`)).data;
};

export const permissionGroupApi = {
    getPermissionGroups,
    getPermissionGroupById,
    createPermissionGroupConditions,
    createPermissionGroup,
    updatePermissionGroup,
    deletePermissionGroup,
    getGroupsByEmployeeId,
};
