import { RealmFeature } from '@/domain/realm/Realm.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { Page } from '@/page/Pages';
import {
    canApproveRejectOtherEmployeeTimesheets,
    canManageOtherEmployeeTimesheets,
    canViewOtherTimesheetInsightBalance,
} from '@/domain/permission/Permission.service';

export const getTimesheetPages = (employeeId: number, realmFeatures: RealmFeature[], policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'timesheets.tabs.monthly_summary',
            path: '/timesheets/monthly-summary',
            condition: (_, policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.pending_timesheets',
            path: '/timesheets/pending',
            condition: (_, policies: EmployeePolicy[]) => canApproveRejectOtherEmployeeTimesheets(policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.all',
            path: '/timesheets/all',
            condition: (_, policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.balances',
            path: '/timesheets/balances',
            condition: (_, policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.insights',
            path: '/timesheets/insights',
            condition: (_, policies: EmployeePolicy[]) => canViewOtherTimesheetInsightBalance(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.statutory_overtime',
            path: '/timesheets/weekly-insights',
            condition: (_, policies: EmployeePolicy[]) => canViewOtherTimesheetInsightBalance(realmFeatures, policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(realmFeatures, policies));
};
