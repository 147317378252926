import { getAppConfig } from '@/config/config';

import { Box, CircularProgress, Paper, Stack, Typography, useTheme } from '@mui/material';

import {
    CellStyleModule,
    ClientSideRowModelModule,
    ColDef,
    ColumnAutoSizeModule,
    EventApiModule,
    LocaleModule,
    ModuleRegistry,
    provideGlobalGridOptions,
    RowAutoHeightModule,
    RowSelectionModule,
    RowStyleModule,
    StatusPanelDef,
    TooltipModule,
    ColumnApiModule,
    QuickFilterModule,
} from 'ag-grid-community';
import { ExcelExportModule, LicenseManager, PinnedRowModule, StatusBarModule } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import { CSSProperties, LegacyRef, ReactElement, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useColumnTypes } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';
import './styles.css';

// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: 'legacy' });

LicenseManager.setLicenseKey(getAppConfig().AG_GRID.LICENCE_KEY);
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    StatusBarModule,
    ExcelExportModule,
    RowSelectionModule,
    LocaleModule,
    RowStyleModule,
    EventApiModule,
    ColumnAutoSizeModule,
    RowAutoHeightModule,
    CellStyleModule,
    TooltipModule,
    PinnedRowModule,
    ColumnApiModule,
    QuickFilterModule,
    // Add this module to add console logs for ag-grid https://www.ag-grid.com/javascript-data-grid/modules/#validation
    // ValidationModule,
]);

export type AgGridWrapperProps<TData> = AgGridReactProps<TData> & {
    compact?: boolean;
    gridId?: string;
    initRef?: (node: AgGridReact<TData>) => void;
    disableAutoSize?: boolean;
    toolbarActions?: ReactElement;
    loading: boolean;
};

export function AgGridWrapper<TData>({
    initRef,
    gridId,
    defaultColDef,
    compact = true,
    columnDefs,
    rowData,
    onRowClicked,
    excelStyles,
    disableAutoSize = false,
    defaultExcelExportParams,
    toolbarActions,
    selectionColumnDef,
    loading,
    ...rest
}: AgGridWrapperProps<TData>): ReactElement {
    const { t } = useTranslation();
    const gridRef = useRef<AgGridReact<TData>>();

    const theme = useTheme();

    const defaultVars = {
        '--ag-font-family': 'Inter',
        '--ag-header-foreground-color': theme.palette.text.primary,
        '--ag-header-background-color': theme.palette.common.white,
        '--ag-header-cell-hover-background-color': theme.palette.common.white,
        '--ag-header-column-separator-color': 'transparent',
        '--ag-header-column-separator-height': '50%',
        '--ag-header-column-separator-display': 'block',
        '--ag-header-column-resize-handle-width': '10px',
        '--ag-header-column-resize-handle-color': 'transparent',
        '--ag-header-column-resize-handle-display': 'block',
        '--ag-border-radius': '8px',
        '--ag-border-color': theme.palette.grey[300],
        '--ag-row-border-style': 'solid',
        '--ag-row-border-width': '1px',
        '--ag-cell-horizontal-padding': '8px',

        '--ag-checkbox-background-color': theme.palette.common.white,
        '--ag-checkbox-checked-color': theme.palette.primary.main,
        '--ag-checkbox-unchecked-color': theme.palette.grey[700],
        '--ag-checkbox-indeterminate-color': theme.palette.grey[700],
        '--ag-row-hover-color': theme.palette.grey[100],

        ...((!compact
            ? {
                  '--ag-borders-critical': 'none',
              }
            : ({
                  '--ag-cell-horizontal-border': '1px solid ' + theme.palette.grey[300],
              } as CSSProperties)) as CSSProperties),
    } as CSSProperties;

    // Use to compact the grid
    useEffect(() => {
        if (compact) {
            const el = document.querySelector<HTMLElement>('.ag-theme-material');
            el?.classList.toggle('compact');
        }
    }, [compact]);

    const getColumnDefs = (): ColDef[] => {
        return (
            (columnDefs as ColDef[]).map(c =>
                // Since ag grid v33, valueFormatter is not used for quick filter text, we need to set getQuickFilterText to valueFormatter to keep the same behavior
                // If you want to remove this hack, please test the quick filter behavior on column with valueFormatter and valueGetter not returning a string
                c.valueFormatter ? ({ ...c, getQuickFilterText: c.valueFormatter } as ColDef) : c,
            ) || []
        );
    };

    // Display a footer with the total number of rows
    const statusBar = useMemo<{
        statusPanels: StatusPanelDef[];
    }>(() => {
        return {
            statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }],
        };
    }, []);

    // Ag grid wrapper is rerendered when the row is selected due to internal state change in hook useAgGridWrapper
    const hasSelectedRows = gridRef.current?.api?.getSelectedRows()?.length;

    const columnTypes = useColumnTypes(compact);

    return (
        <Stack direction='row' height='100%' position='relative'>
            <Box
                style={{
                    overflow: 'hidden',
                    flexGrow: '1',
                }}
            >
                {/* This box is needed to make the grid full height when the parent is flex
                https://github.com/ag-grid/ag-grid/issues/628#issuecomment-182278186
            */}
                <Box height='100%' position={'relative'}>
                    <Box position={'absolute'} sx={{ inset: 0 }} className='ag-theme-material' style={defaultVars}>
                        <AgGridReact<TData>
                            gridId={gridId}
                            // TODO Fix this type
                            ref={gridRef as LegacyRef<AgGridReact<TData>>}
                            suppressRowTransform
                            getLocaleText={params => t(params.key, { ns: 'ag_grid' })}
                            suppressCellFocus
                            suppressCsvExport
                            // Hide the column when dragging it out of the grid
                            suppressDragLeaveHidesColumns
                            suppressContextMenu
                            // This is hack to fix autosize all issue https://www.ag-grid.com/javascript-data-grid/column-sizing/#auto-size-columns
                            suppressColumnVirtualisation
                            enableBrowserTooltips
                            enableCellTextSelection
                            paginationAutoPageSize={false}
                            pagination={false}
                            statusBar={statusBar}
                            animateRows
                            cacheQuickFilter
                            defaultColDef={{
                                rowDrag: false,
                                resizable: true,
                                sortable: true,
                                useValueFormatterForExport: true,
                                menuTabs: [],
                                autoHeight: true,
                                ...defaultColDef,
                            }}
                            columnDefs={getColumnDefs()}
                            rowData={rowData}
                            // To avoid frustration, we disable the row click/navigation when there are selected rows
                            onRowClicked={hasSelectedRows ? undefined : onRowClicked}
                            rowStyle={!hasSelectedRows && onRowClicked ? { cursor: 'pointer' } : {}}
                            selectionColumnDef={{
                                resizable: false,
                                sortable: false,
                                pinned: 'left',
                                lockPinned: true,
                                suppressSizeToFit: true,
                                suppressAutoSize: true,
                                maxWidth: 34,

                                ...selectionColumnDef,
                            }}
                            columnTypes={columnTypes as Record<string, ColDef<TData>>}
                            accentedSort={true}
                            excelStyles={[
                                {
                                    id: 'dateCH',
                                    dataType: 'DateTime',
                                    numberFormat: {
                                        format: getAppConfig().DEFAULT_DATE_FORMAT,
                                    },
                                },
                                ...(excelStyles ?? []),
                            ]}
                            onFirstDataRendered={params => {
                                if (disableAutoSize) {
                                    return;
                                }
                                // In Roger by default we want to auto size all columns
                                // Info 1 : New visible columns are not resized event if we call autoSizeAllColumns on onColumnVisible event
                                params.api.autoSizeAllColumns();
                            }}
                            onGridSizeChanged={params => {
                                if (disableAutoSize) {
                                    return;
                                }
                                params.api.autoSizeAllColumns();
                            }}
                            onGridReady={() => {
                                if (gridRef?.current) {
                                    initRef?.(gridRef?.current);
                                }
                            }}
                            defaultExcelExportParams={{
                                author: 'Roger HR',
                                ...defaultExcelExportParams,
                            }}
                            loading={loading}
                            loadingOverlayComponent={LoadingOverlayComponent}
                            {...rest}
                        />
                    </Box>
                </Box>
            </Box>
            {!!hasSelectedRows && !!toolbarActions && (
                <Stack
                    id='grid-actions-menu'
                    zIndex={8}
                    direction='row'
                    justifyContent='center'
                    position='absolute'
                    left={'50%'}
                    bottom={32}
                    sx={{ transform: 'translateX(-50%)' }}
                >
                    <Stack component={Paper} p={2} gap={2} direction='row' elevation={1} alignItems='center' bgcolor={theme => theme.palette.grey[100]}>
                        <Typography variant='body1bold' sx={{ fontVariantNumeric: 'tabular-nums' }}>
                            {t('grid.selected_rows', { count: hasSelectedRows })}
                        </Typography>
                        {toolbarActions}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

export type AgGridWrapperColumnType = keyof ReturnType<typeof useColumnTypes>;

// We don't want to spread params directly to CircularProgress, it will cause error in console log
const LoadingOverlayComponent = () => {
    return <CircularProgress />;
};
