import { PageContainer } from '@/routes/PageContainer';
import { Navigate, RouteObject } from 'react-router';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { IndividualObjectivesPage } from '@/page/objective/objectives-page/IndividualObjectivesPage';
import { DepartmentObjectivesPage } from '@/page/objective/objectives-page/DepartmentObjectivesPage';

const config: RoutesConfig[] = [
    {
        path: 'individual',
        breadCrumb: [{ nameKey: 'pages.individual_objectives' }],
        component: <IndividualObjectivesPage />,
    },
    {
        path: 'department',
        breadCrumb: [{ nameKey: 'pages.department_objectives' }],
        component: <DepartmentObjectivesPage />,
    },
];

export const objectivesRoutes: RouteObject[] = [
    {
        path: 'objectives',
        errorElement: <RouterBoundary />,
        children: [
            {
                path: '',
                element: <Navigate to='/objectives/individual' replace />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
