import { employeeAPI } from '@/api/employee/Employee.api';
import { employeeAddressAPI } from '@/api/employee/EmployeeAddress.api';
import { employeePersonalInfoAPI } from '@/api/employee/EmployeePersonalInfo.api';
import { SectionActionButton, SectionField } from '@/components/section/types';
import { EmployeeSectionAction, EmployeeSectionField } from '@/domain/employee-section/EmployeeSection.model';
import {
    Employee,
    EmployeeAnniversary,
    EmployeeAnniversaryRequest,
    EmployeeAuthentication,
    EmployeeBasicInfo,
    EmployeeBasicInfoUpdateMutation,
    EmployeeEmailUpdateMutation,
    EmployeeLanguageUpdateRequest,
    EmployeeLoginMethod,
    EmployeeLoginMethodUpdateMutation,
    EmployeeLoginMutation,
    EmployeeSearch,
    EmployeeSearchLoginMethodRequest,
    EmployeeShiftSettings,
    EmployeeShiftSettingsUpdateMutation,
    EmployeeSyncRequest,
} from '@/domain/employee/Employee.model';
import { LoginMethod } from '@/domain/realm/Realm.model';
import { SectionDefinition, SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { getCountry } from '@/utils/countries.util';
import {
    addYears,
    compareAsc,
    differenceInDays,
    differenceInYears,
    getCurrentLocalDate,
    getTodayDate,
    isBeforeDate,
    isSameDate,
    startOfDay,
} from '@/utils/datetime.util';

import { getFormValueByFieldType } from '@/domain/section-setting/Section.service';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { getLabelTranslation } from '@/utils/language.util';
import { localeCompareString, removeAccents } from '@/utils/strings.util';
import { parse } from 'date-fns';
import { EmployeeAddress, EmployeeAddressCreateMutation, EmployeeAddressUpdateMutation } from './EmployeeAddress.model';
import { EmployeePersonalInfo, EmployeePersonalInfoMutation } from './EmployeePersonalInfo.model';

export const getCurrentEmployee = (): Promise<EmployeeAuthentication> => {
    return employeeAPI.getCurrentEmployee();
};

export const getEmployeeById = (employeeId: number): Promise<Employee> => {
    return employeeAPI.getEmployeeById(employeeId);
};

export const searchEmployees = (
    search: EmployeeSearch & {
        offset?: number;
        limit?: number;
        sort?: string;
        sortDirection?: string;
    } = {},
): Promise<Employee[]> => {
    return employeeAPI.searchEmployees(search);
};

export const syncEmployees = (employeeSyncRequest: EmployeeSyncRequest): Promise<void> => {
    return employeeAPI.syncEmployees(employeeSyncRequest);
};

export const searchEmployeeBirthdays = (searchRequest: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    return employeeAPI.searchEmployeeBirthdays(searchRequest);
};

export const searchEmployeeWorkAnniversaries = (searchRequest: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    return employeeAPI.searchEmployeeWorkAnniversaries(searchRequest);
};

export const deleteEmployee = (employeeId: number, email: string): Promise<void> => {
    return employeeAPI.deleteEmployee(employeeId, email);
};

/**
 * Filters a list of employees using an advanced search based on the input string.
 * The search checks both the employee's display name and employee code,
 * and matches all words in the input string, case-insensitive.
 *
 * Example usage:
 * - Employee: "Sam Agace", Employee Code: "1234"
 *   - Input: "gac"     => Matches "Agace"
 *   - Input: "Agace s" => Matches "Agace Sam"
 *   - Input: "sam a"   => Matches "Sam Agace"
 *   - Input: "1234"    => Matches Employee Code
 *
 * @param employees A list of Employee objects
 * @param inputValue The input string used to filter employees by name and code
 * @returns An array of employees that match the input string by name or code
 */
export const filterEmployeesByAdvancedSearch = (employees: Employee[], inputValue: string): Employee[] => {
    const words = inputValue.toLowerCase().split(' ');
    return employees.filter(employee => {
        return words.every(word => employee.displayName.toLowerCase().includes(word) || employee.employeeCode?.toLowerCase().includes(word));
    });
};

/**
 * Returns the number of days until the next birthday
 * @param birthday in format YYYY-MM-DD
 */
export const getDaysUntilNextBirthday = (birthday: LocalDate): number => {
    const [, month, day] = birthday.split('-').map(Number);
    const now = startOfDay(getTodayDate());
    const currentYear = now.getFullYear();
    const birthdayThisYear = parse(`${currentYear}-${month}-${day}`, 'yyyy-MM-dd', now);

    let nextBirthday = birthdayThisYear;
    if (isBeforeDate(birthdayThisYear, now) && !isSameDate(birthdayThisYear, now)) {
        nextBirthday = addYears(birthdayThisYear, 1);
    }

    // Calculate the number of full day periods between two dates.
    // dates should be at the same time of the day
    return differenceInDays(nextBirthday, now);
};

export const getNewJoiners = (employeeWorkAnniversaries: EmployeeAnniversary[]): EmployeeAnniversary[] => {
    const newJoiners = employeeWorkAnniversaries?.filter(anniversary => differenceInYears(getCurrentLocalDate(), anniversary.anniversaryDate) === 0);
    return [...newJoiners].sort((newJoiner1, newJoiner2) => compareAsc(newJoiner1.anniversaryDate, newJoiner2.anniversaryDate));
};

export const getEmployeePersonalInfo = (employeeId: number): Promise<EmployeePersonalInfo> => {
    return employeePersonalInfoAPI.getEmployeePersonalInfo(employeeId);
};

export const updateEmployeePersonalInfo = (employeeId: number, request: EmployeePersonalInfoMutation): Promise<EmployeePersonalInfo> => {
    return employeePersonalInfoAPI.updateEmployeePersonalInfo(employeeId, request);
};

export const createEmployeePersonalInfoPendingRequest = (employeeId: number, request: EmployeePersonalInfoMutation): Promise<void> => {
    return employeePersonalInfoAPI.createEmployeePersonalInfoPendingRequest(employeeId, request);
};

export const updateEmployeePersonalInfoPendingRequest = (employeeId: number, request: EmployeePersonalInfoMutation): Promise<void> => {
    return employeePersonalInfoAPI.createEmployeePersonalInfoPendingRequest(employeeId, request);
};

export const getEmployeeAddresses = ({ employeeId }: { employeeId: number }): Promise<EmployeeAddress[]> => {
    return employeeAddressAPI.searchEmployeeAddresses({ employeeId: employeeId });
};

export const createEmployeeAddressPendingRequest = (request: EmployeeAddressCreateMutation): Promise<void> => {
    return employeeAddressAPI.createEmployeeAddressPendingRequest(request);
};

export const updateEmployeeAddressPendingRequest = (addressId: number, request: EmployeeAddressUpdateMutation): Promise<void> => {
    return employeeAddressAPI.updateEmployeeAddressPendingRequest(addressId, request);
};

export const deleteEmployeeAddressPendingRequest = (addressId: number): Promise<void> => {
    return employeeAddressAPI.deleteEmployeeAddressPendingRequest(addressId);
};

export const searchCurrentEmployeeLoginMethod = (request: EmployeeSearchLoginMethodRequest): Promise<EmployeeLoginMethod> => {
    return employeeAPI.searchCurrentEmployeeLoginMethod(request);
};

export const getEmployeeLoginMethod = (employeeId: number): Promise<LoginMethod> => {
    return employeeAPI.getEmployeeLoginMethod(employeeId);
};

export const updateLoginMethod = (employeeId: number, mutation: EmployeeLoginMethodUpdateMutation): Promise<Employee> => {
    return employeeAPI.updateLoginMethod(employeeId, mutation);
};

export const updateEmployeeLogin = (request: EmployeeLoginMutation): Promise<void> => {
    return employeeAPI.updateEmployeeLogin(request);
};

export const updateEmployeeBasicInfo = (employeeId: number, mutation: EmployeeBasicInfoUpdateMutation): Promise<EmployeeBasicInfo> => {
    return employeeAPI.updateEmployeeBasicInfo(employeeId, mutation);
};

export const updateEmail = (employeeId: number, mutation: EmployeeEmailUpdateMutation): Promise<void> => {
    return employeeAPI.updateEmail(employeeId, mutation);
};

export const deactivateEmployee = (employeeId: number): Promise<Employee> => {
    return employeeAPI.deactivateEmployee(employeeId);
};

export const activateEmployee = (employeeId: number): Promise<Employee> => {
    return employeeAPI.activateEmployee(employeeId);
};

export const getEmployeeAvatar = (employeeId: number): Promise<string> => {
    return employeeAPI.getEmployeeAvatar(employeeId);
};

export const uploadAvatar = (employeeId: number, file: File): Promise<Employee> => {
    return employeeAPI.uploadAvatar(employeeId, file);
};

export const updateEmployeeShiftSettings = (userId: number, mutation: EmployeeShiftSettingsUpdateMutation): Promise<EmployeeShiftSettings> => {
    return employeeAPI.updateEmployeeShiftSettings(userId, mutation);
};

export const updateEmployeeLanguage = (mutation: EmployeeLanguageUpdateRequest): Promise<void> => {
    return employeeAPI.updateEmployeeLanguage(mutation);
};

export const filterEmployeesByIds = (ids: number[], employees: Employee[]): Employee[] => {
    return employees.filter(employee => ids.includes(employee.id));
};

export const buildEmployeeFilterPredicate =
    (
        name: string,
        {
            checkEmployeeCode,
            checkFirstName,
            checkLastName,
        }: {
            checkEmployeeCode: boolean;
            checkFirstName: boolean;
            checkLastName: boolean;
        } = {
            checkFirstName: true,
            checkLastName: true,
            checkEmployeeCode: true,
        },
    ) =>
    ({ firstName, lastName, employeeCode }: Pick<Employee, 'firstName' | 'lastName' | 'employeeCode'>): boolean => {
        return (
            (!checkFirstName || removeAccents(name.toLowerCase()).includes(removeAccents(firstName.toLowerCase()))) &&
            (!checkLastName || removeAccents(name.toLowerCase()).includes(removeAccents(lastName.toLowerCase()))) &&
            (!checkEmployeeCode || (!!employeeCode && name.toLowerCase().includes(employeeCode?.toLowerCase())))
        );
    };
export const getSectionActionButton = (
    action: EmployeeSectionAction,
    mutationButton: SectionActionButton,
    navigationButton: SectionActionButton,
): SectionActionButton | undefined => {
    switch (action) {
        case 'EDIT':
        case 'CREATE_PENDING':
        case 'EDIT_PENDING':
            return mutationButton;
        case 'NAVIGATE_ON_REQUEST_PAGE':
            return navigationButton;
        default:
            return undefined;
    }
};
/**
 * Merge the employee section fields with the section definition fields to create fields use by the form
 * @param employeeSectionId
 * @param sectionFieldDefinitions
 * @param employeeSectionFields
 * @returns fields use by the form
 */
export const convertEmployeeSectionFieldsToSectionFields = (
    employeeSectionId: number,
    sectionFieldDefinitions: SectionFieldDefinition[],
    employeeSectionFields: EmployeeSectionField[],
): SectionField[] => {
    // Merge the section definition fields with the employee fields
    return sectionFieldDefinitions?.map(sectionFieldDefinition => {
        const employeeField = getEmployeeField(sectionFieldDefinition.formId, employeeSectionFields);

        const sectionField: SectionField = {
            fieldDefinitionId: sectionFieldDefinition.id,
            employeeSectionId,
            title: getLabelTranslation(sectionFieldDefinition.name),
            valueType: sectionFieldDefinition.valueType,
            required: sectionFieldDefinition.mandatory,
            // we store the field id in the form value name
            formValueName: sectionFieldDefinition.formId,
            customList: sectionFieldDefinition?.customList,
            countryValue: sectionFieldDefinition?.valueType === 'COUNTRY' && !!employeeField?.stringValue ? getCountry(employeeField?.stringValue) : undefined,
            ...employeeField,
            fieldType: 'EMPLOYEE_CUSTOM_FIELD',
        };

        return sectionField;
    });
};

const getEmployeeField = (sectionFieldDefinitionFormId: string, employeeSectionFields: EmployeeSectionField[]) => {
    return employeeSectionFields?.find(field => field.sectionFieldDefinition.formId === sectionFieldDefinitionFormId);
};
export const getUserEmploymentStatusTranslationKey = (): string => {
    return 'employee.employment.employment_status_enum';
};

export const getContractTypeTranslationKey = (): string => {
    return 'employee.employment.contract_type';
};

export const getGenderTranslationKey = (): string => {
    return 'employee.genders_enum';
};

export const getMaritalStatusTranslationKey = (): string => {
    return 'employee.marital_statuses_enum';
};
export const getUserStatusTranslationKey = (): string => {
    return 'domain.user_status_enum';
};

export const sortEmployeesByDisplayName = (employees: Employee[]): Employee[] => {
    return employees.sort((a, b) => localeCompareString(a.displayName, b.displayName));
};

const FILTERING_FIELD_TYPES = ['LOCATION_IDS', 'DEPARTMENT_IDS', 'JOB_IDS', 'MANAGER_IDS'] as const;
export type EmployeeFilterType = (typeof FILTERING_FIELD_TYPES)[number];
export const isValidEmployeeFilterType = (filterName: string): filterName is EmployeeFilterType => {
    return FILTERING_FIELD_TYPES.includes(filterName as EmployeeFilterType);
};

export const doesEmployeeMatchFilter = (employee: Employee, filteringIds: number[], filteringFieldType: EmployeeFilterType): boolean => {
    const getRelevantIds = () => {
        switch (filteringFieldType) {
            case 'LOCATION_IDS':
                return employee.currentEmployments.flatMap(employment => employment.location?.id);
            case 'DEPARTMENT_IDS':
                return employee.currentEmployments.flatMap(employment => employment.department?.id);
            case 'JOB_IDS':
                return employee.currentEmployments.flatMap(employment => employment.job?.id);
            case 'MANAGER_IDS':
                return employee.currentEmployments?.flatMap(employment => employment.managers.map(manager => manager.id));
        }
    };

    return filteringIds.some(filteringId => getRelevantIds()?.some(id => id === filteringId));
};

export const mapFieldFormValuesToAddressMutation = (
    formValues: SectionDefinitionFormValues,
    sectionDefinition: SectionDefinition,
    employeeId: number,
): EmployeeAddressCreateMutation => {
    return {
        employeeId,
        startDate: getFormValueByFieldType(sectionDefinition, 'ADDRESS_START_DATE', formValues),
        addressLine1: getFormValueByFieldType(sectionDefinition, 'ADDRESS_ADDRESS_LINE_1', formValues),
        addressLine2: getFormValueByFieldType(sectionDefinition, 'ADDRESS_ADDRESS_LINE_2', formValues),
        city: getFormValueByFieldType(sectionDefinition, 'ADDRESS_CITY', formValues),
        postCode: getFormValueByFieldType(sectionDefinition, 'ADDRESS_POST_CODE', formValues),
        region: getFormValueByFieldType(sectionDefinition, 'ADDRESS_REGION', formValues),
        country: getFormValueByFieldType(sectionDefinition, 'ADDRESS_COUNTRY', formValues).value,
    };
};

export const mapFieldFormValuesToBasicInfoMutation = (
    formValues: SectionDefinitionFormValues,
    sectionDefinition: SectionDefinition,
): EmployeeBasicInfoUpdateMutation => {
    return {
        firstName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_FIRSTNAME', formValues),
        lastName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_LASTNAME', formValues),
        maidenName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_MAIDEN_NAME', formValues),
        displayName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_DISPLAY_NAME', formValues),
        phoneNumber: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PHONE_NUMBER', formValues),
        employeeCode: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_CODE', formValues),
    };
};

export const mapFieldFormValuesToPersonalInfoMutation = (
    formValues: SectionDefinitionFormValues,
    sectionDefinition: SectionDefinition,
): EmployeePersonalInfoMutation => {
    return {
        birthdate: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_BIRTH_DATE', formValues) ?? undefined,
        nationality: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_NATIONALITY', formValues)?.value,
        gender: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_GENDER', formValues) ?? undefined,
        maritalStatus: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_MARITAL_STATUS', formValues) ?? undefined,
        maritalStatusSince: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_MARITAL_STATUS_SINCE', formValues) ?? undefined,
        avsNumber: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_AVS', formValues)
            ? (getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_AVS', formValues) as string)
            : undefined,
        personalEmail: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PERSONAL_EMAIL', formValues)
            ? (getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PERSONAL_EMAIL', formValues) as string)
            : undefined,
        personalPhoneNumber: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PERSONAL_PHONE_NUMBER', formValues)
            ? getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PERSONAL_PHONE_NUMBER', formValues)
            : undefined,
    };
};
