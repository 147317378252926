import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { EmployeeLeaveRequestList } from '@/page/leave/EmployeeLeaveRequestList';
import { compareAsc, compareDesc, isFutureDate, isTodayDate } from '@/utils/datetime.util';
import { Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { LeavesEmptyStateIcon } from '@/components/empty-state/icons/LeavesEmptyStateIcon';
import { ArrowDown02Icon, ArrowUp02Icon } from 'hugeicons-react';

type UpcomingLeavesProps = {
    employeeId: number;
    leaveRequests: LeaveRequest[];
    onChange: () => void;
};

export const EmployeeLeaves: FC<UpcomingLeavesProps> = ({ employeeId, leaveRequests, onChange }) => {
    const { t } = useTranslation();
    const [showPastLeaves, setShowPastLeaves] = useState(false);

    const upcomingPredicate = (leaveRequest: LeaveRequest) => {
        return !leaveRequest.endDate || isFutureDate(leaveRequest.endDate) || isTodayDate(leaveRequest.endDate);
    };
    const upcomingLeaves = leaveRequests.filter(upcomingPredicate);
    const pastLeaves = leaveRequests.filter(leaveRequest => !upcomingPredicate(leaveRequest));

    const hasValidLeaves = (leaves: { requestStatus: string }[]): boolean => {
        return leaves.some(leave => !['CANCELLED', 'DECLINED'].includes(leave.requestStatus));
    };

    const emptyUpcomingLeavesState = (
        <Stack component={Paper} flex={1} p={2}>
            <EmptyState icon={<LeavesEmptyStateIcon />} flex={1} title={t('my_leaves.no_leaves')} />
        </Stack>
    );

    const handleShowPastLeaves = () => {
        setShowPastLeaves(show => !show);
    };

    return (
        <Stack direction='column' gap={2} flex={1}>
            <Stack gap={2} flex={!hasValidLeaves(upcomingLeaves) ? 1 : undefined}>
                <Typography variant='h2'>{t('my_leaves.upcoming_leaves')}</Typography>
                {hasValidLeaves(upcomingLeaves) ? (
                    <EmployeeLeaveRequestList leaveRequests={upcomingLeaves} employeeId={employeeId} compareDatesByAge={compareAsc} onChange={onChange} />
                ) : (
                    emptyUpcomingLeavesState
                )}
            </Stack>
            {hasValidLeaves(pastLeaves) && (
                <Stack gap={2}>
                    <Divider sx={{ width: '100%' }}>
                        <IconButton aria-label='toggle archived objective visibility' onClick={handleShowPastLeaves} edge='end' size='small'>
                            <Typography variant='body1'>{showPastLeaves ? t('my_leaves.hide_past_leaves') : t('my_leaves.show_past_leaves')}</Typography>
                            {showPastLeaves ? <ArrowUp02Icon /> : <ArrowDown02Icon />}
                        </IconButton>
                    </Divider>
                    {showPastLeaves && (
                        <Stack gap={2} flex={1} p={1}>
                            <Typography variant='h2'>{t('my_leaves.past_leaves')}</Typography>
                            <EmployeeLeaveRequestList leaveRequests={pastLeaves} employeeId={employeeId} compareDatesByAge={compareDesc} onChange={onChange} />
                        </Stack>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
