import { DateRangeForm } from '@/components/date-range-picker/DateRangeForm';
import { calculateUpdatedDateRange, getDateRange, WEEK_DURATION } from '@/components/date-range-picker/DateRangePicker.util';
import { getAppConfig } from '@/config/config';
import { addDaysAndFormat, differenceInDays, formatDate, formatToLocalDate, getWeek, isValidDate } from '@/utils/datetime.util';

import { getLocale, UserLanguage, useUserLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import {
    CalendarNav,
    CalendarNext,
    CalendarPrev,
    Datepicker,
    localeDe,
    localeEn,
    localeEs,
    localeFr,
    localeIt,
    localePtPT,
    MbscDatepickerOptions,
    MbscLocale,
} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import { Button, ButtonGroupProps, List, ListItem, ListItemButton, ListItemText, Paper, Popover, Stack, Typography, useTheme } from '@mui/material';
import ButtonGroup, { buttonGroupClasses } from '@mui/material/ButtonGroup';
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './date-range-picker.css';

const config = getAppConfig();

// this is a custom type because the mobiscroll datepicker does not have a type for the onChange event
type DatePickerChangeEvent = {
    value: Date | Date[];
};

export type DateRangePickerViewType = 'YEAR' | 'MONTH' | 'FOUR_WEEKS' | 'TWO_WEEKS' | 'WEEK' | 'DAY' | 'RANGE';

export type DateRangePickerProps = ButtonGroupProps & {
    dates: [LocalDate, LocalDate];
    onDatesChanged: (dates: [LocalDate, LocalDate], viewType: DateRangePickerViewType) => void;
    defaultViewType?: DateRangePickerViewType;
    availableViews?: [DateRangePickerViewType, ...DateRangePickerViewType[]]; // means at least one view type is required when provided
    isPopoverDisabled?: boolean;
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
    dates,
    onDatesChanged,
    defaultViewType,
    isPopoverDisabled = false,
    availableViews = ['YEAR', 'MONTH', 'FOUR_WEEKS', 'TWO_WEEKS', 'WEEK', 'DAY', 'RANGE'],
    ...restRootProps
}) => {
    const defaultViewTypeSelected = defaultViewType ?? availableViews[0];
    const [labelDisplayMode, setLabelDisplayMode] = useState<DateRangePickerViewType>(defaultViewTypeSelected);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();
    const selectedLanguage: UserLanguage = useUserLanguage();
    const { palette } = useTheme();

    const buttonGroupRef = useRef<HTMLDivElement>(getNull());

    //We need this used effect for the case where the default view type is changed after the component is mounted by the parent component.
    useEffect(() => {
        setLabelDisplayMode(defaultViewTypeSelected);
    }, [defaultViewTypeSelected]);

    const handleMoveClick = (selectedDate: LocalDate, direction: 'prev' | 'next') => {
        if (labelDisplayMode === 'RANGE') {
            // Move the range from the distance of the first date to the second date
            const [start, end] = dates;
            const difference = differenceInDays(end, start) + 1;
            const distance = direction === 'prev' ? -difference : difference;
            const newStart = addDaysAndFormat(start, distance);
            const newEnd = addDaysAndFormat(end, distance);
            onDatesChanged([newStart, newEnd], labelDisplayMode);
        } else {
            const newRange = calculateUpdatedDateRange(selectedDate, direction, labelDisplayMode);
            onDatesChanged(newRange, labelDisplayMode);
        }
    };

    const handleDatesChanged = (dates: [LocalDate, LocalDate], viewType: DateRangePickerViewType) => {
        setLabelDisplayMode(viewType);
        onDatesChanged(dates, viewType);
    };

    return (
        <>
            <ButtonGroup
                variant={'text'}
                color={'inherit'}
                size='small'
                {...restRootProps}
                sx={{
                    backgroundColor: palette.grey[100],
                    [`& .${buttonGroupClasses.grouped}, .${buttonGroupClasses.grouped}.${buttonGroupClasses.disabled}`]: {
                        border: 'none',
                    },
                    ...restRootProps.sx,
                }}
                ref={buttonGroupRef}
            >
                <Button onClick={() => handleMoveClick(dates[0], 'prev')} sx={{ flex: 0 }} aria-label='previous-date-range'>
                    <ArrowLeft01Icon size={23} />
                </Button>
                <Button onClick={() => setAnchorEl(buttonGroupRef.current)} disabled={isPopoverDisabled} sx={{ flex: 1 }} aria-label='select-date-range'>
                    <Typography noWrap>{getDateRangeLabel(labelDisplayMode, dates, selectedLanguage)}</Typography>
                </Button>
                <Button onClick={() => handleMoveClick(dates[0], 'next')} sx={{ flex: 0 }} aria-label='next-date-range'>
                    <ArrowRight01Icon size={23} />
                </Button>
            </ButtonGroup>

            <Popover
                open={isPopoverDisabled ? false : !!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(getNull())}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ mt: 0.5 }}
            >
                <DateRangePickerByView dates={dates} onDatesChanged={handleDatesChanged} availableViews={availableViews} />
            </Popover>
        </>
    );
};

type DatePickerProps = {
    dates: [LocalDate, LocalDate];
    onDatesChanged: (dates: [LocalDate, LocalDate], viewType: DateRangePickerViewType) => void;
    availableViews: [DateRangePickerViewType, ...DateRangePickerViewType[]];
};
const DateRangePickerByView: FC<DatePickerProps> = props => {
    const { dates, onDatesChanged, availableViews } = props;

    const { t } = useTranslation();
    const selectedLanguage: UserLanguage = useUserLanguage();

    // state for the navigation between the different views
    // If there is only one view available, we don't need to display the list of available views
    const multipleViewsAvailable = availableViews.length > 1;
    const [currentViewType, setCurrentViewType] = useState<DateRangePickerViewType | undefined>(multipleViewsAvailable ? undefined : availableViews[0]);
    //when there is only one view available, we directly set the date picker config
    const initialDatePickerConfig = currentViewType ? getCalendarOptions(currentViewType, selectedLanguage) : {};
    const [datePickerConfig, setDatePickerConfig] = useState<MbscDatepickerOptions>(initialDatePickerConfig);

    const onDatePickerChange = (e: DatePickerChangeEvent, viewType: DateRangePickerViewType) => {
        if (e?.value) {
            const dateValue = e.value instanceof Date ? e.value : e.value[0];
            // Automatically set the end date depends on the view type
            const [start, end] = getDateRange(viewType, formatToLocalDate(dateValue));
            onDatesChanged([start, end], viewType);
        }
    };

    const getDates = (dates: LocalDate[], viewType: DateRangePickerViewType) => {
        const startDateFormatted = isValidDate(dates[0]) && dates[0];
        const endDateFormatted = isValidDate(dates[1]) && dates[1];
        if (viewType === 'MONTH' || viewType === 'DAY') {
            return startDateFormatted;
        }
        return [startDateFormatted, endDateFormatted];
    };

    const handleViewTypeChange = (viewType: DateRangePickerViewType | undefined) => {
        // always update the current view type for the navigation
        setCurrentViewType(viewType);

        // if the view type is undefined, we just want to go back to the list of available views
        if (viewType) {
            setDatePickerConfig(getCalendarOptions(viewType, selectedLanguage));
        }
    };

    return (
        <Paper variant={'outlined'} sx={{ width: 320 }}>
            {/*Available views*/}
            {!currentViewType && (
                <List dense>
                    {availableViews.map(viewType => (
                        <ListItem key={viewType} disableGutters disablePadding aria-label={t('date_range_picker.view_type', { context: viewType })}>
                            <ListItemButton onClick={() => handleViewTypeChange(viewType)} disableRipple>
                                <ListItemText primary={t('date_range_picker.view_type', { context: viewType })} />
                                <ArrowRight01Icon size={20} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}

            {/*Display a datepicker or a form for the RANGE mode*/}
            {currentViewType && (
                <Stack gap={1} py={1}>
                    {/*Go back to available view list*/}
                    {multipleViewsAvailable && (
                        <Button
                            variant='text'
                            color='inherit'
                            fullWidth
                            onClick={() => handleViewTypeChange(undefined)}
                            startIcon={<ArrowLeft01Icon />}
                            sx={{ justifyContent: 'flex-start' }}
                        >
                            {t('date_range_picker.view_type', { context: currentViewType })}
                        </Button>
                    )}

                    {currentViewType !== 'RANGE' && (
                        <Datepicker
                            {...datePickerConfig}
                            format={config.DEFAULT_DATE_PICKER_FORMAT}
                            cancelLabel={t('general.cancel')}
                            value={getDates(dates, currentViewType)}
                            onChange={(e: DatePickerChangeEvent) => onDatePickerChange(e, currentViewType)}
                            dataTimezone='UTC'
                        />
                    )}
                    {currentViewType === 'RANGE' && (
                        <DateRangeForm dates={[dates[0], dates[1]]} onDatesChanged={dates => onDatesChanged(dates, currentViewType)} p={0.5} />
                    )}
                </Stack>
            )}
        </Paper>
    );
};

const calendarHeader = () => {
    return (
        <>
            <CalendarPrev className='custom-prev' />
            <CalendarNav className='custom-nav' />
            <CalendarNext className='custom-next' />
        </>
    );
};

const getCalendarOptions = (viewType: DateRangePickerViewType, selectedLanguage: UserLanguage) => {
    const defaultPickerProps: Partial<MbscDatepickerOptions> = {
        controls: ['calendar'],
        themeVariant: 'light',
        display: 'inline',
        renderCalendarHeader: calendarHeader,
        locale: getMobiscrollLocale(selectedLanguage),
    };

    let datePickerOptions: MbscDatepickerOptions = {};

    switch (viewType) {
        case 'YEAR':
            datePickerOptions = {
                ...defaultPickerProps,
                dateFormat: 'YYYY',
            };
            break;
        case 'MONTH':
            datePickerOptions = {
                ...defaultPickerProps,
                dateFormat: 'MMMM YYYY',
            };
            break;
        case 'FOUR_WEEKS':
            datePickerOptions = {
                ...defaultPickerProps,
                firstDay: 1,
                select: 'preset-range',
                selectSize: WEEK_DURATION * 4,
                rangeHighlight: true,
            };
            break;
        case 'TWO_WEEKS':
            datePickerOptions = {
                ...defaultPickerProps,
                firstDay: 1,
                select: 'preset-range',
                selectSize: WEEK_DURATION * 2,
                rangeHighlight: true,
            };
            break;
        case 'WEEK':
            datePickerOptions = {
                ...defaultPickerProps,
                firstDay: 1,
                select: 'preset-range',
                selectSize: WEEK_DURATION,
                rangeHighlight: true,
            };
            break;
        case 'DAY':
            datePickerOptions = {
                ...defaultPickerProps,
                firstDay: 1,
                select: 'date',
            };
            break;
        case 'RANGE':
            datePickerOptions = {
                ...defaultPickerProps,
                select: 'range',
                rangeHighlight: true,
                showRangeLabels: false,
            };
            break;
    }
    return datePickerOptions;
};

const getMobiscrollLocale = (userLanguage: UserLanguage): MbscLocale => {
    switch (userLanguage) {
        case UserLanguage.EN:
            return localeEn;
        case UserLanguage.FR:
            return localeFr;
        case UserLanguage.DE:
            return localeDe;
        case UserLanguage.IT:
            return localeIt;
        case UserLanguage.ES:
            return localeEs;
        case UserLanguage.PT:
            return localePtPT;
        default:
            return localeFr;
    }
};

const getDateRangeLabel = (viewType: DateRangePickerViewType, dates: LocalDate[], userLanguage: UserLanguage): string => {
    if (userLanguage && dates?.[0] && isValidDate(dates[0])) {
        const locale = getLocale(userLanguage);
        const formattedDate = (date: LocalDate) => formatDate(date, 'dd MMM', { locale: locale });
        const formattedDateLong = (date: LocalDate) => formatDate(date, 'dd MMMM yyyy', { locale: locale });

        switch (viewType) {
            case 'FOUR_WEEKS':
            case 'TWO_WEEKS':
            case 'WEEK':
            case 'RANGE':
                return `${formattedDate(dates[0])} - ${dates[1] ? formattedDate(dates[1]) : ''} | ${getWeek(dates[0])}`;
            case 'DAY':
                return `${formattedDateLong(dates[0])} | ${getWeek(dates[0])}`;
            case 'YEAR':
                return formatDate(dates[0], 'yyyy', { locale: locale });
            case 'MONTH':
            default:
                return formatDate(dates[0], 'MMMM yyyy', { locale: locale });
        }
    }
    return '';
};
