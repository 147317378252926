import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { PrivateQuestionBlock } from '@/page/setting/review/template/FormStepForm';
import { duplicate, maxOrderNumber, remove, reorder, sort } from '@/utils/collections.util';
import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    PostReviewEmployeeFeedbackStepFormType,
    PostReviewManagerFeedbackStepFormType,
    PrivateQuestionBlockForm,
} from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { UserLanguage } from '@/utils/language.util';
import { assignLocalId } from '@/utils/object.util';

type PostReviewManagerFeedbackStepProps = {
    ratingsScale: ReviewRatingScale[] | undefined;
    translationLanguage: UserLanguage;
};

export const PostReviewManagerFeedbackStep: FC<PostReviewManagerFeedbackStepProps> = ({ ratingsScale, translationLanguage }) => {
    const { control, watch } = useFormContext<PostReviewManagerFeedbackStepFormType>();
    const { t } = useTranslation();
    const { fields, replace } = useFieldArray<PostReviewManagerFeedbackStepFormType>({
        control,
        name: 'privateManagerQuestions',
    });
    const privateManagerQuestions = watch('privateManagerQuestions');

    const addQuestion = () => {
        const privateQuestionBlockForm: PrivateQuestionBlockForm = assignLocalId({
            questionName: createDefaultLabel(),
            required: false,
            instructionEnabled: false,
            instruction: createDefaultLabel(),
            reviewScaleEnabled: false,
            reviewRatingScaleId: undefined,
            order: maxOrderNumber(privateManagerQuestions) + 1,
            blockType: 'PRIVATE_MANAGER_QUESTION',
        });

        const newFields = [...privateManagerQuestions, privateQuestionBlockForm];
        const fieldsSorted = sort(newFields);
        const reorderedFields = reorder(fieldsSorted);
        replace(reorderedFields);
    };

    const handleDelete = (index: number) => () => {
        const fieldsWithRemovedIndex = remove(privateManagerQuestions, index);
        const fieldsSorted = sort(fieldsWithRemovedIndex);
        const fieldsReordered = reorder(fieldsSorted);
        replace(fieldsReordered);
    };

    const handleDuplicate = (index: number) => () => {
        const fieldsDuplicated = duplicate(privateManagerQuestions, index);
        const fieldsDuplicatedWithID = fieldsDuplicated.map((field, i) => {
            if (i === index + 1) {
                return assignLocalId({ ...field });
            }
            return field;
        });
        const fieldsSorted = sort(fieldsDuplicatedWithID);
        const fieldsReordered = reorder(fieldsSorted);
        replace(fieldsReordered);
    };

    return (
        <Stack gap={2}>
            <Typography variant={'h1'}>{t('reviews_settings_page.review_template_form.step_private_manager_feedback')}</Typography>
            {fields?.map((item, index) => {
                return (
                    <PrivateQuestionBlock
                        translationLanguage={translationLanguage}
                        commonBlockProps={{
                            onDelete: handleDelete(index),
                            onDuplicate: handleDuplicate(index),
                        }}
                        controllerIndex={index}
                        privateType={'MANAGER'}
                        key={item?.localId}
                        ratingsScale={ratingsScale}
                    />
                );
            })}
            <Button name={'addQuestion'} sx={{ width: 'fit-content' }} onClick={() => addQuestion()}>
                {t('reviews_settings_page.review_template_form.add_question_button_title')}
            </Button>
        </Stack>
    );
};

export const PostReviewEmployeeFeedbackStep: FC<PostReviewManagerFeedbackStepProps> = ({ ratingsScale, translationLanguage }) => {
    const { control, watch } = useFormContext<PostReviewEmployeeFeedbackStepFormType>();
    const { t } = useTranslation();
    const { fields, replace } = useFieldArray<PostReviewEmployeeFeedbackStepFormType>({
        control,
        name: 'privateEmployeeQuestions',
    });

    const privateEmployeeQuestions = watch('privateEmployeeQuestions');

    const addQuestion = () => {
        const privateQuestionBlockForm: PrivateQuestionBlockForm = assignLocalId({
            questionName: createDefaultLabel(),
            required: false,
            reviewScaleEnabled: false,
            instructionEnabled: false,
            instruction: createDefaultLabel(),
            reviewRatingScaleId: undefined,
            order: maxOrderNumber(privateEmployeeQuestions) + 1,
            blockType: 'PRIVATE_EMPLOYEE_QUESTION',
        });

        const newFields = [...privateEmployeeQuestions, privateQuestionBlockForm];
        const fieldsSorted = sort(newFields);
        const reorderedFields = reorder(fieldsSorted);
        replace(reorderedFields);
    };

    const handleDelete = (index: number) => () => {
        const fieldsWithRemovedIndex = remove(privateEmployeeQuestions, index);
        const fieldsSorted = sort(fieldsWithRemovedIndex);
        const fieldsReordered = reorder(fieldsSorted);
        replace(fieldsReordered);
    };

    const handleDuplicate = (index: number) => () => {
        const fieldsDuplicated = duplicate(privateEmployeeQuestions, index);
        const fieldsDuplicatedWithID = fieldsDuplicated.map((field, i) => {
            if (i === index + 1) {
                return assignLocalId({ ...field });
            }
            return field;
        });
        const fieldsSorted = sort(fieldsDuplicatedWithID);
        const fieldsReordered = reorder(fieldsSorted);
        replace(fieldsReordered);
    };

    return (
        <Stack gap={2}>
            <Typography variant={'h1'}>{t('reviews_settings_page.review_template_form.step_private_employee_feedback')}</Typography>
            {fields?.map((item, index) => {
                return (
                    <PrivateQuestionBlock
                        translationLanguage={translationLanguage}
                        commonBlockProps={{
                            onDelete: handleDelete(index),
                            onDuplicate: handleDuplicate(index),
                        }}
                        controllerIndex={index}
                        privateType={'EMPLOYEE'}
                        key={item?.localId}
                        ratingsScale={ratingsScale}
                    />
                );
            })}
            <Button name={'addQuestion'} sx={{ width: 'fit-content' }} onClick={() => addQuestion()}>
                {t('reviews_settings_page.review_template_form.add_question_button_title')}
            </Button>
        </Stack>
    );
};
