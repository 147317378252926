import { Select } from '@/components/form/field-select/Select';
import { Area } from '@/domain/area/Area.model';
import { Employee } from '@/domain/employee/Employee.model';
import {
    ClockInOutCreationMutation,
    EmployeeTimesheetMutation,
    Timesheet,
    TimesheetClockInAreaUpdateMutation,
    TimesheetClockInRule,
    TimesheetCreationMethod,
} from '@/domain/timesheet/Timesheet.model';
import {
    clockInOut,
    deleteLastTimesheetClockInOut,
    getAllowClockInOutsideWorkingHours,
    getLastTimesheetClockInOut,
    updateTimesheet,
    updateTimesheetArea,
} from '@/domain/timesheet/Timesheet.service';
import { useGetAreas } from '@/hooks/area/Area.hook';
import { TimesheetClockOutDialog } from '@/page/timesheet/timesheet-clock-out-dialog/TimesheetClockOutDialog';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { addSeconds, differenceInHours, differenceInMinutes, differenceInSeconds, getTodayDate, isPastDate } from '@/utils/datetime.util';
import { defaultToNull } from '@/utils/object.util';
import { Button, ButtonProps, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Cancel01Icon, PauseIcon, PlayIcon } from 'hugeicons-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ClockInOutProps = {
    currentEmployee: Employee;
} & ButtonProps;

type ObjectValues<T> = T[keyof T];
type ClockInOptions = ObjectValues<'CLOCKIN ' | 'CLOCKOUT' | 'CANCEL'>;

const HOURS_TO_CLOCK_IN = 11;
const MINIMUM_SECONDS_BETWEEN_TIMESHEET_CREATION = 5;
const MINIMUM_SECONDS_BETWEEN_TO_DELETE_LAST_TIMESHEET = 60;
const UPDATE_TIME_ELAPSED_EVERY_MILLISECONDS = 5000;

export const ClockInOut: FC<ClockInOutProps> = ({ currentEmployee, ...rest }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useAppDispatch();

    const [currentHour, setCurrentHour] = useState<string>('');
    const [timesheet, setTimesheet] = useState<Timesheet>();
    const [timesheetToClockOut, setTimesheetToClockOut] = useState<Timesheet>();
    const [clockInRestrictionReason, setClockInRestrictionReason] = useState<TimesheetClockInRule>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isClockInDisabled, setIsClockInDisabled] = useState<boolean>(false);
    const [clockInOption, setClockInOption] = useState<ClockInOptions>('CLOCKIN');
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    const currentEmployeeId = currentEmployee.id;
    const handleClick = async () => {
        setIsWaitingForResponse(true);
        const mutation: ClockInOutCreationMutation = {
            employeeId: currentEmployeeId,
            dateTime: getTodayDate(),
            areaId: undefined,
            comment: undefined,
        };

        if (clockInOption === 'CANCEL') {
            try {
                await deleteLastTimesheetClockInOut(currentEmployeeId);

                dispatch({
                    type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                    refetchEmployee: true,
                });

                await fetchLastTimesheetClockInOut(currentEmployeeId);
                setClockInOption('CLOCKIN');
            } catch (error) {
                handleError(error);
            }
            setIsWaitingForResponse(false);
            return;
        }

        if (clockInOption === 'CLOCKOUT') {
            setTimesheetToClockOut(timesheet);
        } else {
            await handleClockInOutRequest(mutation);
        }
        setIsWaitingForResponse(false);
    };

    const handleClockInOutRequest = async (mutation: ClockInOutCreationMutation, employeeTimesheetMutation?: EmployeeTimesheetMutation) => {
        try {
            const timesheet = await clockInOut(mutation);
            setTimesheet(timesheet);
            //on success we close the dialog (in case it was open)
            setTimesheetToClockOut(undefined);

            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });

            if (employeeTimesheetMutation) {
                await updateTimesheet(employeeTimesheetMutation);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const fetchAllowClockInOutsideWorkingHours = useCallback(async (currentEmployeeId: number) => {
        try {
            const allowClockInOut = await getAllowClockInOutsideWorkingHours(currentEmployeeId);
            setClockInRestrictionReason(allowClockInOut);
            await fetchLastTimesheetClockInOut(currentEmployeeId);
        } catch (error) {
            handleError(error);
            setIsLoading(false);
        }
    }, []);

    const fetchLastTimesheetClockInOut = async (employeeId: number) => {
        try {
            const timesheet = await getLastTimesheetClockInOut(employeeId);
            setTimesheet(timesheet);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const locationIds = currentEmployee?.currentEmployments.map(employment => employment.location.id) ?? [];

    const { data: areas = [], isLoading: isLoadingAreas } = useGetAreas({
        // Search all areas for the current employee based on current employments
        locationIds,
    });

    const isManualClockIn = timesheet?.clockInCreationMethod === TimesheetCreationMethod.MANUAL_CLOCK_IN;

    const calculateTimeElapsedSinceClockIn = () => {
        if (timesheet?.originalStartAt) {
            //any clock in will have an originalStartAt
            const startAt = isManualClockIn ? timesheet.startAt : timesheet.originalStartAt;
            const currentDateTime = getTodayDate();
            const hoursElapsed = differenceInHours(currentDateTime, startAt);
            const minutesElapsed = differenceInMinutes(currentDateTime, startAt) % 60;
            setCurrentHour(hoursElapsed.toString().padStart(2, '0') + ':' + minutesElapsed.toString().padStart(2, '0'));
        }
    };

    useEffect(() => {
        fetchAllowClockInOutsideWorkingHours(currentEmployeeId).catch(handleError);
    }, [currentEmployeeId, fetchAllowClockInOutsideWorkingHours]);

    //start timer to change the current hour
    useEffect(() => {
        calculateTimeElapsedSinceClockIn();
        const timer = setInterval(() => {
            calculateTimeElapsedSinceClockIn();
        }, UPDATE_TIME_ELAPSED_EVERY_MILLISECONDS);
        return function cleanup() {
            clearInterval(timer);
        };
    });

    const isClockIn = !timesheet || (!!timesheet?.originalStartAt && (hasPassedHoursForClockIn(timesheet.originalStartAt) || !!timesheet.endAt));

    useEffect(() => {
        const startAt = isManualClockIn ? timesheet?.startAt : timesheet?.originalStartAt;
        if (!startAt) {
            return;
        }
        const startAtPlusMinimumSecondsBetweenTimesheets = addSeconds(startAt, MINIMUM_SECONDS_BETWEEN_TIMESHEET_CREATION);
        const isClockInDisabledNow = !isClockIn && !isPastDate(startAtPlusMinimumSecondsBetweenTimesheets);

        //we just did a clockIn and disable the button
        if (isClockInDisabledNow) {
            setClockInOption('CANCEL');
            setIsClockInDisabled(true);
            setTimeout(() => setIsClockInDisabled(false), MINIMUM_SECONDS_BETWEEN_TIMESHEET_CREATION * 1000);
        }

        const isCancelOrClockOut = !isClockIn && isPastDate(startAt);
        //we are in the past now based on MINIMUM_SECONDS_BETWEEN_TO_DELETE_LAST_TIMESHEET, so we need to check if we are in the period
        if (isCancelOrClockOut) {
            const missingSeconds = differenceInSeconds(getTodayDate(), startAt) - MINIMUM_SECONDS_BETWEEN_TO_DELETE_LAST_TIMESHEET;
            const canStillCancelTimesheet = missingSeconds < 0;
            if (canStillCancelTimesheet) {
                setClockInOption('CANCEL');
                setTimeout(() => setClockInOption('CLOCKOUT'), Math.abs(missingSeconds) * 1000);
            } else {
                setClockInOption('CLOCKOUT');
            }
        } else {
            setClockInOption('CLOCKIN');
        }
    }, [isClockIn, isManualClockIn, timesheet]);

    if (isLoading) {
        return;
    }

    const getClockInOutDisplayProps = (): ButtonProps => {
        switch (clockInOption) {
            case 'CLOCKIN':
                return {
                    endIcon: <PlayIcon />,
                    children: t('header.clock_in'),
                    color: 'warning',
                };

            case 'CANCEL':
                return {
                    endIcon: <Cancel01Icon />,
                    children: `${currentHour} ${t('header.cancel_clock_in')}`,
                    color: 'error',
                };
            case 'CLOCKOUT':
            default:
                return {
                    endIcon: <PauseIcon />,
                    children: `${currentHour} ${t('header.clock_out')}`,
                    color: 'primary',
                };
        }
    };

    const onSaveFromClockOutDialog = async (mutation: ClockInOutCreationMutation, employeeTimesheetMutation: EmployeeTimesheetMutation | undefined) => {
        setIsWaitingForResponse(true);
        await handleClockInOutRequest(mutation, employeeTimesheetMutation);
        setIsWaitingForResponse(false);
    };

    const onCloseFromClockOutDialog = () => {
        setTimesheetToClockOut(undefined);
        setIsWaitingForResponse(false);
    };

    const buttonProps = getClockInOutDisplayProps();

    const tooltipTitle = () => {
        switch (clockInRestrictionReason) {
            case 'OUTSIDE_WORKING_HOURS':
                return t('header.clock_in_outside_working_hours');
            case 'SUNDAY_AND_PUBLIC_HOLIDAYS':
                return t('header.clock_in_sunday_and_public_holidays');
            default:
                return '';
        }
    };

    const isClockInDisabledNow = isClockInDisabled || clockInRestrictionReason != 'CLOCK_IN_ALLOWED';
    const displayArea = !isLoadingAreas && areas && areas.length >= 1 && !isClockIn && clockInRestrictionReason == 'CLOCK_IN_ALLOWED';

    const handleTimesheetChange = (timesheet: Timesheet) => {
        setTimesheet(timesheet);
    };
    return (
        <>
            {displayArea && !isMobile && !!timesheet?.id && (
                <ClockInAreaAutocomplete areas={areas} timesheet={timesheet} onTimesheetChange={handleTimesheetChange} employeeId={currentEmployeeId} />
            )}
            <Tooltip placement='bottom' title={tooltipTitle()}>
                <span>
                    {/*Hack to display the tooltip when the button is disabled*/}
                    <Button
                        onClick={handleClick}
                        disabled={isClockInDisabledNow || isWaitingForResponse}
                        variant='text'
                        sx={{ textWrap: 'nowrap' }}
                        {...buttonProps}
                        {...rest}
                    />
                </span>
            </Tooltip>
            {timesheetToClockOut && (
                <TimesheetClockOutDialog
                    timesheetSetting={currentEmployee?.currentWorkingPattern?.timesheetSetting}
                    timesheet={timesheetToClockOut}
                    onSave={onSaveFromClockOutDialog}
                    onClose={onCloseFromClockOutDialog}
                    currentEmployee={currentEmployee}
                />
            )}
        </>
    );
};

const hasPassedHoursForClockIn = (startAt: Date) => {
    return differenceInHours(getTodayDate(), startAt) >= HOURS_TO_CLOCK_IN;
};

type ClockInAreaAutocompleteProps = {
    areas: Area[];
    timesheet: Timesheet;
    employeeId: number;
    onTimesheetChange: (timesheet: Timesheet) => void;
} & ButtonProps;

const ClockInAreaAutocomplete: FC<ClockInAreaAutocompleteProps> = ({ areas, timesheet, employeeId, onTimesheetChange }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [areaId, setAreaId] = useState<number | undefined>(timesheet?.area?.id);
    const getAreaResourceOptions = () => {
        const allAreas = areas?.map(area => {
            return {
                id: area.id,
                name: area.name,
            };
        });
        return allAreas ?? [];
    };

    const handleOnAreaChange = async (areaId: number) => {
        const mutation: TimesheetClockInAreaUpdateMutation = {
            areaId: areaId,
            employeeId: employeeId,
        };
        try {
            const returnedTimesheet = await updateTimesheetArea(mutation, timesheet.id);
            onTimesheetChange(returnedTimesheet);
            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });
        } catch (error) {
            handleError(error);
        }
    };

    const allAreas = getAreaResourceOptions() ?? [];

    return (
        <Select
            placeholder={t('header.area_placeholder')}
            autocompleteProps={{
                id: 'area-select',
                sx: { width: '200px' },
                disableListWrap: true,
            }}
            value={defaultToNull(allAreas.find(option => option.id === areaId)) as Area}
            options={allAreas}
            disableClearable
            getOptionLabel={option => option.name}
            onChange={newValue => {
                setAreaId(newValue?.id);
                handleOnAreaChange(newValue?.id);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
