import { FormControlLabel, Stack, TextField } from '@mui/material';
import { Label } from '@/domain/label/Label.model';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelPropertyName, getLabelTranslation, useUserLanguage } from '@/utils/language.util';
import { SurveyUploadFile } from './SurveyUploadFile';
import { isNPS } from '@/domain/survey/Survey.service';
import { SurveyQuestionForm, SurveyQuestionItemForm } from '../SurveyForm.util';

type OpinionScaleProps = {
    question: SurveyQuestionForm;
    isTranslationMode: boolean;
    onQuestionScaleItemAdded: (option: SurveyQuestionItemForm, scaleOptionsLength: number) => void;
    onChangeQuestionItemTitle: (title: Label, index: number) => void;
    onChangeQuestionItemImage: (file: File | undefined, index: number) => void;
    initOpinionScale: () => void;
};

export const SurveyOpinionScale: FC<OpinionScaleProps> = ({
    question,
    isTranslationMode,
    onQuestionScaleItemAdded,
    onChangeQuestionItemTitle,
    onChangeQuestionItemImage,
    initOpinionScale,
}) => {
    const { t } = useTranslation();
    const translateLanguage = useUserLanguage();

    useEffect(() => {
        const OPINION_SCALE_NUMBER_ITEMS = 11;
        // if question has 11 items, it means it's already initialized
        if (question.items.length === OPINION_SCALE_NUMBER_ITEMS) {
            return;
        }
        initOpinionScale();
    }, [onQuestionScaleItemAdded, question, initOpinionScale]);

    return (
        <Stack direction='row' gap={2}>
            <FormControlLabel
                label={'0'}
                labelPlacement='top'
                control={
                    <TextField
                        fullWidth={true}
                        value={getLabelTranslation(question?.items[0]?.label, translateLanguage)}
                        onChange={event => {
                            const property = getLabelPropertyName(translateLanguage);
                            question.items[0].label[property] = event?.target?.value;
                            onChangeQuestionItemTitle(question.items[0].label, 0);
                        }}
                    />
                }
            />

            <FormControlLabel
                label={'10'}
                labelPlacement='top'
                control={
                    <TextField
                        fullWidth={true}
                        value={getLabelTranslation(question?.items[10]?.label, translateLanguage)}
                        onChange={event => {
                            const property = getLabelPropertyName(translateLanguage);
                            question.items[1].label[property] = event?.target?.value;
                            onChangeQuestionItemTitle(question?.items[10]?.label ?? question.items[1].label, 1);
                        }}
                    />
                }
            />
            {/* Todo: use es6+ map and filter to be less redundant */}
            {/* Todo: improve translation of x_image */}
            {/* Todo: rework this defaultImageUrl behavior */}
            {!isTranslationMode && !isNPS(question.type) && (
                <Stack direction={'row'} gap={2} flex={1}>
                    <SurveyUploadFile
                        fileLabel={t('survey_templates_page.1st_image')}
                        defaultImageUrl={question?.items[1]?.imageUrl}
                        onDelete={() => {
                            onChangeQuestionItemImage(undefined, 1);
                        }}
                        onUpload={upload => {
                            onChangeQuestionItemImage(upload.data, 1);
                        }}
                    />
                    <SurveyUploadFile
                        fileLabel={t('survey_templates_page.2nd_image')}
                        defaultImageUrl={question?.items[4]?.imageUrl}
                        onDelete={() => {
                            onChangeQuestionItemImage(undefined, 4);
                        }}
                        onUpload={upload => {
                            onChangeQuestionItemImage(upload.data, 4);
                        }}
                    />

                    <SurveyUploadFile
                        fileLabel={t('survey_templates_page.3rd_image')}
                        defaultImageUrl={question?.items[6]?.imageUrl}
                        onDelete={() => {
                            onChangeQuestionItemImage(undefined, 6);
                        }}
                        onUpload={upload => {
                            onChangeQuestionItemImage(upload.data, 6);
                        }}
                    />
                    <SurveyUploadFile
                        fileLabel={t('survey_templates_page.4th_image')}
                        defaultImageUrl={question?.items[8]?.imageUrl}
                        onDelete={() => {
                            onChangeQuestionItemImage(undefined, 8);
                        }}
                        onUpload={upload => {
                            onChangeQuestionItemImage(upload.data, 8);
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
};
