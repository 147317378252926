import { FilteringConditionValue } from '@/domain/permission/Permission.model';
import { FilteringConditionValueFormValues } from '@/page/setting/permission/permission-setting-form/PermissionSettingForm.schema';
import { getLabelTranslation } from '@/utils/language.util';

export const mapFilteringConditionValueToFormValues = (filterConditionValue: FilteringConditionValue): FilteringConditionValueFormValues => {
    return {
        location: filterConditionValue.location
            ? {
                  id: filterConditionValue.location.id,
                  label: filterConditionValue.location.name,
              }
            : undefined,
        legalUnit: filterConditionValue.legalUnit ? { id: filterConditionValue.legalUnit.id, label: filterConditionValue.legalUnit.displayName } : undefined,
        department: filterConditionValue.department
            ? { id: filterConditionValue.department.id, label: getLabelTranslation(filterConditionValue.department.name) }
            : undefined,
        job: filterConditionValue.job ? { id: filterConditionValue.job.id, label: getLabelTranslation(filterConditionValue.job.name) } : undefined,
        employmentStatus: filterConditionValue.employmentStatus ?? undefined,
        contractType: filterConditionValue.contractType ?? undefined,
    };
};
