import { useQuery, UseQueryOptions, UseQueryResult as RQUseQueryResult } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';

/**
 * While waiting to use a library like React Query or SWR, we use this type to represent the result of a query.
 */
export declare type UseQueryResult<TData = unknown, TError = unknown> = {
    data: TData | undefined;
    error: TError | null;
    isError: boolean;
    isLoading: boolean;
    isFetching?: boolean;
    // This will be replaced by cache api like with react-query
    setData: Dispatch<SetStateAction<TData | undefined>>;
    refetch: () => Promise<void>;
};

export interface UseMutationResult<TData = unknown, TVariables = void, TError = unknown> {
    mutate: (variables: TVariables) => Promise<TData>;
    isPending: boolean;
    isError: boolean;
    error: TError | null;
}

export type UseInfiniteQueryResult<TData = unknown, TError = unknown> = UseQueryResult<TData, TError> & {
    fetchNextPage: () => Promise<void>;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    // totalCount is not inspired by react-query, but it's a common need in our app
    totalCount: number;
};

export const createUseQueryHook = <TData, TQueryParams>(key: string, queryFn: (params: TQueryParams) => Promise<TData>) => {
    return (queryParams: TQueryParams, options?: Omit<UseQueryOptions<TData, Error>, 'queryKey' | 'queryFn'>): RQUseQueryResult<TData, Error> => {
        return useQuery({
            queryKey: [key, queryParams], // Include params to uniquely identify the query
            queryFn: () => queryFn(queryParams),
            ...options,
        });
    };
};
