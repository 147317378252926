import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Employee, EmployeeSyncRequest } from '@/domain/employee/Employee.model';
import { syncEmployees } from '@/domain/employee/Employee.service';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';

type thirdPartySyncForm = yup.InferType<typeof schema>;
type ThirdPartySyncDialogProps = DialogWrapperProps;

export const ThirdPartySyncDialog: FC<ThirdPartySyncDialogProps> = ({ onClose, ...rest }) => {
    const { t } = useTranslation();

    const { data: employees = [], error: employeesError, isError: isEmployeesError, isLoading: isEmployeesLoading } = useGetEmployees();

    return (
        <DialogWrapper header={t('third_party.connector_logs.sync_dialog_title')} {...rest} onClose={onClose}>
            <Stack gap={1} component={DialogContent}>
                <StateHandler isLoading={isEmployeesLoading} isError={isEmployeesError} error={employeesError}>
                    <ThirdPartySyncDialogForm employees={employees} onConfirm={onClose} />
                </StateHandler>
            </Stack>
            <DialogActions>
                <Button type={'submit'} form={'third-party-sync-form'}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type ThirdPartySyncDialogFormProps = {
    employees: Employee[];
    onConfirm: () => void;
};

const ThirdPartySyncDialogForm: FC<ThirdPartySyncDialogFormProps> = ({ employees, onConfirm }) => {
    const { t } = useTranslation();
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSave = async (formData: thirdPartySyncForm) => {
        const employeeSyncRequest: EmployeeSyncRequest = {
            employeeIds: [formData.employeeId],
        };
        try {
            await syncEmployees(employeeSyncRequest);
            showSnackbar(t('third_party.connector_logs.sync_success'), 'success');
        } catch (e) {
            showSnackbar(t('third_party.connector_logs.sync_error'), 'error');
            handleError(e);
        } finally {
            onConfirm();
        }
    };

    return (
        <Stack id={'third-party-sync-form'} component={'form'} onSubmit={handleSubmit(handleSave, console.error)}>
            <FormControlLabel
                label={t('third_party.connector_logs.employee_select')}
                control={
                    <FieldSelect
                        name={'employeeId'}
                        control={control}
                        options={employees.map(employee => employee.id)}
                        fullWidth
                        getOptionLabel={option => employees.find(employee => employee.id === option)?.displayName ?? ''}
                    />
                }
            />
        </Stack>
    );
};

const schema = yup.object().shape({
    employeeId: yup.number().required(),
});
