import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getDepartmentNodes } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { AsyncSelectFilter, AsyncTreeSelectFilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.utils';

export enum ManageTimesheetPendingTableFiltersEnum {
    JOB = 'JOB',
    LOCATION = 'LOCATION',
    DEPARTMENT = 'DEPARTMENT',
    MANAGER = 'MANAGER',
}

type TimesheetPendingFilter = SelectFilter | AsyncSelectFilter | AsyncTreeSelectFilterType;
export const useManageTimesheetPendingTableFilters = (): { filters: TimesheetPendingFilter[] } => {
    const filters: TimesheetPendingFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: ManageTimesheetPendingTableFiltersEnum.LOCATION,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'tree-multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departmentsNodes = await getDepartmentNodes();
                return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
            },
            key: ManageTimesheetPendingTableFiltersEnum.DEPARTMENT,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: ManageTimesheetPendingTableFiltersEnum.JOB,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: ManageTimesheetPendingTableFiltersEnum.MANAGER,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
