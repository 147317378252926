import { TreeItem2, TreeItem2Props, TreeItem2SlotProps } from '@mui/x-tree-view-pro';
import { forwardRef, Ref } from 'react';
import { CheckmarkCircle02Icon, CircleIcon } from 'hugeicons-react';

export type TreeViewItemProps = TreeItem2Props & { multiSelect?: boolean };
export const TreeViewItem = forwardRef(function TreeViewItem({ multiSelect, ...restTreeItemProps }: TreeViewItemProps, ref: Ref<HTMLLIElement>) {
    // slot props to display radio button instead of checkbox when multiSelect is false
    const simpleRadioSlotProps = {
        checkbox: {
            icon: <CircleIcon size={20} />,
            checkedIcon: <CheckmarkCircle02Icon size={20} />,
        },
    } as TreeItem2SlotProps; // typing is not good from the library

    return <TreeItem2 {...restTreeItemProps} ref={ref} slotProps={!multiSelect ? simpleRadioSlotProps : undefined} />;
});
