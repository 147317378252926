import { EmployeesSelectionDialog } from '@/components/employee-selection-dialog/EmployeesSelectionDialog';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldText } from '@/components/form/field-text/FieldText';
import { RouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard';
import { displayFormRouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard.util';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { Folder } from '@/domain/document/Document.model';
import { Employee } from '@/domain/employee/Employee.model';
import { PermissionGroup, PermissionGroupPolicy, PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';
import { ConditionType, PermissionFilteringConditionRule, PermissionFilterType, PolicyPermission } from '@/domain/permission/Permission.model';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import useDebounce from '@/hooks/Debounce.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer, FooterActions } from '@/page/layout/Footer';
import { ConditionEditorDialog } from '@/page/setting/permission/condition/Components/ConditionEditorDialog';
import { RenderCondition } from '@/page/setting/permission/condition/Components/RenderCondition';
import { PermissionPoliciesPanel } from '@/page/setting/permission/permission-policies-panel/PermissionPoliciesPanel';
import {
    FilteringConditionFormValues,
    PermissionFormValues,
    permissionSettingFormSchema,
} from '@/page/setting/permission/permission-setting-form/PermissionSettingForm.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Paper, Radio, Stack, Tab, Tabs, Typography } from '@mui/material';
import { AddTeamIcon } from 'hugeicons-react';
import { FC, Fragment, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useScrollToFirstError } from '@/hooks/ErrorScroll.hook';
import { mapFilteringConditionValueToFormValues } from '@/domain/permission-group/PermissionGroup.util';

export const PermissionSettingForm: FC<{
    permissionGroup?: PermissionGroup;
    allEmployees: Employee[];
    sectionDefinitions: SectionDefinition[];
    folders: Folder[];
    onDelete: () => void;
    onSave: (formValues: PermissionFormValues) => void;
}> = ({ permissionGroup, allEmployees, sectionDefinitions, folders, onDelete, onSave }) => {
    const { t } = useTranslation();
    const debounce = useDebounce();

    // state to select current tab between EMPLOYEE and COMPANY
    const [permissionSettingsTab, setPermissionSettingsTab] = useState<'EMPLOYEE' | 'COMPANY'>('EMPLOYEE');

    const isEdit = !!permissionGroup?.id;

    const groupType = permissionGroup?.groupType ?? PermissionGroupType.CUSTOM;

    const formMethods = useForm<PermissionFormValues>({
        shouldFocusError: false,
        resolver: yupResolver(permissionSettingFormSchema),
        defaultValues: {
            groupType: groupType,
            groupName: permissionGroup?.groupName ?? '',
            description: permissionGroup?.description ?? '',
            filterType: permissionGroup?.filterType ?? PermissionFilterType.EMPLOYEE_LIST,
            targetMembersFilterType: permissionGroup?.targetMembersFilterType ?? PermissionFilterType.EMPLOYEE_LIST,
            groupMembers: permissionGroup?.groupMembers ?? [],
            groupPolicies: permissionGroup?.groupPolicies ?? [],
            targetMembers: permissionGroup?.targetMembers ?? [],
            notificationsEnabled: permissionGroup?.notificationsEnabled ?? true,
            groupMemberConditions: (permissionGroup?.groupMemberConditions ?? []).map(condition => ({
                ...condition,
                filterConditionValues: condition.filterConditionValues.map(mapFilteringConditionValueToFormValues),
            })),
            targetMemberConditions: (permissionGroup?.targetMemberConditions ?? []).map(condition => ({
                ...condition,
                filterConditionValues: condition.filterConditionValues.map(mapFilteringConditionValueToFormValues),
            })),
        },
    });

    const { handleSubmit, formState, control } = formMethods;

    const { resetFocus } = useScrollToFirstError(formState);

    const isDirtyForm = formState.isDirty;

    const handleSave = async (formValues: PermissionFormValues) => {
        onSave(formValues);
    };

    const isCustom = groupType === PermissionGroupType.CUSTOM;

    const canToggleNotification = isCustom || groupType === PermissionGroupType.SELF;

    return (
        <FormProvider {...formMethods}>
            <RouteLeavingGuard when={displayFormRouteLeavingGuard(formState)} />
            <ContentContainer>
                <Stack direction='column' gap={2}>
                    <Stack component={Paper} p={2} justifyContent='center' alignItems='flex-start' gap={2}>
                        <Typography variant='h2'>{t('permissions_setting_page.group_info')}</Typography>
                        <FormControlLabel
                            label={t('permissions_setting_page.group_name')}
                            sx={{ width: '100%' }}
                            control={<FieldText name='groupName' control={control} disabled={!isCustom} fullWidth />}
                        />
                        <FormControlLabel
                            label={t('permissions_setting_page.description')}
                            sx={{ width: '100%' }}
                            control={
                                <FieldText
                                    name='description'
                                    control={control}
                                    disabled={!isCustom}
                                    textFieldProps={{
                                        multiline: true,
                                        minRows: 1,
                                    }}
                                    fullWidth
                                />
                            }
                        />
                    </Stack>

                    <Stack component={Paper} gap={3} p={2}>
                        <GroupMembers groupType={groupType} allEmployees={allEmployees} />

                        {/* Policies tabs for Employee and Company */}
                        <Stack gap={1}>
                            <Typography variant='h2'>{t('permissions_setting_page.employee_management_rights')}</Typography>
                            {isCompanyConfigurationAllowed(groupType) && (
                                <Tabs
                                    value={permissionSettingsTab}
                                    onChange={(_event, value) => setPermissionSettingsTab(value)}
                                    aria-label='Permissions settings'
                                >
                                    <Tab value='EMPLOYEE' label={t('permissions_setting_page.permission_employee_right')} />
                                    <Tab value='COMPANY' label={t('permissions_setting_page.company_level_configuration')} />
                                </Tabs>
                            )}
                            <Policies
                                permissionSettingsTab={permissionSettingsTab}
                                sectionDefinitions={sectionDefinitions}
                                folders={folders}
                                groupType={groupType}
                            />
                        </Stack>
                        {permissionSettingsTab === 'EMPLOYEE' && (
                            <>
                                <TargetMembers groupType={groupType} allEmployees={allEmployees} />
                                {canToggleNotification && (
                                    <Stack>
                                        <Stack direction='column' spacing={1}>
                                            <Typography variant='body1bold'>{t('permissions_setting_page.notifications')}</Typography>

                                            <FormControlLabel
                                                label={t('permissions_setting_page.notify_group_members')}
                                                labelPlacement='end'
                                                control={<FieldSwitch control={control} name='notificationsEnabled' />}
                                            />
                                        </Stack>
                                    </Stack>
                                )}
                            </>
                        )}
                    </Stack>
                </Stack>
            </ContentContainer>
            <Footer>
                <FooterActions
                    actions={[
                        {
                            name: 'secondary',
                            color: 'error',
                            variant: 'contained',
                            onClick: () => onDelete(),
                            children: t('general.delete'),
                        },
                        {
                            name: 'primary',
                            variant: 'contained',
                            onClick: () => {
                                debounce(
                                    handleSubmit(handleSave, err => {
                                        console.error(err);
                                        resetFocus();
                                    }),
                                );
                            },
                            disabled: !isDirtyForm,
                            children: t(isEdit ? 'general.update' : 'general.create'),
                        },
                    ]}
                />
            </Footer>
        </FormProvider>
    );
};

const Policies: FC<{
    permissionSettingsTab: 'EMPLOYEE' | 'COMPANY';
    sectionDefinitions: SectionDefinition[];
    folders: Folder[];
    groupType: PermissionGroupType;
}> = ({ permissionSettingsTab, sectionDefinitions, folders, groupType }) => {
    const { getValues, setValue, watch } = useFormContext<PermissionFormValues>();

    const groupPolicies = watch('groupPolicies');

    const getNewGroupPolicies = (policy: PolicyPermission, checked: boolean, resourceId?: number): PermissionGroupPolicy[] => {
        const newPolicies = [...getValues('groupPolicies')];

        const policyExists = (p: PermissionGroupPolicy) => (resourceId ? p.resourceId === resourceId && p.policy === policy : p.policy === policy);

        const addPolicy = () => {
            if (!newPolicies.some(policyExists)) {
                newPolicies.push({ policy, resourceId });
            }
        };

        const removePolicy = () => {
            const idx = newPolicies.findIndex(policyExists);
            if (idx >= 0) {
                newPolicies.splice(idx, 1);
            }
        };
        if (checked) {
            addPolicy();
        } else {
            removePolicy();
        }

        return newPolicies;
    };

    return (
        <Stack gap={1}>
            <PermissionPoliciesPanel
                permissionSettingsTab={permissionSettingsTab}
                onChangePolicy={selectedPolicy => {
                    const groupPolicies = getNewGroupPolicies(selectedPolicy.policyName, selectedPolicy.checked, selectedPolicy.resourceId);
                    setValue('groupPolicies', groupPolicies, { shouldDirty: true });
                }}
                groupPolicies={groupPolicies}
                groupTypes={groupType}
                sectionDefinitions={sectionDefinitions ?? []}
                folders={folders ?? []}
            />
        </Stack>
    );
};

const TargetMembers: FC<{
    groupType: PermissionGroupType;
    allEmployees: Employee[];
}> = ({ groupType, allEmployees }) => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext<PermissionFormValues>();

    const [appliesDialogOpen, setAppliesDialogOpen] = useState<boolean>(false);
    const [isTargetDialogOpen, setIsTargetDialogOpen] = useState(false);

    const isCustom = groupType === PermissionGroupType.CUSTOM;

    const targetMembersFilterType = watch('targetMembersFilterType');
    const targetMemberConditions = watch('targetMemberConditions');
    const targetMembers = watch('targetMembers');
    const filterType = watch('filterType');

    const isTargetDynamicOrAllEmployeesFilterType =
        targetMembersFilterType === PermissionFilterType?.DYNAMIC || targetMembersFilterType === PermissionFilterType?.ALL_EMPLOYEES;

    const canDisplayTargetCondition = targetMembersFilterType === PermissionFilterType.DYNAMIC && targetMemberConditions.length !== 0;

    const canDisplayTargetMembers =
        !!targetMembers.length && !(filterType === PermissionFilterType.DYNAMIC || filterType === PermissionFilterType.ALL_EMPLOYEES);

    const handleTargetMembersChange = (assigned: Employee[]) => {
        setAppliesDialogOpen(false);
        setValue('targetMembers', assigned, { shouldDirty: true });
    };

    const handleTargetConditionChange = (conditionType: ConditionType, conditions: FilteringConditionFormValues[]) => {
        setValue('targetMembers', [], { shouldDirty: true });

        if (conditionType === ConditionType.ALL_EMPLOYEES) {
            setValue('targetMemberConditions', [], { shouldDirty: true });
            setValue('targetMembersFilterType', PermissionFilterType.ALL_EMPLOYEES, { shouldDirty: true });
        } else {
            setValue('targetMemberConditions', conditions, { shouldDirty: true });
            setValue('targetMembersFilterType', PermissionFilterType.DYNAMIC, { shouldDirty: true });
        }
        setIsTargetDialogOpen(false);
    };

    return (
        <>
            {isCustom && <Typography variant='body1bold'>{t('permissions_setting_page.applies_to')}</Typography>}
            {isCustom && (
                <Stack direction={'row'}>
                    <FormControlLabel
                        control={
                            <Radio
                                onChange={() => {
                                    const options = { shouldDirty: true };
                                    // TODO : maybe we can merge these 3 states into one
                                    setValue('targetMembers', [], options);
                                    setValue('targetMemberConditions', [], options);
                                    setValue('targetMembersFilterType', PermissionFilterType?.EMPLOYEE_LIST, options);
                                }}
                                checked={targetMembersFilterType === PermissionFilterType?.EMPLOYEE_LIST}
                            />
                        }
                        label={t('permissions_setting_page.select_manually')}
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        onChange={() => {
                            setValue('targetMembers', [], { shouldDirty: true });
                            setValue('targetMembersFilterType', PermissionFilterType?.DYNAMIC, {
                                shouldDirty: true,
                            });
                        }}
                        checked={isTargetDynamicOrAllEmployeesFilterType}
                        control={<Radio />}
                        label={t('permissions_setting_page.select_by_condition')}
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                onChange={() => {
                                    setValue('targetMemberConditions', [], {
                                        shouldDirty: true,
                                    });
                                    setValue('targetMembers', [], {
                                        shouldDirty: true,
                                    });
                                    setValue('targetMembersFilterType', PermissionFilterType?.SELF, {
                                        shouldDirty: true,
                                    });
                                }}
                                checked={targetMembersFilterType === PermissionFilterType?.SELF}
                            />
                        }
                        label={t('permissions_setting_page.self')}
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                onChange={() => {
                                    setValue('targetMemberConditions', [], {
                                        shouldDirty: true,
                                    });
                                    setValue('targetMembers', [], {
                                        shouldDirty: true,
                                    });
                                    setValue('targetMembersFilterType', PermissionFilterType?.MANAGER, {
                                        shouldDirty: true,
                                    });
                                }}
                                checked={targetMembersFilterType === PermissionFilterType?.MANAGER}
                            />
                        }
                        label={t('permissions_setting_page.select_direct_report')}
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={targetMembersFilterType === PermissionFilterType.SUBORDINATES}
                                onChange={() => {
                                    setValue('targetMemberConditions', [], {
                                        shouldDirty: true,
                                    });
                                    setValue('targetMembers', [], {
                                        shouldDirty: true,
                                    });
                                    setValue('targetMembersFilterType', PermissionFilterType.SUBORDINATES, {
                                        shouldDirty: true,
                                    });
                                }}
                            />
                        }
                        label={t('permissions_setting_page.select_direct_indirect_report')}
                        labelPlacement='end'
                    />
                </Stack>
            )}
            <Stack>
                {canDisplayTargetCondition && (
                    <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'} gap={1}>
                        {targetMemberConditions?.map((item, index) => (
                            <Fragment key={item.id + (item?.conditionType ?? '')}>
                                <RenderCondition item={item} />
                                {index < targetMemberConditions?.length - 1 && <Typography variant={'body2'}>{t('general.and')}</Typography>}
                            </Fragment>
                        ))}
                    </Stack>
                )}
            </Stack>
            {isCustom && (
                <Stack gap={2} direction={'row'} alignItems={'center'}>
                    {targetMembersFilterType === PermissionFilterType.EMPLOYEE_LIST && (
                        <Button sx={{ width: '20em' }} endIcon={<AddTeamIcon />} onClick={() => setAppliesDialogOpen(true)}>
                            {t('permissions_setting_page.choose_button_label')}
                        </Button>
                    )}
                    {isTargetDynamicOrAllEmployeesFilterType && (
                        <Button sx={{ width: '20em' }} endIcon={<AddTeamIcon />} onClick={() => setIsTargetDialogOpen(true)}>
                            {t('permissions_setting_page.choose_button_label')}
                        </Button>
                    )}
                    {targetMembersFilterType === PermissionFilterType.ALL_EMPLOYEES && (
                        <Typography variant={'body2bold'}>{t('permissions_setting_page.all_employees')}</Typography>
                    )}
                    {canDisplayTargetMembers && <StackedAvatars employeeAvatars={targetMembers} />}
                </Stack>
            )}

            {appliesDialogOpen && (
                <EmployeesSelectionDialog
                    assignedEmployees={targetMembers}
                    allEmployees={allEmployees}
                    open={true}
                    onClose={() => setAppliesDialogOpen(false)}
                    onSave={handleTargetMembersChange}
                    isFilterActive={false}
                />
            )}

            {isTargetDialogOpen && (
                <ConditionEditorDialog
                    open={true}
                    defaultConditions={targetMemberConditions}
                    isAllEmployeeFilterType={targetMembersFilterType === PermissionFilterType.ALL_EMPLOYEES}
                    onCancel={() => setIsTargetDialogOpen(false)}
                    availableRules={[
                        PermissionFilteringConditionRule.EQUALS,
                        PermissionFilteringConditionRule.NOT_EQUALS,
                        PermissionFilteringConditionRule.SAME_AS_VIEWER,
                        PermissionFilteringConditionRule.SAME_DEPARTMENT_TREE,
                    ]}
                    onSave={handleTargetConditionChange}
                />
            )}
        </>
    );
};

const GroupMembers: FC<{
    groupType: PermissionGroupType;
    allEmployees: Employee[];
}> = ({ groupType, allEmployees }) => {
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [isGroupDialogOpen, setIsGroupDialogOpen] = useState(false);

    const { setValue, watch } = useFormContext<PermissionFormValues>();
    const filterType = watch('filterType');
    const groupMembers = watch('groupMembers');
    const groupMemberConditions = watch('groupMemberConditions');

    const isCustom = groupType === PermissionGroupType.CUSTOM;

    const isDynamicOrAllEmployeesFilterType = filterType === PermissionFilterType.DYNAMIC || filterType === PermissionFilterType.ALL_EMPLOYEES;
    const isGroupTypeCustom = groupType === PermissionGroupType.CUSTOM && filterType === PermissionFilterType.DYNAMIC;

    const canDisplayGroupMembers = !!groupMembers?.length && !isDynamicOrAllEmployeesFilterType;

    const canDisplayGroupCondition = filterType === PermissionFilterType.DYNAMIC && groupMemberConditions.length !== 0;

    const handleGroupMembersChange = (assigned: Employee[]) => {
        setDialogOpen(false);
        setValue('groupMembers', assigned, { shouldDirty: true });
    };

    const handleGroupConditionChange = (conditionType: ConditionType, conditions: FilteringConditionFormValues[]) => {
        setValue('groupMembers', [], { shouldDirty: true });
        if (conditionType === ConditionType.ALL_EMPLOYEES) {
            setValue('groupMemberConditions', [], { shouldDirty: true });
            setValue('filterType', PermissionFilterType.ALL_EMPLOYEES, { shouldDirty: true });
        } else {
            setValue('groupMemberConditions', conditions, { shouldDirty: true });
            setValue('filterType', PermissionFilterType.DYNAMIC, { shouldDirty: true });
        }
        setIsGroupDialogOpen(false);
    };

    return (
        <Stack direction='column' gap={2}>
            <Typography variant='h2'>{t('permissions_setting_page.members')}</Typography>

            {isCustom && (
                <Stack direction={'row'}>
                    <FormControlLabel
                        control={
                            <Radio
                                onChange={() => {
                                    setValue('groupMembers', [], { shouldDirty: true });
                                    setValue('filterType', PermissionFilterType.EMPLOYEE_LIST, { shouldDirty: true });
                                }}
                                checked={filterType === PermissionFilterType.EMPLOYEE_LIST}
                            />
                        }
                        label={t('permissions_setting_page.select_manually')}
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                onChange={() => {
                                    setValue('groupMemberConditions', [], { shouldDirty: true });
                                    setValue('filterType', PermissionFilterType?.DYNAMIC, { shouldDirty: true });
                                }}
                                checked={isDynamicOrAllEmployeesFilterType}
                            />
                        }
                        label={t('permissions_setting_page.select_by_condition')}
                        labelPlacement='end'
                    />
                </Stack>
            )}
            {canDisplayGroupCondition && (
                <Stack direction={'row'} gap={1} flexWrap={'wrap'} alignItems={'center'}>
                    {groupMemberConditions?.map((item, index) => (
                        <Fragment key={item.id + (item?.conditionType ?? '')}>
                            <RenderCondition item={item} />
                            {index < groupMemberConditions?.length - 1 && <Typography variant={'body2'}>{t('general.and')}</Typography>}
                        </Fragment>
                    ))}
                </Stack>
            )}

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                {filterType === PermissionFilterType.EMPLOYEE_LIST && isCompanyConfigurationAllowed(groupType) && (
                    <Button sx={{ width: '20em' }} endIcon={<AddTeamIcon />} onClick={() => setDialogOpen(true)}>
                        {t('permissions_setting_page.choose_button_label')}
                    </Button>
                )}
                {isGroupTypeCustom && (
                    <Button sx={{ width: '20em' }} endIcon={<AddTeamIcon />} onClick={() => setIsGroupDialogOpen(true)}>
                        {t('permissions_setting_page.choose_button_label')}
                    </Button>
                )}
                {filterType === PermissionFilterType.ALL_EMPLOYEES && (
                    <Typography variant={'body2bold'}>{t('permissions_setting_page.all_employees')}</Typography>
                )}

                {canDisplayGroupMembers && <StackedAvatars employeeAvatars={groupMembers} />}
            </Stack>

            {isGroupDialogOpen && (
                <ConditionEditorDialog
                    open={true}
                    defaultConditions={groupMemberConditions}
                    isAllEmployeeFilterType={filterType === PermissionFilterType.ALL_EMPLOYEES}
                    onCancel={() => setIsGroupDialogOpen(false)}
                    availableRules={[PermissionFilteringConditionRule.EQUALS, PermissionFilteringConditionRule.NOT_EQUALS]}
                    onSave={handleGroupConditionChange}
                />
            )}

            {dialogOpen && (
                <EmployeesSelectionDialog
                    assignedEmployees={groupMembers}
                    allEmployees={allEmployees}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onSave={handleGroupMembersChange}
                    isFilterActive={false}
                />
            )}
        </Stack>
    );
};

const isCompanyConfigurationAllowed = (groupType: PermissionGroupType): boolean => {
    return groupType === PermissionGroupType.ADMIN || groupType === PermissionGroupType.ALL_EMPLOYEES || groupType === PermissionGroupType.CUSTOM;
};
