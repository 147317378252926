import { Typography, TypographyProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const RichTextTypography: FC<PropsWithChildren<TypographyProps>> = ({ children, ...rest }) => {
    return (
        <Typography
            className={'rich_text'}
            dangerouslySetInnerHTML={{
                __html: children ?? '',
            }}
            sx={{
                wordBreak: 'break-word',
            }}
            {...rest}
        />
    );
};
