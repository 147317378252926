export type ShiftBreak = {
    id: number;
    breakTime: number;
    breakAt?: LocalTime;
    shiftBreakType: ShiftBreakType;
};

export enum ShiftBreakType {
    UNPAID_BREAK = 'UNPAID_BREAK',
    PAID_BREAK = 'PAID_BREAK',
}

export type ShiftBreakMutation = Omit<ShiftBreak, 'id'>;
