import { RouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard';
import { displayFormRouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard.util';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { StepForm, StepperForm } from '@/components/stepper-form/StepperForm';
import { useStepper } from '@/components/stepper-form/StepperForm.util';
import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { ReviewTemplate, ReviewTemplateMutation } from '@/domain/review-template/ReviewTemplate.model';
import { createReviewTemplate, updateReviewTemplate } from '@/domain/review-template/ReviewTemplate.service';
import { useGetReviewRatingScales } from '@/hooks/review-rating-scale/ReviewRatingScale.hook';
import { useGetReviewTemplate } from '@/hooks/review-template/ReviewTemplate.hook';
import { AboutStepForm } from '@/page/setting/review/template/AboutStepForm';
import { FormStep } from '@/page/setting/review/template/FormStepForm';
import { InvitationEmailsContentForm } from '@/page/setting/review/template/InvitationEmailsContentForm';
import { PostReviewEmployeeFeedbackStep, PostReviewManagerFeedbackStep } from '@/page/setting/review/template/PostReviewFeedbackStep';
import { handleError } from '@/utils/api.util';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import { FC, useEffect, useState } from 'react';
import { FormProvider, Resolver, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
    getAboutStepSchema,
    getFormStepSchema,
    getInvitationEmailSchema,
    getPrivateEmployeeFeedbackSchema,
    getPrivateManagerFeedbackSchema,
    PrivateQuestionBlockForm,
    ReviewBlockForm,
    ReviewTemplateFormStepperType,
    ReviewTemplateFormType,
} from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import {
    getDefaultValues,
    getInvitationEmailsContentMutation,
    getPrivateQuestionsMutation,
    getReviewAvailableContributorTypes,
    getReviewBlockFormItemsMutation,
} from '@/page/setting/review/template/ReviewTemplateFormPage.util';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { getLabelTranslation, getRealmLanguage, UserLanguage } from '@/utils/language.util';

export type STEPS_NAME = 'ABOUT' | 'FORM' | 'POST_REVIEW_MANAGER_FEEDBACK' | 'POST_REVIEW_EMPLOYEE_FEEDBACK' | 'INVITATION_EMAIL';

export const ReviewTemplateFormPage: FC = () => {
    const {
        data: reviewRatingScales,
        isLoading: isReviewRatingScalesLoading,
        isError: isReviewRatingScalesError,
        error: reviewRatingScalesError,
    } = useGetReviewRatingScales({});
    const params = useParams();

    const templateId = params.templateId !== 'new' ? Number(params.templateId) || undefined : undefined;
    const {
        data: reviewTemplate,
        isLoading: isReviewTemplateLoading,
        isError: isReviewTemplateError,
        error: reviewTemplateError,
    } = useGetReviewTemplate(templateId);

    const isLoading = isReviewRatingScalesLoading || isReviewTemplateLoading;
    const isError = isReviewRatingScalesError || isReviewTemplateError;
    const error = reviewRatingScalesError || reviewTemplateError;

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <ReviewTemplatesForm reviewRatingScales={reviewRatingScales} reviewTemplate={reviewTemplate} templateId={templateId} />
        </StateHandler>
    );
};

type ReviewTemplatesFormProps = {
    reviewRatingScales: ReviewRatingScale[] | undefined;
    reviewTemplate: ReviewTemplate | undefined;
    templateId: number | undefined;
};

const ReviewTemplatesForm: FC<ReviewTemplatesFormProps> = ({ reviewRatingScales, reviewTemplate, templateId }) => {
    const [currentReorderItems, setCurrentReorderItems] = useState<ReorderItem[]>([]);
    const isTemplateUpdate = !!templateId;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());

    const getAboutStep = (translationLanguage: UserLanguage) => {
        return {
            stepComponent: (
                <AboutStepForm
                    onManagerPrivateQuestionToggle={(value: boolean) =>
                        value ? addPostReviewPrivateManagerFeedbackStep() : removePostReviewPrivateManagerFeedbackStep()
                    }
                    onEmployeePrivateQuestionToggle={(value: boolean) =>
                        value ? addPostReviewPrivateEmployeeFeedbackStep() : removePostReviewPrivateEmployeeFeedbackStep()
                    }
                    translationLanguage={translationLanguage}
                />
            ),
            stepName: 'ABOUT',
            stepTranslationKey: 'reviews_settings_page.review_template_form.step_about',
            order: 1,
        };
    };

    const getFormStep = (translationLanguage: UserLanguage) => {
        return {
            stepComponent: <FormStep ratingsScale={reviewRatingScales} translationLanguage={translationLanguage} />,
            stepName: 'FORM',
            stepTranslationKey: 'reviews_settings_page.review_template_form.step_form',
            order: 2,
        };
    };

    const getPostReviewManagerFeedbackStep = (translationLanguage: UserLanguage) => {
        return {
            stepComponent: <PostReviewManagerFeedbackStep ratingsScale={reviewRatingScales} translationLanguage={translationLanguage} />,
            stepName: 'POST_REVIEW_MANAGER_FEEDBACK',
            stepSchema: getPrivateManagerFeedbackSchema(translationLanguage),
            stepTranslationKey: 'reviews_settings_page.review_template_form.step_private_manager_feedback',
            order: 3,
        };
    };

    const getPostReviewEmployeeFeedbackStep = (translationLanguage: UserLanguage) => {
        return {
            stepComponent: <PostReviewEmployeeFeedbackStep ratingsScale={reviewRatingScales} translationLanguage={translationLanguage} />,
            stepName: 'POST_REVIEW_EMPLOYEE_FEEDBACK',
            stepSchema: getPrivateEmployeeFeedbackSchema(translationLanguage),
            stepTranslationKey: 'reviews_settings_page.review_template_form.step_private_employee_feedback',
            order: 4,
        };
    };

    const getInvitationEmailsContentStep = (translationLanguage: UserLanguage) => {
        return {
            stepComponent: <InvitationEmailsContentForm translationLanguage={translationLanguage} />,
            stepName: 'INVITATION_EMAIL',
            stepSchema: getInvitationEmailSchema(),
            stepTranslationKey: 'reviews_settings_page.review_template_form.step_invitation_email',
            order: 5,
        };
    };

    type StepFormWithOrder = StepForm & {
        order: number;
    };
    const [steps, setSteps] = useState<StepFormWithOrder[]>(getSteps(translationLanguage));

    const useStepperValues = useStepper({
        steps,
    });

    const { currentStep, onNextStep } = useStepperValues;

    function getSteps(
        translationLanguage: UserLanguage,
        includePrivateManagerQuestions?: boolean,
        includePrivateEmployeeQuestions?: boolean,
    ): StepFormWithOrder[] {
        const currentSteps: StepFormWithOrder[] = [getAboutStep(translationLanguage), getFormStep(translationLanguage)];

        if (includePrivateManagerQuestions ?? reviewTemplate?.includePrivateManagerQuestions) {
            currentSteps.push(getPostReviewManagerFeedbackStep(translationLanguage));
        }

        if (includePrivateEmployeeQuestions ?? reviewTemplate?.includePrivateEmployeeQuestions) {
            currentSteps.push(getPostReviewEmployeeFeedbackStep(translationLanguage));
        }

        currentSteps.push(getInvitationEmailsContentStep(translationLanguage));

        return currentSteps.sort((a, b) => a.order - b.order);
    }

    const stepName = steps?.find(step => step?.stepName === currentStep?.stepName)?.stepName as STEPS_NAME;

    const getResolverForStepName = (step_name: STEPS_NAME): Resolver<ReviewTemplateFormStepperType> => {
        switch (step_name) {
            case 'ABOUT':
                return yupResolver<ReviewTemplateFormStepperType>(getAboutStepSchema(translationLanguage));
            case 'FORM':
                return yupResolver<ReviewTemplateFormStepperType>(getFormStepSchema(translationLanguage));
            case 'POST_REVIEW_MANAGER_FEEDBACK':
                return yupResolver<ReviewTemplateFormStepperType>(getPrivateManagerFeedbackSchema(translationLanguage));
            case 'POST_REVIEW_EMPLOYEE_FEEDBACK':
                return yupResolver<ReviewTemplateFormStepperType>(getPrivateEmployeeFeedbackSchema(translationLanguage));
            case 'INVITATION_EMAIL':
                return yupResolver<ReviewTemplateFormStepperType>(getInvitationEmailSchema());
        }
    };

    // Schema is split by step but we need to cast it to a single schema to use it in useForm
    // Maybe we can use a single schema and use conditional validation based on the step (when step === 'FORM' then validate this)
    const resolver: Resolver<ReviewTemplateFormType> = getResolverForStepName(stepName) as unknown as Resolver<ReviewTemplateFormType>;

    const formMethods = useForm<ReviewTemplateFormType>({
        resolver,
        defaultValues: getDefaultValues(reviewTemplate),
        mode: 'onSubmit',
    });

    const { formState, reset } = formMethods;

    function addPostReviewPrivateManagerFeedbackStep() {
        setSteps(prevSteps => [...prevSteps, getPostReviewManagerFeedbackStep(translationLanguage)].sort((a, b) => a.order - b.order));
    }

    function removePostReviewPrivateManagerFeedbackStep() {
        setSteps(prevSteps => prevSteps.filter(step => step.stepName !== 'POST_REVIEW_MANAGER_FEEDBACK').sort((a, b) => a.order - b.order));
    }

    function addPostReviewPrivateEmployeeFeedbackStep() {
        setSteps(prevSteps => [...prevSteps, getPostReviewEmployeeFeedbackStep(translationLanguage)].sort((a, b) => a.order - b.order));
    }

    function removePostReviewPrivateEmployeeFeedbackStep() {
        setSteps(prevSteps => prevSteps.filter(step => step.stepName !== 'POST_REVIEW_EMPLOYEE_FEEDBACK').sort((a, b) => a.order - b.order));
    }

    const handleLanguageChange = (language: UserLanguage) => {
        const includePrivateManagerQuestions = formMethods.getValues('includePrivateManagerQuestions');
        const includePrivateEmployeeQuestions = formMethods.getValues('includePrivateEmployeeQuestions');
        setTranslationLanguage(language);
        setSteps(getSteps(language, includePrivateManagerQuestions, includePrivateEmployeeQuestions));
    };

    // this is a workaround to avoid the error showing automatically when we click on next (because next button call handleSubmit)
    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset(undefined, {
                keepValues: true,
                keepDefaultValues: true,
            });
        }
    }, [formState.isSubmitSuccessful, reset]);

    const onSave = async (reviewTemplateFormValues: ReviewTemplateFormType) => {
        const isManagerContributorType = getReviewAvailableContributorTypes(reviewTemplateFormValues).includes('MANAGER');
        const isManagerPreparationAccess = reviewTemplateFormValues.managerPreparationAccess;
        const reviewTemplateMutation: ReviewTemplateMutation = {
            name: reviewTemplateFormValues.name,
            reviewType: reviewTemplateFormValues.reviewType,
            availableContributorTypes: getReviewAvailableContributorTypes(reviewTemplateFormValues),
            items: [
                ...getReviewBlockFormItemsMutation(reviewTemplateFormValues.reviewBlocksForm, reviewTemplateFormValues),
                ...getPrivateQuestionsMutation(
                    reviewTemplateFormValues.privateManagerQuestions,
                    'PRIVATE_MANAGER_QUESTION',
                    reviewTemplateFormValues.includePrivateManagerQuestions,
                ),
                ...getPrivateQuestionsMutation(
                    reviewTemplateFormValues.privateEmployeeQuestions,
                    'PRIVATE_EMPLOYEE_QUESTION',
                    reviewTemplateFormValues.includePrivateEmployeeQuestions,
                ),
            ],
            includePreparationStep: reviewTemplateFormValues.includePreparationStep,
            managerPreparationAccess: !!(isManagerContributorType && reviewTemplateFormValues.managerPreparationAccess),
            employeePreparationAccess: !!(isManagerContributorType && isManagerPreparationAccess && reviewTemplateFormValues.employeePreparationAccess),
            allowManagerToSkipPreparationStep: !!(isManagerContributorType && reviewTemplateFormValues.allowManagerToSkipPreparationStep),
            includeValidationStep: reviewTemplateFormValues.includeValidationStep,
            includePrivateEmployeeQuestions: reviewTemplateFormValues.includePrivateEmployeeQuestions,
            includePrivateManagerQuestions: reviewTemplateFormValues.includePrivateManagerQuestions,
            emailTemplates: getInvitationEmailsContentMutation(reviewTemplateFormValues),
        };

        if (isTemplateUpdate) {
            await handleUpdateReviewTemplate(reviewTemplateMutation, templateId);
        } else {
            await handleCreateReviewTemplate(reviewTemplateMutation);
        }
    };

    const handleCreateReviewTemplate = async (reviewTemplateMutation: ReviewTemplateMutation) => {
        try {
            await createReviewTemplate(reviewTemplateMutation);
            navigate('/settings/reviews/templates');
        } catch (error) {
            handleError(error);
        }
    };

    const handleUpdateReviewTemplate = async (reviewTemplateMutation: ReviewTemplateMutation, templateId: number) => {
        try {
            await updateReviewTemplate(templateId, reviewTemplateMutation);
            navigate('/settings/reviews/templates');
        } catch (error) {
            handleError(error);
        }
    };

    // we need to create this custom function to set focus on the first error and use it in the case where we don't save the form so we click on next
    // In this case, the reactHookForm automatic focus on error is not triggered
    const setFocusOnFirstError: SubmitErrorHandler<ReviewTemplateFormType> = errors => {
        console.error(errors);
        const el = document.querySelector('.Mui-error, [data-error]');
        (el?.parentElement ?? el)?.scrollIntoView();
    };

    async function validateAndNextStep() {
        return formMethods.handleSubmit(
            () => {
                onNextStep();
            },
            errors => {
                setFocusOnFirstError(errors);
            },
        )();
    }

    const onSaveReorder = (step: STEPS_NAME, reorderItems: ReorderItem[]) => {
        let formKey: keyof ReviewTemplateFormType;
        switch (step) {
            case 'FORM':
                formKey = 'reviewBlocksForm';
                break;
            case 'POST_REVIEW_MANAGER_FEEDBACK':
                formKey = 'privateManagerQuestions';
                break;
            case 'POST_REVIEW_EMPLOYEE_FEEDBACK':
                formKey = 'privateEmployeeQuestions';
                break;
            default:
                throw new Error(`Unsupported step: ${step}`);
        }
        const formValues = formMethods.watch(formKey);
        const reorderedForms = reorderItems.map(reorderItem => {
            const form = formValues.find(item => item.localId === reorderItem.id);
            return {
                ...form,
                order: reorderItem.order + 1,
            };
        });

        switch (step) {
            case 'FORM':
                formMethods.setValue('reviewBlocksForm', reorderedForms as ReviewBlockForm[]);
                break;
            case 'POST_REVIEW_MANAGER_FEEDBACK':
                formMethods.setValue('privateManagerQuestions', reorderedForms as PrivateQuestionBlockForm[]);
                break;
            case 'POST_REVIEW_EMPLOYEE_FEEDBACK':
                formMethods.setValue('privateEmployeeQuestions', reorderedForms as PrivateQuestionBlockForm[]);
                break;
        }
    };

    const getReviewBlockFormItemName = (item: ReviewBlockForm): string => {
        switch (item.blockType) {
            case 'SECTION':
                return getLabelTranslation(item.sectionName);
            case 'QUESTION':
                return getLabelTranslation(item.questionName);
            case 'REVIEW_OBJECTIVES':
                return t('reviews_settings_page.review_template_form.reorder_modal_objectives');
            case 'NEW_OBJECTIVES':
                return t('reviews_settings_page.review_template_form.reorder_modal_create_new_objectives');
            case 'SKILLS':
                return t('reviews_settings_page.review_template_form.reorder_modal_skills');
        }
    };

    const getReorderItems = (step: STEPS_NAME): ReorderItem[] => {
        switch (step) {
            case 'ABOUT':
                return [];
            case 'FORM':
                return formMethods.watch('reviewBlocksForm').map(item => {
                    return {
                        id: item?.localId,
                        name: getReviewBlockFormItemName(item),
                        order: item?.order,
                        hierarchy: item.blockType === 'SECTION' ? 'primary' : 'secondary',
                    };
                });
            case 'POST_REVIEW_MANAGER_FEEDBACK':
                return formMethods.watch('privateManagerQuestions').map(item => {
                    return {
                        id: item?.localId,
                        name: getLabelTranslation(item?.questionName),
                        order: item?.order,
                    };
                });
            case 'POST_REVIEW_EMPLOYEE_FEEDBACK':
                return formMethods.watch('privateEmployeeQuestions').map(item => {
                    return {
                        id: item?.localId,
                        name: getLabelTranslation(item?.questionName),
                        order: item?.order,
                    };
                });
            default:
                return [];
        }
    };

    const openReorderModal = (currentStep: StepForm) => {
        const items = getReorderItems(currentStep.stepName as STEPS_NAME);
        setCurrentReorderItems(items);
    };

    const showReorderButton = (currentStep: StepForm) => {
        switch (currentStep?.stepName) {
            case 'FORM':
                return formMethods.watch('reviewBlocksForm').length > 1;
            case 'POST_REVIEW_MANAGER_FEEDBACK':
                return formMethods.watch('privateManagerQuestions').length > 1;
            case 'POST_REVIEW_EMPLOYEE_FEEDBACK':
                return formMethods.watch('privateEmployeeQuestions').length > 1;
            default:
                return false;
        }
    };

    return (
        <FormProvider {...formMethods}>
            <StepperForm
                steps={steps}
                useStepperValues={useStepperValues}
                onNextStep={validateAndNextStep}
                onSave={formMethods.handleSubmit(onSave, setFocusOnFirstError)}
            >
                <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={handleLanguageChange} />
                {showReorderButton(currentStep) && (
                    <Button variant={'outlined'} onClick={() => openReorderModal(currentStep)}>
                        {t('stepper.reorder')}
                    </Button>
                )}
            </StepperForm>
            <RouteLeavingGuard when={displayFormRouteLeavingGuard(formMethods?.formState)} />
            {!!currentReorderItems?.length && (
                <ReorderModal
                    open={true}
                    initialReorderItems={currentReorderItems}
                    onSave={reorderItems => {
                        onSaveReorder(currentStep?.stepName as STEPS_NAME, reorderItems);
                        setCurrentReorderItems([]);
                    }}
                    onClose={() => {
                        setCurrentReorderItems([]);
                    }}
                />
            )}
        </FormProvider>
    );
};
