import { FilePickerModal, TemporaryFile } from '@/components/file-picker/FilePicker';
import { PhotoPreview } from '@/components/photo-preview/PhotoPreview';
import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type UploadFileProps = {
    defaultImageUrl: string | undefined;
    onUpload: (upload: TemporaryFile) => void;
    onDelete: () => void;
    fileLabel?: string;
};

export const SurveyUploadFile: FC<UploadFileProps> = ({ defaultImageUrl, onDelete, fileLabel, onUpload }) => {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState<string>();
    const [openPhotoPreviewDialog, setOpenPhotoPreviewDialog] = useState<boolean>(false);

    const openPreviewDialog = () => {
        setOpenPhotoPreviewDialog(true);
    };

    const handleUpload = (temporaryFile: TemporaryFile) => {
        if (temporaryFile.preview) {
            setImageUrl(temporaryFile.preview);
        }
        onUpload(temporaryFile);
    };

    return (
        <>
            {!!defaultImageUrl && (
                <PhotoPreview
                    open={openPhotoPreviewDialog}
                    onClosePhotoPreview={() => {
                        setOpenPhotoPreviewDialog(false);
                    }}
                    imageUrl={defaultImageUrl}
                    onDeletePhoto={() => {
                        onDelete();
                        setOpenPhotoPreviewDialog(false);
                    }}
                />
            )}

            <Stack direction='row' gap={1}>
                <Stack justifyContent={'center'}>
                    {fileLabel && <Typography variant={'body2'}>{fileLabel}</Typography>}

                    <FilePickerModal onFilePicked={handleUpload}>
                        <Button>{t('folders.upload')}</Button>
                    </FilePickerModal>
                </Stack>

                {!imageUrl && !!defaultImageUrl && (
                    <Stack
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => openPreviewDialog()}
                    >
                        <img width={60} height={60} key={defaultImageUrl} src={defaultImageUrl} alt={'defaultImage'} />
                    </Stack>
                )}

                {imageUrl && <img width={60} height={60} key={imageUrl} src={imageUrl} alt='preview' />}
            </Stack>
        </>
    );
};
