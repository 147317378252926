import { FC } from 'react';
import { ImportExcel } from './import-excel-dialog/ImportExcel';
import { useParams } from 'react-router';
import { ImportType } from '@/domain/import/Import.model';

export const EmployeesResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.EMPLOYEE} fallbackUrl={'/settings/import/employees'} />;
};

export const EmployeesBasicInformationResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.BASIC_INFORMATION} fallbackUrl={'/settings/import/employees'} />;
};

export const JobsResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.JOB} fallbackUrl={'/settings/import/organization'} />;
};

export const JobFamiliesResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.JOB_FAMILY} fallbackUrl={'/settings/import/organization'} />;
};

export const CostCentersResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.COST_CENTER} fallbackUrl={'/settings/import/organization'} />;
};

export const DepartmentsResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.DEPARTMENT} fallbackUrl={'/settings/import/organization'} />;
};

export const PlanningPositionsResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.PLANNING_POSITION} fallbackUrl={'/settings/import/organization'} />;
};

export const EmployeesAddressesResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.ADDRESS} fallbackUrl={'/settings/import/employees'} />;
};

export const WorkingPatternResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.WORKING_PATTERN} fallbackUrl={'/settings/import/working_pattern'} />;
};

export const LeavesResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.LEAVE} fallbackUrl={'/settings/import/leaves'} />;
};

export const LeaveCorrectionResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.LEAVE_CORRECTION} fallbackUrl={'/settings/import/leaves'} />;
};
export const TimesheetsResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.TIMESHEET} fallbackUrl={'/settings/import/timesheets'} />;
};

export const ObjectiveResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.OBJECTIVE} fallbackUrl={'/settings/import/objectives'} />;
};

export const SkillResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.SKILL} fallbackUrl={'/settings/import/skills'} />;
};

export const TimesheetsAdjustmentsResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.TIMESHEET_ADJUSTMENT} fallbackUrl={'/settings/import/timesheets'} />;
};

export const CalendarResultsPage: FC = () => {
    return <ImportExcel importType={ImportType.CALENDAR_DAYS} fallbackUrl={'/settings/import/calendar_days'} />;
};

export const CustomSectionResultsPage: FC = () => {
    const sectionId = useParams().sectionId;
    return <ImportExcel importType={ImportType.CUSTOM_SECTION} sectionId={sectionId} fallbackUrl={'/settings/import/employees'} />;
};
