import { Survey } from '@/domain/survey/Survey.model';
import { UserLanguage } from '@/utils/language.util';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { SurveyAnswerForm, SurveyQuestionForm } from '../SurveyForm.util';

type Props = {
    activeStep: number;
    selectedLanguage: UserLanguage;
    isPreviewMode: boolean;
    token: string;
    onSubmit: (skipped: boolean) => void;
    survey?: Survey;
    onBack: () => void;
    questions?: SurveyQuestionForm[];
    answers: SurveyAnswerForm[];
    questionCount: number;
    onLanguageChange: (language: UserLanguage) => void;
};

export const EmployeeSurveyFooter: FC<Props> = ({
    activeStep,
    onBack,
    selectedLanguage,
    isPreviewMode,
    onSubmit,
    questions,
    answers,
    questionCount,
    onLanguageChange,
}) => {
    const { t } = useTranslation();

    const isAnswerAdded = (answer: SurveyAnswerForm | undefined) => {
        return !!answer?.textAnswer || !!answer?.items?.length;
    };

    const handleNext = () => {
        onSubmit(!isAnswerAdded(answers[activeStep - 1]));
    };

    const handleBack = () => {
        onBack();
    };

    const theme = useTheme();

    if (!questions) {
        return <></>;
    }

    const actionButtonStyle = {
        fontSize: theme.spacing(2),
        '& svg': {
            height: 20,
        },
    };
    const actionButtonTitleStyle = {
        fontSize: 14,
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    };

    return (
        <Box
            sx={{
                maxWidth: '100%',
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                display: 'flex',
                padding: '12px 24px',
                boxSizing: 'border-box',
                justifyContent: 'space-between',
                background: '#fff',
                '& .MuiMobileStepper-progress': {
                    width: '100%',
                    height: 16,
                    borderRadius: 10,
                    background: 'transparent',
                    '& .MuiLinearProgress-barColorPrimary': {
                        backgroundColor: '#ACE588',
                    },
                },
                [theme.breakpoints.down('lg')]: {
                    position: 'fixed',
                },
            }}
        >
            {activeStep === 0 ? (
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: theme.spacing(2),
                    }}
                >
                    <TranslationLanguageSelector translationLanguage={selectedLanguage} onLanguageChange={onLanguageChange} />
                </Grid>
            ) : (
                <Button onClick={handleBack} disabled={activeStep === 0} sx={actionButtonStyle}>
                    <ArrowLeft01Icon />
                    <span style={actionButtonTitleStyle}>{t('anonymous_surveys.previous')}</span>
                </Button>
            )}
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    maxWidth: 640,
                    alignItems: 'center',
                }}
            >
                <Grid item>
                    <Typography
                        sx={{
                            marginRight: 8,
                            fontSize: 12,
                            color: '#000A1D',
                            [theme.breakpoints.down('lg')]: {
                                display: 'none',
                            },
                        }}
                    >
                        {' '}
                        {questionCount - questions.length + activeStep} / {questionCount}{' '}
                    </Typography>
                </Grid>
                <MobileStepper
                    variant='progress'
                    steps={questions ? questions.length + 1 : 0}
                    position='static'
                    activeStep={activeStep}
                    sx={{
                        maxWidth: 640,
                        flexGrow: 1,
                        background: '#F8F8F7',
                        borderRadius: 10,
                        [theme.breakpoints.down('lg')]: {
                            marginRight: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                        },
                    }}
                    nextButton={<></>}
                    backButton={<></>}
                />
            </Box>

            <Button
                size='small'
                variant='contained'
                color='primary'
                onClick={handleNext}
                disabled={isPreviewMode && activeStep === answers.length}
                sx={actionButtonStyle}
            >
                <span style={actionButtonTitleStyle}>
                    {activeStep === 0 || isAnswerAdded(answers[activeStep - 1]) ? <>{t('anonymous_surveys.next')}</> : <>{t('anonymous_surveys.skip')}</>}
                </span>
                <ArrowRight01Icon />
            </Button>
        </Box>
    );
};
