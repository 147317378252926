import { Page } from '@/page/Pages';

export const individualObjectivePage = {
    labelKey: 'pages.individual_objectives',
    path: '/objectives/individual',
};

export const departmentObjectivePage = {
    labelKey: 'pages.department_objectives',
    path: '/objectives/department',
};

export const getObjectivesPages = (): Page[] => {
    return [individualObjectivePage, departmentObjectivePage];
};
