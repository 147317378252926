import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Stack } from '@mui/material';
import * as yup from 'yup';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { TimeFilterType, TimeRangeType } from '@/components/filters-bar/FilterBar.type';

export const TimeFilter: FC<{ filter: TimeFilterType; onFilterUpdated: (filter: TimeFilterType) => void }> = ({ filter, onFilterUpdated }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<TimeFilterValues>({
        defaultValues: {
            start: filter.value?.[0],
            end: filter.value?.[1],
        },
        resolver: yupResolver(getTimeFilterSchema()),
    });

    const onSubmit = (values: TimeFilterValues) => {
        const timeRange: TimeRangeType | undefined = values.start || values.end ? [values.start, values.end] : undefined;
        const updatedFilter = { ...filter, value: timeRange };
        onFilterUpdated(updatedFilter);
    };

    return (
        <Stack gap={2} p={1}>
            <Stack gap={2} direction={'row'} alignItems={'flex-start'}>
                <FormControlLabel label={t('general.start')} control={<FieldTime name={'start'} control={control} sx={{ flex: 1 }} />} sx={{ flex: 1 }} />

                <FormControlLabel label={t('general.end')} control={<FieldTime name={'end'} control={control} sx={{ flex: 1 }} />} sx={{ flex: 1 }} />
            </Stack>
            <Button onClick={handleSubmit(onSubmit, console.error)} variant={'text'} sx={{ alignSelf: 'end' }}>
                {t('general.submit')}
            </Button>
        </Stack>
    );
};

const getTimeFilterSchema = () => {
    const timeSchema = yup.string<LocalTime>().required();

    return yup.object().shape({
        start: timeSchema,
        end: timeSchema,
    });
};
type TimeFilterValues = yup.InferType<ReturnType<typeof getTimeFilterSchema>>;
