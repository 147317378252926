import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { getLocations } from '@/domain/location/Location.service';
import { getDepartmentNodes } from '@/domain/department/Department.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getJobs } from '@/domain/job/Job.service';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.utils';

export const useTimesheetWeeklyInsightsTableFilters = (): { filters: FilterType[] } => {
    const filters: FilterType[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'locations',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'tree-multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departmentsNodes = await getDepartmentNodes();
                return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
            },
            key: 'departments',
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'jobs',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'managers',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
