import { mapRatingScale } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackForm.util';
import {
    EmployeeReviewFeedbackFormType,
    EmployeeReviewSummaryFeedbackItemFormType,
    EmployeeReviewSummaryFeedbackObjectiveFormType,
    EmployeeReviewSummaryFeedbackQuestionFormType,
    EmployeeReviewSummaryFeedbackSectionFormType,
    EmployeeReviewSummaryFeedbackSkillQuestionFormType,
    EmployeeReviewSummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewSummaryItem } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { getLabelTranslation } from '@/utils/language.util';
import {
    isReviewTemplateItemNewObjectives,
    isReviewTemplateItemObjectives,
    isReviewTemplateItemPrivateEmployeeQuestion,
    isReviewTemplateItemPrivateManagerQuestion,
    isReviewTemplateItemQuestion,
    isReviewTemplateItemSection,
    isReviewTemplateItemSkill,
    isReviewTemplateItemSkills,
} from '@/domain/review-template/ReviewTemplate.service';
import { ReviewFeedback } from '@/domain/review/Review.model';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { EmployeeReviewFeedbackNewObjectiveFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';

const mapReviewFeedback = (reviewFeedback: ReviewFeedback): EmployeeReviewFeedbackFormType => {
    return {
        score: reviewFeedback.score,
        comment: reviewFeedback.comment,
        reviewer: {
            firstName: reviewFeedback.reviewer?.firstName ?? '',
            lastName: reviewFeedback.reviewer?.lastName ?? '',
            displayName: reviewFeedback.reviewer?.displayName ?? '',
            avatarImageUrl: reviewFeedback.reviewer?.avatarImageUrl ?? '',
            id: reviewFeedback.reviewer?.id ?? 0,
            email: reviewFeedback.reviewer?.email ?? '',
        },
    };
};

export const mapEmployeeReviewSummaryFeedbackItem = (
    employeeReviewSummaryFeedbackItem: EmployeeReviewSummaryItem,
): EmployeeReviewSummaryFeedbackItemFormType => {
    switch (employeeReviewSummaryFeedbackItem.type) {
        case 'SECTION':
            return mapEmployeeReviewSummaryFeedbackSectionForm(employeeReviewSummaryFeedbackItem);
        case 'SKILL':
            return mapEmployeeReviewSummaryFeedbackSkillQuestionForm(employeeReviewSummaryFeedbackItem);
        case 'REVIEW_OBJECTIVES':
            return mapEmployeeReviewSummaryFeedbackObjectiveForm(employeeReviewSummaryFeedbackItem);
        case 'NEW_OBJECTIVES':
            return mapEmployeeReviewSummaryNewObjectiveForm(employeeReviewSummaryFeedbackItem);
        case 'SKILLS':
            return mapEmployeeReviewSummaryFeedbackSkillsForm(employeeReviewSummaryFeedbackItem);
        case 'QUESTION':
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
            return mapEmployeeReviewSummaryFeedbackQuestionForm(employeeReviewSummaryFeedbackItem);
        default:
            throw new Error(`Unsupported item type: ${employeeReviewSummaryFeedbackItem.type}`);
    }
};

const mapEmployeeReviewSummaryFeedbackSectionForm = (item: EmployeeReviewSummaryItem): EmployeeReviewSummaryFeedbackSectionFormType => {
    if (!isReviewTemplateItemSection(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        order: item.order,
        title: item?.employeeReviewFeedbackSectionSummary?.title ?? createDefaultLabel(),
        type: 'SECTION',
    };
};

const mapEmployeeReviewSummaryFeedbackSkillQuestionForm = (item: EmployeeReviewSummaryItem): EmployeeReviewSummaryFeedbackSkillQuestionFormType => {
    if (!isReviewTemplateItemSkill(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        required: item.required,
        id: item.id,
        description: getLabelTranslation(item.employeeReviewFeedbackSkillQuestionSummary?.skill?.description),
        order: item.order,
        rating: item.rating ? mapRatingScale(item.rating) : undefined,
        selfFeedbackQuestion: item.employeeReviewFeedbackSkillQuestionSummary?.self
            ? mapReviewFeedback(item.employeeReviewFeedbackSkillQuestionSummary?.self)
            : undefined,
        managerFeedbackQuestion: (item.employeeReviewFeedbackSkillQuestionSummary?.managers ?? []).map(mapReviewFeedback),
        upwardFeedbackQuestion: (item.employeeReviewFeedbackSkillQuestionSummary?.upwards ?? []).map(mapReviewFeedback),
        peerFeedbackQuestion: (item.employeeReviewFeedbackSkillQuestionSummary?.peers ?? []).map(mapReviewFeedback),
        score: item.employeeReviewFeedbackSkillQuestionSummary?.summaryScore,
        comment: item.employeeReviewFeedbackSkillQuestionSummary?.summaryComment ?? '',
        type: 'SKILL',
        title: getLabelTranslation(item.employeeReviewFeedbackSkillQuestionSummary?.skill?.name),
    };
};

const mapEmployeeReviewSummaryFeedbackObjectiveForm = (item: EmployeeReviewSummaryItem): EmployeeReviewSummaryFeedbackObjectiveFormType => {
    if (!isReviewTemplateItemObjectives(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'REVIEW_OBJECTIVES',
        order: item.order,
    };
};

const mapEmployeeReviewSummaryNewObjectiveForm = (item: EmployeeReviewSummaryItem): EmployeeReviewFeedbackNewObjectiveFormType => {
    if (!isReviewTemplateItemNewObjectives(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'NEW_OBJECTIVES',
        order: item.order,
    };
};

const mapEmployeeReviewSummaryFeedbackSkillsForm = (item: EmployeeReviewSummaryItem): EmployeeReviewSummaryFeedbackSkillsFormType => {
    if (!isReviewTemplateItemSkills(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'SKILLS',
        rating: item.rating ? mapRatingScale(item.rating) : undefined,
        minSkills: item.employeeReviewFeedbackSkillsSummary?.minSkills ?? undefined,
        maxSkills: item.employeeReviewFeedbackSkillsSummary?.maxSkills ?? undefined,
        allSkillsRequired: item.employeeReviewFeedbackSkillsSummary?.allSkillsRequired ?? false,
        instruction: item.employeeReviewFeedbackSkillsSummary?.instruction ?? createDefaultLabel(),
        order: item.order,
        skills: (item.employeeReviewFeedbackSkillsSummary?.skills ?? [])
            .sort((a, b) => a.skill.category.order - b.skill.category.order)
            .map(skill => ({
                score: skill.summaryScore ?? undefined,
                comment: skill.summaryComment ?? '',
                skill: {
                    id: skill.skill.id,
                    name: getLabelTranslation(skill.skill.name),
                    description: getLabelTranslation(skill.skill.description),
                    levels: skill.skill.category.levels.map(level => ({
                        id: level.id,
                        description: getLabelTranslation(level.description) ?? '',
                        score: level.score,
                        commentRequired: level.commentRequired,
                        name: getLabelTranslation(level.name),
                    })),
                },
                selfFeedbackQuestion: skill.self ? mapReviewFeedback(skill.self) : undefined,
                managerFeedbackQuestion: (skill.managers ?? []).map(mapReviewFeedback),
                upwardFeedbackQuestion: (skill.upwards ?? []).map(mapReviewFeedback),
                peerFeedbackQuestion: (skill.peers ?? []).map(mapReviewFeedback),
                category: {
                    id: skill.skill.category.id,
                    name: getLabelTranslation(skill.skill.category.name),
                },
            })),
    };
};

const mapEmployeeReviewSummaryFeedbackQuestionForm = (item: EmployeeReviewSummaryItem): EmployeeReviewSummaryFeedbackQuestionFormType => {
    if (
        !isReviewTemplateItemQuestion(item.type) &&
        !isReviewTemplateItemPrivateEmployeeQuestion(item.type) &&
        !isReviewTemplateItemPrivateManagerQuestion(item.type)
    ) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
        /* The response to the question is mandatory in the manager-summary only if both the 'required' checkbox and the 'manager' checkbox are checked in the template.
           This ensures that questions intended only for the employee are not mandatory for the manager, reducing unnecessary input for managers. */
        required:
            item.required &&
            (item.contributorTypes.includes('MANAGER') || item.type === 'PRIVATE_MANAGER_QUESTION' || item.type === 'PRIVATE_EMPLOYEE_QUESTION'),
        order: item.order,
        id: item.id,
        // todo : complete the description, the backend should return this value
        description: '',
        score: item.employeeReviewFeedbackQuestionSummary?.summaryScore ?? undefined,
        comment: item.employeeReviewFeedbackQuestionSummary?.summaryComment ?? '',
        title: item.employeeReviewFeedbackQuestionSummary?.title ?? createDefaultLabel(),
        instruction: item.employeeReviewFeedbackQuestionSummary?.instruction ?? createDefaultLabel(),
        selfFeedbackQuestion: item.employeeReviewFeedbackQuestionSummary?.self
            ? mapReviewFeedback(item.employeeReviewFeedbackQuestionSummary?.self)
            : undefined,
        managerFeedbackQuestion: (item.employeeReviewFeedbackQuestionSummary?.managers ?? []).map(mapReviewFeedback),
        upwardFeedbackQuestion: (item.employeeReviewFeedbackQuestionSummary?.upwards ?? []).map(mapReviewFeedback),
        peerFeedbackQuestion: (item.employeeReviewFeedbackQuestionSummary?.peers ?? []).map(mapReviewFeedback),
        type: item.type,
    };
};
