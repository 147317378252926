import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { ShiftBreak, ShiftBreakMutation, ShiftBreakType } from '@/domain/shift-break/ShiftBreak.model';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { handleError } from '@/utils/api.util';
import { createShiftBreak, updateShiftBreak } from '@/domain/shift-break/ShiftBreak.service';

type Props = {
    onSave: () => void;
    shiftBreak: ShiftBreak | undefined;
} & DialogWrapperProps;

const shiftBreakSchema = yup.object().shape({
    breakTime: yup.number().required().min(1).max(600), //more than 10 hours break seems like an error
    breakAt: yup.string<LocalTime>().nullable(),
    //TODO RP-6212 implement shiftBreakType when we have paid/unpaid breaks working
});

export type ShiftBreakFormValues = yup.InferType<typeof shiftBreakSchema>;

export const ShiftBreakDialog: FC<Props> = props => {
    const { t } = useTranslation();

    const { onSave, shiftBreak, ...restProps } = props;

    const { control, handleSubmit } = useForm<ShiftBreakFormValues>({
        resolver: yupResolver(shiftBreakSchema),
        defaultValues: {
            breakTime: shiftBreak ? shiftBreak.breakTime : 60,
            breakAt: shiftBreak ? shiftBreak.breakAt : '12:00',
        },
    });

    const handleOnSave = async (data: ShiftBreakFormValues) => {
        const request: ShiftBreakMutation = {
            ...data,
            breakAt: data.breakAt ?? undefined,
            shiftBreakType: ShiftBreakType.UNPAID_BREAK, //TODO RP-6212 allow the use to change this when the BE is prepared
        };
        if (shiftBreak?.id) {
            await updateBreak(shiftBreak.id, request);
        } else {
            await createBreak(request);
        }
    };

    const updateBreak = async (id: number, request: ShiftBreakMutation) => {
        try {
            await updateShiftBreak(id, request);
            onSave();
        } catch (e) {
            handleError(e);
        }
    };

    const createBreak = async (request: ShiftBreakMutation) => {
        try {
            await createShiftBreak(request);
            onSave();
        } catch (e) {
            handleError(e);
        }
    };

    const title = shiftBreak ? t('shift_break_page.dialog.edit_title') : t('shift_break_page.dialog.add_title');

    return (
        <DialogWrapper header={title} {...restProps}>
            <Stack component={DialogContent} gap={2}>
                <FormControlLabel
                    label={t('shift_break_page.dialog.break_time')}
                    control={<FieldNumber name='breakTime' control={control} fullWidth min={1} max={600} precision={0} />}
                />
                <FormControlLabel
                    label={t('shift_break_page.dialog.break_at')}
                    control={<FieldTime name={'breakAt'} control={control} sx={{ width: '100%' }} />}
                    sx={{ width: '100%' }}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} fullWidth>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
