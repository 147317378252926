import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { EmptyStateIcon } from '@/components/empty-state/icons/EmptyStateIcon';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { ThirdPartyMapping, ThirdPartyMappingType } from '@/domain/third-party-mapping/ThirdPartyMapping.model';
import { createThirdPartyMapping, deleteThirdPartyMapping } from '@/domain/third-party-mapping/ThirdPartyMapping.service.';
import { useGetThirdPartyMappings } from '@/hooks/third-party-mapping/ThirdPartyMapping.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import {
    ThirdPartyMappingFormValues,
    ThirdPartyMappingSettingsDialog,
} from '@/page/setting/third-party-mapping/third-party-mapping-dialog/ThirdPartyMappingSettingsDialog';
import { ConfigType } from '@/page/setting/types';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ThirdPartyMappingSettingsPage: FC = () => {
    const { t } = useTranslation();

    const [thirdPartyMappingDialogOpen, setThirdPartyMappingDialogOpen] = useState<boolean>(false);
    const [thirdPartyMappingToEdit, setThirdPartyMappingToEdit] = useState<ThirdPartyMapping>();

    const { data: thirdParties = [], isLoading, isError, error: errorThirdParties, refetch: refetchThirdParties } = useGetThirdPartyMappings();

    const handleDelete = async (id: number) => {
        try {
            await deleteThirdPartyMapping(id);
            await refetchThirdParties();
        } catch (e) {
            handleError(e);
        }
    };

    const handleOnClose = () => {
        setThirdPartyMappingDialogOpen(false);
        setThirdPartyMappingToEdit(undefined);
    };

    const handleOnSave = async (data: ThirdPartyMappingFormValues) => {
        try {
            if (thirdPartyMappingToEdit?.id) {
                // do nothing, it's not possible to update
            } else {
                await createThirdPartyMapping({
                    mappingType: data.mappingType,
                    customListId: data.customList?.id,
                    sectionDefinitionId: data.sectionDefinition?.id,
                });
            }
            await refetchThirdParties();
            handleOnClose();
        } catch (e) {
            handleError(e);
        }
    };

    const getMenuItems = (params: ICellRendererParams<ThirdPartyMapping>) => {
        return [
            {
                title: t('general.delete'),
                onClick: () => params.data?.id && handleDelete(params.data.id),
                confirmationRequired: true,
            },
        ];
    };
    const cellActionRenderer = (params: ICellRendererParams<ThirdPartyMapping>) => <BasicMenu items={getMenuItems(params)} />;

    const columns: ColDef<ThirdPartyMapping>[] = [
        {
            field: 'mappingType',
            headerName: t('third_party_mapping_settings_page.table_headers.mapping_type'),
            valueGetter: ({ data }) => {
                const mappingType = t(`third_party_mapping.mapping_type`, { context: data?.mappingType });

                if (data?.mappingType === ThirdPartyMappingType.CUSTOM_LIST) {
                    return `${mappingType} / ${getLabelTranslation(data.customList?.name)}`;
                }

                if (data?.mappingType === ThirdPartyMappingType.SECTION_DEFINITION) {
                    return `${mappingType} / ${getLabelTranslation(data.sectionDefinition?.name)}`;
                }

                return mappingType;
            },
        },
        {
            type: 'actionMenu',
            cellRenderer: cellActionRenderer,
        },
    ];

    const config: ConfigType<ThirdPartyMapping> = {
        type: 'table',
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setThirdPartyMappingDialogOpen(true);
            },
        },
        isOnRowClickActive: true,
        table: {
            columns: columns,
        },
    };

    const emptyState = <EmptyState icon={<EmptyStateIcon />} flex={1} title={t('third_party_mapping_settings_page.empty')} />;

    return (
        <StateHandler
            isLoading={isLoading}
            isError={isError}
            error={errorThirdParties}
            // We cannot use empty state here because button to create new third party mapping is in child component
            // TODO: Improve CompanySettingsLayout to handle this case
            isEmpty={false}
            emptyStateComponent={emptyState}
        >
            <CompanySettingsLayout options={config} data={thirdParties} isSearchAvailable={true} />
            {thirdPartyMappingDialogOpen && (
                <ThirdPartyMappingSettingsDialog
                    open={thirdPartyMappingDialogOpen}
                    onSave={handleOnSave}
                    closeDialog={handleOnClose}
                    thirdPartyMapping={thirdPartyMappingToEdit}
                />
            )}
        </StateHandler>
    );
};
