import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import {
    EmployeeWorkingPattern,
    EmployeeWorkingPatternSearchRequest,
    EmployeeWorkingPatternTemplateCreationMutation,
    EmployeeWorkingPatternUpdateMutation,
} from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { AxiosResponse } from 'axios';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';

const searchEmployeeWorkingPatterns = async (request: EmployeeWorkingPatternSearchRequest): Promise<EmployeeWorkingPattern[]> => {
    const url = API_BASE_URL + '/employee/working-patterns/search';
    return (await client.post<EmployeeWorkingPatternDTO[], AxiosResponse<EmployeeWorkingPatternDTO[]>, EmployeeWorkingPatternSearchRequest>(url, request)).data;
};

const createEmployeeWorkingPattern = async (mutation: EmployeeWorkingPatternTemplateCreationMutation): Promise<EmployeeWorkingPattern> => {
    const url = API_BASE_URL + `/employee/working-patterns`;

    return (
        await client.post<EmployeeWorkingPatternDTO, AxiosResponse<EmployeeWorkingPatternDTO>, EmployeeWorkingPatternTemplateCreationMutationDTO>(url, mutation)
    ).data;
};

const updateEmployeeWorkingPattern = async (id: number, mutation: EmployeeWorkingPatternUpdateMutation): Promise<EmployeeWorkingPattern> => {
    const url = API_BASE_URL + `/employee/working-patterns/${id}`;

    return (await client.put<EmployeeWorkingPatternDTO, AxiosResponse<EmployeeWorkingPatternDTO>, EmployeeWorkingPatternUpdateMutationDTO>(url, mutation)).data;
};

const deleteEmployeeWorkingPattern = (id: number): Promise<void> => {
    const url = API_BASE_URL + `/employee/working-patterns/${id}`;
    return client.delete(url);
};

const importEmployeeWorkingPattern = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/employee/working-patterns/import`, formData)).data;
};

export const employeeWorkingPatternAPI = {
    searchEmployeeWorkingPatterns,
    createEmployeeWorkingPattern,
    updateEmployeeWorkingPattern,
    deleteEmployeeWorkingPattern,
    importEmployeeWorkingPattern,
};

type EmployeeWorkingPatternDTO = EmployeeWorkingPattern;

type EmployeeWorkingPatternTemplateCreationMutationDTO = EmployeeWorkingPatternTemplateCreationMutation;

type EmployeeWorkingPatternUpdateMutationDTO = EmployeeWorkingPatternUpdateMutation;
