import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldText } from '@/components/form/field-text/FieldText';
import { Label } from '@/domain/label/Label.model';
import { LeaveReportType, Option, REPORT_CATEGORIES, ReportCategory, ReportCreateMutation, ReportType } from '@/domain/report/Report.model';
import { AVAILABLE_REPORT_CATEGORY, getReportCustomTables } from '@/domain/report/Report.service';
import { searchReviews } from '@/domain/review/Review.service';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type ReportDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    onSave: (report: ReportCreateMutation) => void;
    reportCategory: ReportCategory;
};

export const ReportDialog: FC<ReportDialogProps> = ({ onSave, reportCategory, ...rest }) => {
    const { t } = useTranslation();

    const form = useForm<ReportFormValues>({
        resolver: getResolver(reportCategory),
        defaultValues: {
            category: reportCategory as ReportFormValues['category'],
            includeHistory: false,
            matrixView: false,
        },
    });

    const { handleSubmit, control } = form;

    const handleSave = (values: ReportFormValues) => {
        const request = formToDomainCreation(values);
        onSave(request);
    };

    return (
        <FormProvider {...form}>
            <DialogWrapper header={t('reports.report_dialog.new_report')} {...rest}>
                <Stack gap={2} component={DialogContent}>
                    <FormControlLabel label={t('general.title')} control={<FieldText name='title' control={control} defaultValue='' autoFocus fullWidth />} />

                    {reportCategory === 'REPORT_EMPLOYEE' && <EmployeeReportForm />}
                    {reportCategory === 'REPORT_REVIEW' && <ReviewReportForm />}
                    {reportCategory === 'REPORT_LEAVE' && <LeaveReportForm />}
                    {reportCategory === 'REPORT_TIMESHEET' && <TimesheetReportForm />}
                    {reportCategory === 'REPORT_OBJECTIVE' && <ObjectiveReportForm />}
                    {reportCategory === 'REPORT_LONG_LEAVE' && <LongLeaveReportForm />}
                </Stack>
                <DialogActions>
                    <Button onClick={handleSubmit(handleSave, console.error)} fullWidth>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
        </FormProvider>
    );
};

const getResolver = (reportCategory: (typeof AVAILABLE_REPORT_CATEGORY)[number]): Resolver<ReportFormValues> => {
    switch (reportCategory) {
        case 'REPORT_REVIEW':
            return yupResolver<ReportFormValues>(getReviewReportSchema());
        case 'REPORT_LEAVE':
            return yupResolver<ReportFormValues>(getLeaveReportSchema());
        case 'REPORT_TIMESHEET':
            return yupResolver<ReportFormValues>(getTimesheetReportSchema());
        case 'REPORT_OBJECTIVE':
            return yupResolver<ReportFormValues>(getObjectiveReportSchema());
        case 'REPORT_LONG_LEAVE':
            return yupResolver<ReportFormValues>(getLongLeaveReportSchema());
        case 'REPORT_EMPLOYEE':
        default:
            return yupResolver<ReportFormValues>(getEmployeeReportSchema());
    }
};

type CommonFormValues = {
    title: string;
    includeHistory: boolean;
    matrixView: boolean;
};

type EmployeeReportFormValues = CommonFormValues & {
    category: 'REPORT_EMPLOYEE';
    type: {
        reportType: ReportType;
        sectionDefinitionId?: number;
    };
};

type LeaveReportFormValues = CommonFormValues & {
    category: 'REPORT_LEAVE';
    type: 'LEAVE_TYPE_POLICY_REPORT' | 'LEAVE_CORRECTION_REPORT';
};

type LongLeaveReportFormValues = CommonFormValues & {
    category: 'REPORT_LONG_LEAVE';
    type: 'LONG_LEAVE_REPORT';
};

type TimesheetReportFormValues = CommonFormValues & {
    category: 'REPORT_TIMESHEET';
    type: ReportType;
};

type ObjectiveReportFormValues = CommonFormValues & {
    category: 'REPORT_OBJECTIVE';
    type: ReportType;
};

type ReviewReportFormValues = CommonFormValues & {
    category: 'REPORT_REVIEW';
    type: 'REVIEW_FEEDBACK_REPORT';
    review: Option;
};

type ReportFormValues =
    | EmployeeReportFormValues
    | LeaveReportFormValues
    | ReviewReportFormValues
    | TimesheetReportFormValues
    | ObjectiveReportFormValues
    | LongLeaveReportFormValues;

const commonSchema = {
    title: yup.string().required(),
    includeHistory: yup.boolean().required(),
    matrixView: yup.boolean().required(),
};

const getEmployeeReportSchema = (): yup.ObjectSchema<EmployeeReportFormValues> => {
    const employeeReportTypes: ReportType[] = [...REPORT_CATEGORIES['REPORT_EMPLOYEE']];
    return yup.object<EmployeeReportFormValues>().shape({
        ...commonSchema,
        category: yup
            .string()
            .required()
            .oneOf(['REPORT_EMPLOYEE'] as const),
        type: yup
            .object()
            // Set default undefined to check required validation before nested object validation
            .default(undefined)
            .required()
            .shape({
                reportType: yup.string().required().oneOf(employeeReportTypes),
                sectionDefinitionId: yup.number(),
            }),
    });
};

const getReviewReportSchema = (): yup.ObjectSchema<ReviewReportFormValues> => {
    return yup.object<ReviewReportFormValues>().shape({
        ...commonSchema,
        category: yup
            .string()
            .required()
            .oneOf(['REPORT_REVIEW'] as const),
        type: yup
            .string()
            .required()
            .oneOf(['REVIEW_FEEDBACK_REPORT'] as const),
        review: yup
            .object()
            .shape({
                value: yup.string().required(),
                label: yup.string().required(),
            })
            .required(),
    });
};

const getLeaveReportSchema = (): yup.ObjectSchema<LeaveReportFormValues> => {
    return yup.object<LeaveReportFormValues>().shape({
        ...commonSchema,
        category: yup
            .string()
            .required()
            .oneOf(['REPORT_LEAVE'] as const),
        type: yup
            .string()
            .required()
            .oneOf(['LEAVE_TYPE_POLICY_REPORT', 'LEAVE_CORRECTION_REPORT'] satisfies LeaveReportType[]),
    });
};

const getTimesheetReportSchema = (): yup.ObjectSchema<TimesheetReportFormValues> => {
    return yup.object<TimesheetReportFormValues>().shape({
        ...commonSchema,
        category: yup
            .string()
            .required()
            .oneOf(['REPORT_TIMESHEET'] as const),
        type: yup
            .string()
            .required()
            .oneOf(Object.values(REPORT_CATEGORIES['REPORT_TIMESHEET']) satisfies ReportType[]),
    });
};

const getObjectiveReportSchema = (): yup.ObjectSchema<ObjectiveReportFormValues> => {
    return yup.object<ObjectiveReportFormValues>().shape({
        ...commonSchema,
        category: yup
            .string()
            .required()
            .oneOf(['REPORT_OBJECTIVE'] as const),
        type: yup
            .string()
            .required()
            .oneOf(Object.values(REPORT_CATEGORIES['REPORT_OBJECTIVE']) satisfies ReportType[]),
    });
};

const getLongLeaveReportSchema = (): yup.ObjectSchema<LongLeaveReportFormValues> => {
    return yup.object<LongLeaveReportFormValues>().shape({
        ...commonSchema,
        category: yup
            .string()
            .required()
            .oneOf(['REPORT_LONG_LEAVE'] as const),
        type: yup
            .string()
            .required()
            .oneOf(Object.values(REPORT_CATEGORIES['REPORT_LONG_LEAVE']) satisfies ReportType[]),
    });
};

const EmployeeReportForm: FC = () => {
    const { t } = useTranslation();
    const { control, watch, setValue, getValues } = useFormContext<EmployeeReportFormValues>();

    type EmployeeReportType = EmployeeReportFormValues['type'] & {
        sectionDefinitionName?: Label;
    };

    const defaultReportType: EmployeeReportType[] = Object.values(REPORT_CATEGORIES['REPORT_EMPLOYEE'])
        .filter(reportType => reportType !== 'EMPLOYEE_SECTION_REPORT')
        .map(reportType => ({
            reportType,
        }));
    const [reportTypesOptions, setReportTypesOptions] = useState<EmployeeReportType[]>(defaultReportType);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const type: EmployeeReportFormValues['type'] | undefined = watch('type');
    const isEmployeePersonalReportType = type?.reportType === 'EMPLOYEE_PERSONAL_REPORT';

    // If the report type is not EMPLOYEE_PERSONAL_REPORT,
    // includeHistory and matrixView are hidden and reset to false
    if (!isEmployeePersonalReportType) {
        const { includeHistory, matrixView } = getValues();
        if (includeHistory) {
            setValue('includeHistory', false);
        }
        if (matrixView) {
            setValue('matrixView', false);
        }
    }

    useEffect(() => {
        const convertToReportTypesOptions = (customTables: SectionDefinition[]): EmployeeReportType[] => {
            return customTables.map(table => ({
                reportType: 'EMPLOYEE_SECTION_REPORT' as ReportType,
                sectionDefinitionId: table.id,
                sectionDefinitionName: table.name,
            }));
        };
        getReportCustomTables()
            .then(customTables => {
                // append custom tables to the default report types
                setReportTypesOptions(reportTypesOptions => [...reportTypesOptions, ...convertToReportTypesOptions(customTables)]);
            })
            .catch(error => {
                // If an error occurs the user can create a new report, so we don't want to show the error state
                handleError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const getOptionLabel = (option: EmployeeReportType) => {
        if (option.reportType === 'EMPLOYEE_SECTION_REPORT') {
            return option.sectionDefinitionName ? getLabelTranslation(option.sectionDefinitionName) : '';
        }

        return t('reports.report_type.enum', {
            context: option.reportType,
            defaultValue: option.reportType,
        });
    };

    return (
        <>
            <FormControlLabel
                label={t('reports.report_type.label')}
                control={
                    <FieldSelect
                        name='type'
                        control={control}
                        loading={isLoading}
                        fullWidth
                        options={reportTypesOptions}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={(option, value) =>
                            option.reportType === value.reportType && option.sectionDefinitionId === value.sectionDefinitionId
                        }
                    />
                }
            />

            {isEmployeePersonalReportType && (
                <>
                    <FormControlLabel
                        label={t('reports.report_dialog.highlight_changes')}
                        labelPlacement='end'
                        control={<FieldSwitch name='includeHistory' control={control} />}
                    />

                    <FormControlLabel
                        label={t('reports.report_dialog.matrix_view')}
                        labelPlacement='end'
                        control={<FieldSwitch name='matrixView' control={control} />}
                    />
                </>
            )}
        </>
    );
};

const ReviewReportForm: FC = () => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext<ReviewReportFormValues>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [reviewOptions, setReviewOptions] = useState<Option[]>([]);

    useEffect(() => {
        searchReviews()
            .then(data =>
                setReviewOptions(
                    data?.map(review => ({
                        // review.id could not be null, it's an error in the Review type
                        value: review.id.toString(),
                        label: getLabelTranslation(review.name),
                    })),
                ),
            )
            .catch(error => {
                // If an error occurs the user can create a new report, so we don't want to show the error state
                handleError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    // There is only one type of report for reviews, so we set it by default
    // This avoid to handle default value in the parent form
    useEffect(() => {
        setValue('type', 'REVIEW_FEEDBACK_REPORT');
    }, [setValue]);

    return (
        <FormControlLabel
            label={t('reports.report_type.label')}
            control={<FieldSelect name='review' control={control} loading={isLoading} fullWidth options={reviewOptions} />}
        />
    );
};

const LeaveReportForm: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<LeaveReportFormValues>();

    const options = Object.values(REPORT_CATEGORIES['REPORT_LEAVE']);

    return (
        <FormControlLabel
            label={t('reports.report_type.label')}
            control={
                <FieldSelect
                    name='type'
                    control={control}
                    fullWidth
                    options={options}
                    getOptionLabel={option =>
                        t('reports.report_type.enum', {
                            context: option,
                            defaultValue: option,
                        })
                    }
                />
            }
        />
    );
};

const TimesheetReportForm: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<TimesheetReportFormValues>();

    const options = Object.values(REPORT_CATEGORIES['REPORT_TIMESHEET']);

    return (
        <FormControlLabel
            label={t('reports.report_type.label')}
            control={
                <FieldSelect
                    name='type'
                    control={control}
                    fullWidth
                    options={options}
                    getOptionLabel={option =>
                        t('reports.report_type.enum', {
                            context: option,
                            defaultValue: option,
                        })
                    }
                />
            }
        />
    );
};

const ObjectiveReportForm: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<TimesheetReportFormValues>();

    const options = Object.values(REPORT_CATEGORIES['REPORT_OBJECTIVE']);

    return (
        <FormControlLabel
            label={t('reports.report_type.label')}
            control={
                <FieldSelect
                    name='type'
                    control={control}
                    fullWidth
                    options={options}
                    getOptionLabel={option =>
                        t('reports.report_type.enum', {
                            context: option,
                            defaultValue: option,
                        })
                    }
                />
            }
        />
    );
};

const LongLeaveReportForm: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<TimesheetReportFormValues>();

    const options = Object.values(REPORT_CATEGORIES['REPORT_LONG_LEAVE']);

    return (
        <FormControlLabel
            label={t('reports.report_type.label')}
            control={
                <FieldSelect
                    name='type'
                    control={control}
                    fullWidth
                    options={options}
                    getOptionLabel={option =>
                        t('reports.report_type.enum', {
                            context: option,
                            defaultValue: option,
                        })
                    }
                />
            }
        />
    );
};

const formToDomainCreation = (values: ReportFormValues): ReportCreateMutation => {
    const { category, title: titleString, type } = values;

    const title = {
        translationEn: titleString,
        translationFr: titleString,
        translationDe: titleString,
        translationIt: titleString,
        translationEs: titleString,
        translationPt: titleString,
    };
    const common: Omit<ReportCreateMutation, 'reportType'> = {
        title,
        fields: [],
        filters: [],
        historyRange: [],
        includeHistory: values.includeHistory,
        groupByFields: [],
        displayMode: values.matrixView ? 'MATRIX' : 'TABLE',
        sorts: [],
    };
    if (category === 'REPORT_EMPLOYEE') {
        const employeeReportFormValuesCommon: ReportCreateMutation = {
            ...common,
            reportType: type.reportType,
        };
        if (type.reportType === 'EMPLOYEE_SECTION_REPORT') {
            return { ...employeeReportFormValuesCommon, sectionDefinitionId: type.sectionDefinitionId };
        }

        return employeeReportFormValuesCommon;
    } else if (category === 'REPORT_REVIEW') {
        return {
            ...common,
            reportType: type,
            reviewId: Number(values.review?.value),
        };
    } else if (category === 'REPORT_TIMESHEET' || category === 'REPORT_LEAVE' || category == 'REPORT_OBJECTIVE' || category == 'REPORT_LONG_LEAVE') {
        return {
            ...common,
            reportType: type,
        };
    }
    throw new Error('Not implemented');
};
