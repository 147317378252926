import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { ShiftBreak } from '@/domain/shift-break/ShiftBreak.model';
import { getShiftBreaks } from '@/domain/shift-break/ShiftBreak.service';
import { handleError } from '@/utils/api.util';

export const useGetShiftBreaks = (): UseQueryResult<ShiftBreak[]> => {
    const [shiftBreaks, setShiftBreaks] = useState<ShiftBreak[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchShiftBreaks = useCallback(async () => {
        try {
            const data = await getShiftBreaks();
            setShiftBreaks(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchShiftBreaks().catch(handleError);
    }, [fetchShiftBreaks]);

    return {
        data: shiftBreaks,
        setData: setShiftBreaks,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchShiftBreaks(),
    };
};
