import { searchEmployees } from '@/domain/employee/Employee.service';

import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getJobFamilies } from '@/domain/job-family/JobFamily.service';
import { searchSkillCategories } from '@/domain/skill-category/SkillCategory.service';
import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';

export type EmployeeSkillFilter = SelectFilter | AsyncSelectFilter;

/**
 * Hook to get the list of filter for employee
 */
export const useEmployeeSkillFilter = (): { filters: EmployeeSkillFilter[] } => {
    const filters: EmployeeSkillFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return searchEmployees().then(employees =>
                    employees?.map(employee => ({
                        label: employee.displayName,
                        value: employee.id,
                    })),
                );
            },
            key: 'assignee.id',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'departmentIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'locationIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB_FAMILY' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobFamilies = await getJobFamilies();
                return jobFamilies?.map(jobFamily => ({
                    label: jobFamily.name,
                    value: jobFamily.id,
                }));
            },
            key: 'jobFamilyIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'jobIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'SKILL_CATEGORY' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const skillCategories = await searchSkillCategories();
                return skillCategories?.map(skillCategory => ({
                    label: getLabelTranslation(skillCategory.name),
                    value: skillCategory.id,
                }));
            },
            key: 'skillCategoryIds',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
