import {
    EmployeeLeaveBalanceHistorySearchRequest,
    EmployeeLeaveTypeBalance,
    LeaveBalanceSearchRequest,
    LeaveTypeHistory,
} from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';

type LeaveTypeHistoryDTO = LeaveTypeHistory;
type EmployeeLeaveTypeBalanceDTO = Overwrite<EmployeeLeaveTypeBalance, { employee: EmployeeDTO }>;
type LeaveBalanceSearchRequestDTO = LeaveBalanceSearchRequest;
type EmployeeLeaveBalanceHistorySearchRequestDTO = Omit<EmployeeLeaveBalanceHistorySearchRequest, 'employeeId'>;

async function getLeaveTypeHistories(searchRequest: EmployeeLeaveBalanceHistorySearchRequest): Promise<LeaveTypeHistory[]> {
    const employeeId = searchRequest.employeeId;
    const url = API_BASE_URL + `/leave-balances/employee/${employeeId}/history`;
    const { data } = await client.post<LeaveTypeHistoryDTO[], AxiosResponse<LeaveTypeHistoryDTO[]>, EmployeeLeaveBalanceHistorySearchRequestDTO>(
        url,
        searchRequest,
    );
    return data;
}

async function getLeaveTypeHistory(employeeId: number, employeeLeaveTypeId: number): Promise<LeaveTypeHistory> {
    const url = API_BASE_URL + `/leave-balances/employee/${employeeId}/leave-types/${employeeLeaveTypeId}/history`;
    const { data } = await client.get<LeaveTypeHistoryDTO>(url);
    return data;
}

const mapLeaveBalanceDTO = (dto: EmployeeLeaveTypeBalanceDTO): EmployeeLeaveTypeBalance => {
    const { employee, ...restEmployeeLeaveTypeBalanceDTO } = dto;
    return {
        ...restEmployeeLeaveTypeBalanceDTO,
        employee: mapEmployeeDTO(employee),
    };
};

async function getLeaveBalance(leaveTypeId: number, searchRequest: LeaveBalanceSearchRequest): Promise<EmployeeLeaveTypeBalance[]> {
    const url = API_BASE_URL + `/leave-balances/leave-types/${leaveTypeId}`;
    const { data } = await client.post<EmployeeLeaveTypeBalanceDTO[], AxiosResponse<EmployeeLeaveTypeBalanceDTO[]>, LeaveBalanceSearchRequestDTO>(
        url,
        searchRequest,
    );
    return data.map(mapLeaveBalanceDTO);
}

export const leaveTypeHistoryApi = {
    getLeaveTypeHistories,
    getLeaveTypeHistory,
    getLeaveBalance,
};
